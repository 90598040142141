import store from "cmn-utils/lib/store";
import http from "http";
import { message } from "antd";
import buildUrl from "build-url";
import { BaseUrl } from "./constant";
import querystring from "querystring";
import { saveAs } from "file-saver";

message.config({
  maxCount: 5,
});
const antMessage = message;
/**
 * get请求
 * @param url 请求路径(\api)
 * @param params 请求参数
 * @returns Promise<any>
 */
function Get(url: string, params: any = undefined): Promise<any> {
  return new Promise((resolve, reject) => {
    let buildOptions: any = {
      path: url,
    };
    if (params) {
      buildOptions.queryParams = { ...params };
    }
    let sendUrl = buildUrl(BaseUrl, buildOptions);
    fetch(sendUrl, {
      method: "GET",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        ...store.getStore("headers"),
      },
    })
      .then((ret) => {
        const { status } = ret;
        if (status !== 200) {
          resolve(false);
          antMessage.error(`HttpError:${status}`);
          return undefined;
        }
        return ret.json();
      })
      .then((ret) => {
        if (ret) {
          try {
            resolve(afterResponse(ret));
          } catch (e: any) {
            antMessage.error(`HttpError: ${e.message}`);
            console.error(e.message);
          }
        }
      });
  });
}

function GetFile(
  url: string,
  params: any = undefined,
  getFileName: string = ``
): Promise<any> {
  return new Promise((resolve, reject) => {
    let buildOptions: any = {
      path: url,
    };
    if (params) {
      buildOptions.queryParams = { ...params };
    }
    let sendUrl = buildUrl(BaseUrl, buildOptions);

    let fileName = getFileName;
    fetch(sendUrl, {
      method: "GET",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        ...store.getStore("headers"),
      },
    })
      .then((ret) => {
        const { status } = ret;
        if (status !== 200) {
          resolve(false);
          antMessage.error(`HttpError:${status}`);
          return undefined;
        }
        const fileNameEncode =
          ret.headers.get(`content-disposition`) ?? ``.split("filename=")[1];
        if (!(fileName && fileName.length > 0)) {
          let key = `filename=`;
          if (fileNameEncode) {
            fileName = fileNameEncode.substring(
              fileNameEncode.indexOf(key) + key.length
            );
            fileName = decodeURI(fileName);
          }
        }
        return ret.blob();
      })
      .then((ret) => {
        if (ret) {
          saveAs(ret, fileName);
          resolve(true);
        }
      });
  });
}
/**
 * POST请求 数组类型值转换 目前只支持一层
 * @param params 请求参数
 */
function arrChange(params: any) {
  let arrKey: Array<string> = [];
  for (const key of Object.keys(params)) {
    //清理空数据
    if (params[key] === undefined || params[key] === null) {
      delete params[key];
    }
    if (
      params.hasOwnProperty(key) &&
      (params[key] instanceof Array || params[key] instanceof Object)
    ) {
      arrKey.push(key);
    }
  }
  for (const key of Object.keys(params)) {
    if (
      params.hasOwnProperty(key) &&
      (params[key] instanceof Array || params[key] instanceof Object)
    ) {
      arrKey.push(key);
    }
  }
  arrKey.forEach((key) => {
    let tempData = params[key];
    delete params[key];
    if (tempData instanceof Array) {
      let arrData = tempData as Array<any>;
      arrData.forEach((item, index) => {
        if (item instanceof Object) {
          for (const itemKey of Object.keys(item)) {
            if (item.hasOwnProperty(itemKey)) {
              params[`${key}[${index}].${itemKey}`] = item[itemKey];
            }
          }
        } else {
          params[`${key}[${index}]`] = item;
        }
      });
    } else if (tempData instanceof Object) {
      for (const itemKey of Object.keys(tempData)) {
        params[`${key}[${itemKey}]`] = tempData[itemKey];
      }
    }
  });
}
/**
 * post请求
 * @param url 请求路径
 * @param params 请求参数
 * @returns Promise<any>
 */
function Post(
  url: string,
  params: any = undefined,
  noToken: boolean = false
): Promise<any> {
  return new Promise((resolve, reject) => {
    let buildOptions: any = {
      path: url,
    };
    let sendUrl = buildUrl(BaseUrl, buildOptions);
    arrChange(params);
    let content = querystring.stringify(params);
    let token = noToken ? `` : store.getStore("headers");

    const options = {
      path: sendUrl,
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        "Cache-Control": `max-age=0`,
        ...token,
      },
    };
    let rep = http.request(options, (res: http.IncomingMessage) => {
      res.setEncoding("utf8");
      let str = ``;
      res.on("data", (chunk) => {
        str += chunk;
      });
      res.on("end", () => {
        resolve(afterResponse(JSON.parse(str)));
      });
    });
    rep.on("error", (e) => {
      antMessage.error(`HttpError: ${e.message}`);
    });
    rep.on("response", (response) => {
      if (response.statusCode !== 200) {
        resolve(false);
        antMessage.error(`HttpError:${response.statusCode}`);
      }
    });
    rep.on("timeout", () => {
      antMessage.error(`HttpError:timeout`);
    });
    rep.cork();
    rep.write(content);
    process.nextTick(() => {
      rep.uncork();
      rep.end();
    });
  });
}
/**
 * 对响应结果进行统一处理
 * @param bodyInfo 响应结果
 * @returns body
 */
function afterResponse(bodyInfo: any): any {
  const { code, body, message } = bodyInfo;
  if (!code) return bodyInfo;
  if (code === "OK") {
    return body !== null ? body : true;
  } else if (code === "UNAUTHORIZED") {
    window.location.href = `${window.location.origin}/#/account/login`;
    return null;
  } else if (code === "NOT_FOUND") {
    return bodyInfo;
  } else if (code === "FAILED") {
    antMessage.error(message);
    return null;
  } else {
    antMessage.error(message);
  }
}

const HttpUtils = { Get, Post, GetFile };
export { HttpUtils };
