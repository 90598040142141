import { ParamsId } from 'httpRequest/interface';
import { HttpUtils } from 'utils/http-utils'
import * as RoleInterface from './interface'
const BaseUrl = "/api/sys/role/";
const Role = {
    List: (params: { unitId: string, userId: string }): Promise<Array<RoleInterface.RoleInfo>> => {
        return HttpUtils.Get(BaseUrl + 'list', params);
    },
    Create: (params: RoleInterface.CreateParam): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'create', params);
    },
    Delete: (params: ParamsId): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'delete', params);
    },
    Update: (params: RoleInterface.UpdateParam): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update', params);
    },
    Get: (params: { unitId: string, userId: string }): Promise<RoleInterface.RoleInfo> => {
        return HttpUtils.Get(BaseUrl + 'get', params);
    },
}
export { Role, RoleInterface };