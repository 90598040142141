import React, {Component} from "react";
import {Badge, Button, List, Tabs, Tooltip} from "antd";
import ListFooter from "./ListFooter";
import AlarmModal from "../Modal/ModalAlarm";
import SystemModal from "../Modal/ModalSystem";
import InstallModal from "../Modal/ModalInstall";
import InfiniteScroll from "../InfiniteScroll";
import Icon from '../Icon';
import "./style/noticetab.less";
import {connect} from "dva";
import intl from 'react-intl-universal';
import DealModal from "../../routes/order/realtime/components/DealModal";
import $$ from "cmn-utils/lib";
import read from "@/assets/images/read.png";
import unread from "@/assets/images/unread.png";

const TabPane = Tabs.TabPane;
const ListItem = List.Item;
@connect(({global, loading}) => ({
    global,
    loading: loading.models.global,
}))
export default class NoticeTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: intl.get('message.tabs.setReadAll'),
            type: "Install",
            modalVisible: false,
            AlarmVisible: false,
            InstallVisible: false,
            NewInspectVisible: false,
            systemModal: false,
            orderDealVisible: false,
            orderDetail: {},
            noticePage: 1,
        };
        this.handleTabChange();
    }

    /**
     * 切换弹框的显示隐藏
     */
    handleSwitchModal(type, state) {
        if (state) {
            this.setState({
                Install: false,
                AlarmVisible: false,
                systemVisible: false
            });
            this.setState({[type + "Visible"]: true});
        } else {
            this.setState({[type + "Visible"]: false});
        }
    }

    /**
     * 通知条目被点击
     */
    handleItemClick(type, data, id, state) {
        this.handleSetRead(id, type, state);
        this.setState({type, data});
        this.handleSwitchModal(type, "open");
    }

    /**
     * 通知Tab切换
     */
    handleTabChange() {
        const {type} = this.state;
        this.props.dispatch({
            type: "global/getNotice", payload: {
                data: {type, readState: 'UnRead'}, resolve: (ret) => {
                    this.setState({noticePage: ret.pageNumber},)
                }
            }
        });
        this.setState({title: intl.get('message.tabs.setReadAll')});
    }

    loadMoreNotice = (page, callBack) => {
        const {type} = this.state;
        this.props.dispatch({
            type: "global/loadMoreNotice",
            payload: {
                data: {type, readState: 'UnRead', page},
                resolve: (ret) => {
                    this.setState({noticePage: ret.pageNumber}, () => callBack(ret))
                }
            }
        });

    }

    /**
     * 设置消息已读
     */
    handleSetRead(idList, type, state) {
        if (state !== 'Read') {
            this.props.dispatch({type: 'global/setRead', payload: {data: {idList, type, readState: 'UnRead'}}})
        }
    }

    /**
     * 批量设置已读
     */
    handleSetReadBatch(type) {
        this.props.dispatch({type: 'global/setReadBatch', payload: {data: {type, readState: 'UnRead'}}})
    }


    /** 获取工单详情 */
    handleCheckModal(id) {
        this.props.dispatch({
            type: "global/getOrderDetail",
            payload: {
                data: {alarmLogId: id},
                resolve: (orderDetail) => this.setState({orderDealVisible: true, orderDetail, alarmLogId: id})

            }
        });
    }

    /**
     * 提交工单处理
     */
    handleSubmitDeal(data) {
        this.props.dispatch({
            type: "global/dealOrder",
            payload: {data, resolve: () => this.setState({orderDealVisible: false})}
        });
    }

    render() {
        const {global} = this.props
        const {noticeList, unReadCount = {}} = global;
        const {
            countAlarm = 0, countInstall = 0, countNewInspect = 0, countNewWorkOrder = 0,
            countRecoverAlarm = 0, countSysMsg = 0, countUnInstall = 0,
        } = unReadCount
        const {
            title, type, data, noticePage,
            AlarmVisible,
            systemVisible,
            InstallVisible,
            orderDealVisible, orderDetail
        } = this.state;
        const alarmModalProps = {
            title: intl.get('message.tabs.alarm'),
            visible: AlarmVisible,
            data,
            onCancel: () => this.handleSwitchModal("Alarm"),
            footer: [
                <Button key="back" onClick={() => this.handleSwitchModal("Alarm")}>
                    {intl.get('button.close')}
                </Button>
            ]
        };
        const systemModalProps = {
            title: intl.get('message.tabs.system'),
            visible: systemVisible,
            data,
            onCancel: () => this.handleSwitchModal("system"),
            footer: [
                <Button key="back" onClick={() => this.handleSwitchModal("system")}>
                    {intl.get('button.close')}
                </Button>
            ]
        };
        const installModalProps = {
            title: intl.get('message.tabs.install'),
            visible: InstallVisible,
            data,
            onCancel: () => this.handleSwitchModal("Install"),
            footer: [
                <Button key="back" onClick={() => this.handleSwitchModal("Install")}>
                    {intl.get('button.close')}
                </Button>
            ]
        };


        const dealProps = {
            title: intl.get('order.modalName.deal'),
            visible: orderDealVisible,
            detail: orderDetail,
            onCancel: () => this.setState({orderDealVisible: false}),
            onOk: data => this.handleSubmitDeal(data)
        };
        return (
            <div className="notice-tab">
                <AlarmModal {...alarmModalProps} />
                <SystemModal {...systemModalProps} />
                <InstallModal {...installModalProps} />
                <DealModal {...dealProps} />
                <Tabs
                    onChange={type => this.setState({type}, this.handleTabChange)}
                    defaultActiveKey="Install"
                    className="notice-tab"
                >
                    <TabPane tab={<Badge count={countInstall}>&nbsp;&nbsp;报&nbsp;装&nbsp;通&nbsp;知&nbsp;&nbsp;</Badge>}
                             key="Install">

                        <InfiniteScroll loadMore={this.loadMoreNotice} page={noticePage}>
                            <List
                                className="list-box"
                                dataSource={noticeList}
                                renderItem={item => {
                                    const data = JSON.parse(item.data);
                                    // const data = item.data;
                                    return (
                                        <ListItem
                                            onClick={() =>
                                                this.handleItemClick(
                                                    "Install",
                                                    data,
                                                    item.id,
                                                    item.readState
                                                )
                                            }
                                        >
                                            <a className="notice-item">
                                                {/*<div className="item-left">*/}
                                                {/*<img*/}
                                                {/*alt=""*/}
                                                {/*src={item.readState === "UnRead" ? unread : read}*/}
                                                {/*/>*/}
                                                {/*</div>*/}
                                                {item.readState == "UnRead" ?
                                                    <Badge status='processing' style={{marginLeft: 10}}/> : ''}
                                                <div className="item-right">
                                                    {data.unitName}的{data.deviceSn}（{data.deviceName}
                                                    ）在{data.address}
                                                    {data.installLocation}报装了
                                                    <div className="time-box">{data.time}</div>
                                                </div>
                                            </a>
                                        </ListItem>
                                    );
                                }}
                            /></InfiniteScroll>
                    </TabPane>
                    <TabPane tab={<Badge count={countAlarm}>&nbsp;&nbsp;告&nbsp;警&nbsp;消&nbsp;息&nbsp;&nbsp;</Badge>}
                             key="Alarm">
                        <InfiniteScroll loadMore={this.loadMoreNotice} page={noticePage}>
                            <List
                                className="list-box"
                                dataSource={noticeList}
                                renderItem={item => {
                                    const data = JSON.parse(item.data);
                                    return (
                                        <ListItem
                                            onClick={() =>
                                                this.handleItemClick(
                                                    "Alarm",
                                                    data,
                                                    item.id,
                                                    item.readState
                                                )
                                            }
                                        >
                                            <a className="notice-item">
                                                {/*<div className="item-left">*/}
                                                {/*<img*/}
                                                {/*alt=""*/}
                                                {/*src={item.readState === "UnRead" ? unread : read}*/}
                                                {/*/>*/}
                                                {/*</div>*/}
                                                {item.readState == "UnRead" ?
                                                    <Badge status='processing' style={{marginLeft: 10}}/> : ''}
                                                <div className="item-right">
                                                    {data.content}
                                                    <div className="time-box">{data.time}
                                                        <a className="item-button" onClick={e => {
                                                            const data = JSON.parse(item.data)
                                                            this.handleSetRead(item.id, 'Alarm', 'UnRead')
                                                            this.handleCheckModal(data.alarmLogId);
                                                            e.stopPropagation()
                                                        }}>
                                                            {$$.getStore("permissions").includes("WORK_ORDER_HANDLE") ?
                                                                <Tooltip
                                                                    title={'进入工单'}
                                                                    placement="top"
                                                                    overlayClassName="numeric-input"><Icon
                                                                    type="arrow-right" antd/></Tooltip> : ''}
                                                        </a>
                                                    </div>
                                                </div>
                                            </a>
                                        </ListItem>
                                    );
                                }}
                            />
                        </InfiniteScroll>
                        {/*</InfiniteScroll>*/}
                    </TabPane>
                    {/*<TabPane tab={<Badge count={countNewWorkOrder}>{intl.get('message.tabs.order')}</Badge>}*/}
                    {/*key="NewWorkOrder">*/}
                    {/*<List*/}
                    {/*className="list-box"*/}
                    {/*dataSource={noticeList}*/}
                    {/*renderItem={item => {*/}
                    {/*const data = JSON.parse(item.data);*/}
                    {/*return (*/}
                    {/*<ListItem*/}
                    {/*onClick={() =>*/}
                    {/*this.handleItemClick(*/}
                    {/*"NewWorkOrder",*/}
                    {/*data,*/}
                    {/*item.id,*/}
                    {/*item.readState*/}
                    {/*)*/}
                    {/*}*/}
                    {/*>*/}
                    {/*<a className="notice-item">*/}
                    {/*<div className="item-left">*/}
                    {/*<img*/}
                    {/*alt=""*/}
                    {/*src={item.readState === "UnRead" ? unread : read}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*<div className="item-right">*/}
                    {/*您有一条编号{data.no}（{data.name}）的*/}
                    {/*{(() => {*/}
                    {/*if (data.type === "Alarm") {*/}
                    {/*return "告警";*/}
                    {/*} else if (data.type === "Repair") {*/}
                    {/*return "维修";*/}
                    {/*} else if (data.type === "Inspect") {*/}
                    {/*return "巡检";*/}
                    {/*} else {*/}
                    {/*return "-";*/}
                    {/*}*/}
                    {/*})()}*/}
                    {/*工单*/}
                    {/*<div className="time-box">{data.time}</div>*/}
                    {/*</div>*/}
                    {/*</a>*/}
                    {/*</ListItem>*/}
                    {/*);*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab={<Badge count={countNewInspect}>{intl.get('message.tabs.inspect')}</Badge>}*/}
                    {/*key="NewInspect">*/}
                    {/*<List*/}
                    {/*className="list-box"*/}
                    {/*dataSource={noticeList}*/}
                    {/*renderItem={item => {*/}
                    {/*const data = JSON.parse(item.data);*/}
                    {/*return (*/}
                    {/*<ListItem*/}
                    {/*onClick={() =>*/}
                    {/*this.handleItemClick(*/}
                    {/*"NewInspect",*/}
                    {/*data,*/}
                    {/*item.id,*/}
                    {/*item.readState*/}
                    {/*)*/}
                    {/*}*/}
                    {/*>*/}
                    {/*<a className="notice-item">*/}
                    {/*<div className="item-left">*/}
                    {/*<img*/}
                    {/*alt=""*/}
                    {/*src={item.readState === "UnRead" ? unread : read}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*<div className="item-right">*/}
                    {/*您有一条编号{data.no}（{data.name}）的*/}
                    {/*{(() => {*/}
                    {/*if (data.type === "Alarm") {*/}
                    {/*return "告警";*/}
                    {/*} else if (data.type === "Repair") {*/}
                    {/*return "维修";*/}
                    {/*} else if (data.type === "Inspect") {*/}
                    {/*return "巡检";*/}
                    {/*} else {*/}
                    {/*return "-";*/}
                    {/*}*/}
                    {/*})()}*/}
                    {/*工单*/}
                    {/*<div className="time-box">{data.time}</div>*/}
                    {/*</div>*/}
                    {/*</a>*/}
                    {/*</ListItem>*/}
                    {/*);*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*</TabPane>*/}
                    <TabPane tab={<Badge count={countSysMsg}>&nbsp;&nbsp;系&nbsp;统&nbsp;通&nbsp;知&nbsp;&nbsp;</Badge>}
                             key="SysMsg">
                        <InfiniteScroll loadMore={this.loadMoreNotice} page={noticePage}>
                            <List
                                className="list-box"
                                dataSource={noticeList}
                                renderItem={item => {
                                    const data = JSON.parse(item.data);
                                    return (
                                        <ListItem
                                            onClick={() =>
                                                this.handleItemClick(
                                                    "System",
                                                    data,
                                                    item.id,
                                                    item.readState
                                                )
                                            }
                                        >
                                            <a className="notice-item">
                                                <div className="item-left">
                                                    <img
                                                        alt=""
                                                        src={item.readState === "UnRead" ? unread : read}
                                                    />
                                                </div>
                                                <div className="item-right">
                                                    <p>{data.title}</p>
                                                    {data.content}
                                                    <div className="time-box">{data.time}</div>
                                                </div>
                                            </a>
                                        </ListItem>
                                    );
                                }}
                            /></InfiniteScroll>
                    </TabPane>
                </Tabs>
                <ListFooter
                    title={title}
                    type={type}
                    onSetReadBatch={type => this.handleSetReadBatch(type)}
                />
            </div>
        );
    }
}
