import React, { ReactElement, ReactNode } from "react";
import { Form, Input, Modal, TreeSelect } from "antd";
import styles from "./create-modal.module.less"
import classNames from "classnames";
import { FormStyle, InputStyle } from "components/common-styles/common-styles";
import { BaseFormModal } from "components/base-interface";
import { SelectUnitTableModal } from "../tree-select/unit-tree-select/select-unit-table-modal";
import { UnitInfoInterface } from "httpRequest/tokiot/sys/unit";
import { UnitTreeSelect } from "components/tree-select/unit-tree-select";
interface IProps extends Omit<BaseFormModal, `visible`> {
    titleName?: string;
    lableName?: string;
    visible?: boolean;
    nameErrorMessage?: string;
    name?: string;
    selectUnit?: boolean;
    onOk?: (values: any) => void;
    onlyType?: `Organs` | `Unit`;
    visibleChange?: (visible: boolean) => void;

}
interface IState {
    visible: boolean;
    unitVisible: boolean;
    selectUnit?: UnitInfoInterface.UnitInfo;
}

class CreateModalClass extends React.PureComponent<IProps, IState> {
    state: IState = {
        visible: false,
        unitVisible: false,
    }
    componentDidMount() {
    }
    onFinish = () => {
        const { onOk, visible, form } = this.props;
        const { selectUnit } = this.state;
        const { validateFieldsAndScroll } = form;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.info(values);
                if (`name` in values) {
                    let ret: any = { name: values.name, unitId: undefined };
                    if (`unitId` in values) {
                        ret.unitId = values.unitId;
                    }
                    onOk && onOk({ ...ret });
                    if (visible === undefined) {
                        this.visibleChange();
                    }
                }
            } else {
                return;
            }
        });
    }

    unitVisibleChange = () => {
        this.setState({ unitVisible: !this.state.unitVisible });
    }

    visibleChange = () => {
        const { visible: propsVisible, visibleChange } = this.props;
        const { visible: stateVisible } = this.state;
        let visible = propsVisible ?? stateVisible;
        this.setState({ visible: !visible }, () => { visibleChange && visibleChange(!visible); });
    }

    selectUnit = (unitInfo: UnitInfoInterface.UnitInfo) => {
        const { form } = this.props;
        const { setFields, setFieldsValue } = form;
        setFieldsValue({ unitId: unitInfo.name });
        this.setState({ selectUnit: unitInfo });
    }

    getModal = () => {
        const { visible: propsVisible, name, titleName, lableName, nameErrorMessage, form, onlyType, selectUnit } = this.props;
        const { visible: stateVisible, unitVisible } = this.state;
        const { getFieldDecorator } = form;
        let visible = propsVisible ?? stateVisible;
        return (
            <Modal
                destroyOnClose={true}
                afterClose={() => { form.resetFields(); }}
                centered
                mask={true}
                maskClosable={true}
                width={`400px`}
                title={titleName}
                visible={visible}
                onCancel={() => { this.visibleChange() }}
                onOk={() => { this.onFinish() }}
            >
                <div className={styles.unitGroupManage} >
                    {unitVisible && selectUnit ? <SelectUnitTableModal onlyType={onlyType} visible={unitVisible} onClose={this.unitVisibleChange} onOk={this.selectUnit} /> : ``}
                    <Form className={classNames(FormStyle, styles.form)}>
                        {selectUnit ?
                            <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label={`所属项目`}>
                                {getFieldDecorator('unitId', {
                                    rules: [{ required: true, message: nameErrorMessage ?? `请选择所属项目` }],
                                })(
                                    <UnitTreeSelect onlyType={`Unit`} />
                                )}
                            </Form.Item>
                            : ``}
                        <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} label={lableName ?? `名称`}>
                            {getFieldDecorator('name', {
                                initialValue: name ?? ``,
                                rules: [{ required: true, message: nameErrorMessage ?? `请填写${lableName ?? `名称`}` }],
                            })(
                                <Input className={InputStyle} ref={e => {
                                    e?.focus();
                                    e?.select();
                                }} />
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        )
    }

    render() {
        const { visible: propsVisible, children } = this.props;
        const { visible: stateVisible } = this.state;
        let visible = propsVisible ?? stateVisible;
        let last: any = undefined;
        let body: ReactNode = ``;
        if (children) {
            last = (children as ReactElement).props.onClick;
            body = React.cloneElement(children as ReactElement, { onClick: () => { last && last(); this.visibleChange() } });
        }
        return (
            <React.Fragment>
                {visible ? this.getModal() : ``}{body}
            </React.Fragment >
        );
    }
}
const CreateModal = Form.create<IProps>()(CreateModalClass);
export { CreateModal };

