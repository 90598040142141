import './style/index.less';
import Coming from './Coming';
import ScreenLock from './ScreenLock';
import P403 from './403';
import P404 from './404';
import P500 from './500';
import Result from './Result';

export {Coming, ScreenLock, Result, P403, P404, P500};

export default {
    Coming,
    ScreenLock,
    P403,
    P404,
    P500,
    Result
};
