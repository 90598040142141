import React, { PureComponent } from "react";
import defaultImg from "../../assets/images/pic-error.png";
import ImgPreview from "./ImgPreview";
class Img extends PureComponent {
  static defaultProps = {
    big: true,
    defaultImg: defaultImg,
  };

  constructor(proprs) {
    super(proprs);
    this.state = {
      src: defaultImg,
      bigImageVisible: false,
    };
    this.handleError = this.handleError.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClosePreview = this.handleClosePreview.bind(this);
  }

  componentDidMount() {
    const { src = "undefined" } = this.props;
    const s = src.split("=")[1];
    if (
      s &&
      s != undefined &&
      s != null &&
      s != "undefined" &&
      s != "null" &&
      s != "undefined "
    ) {
      this.setState({
        src: src,
      });
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { src = "undefined" } = nextProps;
    const s = src.split("=")[1];
    if (
      s &&
      s != undefined &&
      s != null &&
      s != "undefined" &&
      s != "null" &&
      s != "undefined "
    ) {
      this.setState({
        src: src,
      });
    }
  }

  handleError() {
    const { defaultImg } = this.props;
    this.setState({
      src: defaultImg,
    });
  }

  handleClick() {
    const { setWindowVisible, big } = this.props;
    if (!big) return;
    if (setWindowVisible) setWindowVisible(false);
    this.setState({
      bigImageVisible: true,
    });
  }

  handleClosePreview() {
    const { setWindowVisible } = this.props;
    this.setState({
      bigImageVisible: false,
    });
    if (setWindowVisible) setWindowVisible(true);
  }

  render() {
    const { src, bigImageVisible } = this.state;
    const { className, style } = this.props;
    return (
      <div>
        <img
          alt=""
          onClick={this.handleClick}
          className={className || {}}
          style={style || {}}
          src={src}
          onError={this.handleError}
        />
        <ImgPreview
          visible={bigImageVisible} // 是否可见
          onClose={this.handleClosePreview} // 关闭事件
          src={src} // 图片url
          // picKey={currentKey} // 下载需要的key，根据自己需要决定
          isAlwaysCenterZoom={false} // 是否总是中心缩放，默认false，若为true，每次缩放图片都先将图片重置回屏幕中间
          isAlwaysShowRatioTips={false} // 是否总提示缩放倍数信息，默认false，只在点击按钮时提示，若为true，每次缩放图片都会提示
        />
      </div>
    );
  }
}
export default ({ src, ...otherProps }) => (
  <Img key={src} src={src} {...otherProps} />
);
