import {
  DatePicker,
  Form,
  Input,
  Select,
  TimePicker,
  Icon,
  Button,
  Switch,
} from "antd";
import { DatePickerProps } from "antd/lib/date-picker/interface";
import { FormComponentProps } from "antd/lib/form";
import { InputProps } from "antd/lib/input";
import { SelectProps, SelectValue } from "antd/lib/select";
import { SwitchProps } from "antd/lib/switch";
import { TimePickerProps } from "antd/lib/time-picker";
import { TreeSelectProps } from "antd/lib/tree-select";
import { TreeNodeValue } from "antd/lib/tree-select/interface";
import classNames from "classnames";
import {
  DatePickerStyle,
  FormStyle,
  InputStyle,
  SelectDropdownStyle,
  SelectStyle,
} from "components/common-styles/common-styles";
import {
  AreaTreeSelect,
  AreaTreeSelectProps,
} from "components/tree-select/area-tree-select";
import {
  UnitTreeSelect,
  UnitTreeSelectProps,
} from "components/tree-select/unit-tree-select";
import moment from "moment";
import React, { ReactNode } from "react";
import { FieldData } from "utils/common-parameter";
import styles from "./index.module.less";
type itemType =
  | InputProps
  | (SelectProps<SelectValue> & {
      dict?: Array<{ code: string; codeName: string }>;
    })
  | UnitTreeSelectProps
  | AreaTreeSelectProps
  | DatePickerProps
  | TimePickerProps
  | SwitchProps;
export interface SearchItem {
  name: string;
  key: string;
  param: itemType & {
    type:
      | `input`
      | `select`
      | `unit-select`
      | `area-select`
      | `date-picker`
      | `time-picker`
      | `switch`;
  };
  width?: string;
  type?: `all` | `mini` | `normal`;
}

export interface SearchBarPoros {
  searchKey?: number;
  searchList?: Array<SearchItem>;
  onSearch: (data?: any) => void;
  additional?: ReactNode;
}
type IProps = FormComponentProps & SearchBarPoros & { className?: string };

interface IState {
  mini: boolean;
  size: number;
}
class SearchBarClass extends React.PureComponent<IProps, IState> {
  state: IState = {
    mini: true,
    size: 1,
  };
  thisFinish = false;
  barRef = React.createRef<HTMLDivElement>();
  componentDidMount() {
    let tableRect = this.barRef.current?.getClientRects()[0];
    let size = Math.floor((tableRect?.width ?? 0) / 350);
    if (size <= 0) {
      size = 1;
    }
    this.setState({ size });
  }
  componentDidUpdate(lastProps: SearchBarPoros) {
    if (lastProps.searchKey !== this.props.searchKey) {
      this.onFinish();
    }
  }

  onFinish = () => {
    const { searchList } = this.props;
    const { onSearch, form } = this.props;
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      for (const key of Object.keys(values)) {
        let item = searchList?.find((f1) => key === f1.key);
        if (
          item &&
          item.param.type === `date-picker` &&
          moment.isMoment(values[key])
        ) {
          values[key] = (values[key] as moment.Moment).format(`YYYY-MM-DD`);
        }
        if (
          item &&
          item.param.type === `time-picker` &&
          moment.isMoment(values[key])
        ) {
          values[key] = (values[key] as moment.Moment).format(
            `YYYY-MM-DD HH:mm:ss`
          );
        }
      }
      if (this.thisFinish) {
        this.thisFinish = false;
        onSearch({ ...values, page: 1 });
      } else {
        onSearch({ ...values });
      }
    });
  };

  formChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const { searchList } = this.props;
    if (changedFields.length > 0) {
      let field = changedFields[0];
      let item = searchList?.find((f1) =>
        Array.isArray(field.name)
          ? field.name.length > 0
            ? f1.key === field.name[0]
            : false
          : f1.key === field.name
      );
      if (
        item &&
        (item.param.type === `select` ||
          item.param.type === `unit-select` ||
          item.param.type === `area-select`)
      ) {
        this.thisFinish = true;
        this.onFinish();
      }
    }
  };

  getItem = (item: SearchItem) => {
    const { mini, size } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    switch (item.type) {
      case `all`:
        break;
      case `mini`:
        if (!mini) return ``;
        break;
      default:
        if (mini) return ``;
        break;
    }
    let control: ReactNode = undefined;
    let param: itemType;
    let className: String = ``;
    let style: React.CSSProperties = { width: mini ? item.width : `100%` };
    switch (item.param.type) {
      case `input`:
        param = item.param as InputProps;
        control = (
          <Input
            className={InputStyle}
            placeholder={`请输入` + item.name}
            {...param}
            style={style}
          />
        );
        break;
      case `select`:
        className = SelectStyle;
        param = item.param as SelectProps<SelectValue>;
        let items: Array<ReactNode> | undefined = [];
        if (`dict` in item.param) {
          let dict = item.param.dict;
          items = dict?.map((f1) => {
            return <Select.Option value={f1.code}>{f1.codeName}</Select.Option>;
          });
        }
        control = (
          <Select
            optionFilterProp={`label`}
            allowClear={true}
            showSearch={true}
            dropdownClassName={SelectDropdownStyle}
            placeholder={item.name}
            {...param}
            style={style}
          >
            {items}
          </Select>
        );
        break;
      case `unit-select`:
        className = SelectStyle;
        param = item.param as UnitTreeSelectProps;
        control = (
          <UnitTreeSelect
            allowClear={true}
            showSearch={true}
            dropdownClassName={SelectDropdownStyle}
            placeholder={item.name}
            {...param}
            style={style}
          />
        );
        break;
      case `area-select`:
        className = SelectStyle;
        param = item.param as AreaTreeSelectProps;
        control = (
          <AreaTreeSelect
            allowClear={true}
            showSearch={true}
            dropdownClassName={SelectDropdownStyle}
            placeholder={item.name}
            {...param}
            style={style}
          />
        );
        break;
      case `date-picker`:
        param = item.param as DatePickerProps;
        control = (
          <DatePicker
            placeholder={`请输入` + item.name}
            {...param}
            style={style}
          />
        );
        break;
      case `time-picker`:
        param = item.param as TimePickerProps;
        control = (
          <TimePicker
            className={DatePickerStyle}
            placeholder={`请输入` + item.name}
            {...param}
            style={style}
          />
        );
        break;
      case `switch`:
        param = item.param as SwitchProps;
        control = <Switch {...param} />;

        break;
    }

    if (control) {
      if (mini) {
        return (
          <React.Fragment>
            {getFieldDecorator(item.key)(control)}
          </React.Fragment>
        );
      } else {
        return (
          <div
            className={classNames(styles.formItemDiv, className)}
            style={mini ? {} : { width: `${100 / size}%` }}
          >
            {mini ? `` : <div className={styles.lable}>{`${item.name}：`}</div>}
            <Form.Item className={classNames(styles.formItem, className)}>
              {getFieldDecorator(item.key)(control)}
            </Form.Item>
          </div>
        );
      }
    } else {
      return ``;
    }
  };

  render() {
    const { className, searchList, additional, form } = this.props;
    const { mini, size } = this.state;
    if ((searchList ?? []).length <= 0 && !additional)
      return (
        <Form
          className={classNames(styles.form, FormStyle)}
          name="form1"
          // onFieldsChange={this.formChange}
        ></Form>
      );
    let controls = searchList?.map((f1) => this.getItem(f1)) ?? [];

    controls.push(
      mini ? (
        <Button.Group className={styles.buttonGroup}>
          <Button
            title={`查询`}
            htmlType="submit"
            icon="search"
            onClick={(e) => {
              this.thisFinish = true;
              this.onFinish();
            }}
          />
          <Button
            title={`重置`}
            type={`default`}
            icon="reload"
            onClick={(e) => {
              this.setState({}, () => {
                this.thisFinish = true;
                form.resetFields();
                this.onFinish();
              });
            }}
          />
        </Button.Group>
      ) : (
        <div
          className={classNames(styles.formItemDiv)}
          style={mini ? {} : { width: `${100 / size}%` }}
        >
          {mini ? `` : <div className={styles.lable}></div>}
          <React.Fragment>
            <Button
              title={`查询`}
              htmlType="submit"
              type={`primary`}
              icon="search"
              className={styles.button}
              onClick={(e) => {
                this.thisFinish = true;
                this.onFinish();
              }}
            >
              查询
            </Button>
            <Button
              title={`重置`}
              type={`default`}
              icon="reload"
              className={styles.button}
              onClick={(e) => {
                this.setState({}, () => {
                  this.thisFinish = true;
                  form.resetFields();
                  this.onFinish();
                });
              }}
            >
              重置
            </Button>
          </React.Fragment>
        </div>
      )
    );
    return (
      <div className={classNames(styles.main, className)} ref={this.barRef}>
        <Form
          className={classNames(styles.form, FormStyle)}
          name="form1"
          // onFieldsChange={this.formChange}
        >
          <div
            className={classNames(styles.miniDiv, {
              [styles.hide]: !mini && additional === undefined,
            })}
          >
            <div className={styles.actionDiv}>{additional}</div>
            <div className={styles.searchDiv}>
              {mini ? (
                <Input.Group compact className={styles.inputGroup}>
                  {" "}
                  {controls}{" "}
                </Input.Group>
              ) : (
                ``
              )}
            </div>
          </div>
          <div
            className={classNames(styles.normalDiv, { [styles.hide]: mini })}
          >
            <div className={styles.searchDiv}>{!mini ? controls : ``}</div>
          </div>
        </Form>
        <div
          className={styles.more}
          onClick={(e) => this.setState({ mini: !this.state.mini })}
        >
          {mini ? (
            <Icon type={`caret-down`} className={styles.icon} />
          ) : (
            <Icon type={`caret-up`} className={styles.icon} />
          )}
          <div className={styles.text}>{mini ? `展开` : `收起`}</div>
        </div>
      </div>
    );
  }
}
const SearchBar = Form.create<IProps>({
  onValuesChange: (e) => (e.searchKey = e.searchKey ?? 0 + 1),
})(SearchBarClass);
export { SearchBar };
