import {createRoute, dynamicWrapper} from "@/utils/core";
import intl from 'react-intl-universal';

const routesConfig = app => ({
    path: "/datav/datascreen/index/:id",
    title: intl.get('/datav/datascreen'),
    component: dynamicWrapper(app, [import("./model/index")], () =>
        import("./components/index")
    )
});

export default app => createRoute(app, routesConfig);
