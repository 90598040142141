import {get, post} from "@/http/flv"

const baseUrl = "api/mega/";

export default {
    mega: {
        startStream: (params) => {
            return post(baseUrl + 'start-stream', params)
        },
        keepStream: (params) => {
            return post(baseUrl + 'keep-stream', params)
        },
    }

}