import React from 'react';
import {Icon, Input, Tooltip} from "antd";
import {randomId} from "@/utils/tool"

class PasswordInput extends React.Component {
    state = {
        type: 'password',
        icon: 'eye'
    };

    constructor(props) {
        super(props);
        this.state = {
            type: 'password',
            icon: 'eye-invisible',
            CapsLock: false, // 锁定状态
            CapsLockDown: false, // 锁定按下状态
            Shift: false, // 特殊值锁定
            selected: false,//焦点，是否被选中
        };
    }


    componentDidMount() {
        const {id} = this.props
        const node = document.getElementById(id)
        console.info('密码输入框',id,node)
        if (node) {
            node.addEventListener('keydown', this.keyDown)
            node.addEventListener('keyup', this.keyUp)
            node.addEventListener('focus', this.onFocus)
            node.addEventListener('blur', this.onBlur)
        }
    }

    onFocus = (e) => {
        this.setState({selected: true})
    }
    onBlur = (e) => {
        this.setState({selected: false})
    }

    keyDown = (e) => {
        const {CapsLock, CapsLockDown, Shift} = this.state
        if (16 == e.keyCode) {
            this.setState({Shift: true})
            return
        }
        if (20 == e.keyCode) {
            this.setState({CapsLockDown: true})
            return
        }
        if (65 <= e.keyCode && e.keyCode <= 90) {
            const big = e.keyCode == e.key.charCodeAt(0)  // 实际输出 大写
            const bigState = ((CapsLockDown ? 1 : 0) + (CapsLock ? 1 : 0) + (Shift ? 1 : 0)) % 2 != 0
            // const state = ((CapsLockDown ? 1 : 0) + (Shift ? 1 : 0)) % 2 != 0// 组合键
            // console.error('记录的大写有效状态', bigState )
            // console.error('实际输入的大写状态', big)
            if (bigState != big) {
                this.setState({CapsLock: !CapsLock})
            }
        } else {
            // console.info('按下按键', e.key, e.keyCode)
        }
    }

    keyUp = (e) => {
        const {CapsLock} = this.state
        if (16 == e.keyCode) {
            this.setState({Shift: false})
            return
        }
        if (20 == e.keyCode) {
            this.setState({CapsLockDown: false, CapsLock: !CapsLock})
            return
        }
        // if (65 <= e.keyCode && e.keyCode <= 90) {
        //     if (e.keyCode == e.key.charCodeAt(0)) {
        //         console.info('松开了大写字母', e.key)
        //     }
        //     else {
        //         console.info('松开了小写字母', e.key)
        //     }
        // } else {
        //     console.info('松开按键', e.key, e.keyCode)
        // }
    }

    // 切换显示状态
    handleChange() {
        const {type} = this.state;
        if (type == "text") {
            this.setState({
                type: 'password',
                icon: 'eye-invisible'
            })
        } else {
            this.setState({
                type: 'text',
                icon: 'eye'
            })
        }


    }

    render() {
        const {...props} = this.props;
        const {type, icon, CapsLock, selected} = this.state;
        console.info("提示",CapsLock && selected)

        return <Tooltip
            title={'大写锁定已打开'}
            visible={CapsLock && selected}
            placement="bottomLeft"
            overlayClassName="numeric-input"
        ><Input type={type}
                {...props}
                suffix={
                    <Icon type={icon} onClick={() => this.handleChange()}/>
                }
        /></Tooltip>
    }


}

export default PasswordInput;