import { Page, ParamsId } from "httpRequest/interface";
import { HttpUtils } from "utils/http-utils";
import * as PayIotCardInterface from "./interface";
const BaseUrl = "/api/pay/iot-card/";
const PayIotCard = {
  List: (
    params?: PayIotCardInterface.ListParam
  ): Promise<Page<PayIotCardInterface.IotCardInfo>> => {
    return HttpUtils.Get(BaseUrl + "list", params);
  },

  Delete: (params: ParamsId): Promise<boolean> => {
    return HttpUtils.Post(BaseUrl + "delete", params);
  },
  Update: (params: PayIotCardInterface.UpdateParam): Promise<boolean> => {
    return HttpUtils.Post(BaseUrl + "update", params);
  },
  Get: (params: {
    unitId: string;
    userId: string;
  }): Promise<PayIotCardInterface.IotCardInfo> => {
    return HttpUtils.Get(BaseUrl + "get", params);
  },
};
export { PayIotCard, PayIotCardInterface };
