import {serviceKey} from '@/utils/constant'

const baseUrl = "https://restapi.amap.com/v3/"
const getUrl = (mainUrl, params = {}) => {
    let paramUrl = "?key=" + serviceKey;
    Object.keys(params).map(key => {
        if (params[key] && params[key].toString().indexOf("undefined") == -1) {
            paramUrl += "&" + key + "=" + params[key]
        }
    })
    return baseUrl + mainUrl + paramUrl;
}


function send(url, index = 0) {
    if (index < 3) {
        index++;// 未知问题失败时再次提交，三次机会
        return new Promise(resolve =>
            fetch(url).then(response => {
                return response.json()
            }).then(res => {
                res.status === '1' && resolve(res);
            }).catch(err => {
                console.info("高德失败", err);
                send(url, index)
            }))
    }
}

export default {
    /**
     * 获取行政区边界  https://lbs.amap.com/api/webservice/guide/api/district
     * keywords:行政区名称、citycode、adcode
     * subdistrict:设置显示下级行政区级数(0：不返回下级行政区| 1：返回下一级行政区| 2：返回下两级行政区|3：返回下三级行政区)
     * extensions:(base:不返回行政区边界坐标点|all:只返回当前查询district的边界值，不返回子节点的边界值；
     * @returns {Promise<any>}
     */
    getDistrict: (params) => {
        const url = getUrl("config/district", params);
        return send(url)
    },
    /**
     * 关键字搜索：通过用POI的关键字进行条件搜索 同时支持设置POI类型搜索，例如：银行
     * https://lbs.amap.com/api/webservice/guide/api/search
     * keywords:查询关键词（必填）
     * types:POI类型 https://lbs.amap.com/api/webservice/download
     * city:城市名（城市中文|城市全拼|citycode|adcode）
     * citylimit:仅返回指定城市数据 (true|false) 默认false
     * children:展示层级（0:子POI都显示|1:子POI归类到父POI）默认0
     * offset:每页记录条数(建议不超过25，可能报错）默认20
     * page:当前页数 （最大100）默认1
     * extensions:返回结果控制（all:返回地址信息、附近POI、道路信息|base:只返回基本信息）默认base
     *
     * @returns {Promise<any>}
     */
    search: (params) => {
        const url = getUrl("place/text", params);
        return send(url)
    },

    /**
     * 周边搜索：在用户传入经纬度坐标点附近，在设定的范围内，按照关键字或POI类型搜索
     * https://lbs.amap.com/api/webservice/guide/api/search
     * location:中心点坐标(116.473168,39.993015)（必填）
     * keywords:查询关键词
     * types:POI类型 https://lbs.amap.com/api/webservice/download
     * radius:查询半径
     * sortrule:排序规则（distance:距离|weight:综合）默认distance
     * offset:每页记录条数(建议不超过25，可能报错）默认20
     * page:当前页数 （最大100）默认1
     * extensions:返回结果控制（all:返回地址信息、附近POI、道路信息|base:只返回基本信息）默认base
     *
     * @returns {Promise<any>}
     */
    getAround: (params) => {
        const url = getUrl("place/around", params);
        return send(url)
    },
    /**
     * IP定位：将IP信息转换为地理位置信息
     * https://lbs.amap.com/api/webservice/guide/api/ipconfig
     * ip:需要转换的IP 默认当前请求的IP
     * extensions:返回结果控制（all:返回地址信息、附近POI、道路信息|base:只返回基本信息）默认base
     *
     * @returns {Promise<any>}
     */
    ip: (params) => {
        const url = getUrl("ip", params);
        return send(url)
    },
    /**
     * 输入提示
     * https://lbs.amap.com/api/webservice/guide/api/inputtips
     * keywords:查询关键字（必填）
     * type：POI分类（建议使用分类代码）
     * location：坐标(116.473168,39.993015)
     * city:搜索城市(citycode|adcode)
     * citylimit:仅返回指定城市数据 (true|false) 默认false
     * datatype:返回的数据类型，多类型用“|”间隔(all:所有|poi:POI数据类型|bus:公交站点|bushine:公交线路)
     *
     * @returns {Promise<any>}
     */
    inputtips: (params) => {
        const url = getUrl("ip", params);
        return send(url)
    },
    /**
     * 地理编码
     * https://lbs.amap.com/api/webservice/guide/api/georegeo
     * address:结构化地址信息，解析多个地址用“|”间隔，最多10个，batch需为true（必填）
     * city:指定查询的城市，不支持县级市(城市的中文|城市的中文全拼|citycode|adcode)默认全国
     * batch:批量查询控制（true|false）默认false
     *
     * @returns {Promise<any>}
     */
    geo: (params) => {
        const url = getUrl("geocode/geo", params);
        return send(url)
    },
    /**
     * 逆地理编码
     * https://lbs.amap.com/api/webservice/guide/api/georegeo
     * location:经纬度坐标，解析多个地址用“|”间隔，最多20个，batch需为true（必填）
     * poitype:附近POI类型
     * radius:搜索半径，默认1000米
     * extensions:返回结果控制（base:基本信息|all:基本信息、附近POI、道路）
     * batch:批量查询控制（true|false）默认false
     * roadlevel:道路等级 extensions为true时生效（0:所有道路|1:主干道路）
     * homeorcorp:优化POI内容排序（0:不排|1:居家相关靠前|2:公司相关靠前）
     *
     * @returns {Promise<any>}
     */
    regeo: (params) => {
        const url = getUrl("geocode/regeo", params);
        return send(url)
    },


}