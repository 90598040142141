import $$ from 'cmn-utils'

const baseUrl = "/api/sys/protocol/";
export default {
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    update: (params) =>{
        return $$.post(baseUrl + 'update', params)
    },
    userProtocolList: (params) =>{
        return $$.get(baseUrl + 'user-protocol/list', params)
    },
    signProtocol: (params) =>{
        return $$.post(baseUrl + 'sign-protocol', params)
    },
    lastVersionProtocol:(params)=>{
        return $$.get(baseUrl+'last-version-protocol',params)
    }
}