import React, {Component} from 'react'
import {Col, Form, Icon, Input, message, Modal, Row, Upload} from 'antd'
import {downloadImg, uploadImg} from '@/utils/constant'
import {getStrLength} from '@/utils/tool'
import $$ from 'cmn-utils'
import './style/modal.less'
import intl from "react-intl-universal";


const FormItem = Form.Item
const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 17},
    },
};
const uploadFileType='image/*'

class InfoModal extends Component {

    state = {
        imgUrl: null,
    }


    /**
     * 处理上传
     */
    handleUpload = (e) => {
        const file = e.fileList[e.fileList.length - 1]
        if (file.status === "done") {
            const res = file.response
            if (res.code === "OK") {
                this.setState({imgUrl: res.body})
                return res.body
            } else {
                message.error(res.message)
            }
        }
    }

    /**
     * 处理提交
     */
    handleSubmit(e) {
        e.preventDefault()
        const {onOk, form} = this.props
        const {validateFieldsAndScroll} = form
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                onOk(values)
            } else {
                return
            }
        })
    }

    /**
     * 校验昵稱長度
     */
    handleRealName(realName, callback) {
        const length = getStrLength(realName);
        console.info("昵称", length);
        if (length >= 4 && length <= 20) {

            callback();

        } else {
            callback(intl.get('message.realName.longError'));
        }
    }
    /**
     * 校验手机号是否存在
     */
    handleExistMobile(mobile, callback) {
        if (mobile.length === 11) {
            const { userInfo, onExistMobile} = this.props;
            // const reg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
            // if (reg.test(mobile)) {
            if (true) {// 手机号不再验证格式
                if ( userInfo.tel === mobile) {
                    callback();
                } else {
                    onExistMobile(mobile, ret => {
                        if (ret) {
                            callback();
                        } else {
                            callback(intl.get('message.phone.exist'));
                        }
                    });
                }
            } else {
                callback(intl.get('message.phone.formatError'));
            }
        } else {
            callback();
            // callback(intl.get('message.phone.longError'));
        }
    }

    /**
     * 校验邮箱是否存在
     */
    handleExistEmail(email, callback) {
        if (email.trim().length > 0) {
            const { userInfo, onExistEmail} = this.props;
            const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (reg.test(email)) {
                if (userInfo.email === email) {
                    callback();
                } else {
                    onExistEmail(email, ret => {
                        if (ret) {
                            callback();
                        } else {
                            callback(intl.get('message.email.exist'));
                        }
                    });
                }
            } else {
                callback(intl.get('message.email.formatError'));
            }
        } else {
            callback(intl.get('message.email.none'));
        }
    }


    render() {
        const {visible, onCancel, title, userInfo, form} = this.props
        const {imgUrl} = this.state
        const modalProps = {visible, onCancel, title, onOk: (e) => this.handleSubmit(e)}
        const {getFieldDecorator} = form
        const uploadButton = (
            <div>
                <Icon type='plus'/>
                <div className="ant-upload-text">Upload</div>
            </div>
        )

        return (
            <Modal {...modalProps} afterClose={() => form.resetFields()}>
                <Form>
                    <Row>
                        <Col span={18}>
                            <FormItem {...formItemLayout} label="用户名">
                                <span>{userInfo.username}</span>
                            </FormItem>
                            <FormItem {...formItemLayout} label="昵称">
                                {getFieldDecorator("realName", {
                                    initialValue: userInfo.realName,
                                    rules: [{required: true, message: "请输入昵称"},
                                        {
                                            validator: (rule, value, callback) =>
                                                this.handleRealName(value, callback)
                                        }]
                                })(<Input placeholder="请输入昵称"/>)}
                            </FormItem>
                            <FormItem {...formItemLayout} label="邮箱">
                                {getFieldDecorator("email", {
                                    initialValue: userInfo.email,
                                    rules: [{required: true, message: "请输入邮箱"},
                                        // {
                                        //     validator: (rule, value, callback) =>
                                        //         this.handleExistEmail(value, callback)
                                        // }
                                        ]
                                })(<Input placeholder="请输入邮箱"/>)}
                            </FormItem>
                            <FormItem {...formItemLayout} label="手机号码">
                                {getFieldDecorator("tel", {
                                    initialValue: userInfo.tel,
                                    rules: [{required: true, message: "请输入手机号码"},
                                        // {
                                        //     validator: (rule, value, callback) =>
                                        //         this.handleExistMobile(value, callback)
                                        // }
                                        ]
                                })(<Input placeholder="请输入联系电话"/>)}
                            </FormItem>
                            <FormItem {...formItemLayout} label="角色">
                                <span>{userInfo && userInfo.role}</span>
                            </FormItem>
                            <FormItem {...formItemLayout} label="所属区域">
                                <span>{userInfo && userInfo.area}</span>
                            </FormItem>
                            <FormItem {...formItemLayout} label="所属项目">
                                <span>{userInfo && userInfo.unit}</span>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem>
                                {getFieldDecorator("avatar", {
                                    initialValue: userInfo.avatar,
                                    getValueFromEvent: (e) => this.handleUpload(e, "avatar"),
                                    rules: [{required: true, message: '请上传头像'}]
                                })(
                                    <Upload
                                        data={{type: 'SystemImage'}}
                                        name="file"
                                        accept={uploadFileType}
                                        action={uploadImg}
                                        listType="picture-card"
                                        headers={$$.getStore("headers")}
                                        showUploadList={false}
                                    >
                                        {imgUrl || (userInfo && userInfo.avatar) ?
                                            <img style={{width: "102px", height: "102px"}} alt="logo"
                                                 src={downloadImg + (imgUrl ? imgUrl : userInfo.avatar)}/> : uploadButton}
                                    </Upload>
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                </Form>
            </Modal>
        )
    }

}

export default Form.create()(InfoModal)
