import {createRoute, dynamicWrapper} from "@/utils/core";

import intl from 'react-intl-universal';

const routesConfig = app => ({
    path: "/inspect/line",
    title: intl.get('/inspection/ai'),
    component: dynamicWrapper(app, [import("./model/index")], () =>
        import("./components/index")
    )
});

export default app => createRoute(app, routesConfig);
