import React from 'react';
import {Switch} from 'dva/router';
import "./styles/iframe.less";

export default class IFrameLayout extends React.PureComponent {


    render() {
        const {routerData} = this.props
        const {childRoutes} = routerData

        return <div className='topcode-web-iframe'><Switch>{childRoutes}</Switch></div>
    }
}
