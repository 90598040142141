import React from 'react'
import {Modal} from 'antd'
import './style/modal.less'

const InstallModal = (props) => {

    const {visible, onCancel, title, data, footer} = props
    const modalProps = {visible, onCancel, title, footer}

    return (
        <Modal {...modalProps}>
            <div className="detail-item">
                <h4>报装消息</h4>
                <div className="item-container">
                    <div className="item-label">产品名称</div>
                    <div className="item-value">{data ? data.productName : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">设备名称</div>
                    <div className="item-value">{data ? data.deviceName : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">设备编号</div>
                    <div className="item-value">{data ? data.deviceSn : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">安装区域</div>
                    <div className="item-value">{data ? data.areaName : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">所属单位</div>
                    <div className="item-value">{data ? data.unitName : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">详细地址</div>
                    <div className="item-value">{data ? data.address : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">安装地址</div>
                    <div className="item-value">{data ? data.installLocation : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">安装时间</div>
                    <div className="item-value">{data ? data.time : ""}</div>
                </div>
            </div>
        </Modal>
    )
}

export default InstallModal