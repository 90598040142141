import {createRoute, dynamicWrapper} from "@/utils/core";
import intl from 'react-intl-universal';

const routesConfig = app => ({
    path: "/system/super/url",
    title: intl.get('/system/url'),
    component: dynamicWrapper(app, [import("./model")], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
