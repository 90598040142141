import { Empty } from 'antd';
import { EmptyProps } from 'antd/lib/empty';
import classNames from 'classnames';
import React from 'react';
import styles from './common-empty.module.less';

class CommonEmpty extends React.PureComponent<EmptyProps> {
    static defaultProps: EmptyProps = {
        image: Empty.PRESENTED_IMAGE_SIMPLE,
        description: `暂无数据`,
    }
    componentDidMount() {
    }

    render() {
        const { className, ...props } = this.props;
        return (
            <Empty className={classNames(className, styles.emptyMain)} {...props} />
        );
    }
}

export { CommonEmpty };