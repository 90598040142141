import $$ from "cmn-utils";

interface Dict {
  [key: string]: DictItem;
}
interface DictItem {
  list: Array<{
    code: string;
    codeName: string;
  }>;
  map: { [key: string]: string };
}
export const getList = (type: string) => {
  const DICT = getDICT(type);
  return DICT?.list;
};
export const getValue = (type: string, key: string) => {
  const DICT = getDICT(type);
  const value = DICT?.map[key];
  return value;
};

export const getDICT = (type: string) => {
  let Dict = get();
  let DictItem: DictItem | undefined = undefined;
  if (type in Dict) {
    DictItem = Dict[type];
  }
  return DictItem;
};
export const get = () => {
  let DICT: Dict | undefined = undefined;
  DICT = $$.getStore("DICT") as Dict;
  return DICT;
};

const DictGetList = getList;
const DictGetValue = getValue;
export { DictGetList, DictGetValue };
