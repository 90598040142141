/* eslint-disable no-undef */
import React, {Component} from "react"
import "./script/player"
import "./script/flvJsPlayer"
import api from "@/http/flv/api"
import PropTypes from "prop-types";

/**
 *  https://www.cnblogs.com/phperlinxinlan/p/11289556.html
 * 云视频直播
 * 1、接收一个设备编号获取直播地址
 * 2、根据直播地址创建一个flv播放器 (创建前需保证直播地址的正确性)
 * 3、持续发送心跳保证推流不间断  （20s）
 * 4、关闭播放器时关闭心跳
 */

class FlvPlayer extends Component {

    static propTypes = {
        sn: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props)
        this.sn = props.sn
        this._id = `player_${this.sn}_${new Date().getTime()}`
        this.state = {
            httpFlvUrl: '',
            play: false
        }

    }

    componentDidMount() {
        this.start();
    }

    start = () => {
        api.mega.startStream({sn: this.sn})
            .then(ret => {
                if (ret) {
                    const {hlsUrl, httpFlvUrl, rtmpUrl} = ret
                    this.setState({httpFlvUrl}, this.init)
                }
            })
    }
    init = () => {
        const {httpFlvUrl} = this.state
        urlIsOk(httpFlvUrl, this.play)
    }
    play = () => {
        const {httpFlvUrl} = this.state
        this._player = new FlvJsPlayer({
            id: this._id,
            isLive: false,
            playsinline: true,
            url: httpFlvUrl,
            autoplay: true,
            height: '100%',
            width: '100%'
        })
        window.__player = this._player
        this.heartbeat()
    }

    heartbeat = () => {
        this._timer = setInterval(this.keep, 9 * 1000)
    }
    keep = () => api.mega.keepStream({sn: this.sn})


    componentWillUnmount() {
        clearInterval(this._timer)
    }

    render() {
        const {style} = this.props

        return <div style={{width: '100%', height: '100%', ...style}}>
            <div id={this._id} key={this._id} />
        </div>

    }
}


export default FlvPlayer

function urlIsOk(url, resolve) {
    const controller = new AbortController()
    const {signal} = controller
    fetch(url, {signal}).then(ret => {
        const {status} = ret
        if (200 === status) {

            resolve()
            controller.abort()
        } else {
            setTimeout(() => urlIsOk(url, resolve), 500)
        }
    }).catch(err => {
        setTimeout(() => urlIsOk(url, resolve), 500)
    })


}