import { HttpUtils } from "utils/http-utils";
import { Page, ParamsId } from "httpRequest/interface";
import * as AreaInterface from "./interface";
namespace Area {
  const BaseUrl = "/api/sys/area/";
  export const List = (
    params?: AreaInterface.ListParams
  ): Promise<Page<AreaInterface.AreaInfo>> => {
    return HttpUtils.Get(BaseUrl + `list`, params);
  };

  export const Get = (params: ParamsId): Promise<AreaInterface.AreaInfo> => {
    return HttpUtils.Get(BaseUrl + `get`, params);
  };

  export const AsyncList = (
    params?: AreaInterface.ListParams
  ): Promise<Page<AreaInterface.AreaInfo>> => {
    return HttpUtils.Get(BaseUrl + `async-list`, params);
  };

  export const Create = (params: {
    code: string;
    lat: number;
    lng: number;
    name: string;
    nameAbbr: string;
    parentId?: string;
    type: string;
  }): Promise<AreaInterface.AreaInfo> => {
    return HttpUtils.Post(BaseUrl + `create`, params);
  };

  export const Update = (params: {
    id?: string;
    code: string;
    lat: number;
    lng: number;
    name: string;
    nameAbbr: string;
    parentId?: string;
    type: string;
  }): Promise<AreaInterface.AreaInfo> => {
    return HttpUtils.Post(BaseUrl + `update`, params);
  };

  export const Delete = (params: ParamsId): Promise<AreaInterface.AreaInfo> => {
    return HttpUtils.Post(BaseUrl + `delete`, params);
  };
}

export { Area, AreaInterface };
