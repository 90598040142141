import $$ from 'cmn-utils'

const baseUrl = "/api/sys/url/";
export default {
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },

}