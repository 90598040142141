import React from 'react';
import LevelItem from "./LevelItem";

class Level extends React.Component {
    static defaultProps = {
        score: 0,
        itemWidth: 32,
        itemHeight: 5
    };


    state = {
        score: 0,
    };

    componentWillReceiveProps(newProps) {
        this.setState({
            score: newProps.score
        });
    }

    render() {
        const {itemWidth, itemHeight} = this.props;
        const {score} = this.state;
        // const old=
        //     <div style={{display: 'flex'}}>
        //         <LevelItem width={itemWidth} height={itemHeight} color={0 < score ? '#ff0000' : 'gray'} text='弱'/>
        //         <LevelItem width={itemWidth} height={itemHeight} color={50 < score ? '#ff9933' : 'gray'} text='中'/>
        //         <LevelItem width={itemWidth} height={itemHeight} color={85 < score ? '#1890FF' : 'gray'} text='强'/>
        //     </div>

        // const progress =
        //     <Progress
        //         style={{marginTop: -10, marginBottom: -20}}
        //         percent={score}
        //         trailColor={'red'}
        //         status={score >= 80 ? 'success' : score >= 60 ? 'active' : 'exception'}
        //
        //         format={(persent) => {
        //             return persent + '分'
        //
        //         }}></Progress>;

        const status = ['success', 'exception', 'normal', 'active']
        //              绿色          红色          蓝色        蓝色

        return (
            <div style={{width: 120}}>
                <div style={{display: 'flex', color: '#1E1E1E'}}>
                    <LevelItem width={itemWidth} height={itemHeight} color={0 < score ? '#ff0000' : 'gray'} text=''/>
                    <LevelItem width={itemWidth} height={itemHeight} color={50 < score ? '#ff9933' : 'gray'} text=''/>
                    <LevelItem width={itemWidth} height={itemHeight} color={85 < score ? '#1890FF' : 'gray'} text=''/>
                </div>
                <div style={{textAlign: 'center',lineHeight:'40px'}}>
                    安全程度：{score}分
                </div>
            </div>
        )
    }
}

export default Level