import $$ from 'cmn-utils'

const baseUrl = "/api/core/linkage/";
export default {
    create: (data) => {
        const params = {};
        Object.assign(params, data)
        const {executeList} = params
        delete params.executeList
        executeList.filter(item => item != undefined).map((item, index) => {
            params['executeList[' + index + "].actionId"] = item.actionId;
            params['executeList[' + index + "].actionCode"] = item.actionCode;
            params['executeList[' + index + "].deviceId"] = item.deviceId;
            params['executeList[' + index + "].params"] = item.params;
        })
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    changeState: (params) => {
        return $$.post(baseUrl + 'update-state', params)
    },
    update: (data) => {
        const params = {};
        Object.assign(params, data)
        const {executeList} = params
        delete params.executeList
        executeList.filter(item => item != undefined).map((item, index) => {
            params['executeList[' + index + "].actionId"] = item.actionId;
            params['executeList[' + index + "].actionCode"] = item.actionCode;
            params['executeList[' + index + "].deviceId"] = item.deviceId;
            params['executeList[' + index + "].params"] = item.params;
        })
        return $$.post(baseUrl + 'update', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    deviceLinkageList: (params) => {
        return $$.get(baseUrl + 'device-linkage-list', params)
    },

}