import React from 'react'
import {Modal} from 'antd'
import './style/modal.less'

const SystemModal = (props) => {

    const {visible, onCancel, title, data, footer} = props
    const modalProps = {visible, onCancel, title, footer}

    return (
        <Modal {...modalProps}>
            <div className="detail-item">
                <h4>{data ? data.title : ""}</h4>
                <div className="item-container">
                    <div className="item-value">{data ? data.content : ""}</div>
                </div>
                <p>{data ? data.time : ""}</p>
            </div>
        </Modal>
    )
}

export default SystemModal