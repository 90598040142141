import './styles/user.less';
import React from 'react';
import {connect} from 'dva';
import {Layout} from 'antd';
import {Switch} from 'dva/router';
import Version from "@/components/Version";

const {Content} = Layout;

@connect()
export default class UserLayout extends React.PureComponent {
    render() {
        const {routerData} = this.props;
        const {childRoutes} = routerData;

        return (
            <Layout className="full-layout user-layout fixed">
                <Content>
                    <Version/>
                    <Switch>{childRoutes}</Switch>
                </Content>
            </Layout>
        );
    }
}