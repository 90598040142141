import {createRoute, dynamicWrapper} from '@/utils/core';
import intl from 'react-intl-universal';

const routesConfig = (app) => ({
    path: '/system/super/product',
    title: intl.get('/system/access'),
    component: dynamicWrapper(app, [import('./model/index')], () => import('./components/index')),
});

export default (app) => createRoute(app, routesConfig);
