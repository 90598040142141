import $$ from 'cmn-utils'
import {downloadExcel, formatAreaArray, getFileName} from "@/utils/tool";


const baseUrl = "/api/datav/datascreen/echarts/";
export default {
    exportExcel: (data) => {

        let url = baseUrl + "export-excel";let paramsArray = [];
        //拼接参数
        Object.keys(data).map(key => {
            if (!data[key] || data[key] == 'null' || data[key] == 'undefined') delete data[key]
        })
        Object.keys(data).forEach(key => paramsArray.push(key + "=" + data[key]));
        if (url.search(/\?/) === -1) {
            url += "?" + paramsArray.join("&");
        } else {
            url += "&" + paramsArray.join("&");
        }
        fetch(url, {
            method: "GET",
            credentials: "include",
            headers: $$.getStore("headers")
        }).then(res => {
            res.blob().then(blob => {
                const fileName = getFileName(res.headers)
                downloadExcel(blob, fileName)
            });
        });
        return true
    },
    countWorkOrderDoubler: (params) => {
        return $$.get(baseUrl + 'count-work-order/doubler', params)
    },
    countWorkOrderStatePie: (params) => {
        return $$.get(baseUrl + 'count-work-order-state/pie', params)
    },

    countWorkOrderTypePie: (params) => {
        return $$.get(baseUrl + 'count-work-order-type/pie', params)
    },
    countWorkOrderHandleMethodPie: (params) => {
        return $$.get(baseUrl + 'count-work-order-handle-method/pie', params)
    },

    countAlarmDoubler: (params) => {
        return $$.get(baseUrl + 'count-alarm/doubler', params)
    },
    countAlarmStatePie: (params) => {
        return $$.get(baseUrl + 'count-alarm-state/pie', params)
    },

// 工单增长趋势
    workOrderIncreasingTendencyLine: (params) => {
        return $$.get(baseUrl + 'work-order-increasing-tendency/line', params)
    },
    //告警增长趋势
    alarmIncreasingTendencyLine: (params) => {
        return $$.get(baseUrl + 'alarm-increasing-tendency/line', params)
    },

    //地图
    countDeviceMapMigrate: (params) => {
        return $$.get(baseUrl + 'count-device/map-migrate', params)
    },
    countDeviceStateDoubler: (params) => {
        return $$.get(baseUrl + 'count-device-state/doubler', params)
    },

// 设备统计
    countDevicePie: (params) => {
        return $$.get(baseUrl + 'count-device/pie', params)
    },
    deviceIncreasingTendencyLine: (params) => {
        return $$.get(baseUrl + 'device-increasing-tendency/line', params)
    },


    newAlarmList: (params) => {
        return $$.get(baseUrl + 'new-alarm-list', params)
    },
    newWorkOrderList: (params) => {
        return $$.get(baseUrl + 'new-work-order-list', params)
    },
}