import { CommonButton } from "components/Button/common-button";
import { CommonModal } from "components/Modal/common-modal";
import React from "react";
import { Checkbox, Icon, Input, message } from "antd";
import styles from "./user-group-add-modal.module.less"
import { CheckboxStyle, InputStyle } from "components/common-styles/common-styles";
import { UnitGroupInterface } from "httpRequest/tokiot/sys/unit/group";
import { PageClass } from "httpRequest/interface";
import { PaginationLayout } from "layouts/pagination";
import { DownloadImg } from "utils/constant";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { UnitGroupUser, UnitGroupUserInterface } from "httpRequest/tokiot/sys/unit/group/user";
interface IProps {
    visible: boolean;
    selectGroup: UnitGroupInterface.UnitGroupInfo;
    onClose?: () => void;
    onChange?: () => void;
    getContainer: () => HTMLElement;
}
interface IState {
    visible: boolean;
    unSelectUserList: PageClass<UnitGroupUserInterface.UnSelectUserInfo>;
    checkBoxSelectUserList: Array<CheckboxValueType>;
}
class UserGroupAddModal extends React.PureComponent<IProps, IState> {
    state: IState = {
        visible: false,
        unSelectUserList: new PageClass(),
        checkBoxSelectUserList: []
    }
    searchUnUserListData = {};
    searchText = ``;

    componentDidMount() {
    }

    Refresh = () => {
        this.getUnUserList(this.searchUnUserListData);
    }

    getUnUserList = (data?: any) => {
        const { selectGroup } = this.props;
        UnitGroupUser.UnSelectList({ unitId: selectGroup.unitId, currentUnitGroupId: selectGroup.id, fullText: this.searchText, ...data }).then(ret => {
            this.searchUnUserListData = data;
            if (ret && ret.rows && ret.rows.length > 0) {
                this.setState({ unSelectUserList: ret, checkBoxSelectUserList: [] });
            } else {
                this.setState({ unSelectUserList: new PageClass() });
            }
        })
    }

    addUserList = () => {
        const { selectGroup, onChange, onClose } = this.props;
        const { checkBoxSelectUserList } = this.state;
        UnitGroupUser.Create({ unitGroupId: selectGroup.id, userIdList: checkBoxSelectUserList as Array<string> }).then(ret => {
            if (ret) {
                onChange && onChange();
                onClose && onClose();
            } else {
                message.error(`添加用户失败`);
            }
        })
    }

    onSelectUserChange = (checkedValue: Array<CheckboxValueType>) => {
        this.setState({ checkBoxSelectUserList: checkedValue });
    }

    inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.searchText = e.target.value;
        this.Refresh();
    }

    render() {
        const { visible, onClose, selectGroup, getContainer } = this.props;
        let { unSelectUserList, checkBoxSelectUserList } = this.state;
        return (
            <CommonModal
                mask={false}
                wrapClassName={styles.userModal}
                className={styles.userClass}
                getContainer={getContainer}
                width={`300px`}
                title={`新增用户`}
                visible={visible}
                footer={
                    <CommonButton
                        disabled={checkBoxSelectUserList.length <= 0}
                        className={styles.button}
                        onClick={this.addUserList}
                    >添加</CommonButton>
                }
                onCancel={onClose}
            >
                <div className={styles.userModalDiv} >
                    <div className={styles.searchTitle}>
                        <Input className={InputStyle} allowClear onChange={this.inputChange} prefix={<Icon type="search" className={styles.icon} />} />
                    </div>
                    <div className={styles.unUserBody}>
                        <PaginationLayout
                            onSearch={this.getUnUserList}
                            pageData={unSelectUserList}
                            defaultPageSize={1000}
                            showSizeChanger={false}
                            showEmpty={false}
                        >
                            <Checkbox.Group value={checkBoxSelectUserList} className={styles.checkboxGroup} onChange={this.onSelectUserChange}>
                                {unSelectUserList.rows.map(f1 => {
                                    return (<div className={styles.userItem}
                                        onClick={e => {
                                            let ret = checkBoxSelectUserList.filter(id => id === f1.id);
                                            if (ret && ret.length > 0) {
                                                checkBoxSelectUserList = checkBoxSelectUserList.filter(id => id !== f1.id);
                                            } else {
                                                checkBoxSelectUserList.push(f1.id);
                                            }
                                            this.setState({ checkBoxSelectUserList }, this.forceUpdate);
                                        }}
                                    >
                                        <div className={styles.title}>
                                            <Checkbox value={f1.id} onClick={e => e.stopPropagation()} />
                                        </div>
                                        <div className={styles.body}>
                                            <img alt={``} className={styles.image} src={DownloadImg + f1.avatar} />
                                            <div className={styles.info}>
                                                <div className={styles.text1}>{f1.realName}</div>
                                                <div className={styles.text2}>{`电话：` + f1.tel}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </Checkbox.Group>
                        </PaginationLayout>
                    </div>
                </div>
            </CommonModal>
        );
    }
}
export { UserGroupAddModal };

