import $$ from 'cmn-utils'

const baseUrl = "/api/work-order/";
export default {
    countWorkOrderState: {
        myself: (params) => {
            return $$.get(baseUrl + 'count-work-order-state/myself', params)
        }
    },
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    detail: (params) => {
        return $$.get(baseUrl + 'get-detail', params)
    },
    handle: (params) => {
        return $$.post(baseUrl + 'handle', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    list: {
        all: (params) => {
            return $$.get(baseUrl + 'list', params)
        },
        history: (params) => {
            return $$.get(baseUrl + 'list/history', params)
        },
        myself: (params) => {
            return $$.get(baseUrl + 'list/myself', params)
        },
    },
    unitAccounts: (params) => {
        return $$.get(baseUrl + 'unit-accounts', params)
    },

}