import React from "react";
import { Button, Form, message, Upload } from "antd";
import { BaseFormModal } from "components/base-interface";
import styles from "./index.module.less";
import { CommonModal } from "components/Modal/common-modal";
import { BaseUrl, DownExcel } from "utils/constant";
import { HttpUtils } from "utils/http-utils";
import $$ from "cmn-utils";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    sm: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 17 },
  },
};

interface IProps extends BaseFormModal {}

interface IState {}
class ImportModalClass extends React.PureComponent<IProps, IState> {
  state: IState = {};
  componentDidMount() {}

  handleSubmit = () => {
    const { form, onChange, onClose } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        HttpUtils.GetFile(DownExcel + values.excelRet).then((ret) => {
          if (ret) {
            onChange && onChange();
            onClose && onClose();
          } else {
            message.error(`导入文件失败`);
          }
        });
      } else {
        return;
      }
    });
  };

  downloadTemplate = () => {
    HttpUtils.GetFile(
      `/excel-templates/iot_card_v1.xlsx`,
      {},
      `iot_card_v1.xlsx`
    );
  };
  render() {
    const { visible, onClose, form } = this.props;
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
    let isValue = getFieldValue(`excelRet`);
    return (
      <CommonModal
        title={`导入`}
        onCancel={() => {
          onClose && onClose();
        }}
        onOk={this.handleSubmit}
        width={500}
        visible={visible}
      >
        <Form className={styles.form}>
          {getFieldDecorator("excelRet")}
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`Excel文件`}
          >
            {getFieldDecorator("excel", {
              rules: [
                {
                  required: true,
                  message: `请选择导入文件`,
                },
              ],
            })(
              <Upload
                // showUploadList={false}
                name="file"
                accept={`.xls,.xlsx`}
                action={BaseUrl + `/api/pay/iot-card/import-excel`}
                onRemove={(file) => {
                  setFieldsValue({
                    excelRet: undefined,
                  });
                  this.forceUpdate();
                  return true;
                }}
                onChange={(info) => {
                  if (info.file.status === `done`) {
                    if (`code` in info.file.response) {
                      if (info.file.response.code === `FAILED`) {
                        if (`message` in info.file.response) {
                          message.error(info.file.response.message);
                        }
                        message.error(`导入失败`);
                      } else if (info.file.response.code === `OK`) {
                        if (`message` in info.file.response) {
                          setFieldsValue({
                            excelRet: info.file.response.message,
                          });
                        }
                      }
                    }
                  }
                }}
                headers={{
                  ...$$.getStore("headers"),
                }}
              >
                <Button disabled={isValue} icon={`upload`}>
                  点击选择文件上传
                </Button>
              </Upload>
            )}
            <Button
              className={styles.button}
              type={`primary`}
              onClick={() => {
                this.downloadTemplate();
              }}
            >
              下载模板
            </Button>
          </FormItem>
        </Form>
      </CommonModal>
    );
  }
}
const ImportModal = Form.create<IProps>()(ImportModalClass);
export { ImportModal };
