import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/job/approve",
    title: '作业审批',
    component: dynamicWrapper(app, [import("./model")], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
