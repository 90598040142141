import $$ from 'cmn-utils'

const baseUrl = "/api/core/device/state-log/";
export default {
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    fieldList: (params) => {
        return $$.get(baseUrl + 'field-list', params)
    },
    timeSequence: (params) => {
        return $$.get(baseUrl + 'time-sequence', params)
    },
    timeSequenceV2: (params) => {
        if (params.startTime) {
            params.startTime = params.startTime.format("YYYY-MM-DD HH:mm:ss");
        }
        if (params.endTime) {
            params.endTime = params.endTime.format("YYYY-MM-DD HH:mm:ss");
        }
        return $$.get(baseUrl + 'time-sequence-v2', params)
    },
}