export type CycleType = `DAY` | `WEEK` | `MONTH`;
export type DataSourceType = `UNIT` | `ORGANS`;
export type RoleCodeType = `UNIT` | `ORGAN` | `UNIT_ADMIN`;

export interface ParamsId {
    id: string,
}

export interface BaseParams {
    /** page */
    page?: number;
    /** 所属单位ID */
    unitId?: string;
    /** size */
    size?: number;
}

export interface Page<T> {
    pageNumber: number;
    pageSize: number;
    pageTotal: number;
    rows: Array<T>;
    total: number;
}

export class PageClass<T> implements Page<T> {
    pageNumber: number = 0;
    pageSize: number = 0;
    pageTotal: number = 0;
    rows: Array<T> = [];
    total: number = 0;
}

export interface CommonParams {
    code: string;
    name: string;
    value: string;
}

/** 设备参数信息 */
export interface DeviceStateProp extends CommonParams {
    unit?: any;
    icon: string;
    defValue: any;
}