import { Input, Layout, Modal, Tree } from "antd";
import React, { Component } from "react";

const { Header, Content } = Layout;
const { Search } = Input;
const { TreeNode } = Tree;

export default class SelectUnitTableModal extends Component {
  state = {
    treeData: [],
  };

  treeKey = 0;

  componentDidMount() {
    this.onSearch();
  }

  onSearch = (name = "") => {
    this.treeKey = this.treeKey + 1;
    return this.getData({ name, size: 999 }).then((list) =>
      this.setState({ treeData: list })
    );
  };

  getData = (data) => {
    Object.keys(data).map((key) => {
      if (!data[key] || data[key] == "null" || data[key] == "undefined")
        delete data[key];
    });
    return this.$http.tokiot.sys.unit
      .asyncList(data)
      .then((ret) => ret.rows.map(this.fmtData));
  };

  /**
   * 格式化数据
   */
  fmtData = (record) => {
    record.isLeaf = this.isLeaf(record);
    return this.props.fmtData(record);
  };

  /**
   * 判断是否是子节点
   */
  isLeaf = (record) => {
    return !record.parent;
  };

  // onSelect = (keys, rows) => this.setState({keys, rows})
  onSelect = (selectedKeys, e) => {
    this.props.onSubmit(selectedKeys[0], e.node.props.dataRef);
  };

  loadData = (treeNode) => {
    return new Promise((resolve) => {
      this.getData({ parentId: treeNode.props.dataRef.id, size: 999 }).then(
        (list) => {
          treeNode.props.dataRef.children = list;
          this.setState({});
          resolve();
        }
      );
    });
  };

  handleSubmit = () => {
    const { onSubmit, selectType } = this.props;
    const { keys, rows } = this.state;
    if (keys && keys.length > 0) {
      if ("checkbox" == selectType) {
        onSubmit(keys, rows);
      } else {
        onSubmit(keys[0], rows[0]);
      }
    } else {
      this.$message.error("请选择项目");
    }
  };

  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.name} key={item.id} {...item} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode key={item.id} title={item.name} {...item} dataRef={item} />
      );
    });

  render() {
    const { selectType, visible, value, onCancel, maskClosable } = this.props;
    const modalProps = {
      maskClosable,
      title: "请选择项目",
      visible,
      width: 300,
      onCancel,
      footer: null,
    };
    return (
      <Modal {...modalProps}>
        <Search placeholder="请输入项目名称" onSearch={this.onSearch} />
        <Tree
          key={this.treeKey}
          loadData={this.loadData}
          onSelect={this.onSelect}
          defaultExpandedKeys={this.state.treeData.map((i) => i.id)}
          selectedKeys={[]}
          style={{ height: "calc(100vh - 232px)", overflowY: "auto" }}
        >
          {this.renderTreeNodes(this.state.treeData)}
        </Tree>
      </Modal>
    );
  }
}
