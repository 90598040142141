import { message } from "antd";
import { PageClass } from "httpRequest/interface";
import { UnitGroup, UnitGroupInterface } from "httpRequest/tokiot/sys/unit/group";
import { PaginationLayout, SearchItem } from "layouts/pagination";
import React from "react";
import { DeviceGroupModal } from "./device/device-group-modal";
import styles from "./index.module.less"
import TitleImage from "./image/title-img.png";
import DeleteImage from "./image/delete.png";
import UserImage from "./image/user.png";
import DeviceImage from "./image/device.png";
import RenameImage from "./image/rename.png";
import AddImage from "./image/add.png";
import { UserGroupModal } from "./user/user-group-modal";
import { PopoverTable } from "components/popover/popover-table";
import { CreateModal } from "components/Modal/create-modal";
import { UserInterface } from "httpRequest/tokiot/sys/user";
import { CommonBox } from "components/box/common-box";
import { CommonCard } from "components/card/common-card";
import classNames from "classnames";
import { Unit, UnitInfoInterface } from "httpRequest/tokiot/sys/unit";
import { TreeNode } from "antd/lib/tree-select";
type UnitGroupInfo = UnitGroupInterface.UnitGroupInfo;

interface IProps {
    userInfo: UserInterface.UserInfo;
}
interface IState {
    groupVisivle: boolean;
    groupInfo: PageClass<UnitGroupInfo>;
    groupDeviceVisivle: boolean;
    groupUserVisivle: boolean;
    selectGroup?: UnitGroupInfo;
}

class UnitGroupManage extends React.PureComponent<IProps, IState> {
    state: IState = {
        groupVisivle: false,
        groupInfo: new PageClass(),
        groupDeviceVisivle: false,
        groupUserVisivle: false,
    }
    searchData = {}
    componentDidMount() {
    }

    getGroupList = (data?: any) => {
        UnitGroup.List({ ...data }).then(ret => {
            this.searchData = data;
            if (ret && ret.rows && ret.rows.length > 0) {
                this.setState({ groupInfo: ret });
            } else {
                this.setState({ groupInfo: new PageClass() });
            }
        })
    }

    deleteGroup = (id: string) => {
        UnitGroup.Delete({ id }).then(ret => {
            if (ret) {
                this.onChange();
            } else {
                message.error(`删除失败`);
            }
        })
    }

    groupVisivleChange = (selectGroup?: UnitGroupInfo) => {
        this.setState({ selectGroup, groupVisivle: !this.state.groupVisivle });
    }

    groupDeviceVisivleChange = (selectGroup?: UnitGroupInfo) => {
        this.setState({ selectGroup, groupDeviceVisivle: !this.state.groupDeviceVisivle });
    }
    groupUserVisivleChange = (selectGroup?: UnitGroupInfo) => {
        this.setState({ selectGroup, groupUserVisivle: !this.state.groupUserVisivle });
    }

    getOperatingContent = (info: UnitGroupInfo) => {
        let itemList = [{
            name: `重命名`,
            icon: <img alt={``} src={RenameImage} className={styles.image} />,
            onClick: () => {
                this.groupVisivleChange(info);
            },
        }, {
            name: `绑定设备`,
            icon: <img alt={``} src={DeviceImage} className={styles.image} />,
            onClick: () => {
                this.groupDeviceVisivleChange(info);
            },
        }, {
            name: `绑定用户`,
            icon: <img alt={``} src={UserImage} className={styles.image} />,
            onClick: () => {
                this.groupUserVisivleChange(info);
            },
        }, {
            name: `删除`,
            icon: <img alt={``} src={DeleteImage} className={styles.image} />,
            onClick: () => { this.deleteGroup(info.id); },
            tip: {
                title: `删除-${info.groupName}`,
                message: `确定要删除[${info.groupName}]吗？`,
            }
        }
        ]
        return itemList;
    }
    onChange = () => {
        this.getGroupList(this.searchData)
    }
    createOrRename = (values: any) => {
        const { selectGroup, } = this.state;
        let pRet: Promise<boolean>;
        if (selectGroup) {
            pRet = UnitGroup.Update({ id: selectGroup.id, groupName: values.name, })
        } else {
            pRet = UnitGroup.Create({ unitId: values.unitId, groupName: values.name, })
        }
        pRet.then(ret => {
            if (ret) {
                this.onChange();
                this.groupVisivleChange();
            } else {
                selectGroup ? message.error(`重命名失败`) : message.error(`新增分组失败`);
            }
        })
    }

    getUnitItem = (unit: UnitInfoInterface.UnitInfo) => {
        let ret: TreeNode = {
            value: unit.id,
            title: unit.name,
        }
        if (unit.childrenList && unit.childrenList?.length > 0) {
            ret.children = unit.childrenList?.map(f1 => this.getUnitItem(f1));
        }
        return ret;
    }
    searchList: Array<SearchItem> = [{
        key: `unitId`,
        type: `all`,
        name: `所属项目`,
        width: `180px`,
        param: {
            type: `unit-select`,
            onlyType: `Unit`,
        }
    }]
    render() {
        const { groupVisivle, groupInfo, selectGroup, groupDeviceVisivle, groupUserVisivle } = this.state;
        return (
            <CommonBox>
                <div className={styles.unitGroup} >
                    {groupVisivle ? <CreateModal
                        titleName={selectGroup ? `重命名` : `新增分组`}
                        name={selectGroup?.groupName}
                        lableName={`分组名称`}
                        visible={groupVisivle}
                        visibleChange={visible => this.setState({ groupVisivle: visible })}
                        selectUnit={!selectGroup}
                        onlyType={`Unit`}
                        onOk={this.createOrRename} /> : ``}
                    {groupDeviceVisivle && selectGroup ? <DeviceGroupModal selectGroup={selectGroup} visible={groupDeviceVisivle} onClose={this.groupDeviceVisivleChange} onChange={this.onChange} /> : ``}
                    {groupUserVisivle && selectGroup ? <UserGroupModal selectGroup={selectGroup} visible={groupUserVisivle} onClose={this.groupUserVisivleChange} onChange={this.onChange} /> : ``}
                    <PaginationLayout
                        searchList={this.searchList}
                        onSearch={this.getGroupList}
                        pageData={groupInfo}
                        itemSize={{ width: 300, rowCount: 4, showAdd: true }}
                    >
                        {(width, height, columnCount) => {
                            let style: React.CSSProperties = { width: `${100 / (columnCount ?? 100)}%` };
                            return (
                                <div className={styles.body}>
                                    <CommonCard className={classNames(styles.groupItem, styles.addGroupItem)} onClick={this.groupVisivleChange} style={style}>
                                        <div className={styles.groupBody}>
                                            <img alt={``} className={styles.image} src={AddImage} />
                                            <div className={styles.text}>新增分组</div>
                                        </div>
                                    </CommonCard>
                                    {groupInfo.rows.map(f1 => {
                                        return (<CommonCard className={styles.groupItem} style={style}>
                                            <div className={styles.groupBody}>
                                                <div className={styles.itemTitle}>
                                                    <img alt={``} className={styles.image} src={TitleImage} />
                                                    <div className={styles.info}>
                                                        <div className={styles.text}>{f1.groupName}</div>
                                                        <div className={styles.text1}>{f1.unitName}</div>
                                                    </div>
                                                    <PopoverTable placement="bottomLeft" key={f1.id} buttonList={this.getOperatingContent(f1)} />
                                                </div>
                                                <div className={styles.itemBody}>
                                                    <div className={styles.device}>
                                                        <div className={styles.name}>绑定设备</div>
                                                        <div className={styles.count}>{f1.deviceTotal}</div>
                                                    </div>
                                                    <div className={styles.user}>
                                                        <div className={styles.name}>绑定用户</div>
                                                        <div className={styles.count}>{f1.userTotal}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CommonCard>)
                                    })}
                                </div>
                            );
                        }}
                    </PaginationLayout>
                </div>
            </CommonBox>
        );
    }
}
export { UnitGroupManage };

