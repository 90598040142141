import $$ from 'cmn-utils'

const baseUrl = "/api/core/device/event-log/";
export default {
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    }
}