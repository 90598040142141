export default {
    /**  事件类型 */
    Generic: '',
    HeartBeat: '#3598dc',
    AlarmRecover: '#CC00FF',
    NotifyEvent: '#6CCD3C',
    Level1Alarm: '#F77478',
    Level2Alarm: '#F7784C',
    Level3Alarm: '#FFAB66',
    Level4Alarm: '#FFD200',

    /** 处理状态 */
    UnHandle: '#f77478',
    Handle: '#6CCD3C',
    AutoRecover: '',

    /** 操作类型 */
    Normal: '#3598dc',
    arm: '#32c5d2',
    disarm: '#ffab66',
    disAlarm: '#f77478',
    DisRing: '#FFAB66',

    /** 控制操作状态 */
    Success: 'green',
    Failed: 'red',
    Discard: '#FFD200',
    Pendding: '#FFD200',
    Wait: '#FFD200',

    /** 操作触发类型 */
    Initiative: 'green',
    Timer: '#f77478',
    Linkage: '#FFD200',

    /** 动火审批状态 */
    CREATE:'#3598dc',
    SUBMIT:'#3598dc',
    APPROVAL1_PASS:'#14A255',
    APPROVAL1_UN_PASS:'#F56335',
    APPROVAL2_PASS:'#14A255',
    APPROVAL2_UN_PASS:'#F56335',
    FINISH:'#14A255',


}