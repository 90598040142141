import api from "./api"
import other from "@/http/tokiot/other"
//  未知原因请求总是失败，抓包却是成功，因此使用透传

const baseUrl = "http://121.204.186.158:37002/"

export function get(url, data) {
    return other.get({url: baseUrl + url, ...data})
}

export function post(url, data) {
    return other.post({url: baseUrl + url, ...data})
}


export default {api,}