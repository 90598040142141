import $$ from 'cmn-utils'
import config from "./config"
import event from "./event"
import prop from "./prop"
import action from "./action"
import version from "./version"


const baseUrl = "/api/sys/product/";
export default {
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    listHome: (params) => {
        return $$.get(baseUrl + 'list-home', params)
    },
    setAuthorized: (params) => {
        return $$.post(baseUrl + 'set-authorized', params)
    },
    setIcon: (params) => {
        return $$.post(baseUrl + 'set-icon', params)
    },
    setIconImage: (params) => {
        return $$.post(baseUrl + 'set-icon-image', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    listByUnit: (params) => {
        return $$.get(baseUrl + 'list-by-unit', params)
    },
    listByGateway: (params) => {
        return $$.get(baseUrl + 'list-by-gateway', params)
    },
    getSubProductList: (params) => {
        return $$.get(baseUrl + 'get-sub-product-list', params)
    },
    config, event, prop, action, version

}