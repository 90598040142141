import React, {PureComponent} from 'react'
import {connect} from "dva";

@connect(({global}) => ({
    version:global.version
}))
class Version extends  PureComponent{

    render(){
        const {version}=this.props

        return (
            <div style={{position:'fixed',right:5,bottom:5,color:'#aaa',opacity:0.6,zIndex:9999}}>
                <div>服务版本：{version.server}</div>
                <div>程序版本：{version.client}</div>
            </div>

        )
    }
}
export default Version