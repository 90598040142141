import React, {Component} from "react";
import {message, Spin} from 'antd';
import {randomId} from "@/utils/tool"


export default class InfiniteScroll extends Component {
    static defaultProps = {
        bottom: 10
    }

    constructor(props) {
        super(props)
        this.state = {
            id: randomId(),
            loading: false,// 加载状态
            listener: false,// 监听状态
            timer: null, // 防抖函数
            hasMore: true // 更多
        }
    }

    componentDidMount() {
        this.bindListener()
    }

    debounce = (fn, wait) => {
        const {timer} = this.state
        if (timer !== null) {
            clearTimeout(timer);
        }
        this.setState({timer: setTimeout(fn, wait)})
    }

    bindListener = () => {
        const {listener, id} = this.state
        const {bottom} = this.props
        const documentNode = document.getElementById(id)
        console.info("监听滚动",id,documentNode)
        if (!documentNode || listener) return;
        const childrenNode = documentNode.children[0]
        if (!childrenNode) return
        if (childrenNode.scrollHeight == childrenNode.offsetHeight) {
            // this.loadMore()
            this.setState({hasMore: true})
        }
        childrenNode.addEventListener('scroll', (event) => this.debounce(() => this.scrollEvent(event), 200))
        childrenNode.addEventListener('click', (event) => console.info("点击",event,event.target))
        console.info("监听成功",childrenNode)
        this.setState({listener: true})
    }
    scrollEvent = (event) => {
        const {hasMore} = this.state
        // if (!hasMore)return ;
        const {bottom} = this.props
        const {scrollHeight, scrollTop, offsetHeight} = event.target
        const marginBottom = scrollHeight - scrollTop - offsetHeight
        console.info("滚动", scrollHeight, scrollTop, offsetHeight, marginBottom)
        // scrollHeight != offsetHeight 过滤掉切换卡片时引发的滚动事件
        if (marginBottom < bottom && scrollHeight != offsetHeight) {
            this.loadMore()
        }
    }

    loadMore = () => {
        const {loadMore, page} = this.props
        if (typeof  loadMore == "function") {
            this.setState({loading: true}, () => loadMore(page + 1, this.loadMoreCallBack))
        } else {
            console.info('loadMore is not a function')
        }
    }
    loadMoreCallBack = (ret = false) => {
        if (ret) {
            const hasMore = ret.rows.length >= ret.pageSize
            !hasMore && message.info('没有更多了')
            this.setState({loading: false, hasMore})
        } else {
            this.setState({loading: false})
        }
    }

    render() {
        const {children} = this.props;
        const {id, loading} = this.state
        return <Spin spinning={loading}>
            <div id={id} style={{width:'100%',height:'100%'}}>{children}</div>
        </Spin>;
    }
}