import {createRoute, dynamicWrapper} from '@/utils/core'
import intl from 'react-intl-universal';

const routesConfig = app => ({
    path: '/account/forget-password',
    title: intl.get('/account/forget-password'),
    component: dynamicWrapper(app, [import('./model')], () =>
        import('./components')
    )
})

export default app => createRoute(app, routesConfig)
