import { Input, Layout, Modal, Tree } from "antd";
import { AntTreeNode } from "antd/lib/tree";
import { BaseModal } from "components/base-interface";
import { Unit, UnitInfoInterface } from "httpRequest/tokiot/sys/unit";
import React, { PureComponent } from "react";
import { AntTreeNodeSelectedEvent, TreeNodeNormal } from "antd/lib/tree/Tree";
import { PageClass } from "httpRequest/interface";
import { CommonModal } from "../../Modal/common-modal";

const { Search } = Input;
const { TreeNode } = Tree;
interface IProps extends BaseModal {
    onOk: (unitInfo: UnitInfoInterface.UnitInfo) => void;
    onlyType?: `Organs` | `Unit`;
}
interface IState {
    unitTree: PageClass<UnitInfoInterface.UnitInfo>;
    unitTreeNode: Array<TreeNodeNormal>;
}

class SelectUnitTableModal extends PureComponent<IProps, IState>{
    state: IState = {
        unitTree: new PageClass<UnitInfoInterface.UnitInfo>(),
        unitTreeNode: [],
    }
    componentDidMount() {
        this.getUnitTerr()
    }
    getNode = (unit: UnitInfoInterface.UnitInfo) => {
        const { onlyType } = this.props;
        let ret: TreeNodeNormal & { dataRef: TreeNodeNormal, unitInfo: UnitInfoInterface.UnitInfo } = {
            key: unit.id,
            title: unit.name,
            children: [],
            isLeaf: unit.type === `Unit`,
            dataRef: { key: unit.id, },
            unitInfo: unit,
        }
        if (onlyType) {
            ret.disabled = unit.type !== onlyType
        }
        ret.dataRef = ret;
        if (unit.childrenList && unit.childrenList.length > 0) {
            ret.children = unit.childrenList.map(f1 => this.getNode(f1));
        }
        return ret;
    }
    getUnitTerr = (name?: string) => {
        Unit.AsyncList({ name, size: 999 }).then(ret => {
            if (ret && ret.rows) {
                let unitTreeNode = ret.rows.map(f1 => this.getNode(f1));
                this.setState({ unitTree: ret, unitTreeNode });
            } else {
                this.setState({ unitTreeNode: [] });
            }
        })
    }
    onSearch = (name: string) => {
        this.getUnitTerr(name);
    }
    onSelect = (selectedKeys: string[], e: AntTreeNodeSelectedEvent) => {
        const { onOk, onClose } = this.props;
        onOk(e.node.props.dataRef.unitInfo);
        onClose && onClose();
    }

    loadData = async (treeNode: AntTreeNode) => {
        const ret = await Unit.AsyncList({ parentId: treeNode.props.dataRef.key, size: 999 });
        if (ret) {
            treeNode.props.dataRef.children = ret.rows.map(f1 => this.getNode(f1));
            this.forceUpdate();
        } else {
        }
    }

    renderTreeNodes = (data: Array<TreeNodeNormal>) =>
        data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode {...item} >
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            } else {
                return <TreeNode  {...item} children={undefined} />;
            }
        });
    render() {
        const { visible, onClose } = this.props;
        const { unitTreeNode } = this.state;
        return (
            <CommonModal
                maskClosable
                title={'请选择项目'}
                visible={visible}
                width={300}
                onCancel={e => { onClose && onClose() }}
                footer={null}
            >
                <Search placeholder='请输入项目名称' onSearch={this.onSearch} />
                {unitTreeNode.length > 0 ?
                    <Tree
                        loadData={this.loadData}
                        onSelect={this.onSelect}
                        defaultExpandedKeys={unitTreeNode.map(i => i.key)}
                        style={{ height: 'calc(100vh - 232px)', overflowY: 'auto' }}>
                        {this.renderTreeNodes(unitTreeNode)}
                    </Tree>
                    : ``}
            </CommonModal>
        )
    }
}

export { SelectUnitTableModal }