import { Popconfirm, Popover } from 'antd';
import classNames from 'classnames';
import Icon from 'components/Icon';
import React, { ReactNode } from 'react';
import styles from './icon-list.module.less';
import intl from 'react-intl-universal';
export interface IconItem {
    icon: ReactNode,
    name: string,
    onClick?: () => void,
    antd?: boolean,
    message?: string,
}
interface IProps {
    list: Array<IconItem>,
}

class IconList extends React.PureComponent<IProps> {
    render() {
        const { list } = this.props
        return (
            <div className={styles.listMain}>
                {list.map(f1 => {
                    if (f1.message) {
                        return (
                            <Popconfirm
                                title={f1.message}
                                okText={intl.get('button.ok')}
                                cancelText={intl.get('button.cancel')}
                                onConfirm={() => { f1.onClick && f1.onClick() }}
                            >
                                <div className={styles.iconItem} >
                                    <Icon antd={f1.antd} type={f1.icon} className={styles.icon} />
                                </div>
                            </Popconfirm>
                        )
                    } else {
                        return (
                            <Popover content={f1.name} trigger="hover">
                                <div className={styles.iconItem} onClick={e => { f1.onClick && f1.onClick() }}>
                                    <Icon antd={f1.antd} type={f1.icon} className={styles.icon} />
                                </div>
                            </Popover>
                        )
                    }
                })}
            </div>
        );
    }
}
export { IconList };