import { HttpUtils } from 'utils/http-utils'
import * as PermissionInterface from './interface'
const BaseUrl = "/api/sys/permission/";
const Permission = {

    Create: (params: PermissionInterface.CreateParam): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'create', params);
    },
    Delete: (params: { unitId: string, userId: string }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'delete', params);
    },
    Update: (params: { unitId: string, userId: string }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update', params);
    },
    Get: (params: { unitId: string, userId: string }): Promise<PermissionInterface.RoleInfo> => {
        return HttpUtils.Get(BaseUrl + 'get', params);
    },
    List: (params: { unitId: string, userId: string }): Promise<Array<PermissionInterface.RoleInfo>> => {
        return HttpUtils.Get(BaseUrl + 'list', params);
    },
    ResourcePermissionList: (params: { unitId: string, userId: string }): Promise<Array<PermissionInterface.RoleInfo>> => {
        return HttpUtils.Get(BaseUrl + 'resource-permission-list', params);
    },
    RoleResourcePermissionList: (): Promise<Array<PermissionInterface.RoleResourcePermissionInfo>> => {
        return HttpUtils.Get(BaseUrl + 'role-resource-permission-list');
    },
    RoleResourcePermissionListUpdate: (params: { roleId: string }): Promise<Array<PermissionInterface.RoleResourcePermissionInfo>> => {
        return HttpUtils.Get(BaseUrl + 'role-resource-permission-list-update', params);
    },
}
export { Permission, PermissionInterface };