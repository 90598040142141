import React from "react";
import { DatePicker, Form, Input, message, Select } from "antd";
import intl from "react-intl-universal";
import { BaseFormModal } from "components/base-interface";
import styles from "./index.module.less";
import { CommonModal } from "components/Modal/common-modal";
import {
  PayIotCard,
  PayIotCardInterface,
} from "httpRequest/tokiot/pay/iot-card";
import { DictGetList } from "utils/dict";
import moment from "moment";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    sm: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 17 },
  },
};

interface IProps extends BaseFormModal {
  selectInfo?: PayIotCardInterface.IotCardInfo;
  disabled?: boolean;
}

interface IState {}
class UpdateModalClass extends React.PureComponent<IProps, IState> {
  state: IState = {};
  componentDidMount() {}

  handleSubmit = () => {
    const { onChange, onClose, selectInfo, form } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (selectInfo) {
          PayIotCard.Update({
            id: selectInfo.id,
            buyCardTime: (values.buyCardTime as moment.Moment)?.format(
              `YYYY-MM-DD`
            ),
            cardType: values.cardType,
            checkoutUser: values.checkoutUser,
            feePayTime: (values.feePayTime as moment.Moment)?.format(
              `YYYY-MM-DD`
            ),
            feeState: values.feeState,
            remark: values.remark,
            cardExpireTime: (values.cardExpireTime as moment.Moment)?.format(
              `YYYY-MM-DD`
            ),
            cardSrc: values.cardSrc,
            cardStartTime: (values.cardStartTime as moment.Moment)?.format(
              `YYYY-MM-DD`
            ),
            syncState: values.syncState,
          }).then((ret) => {
            if (ret) {
              onChange && onChange();
              onClose && onClose();
            } else {
              message.error(`修改失败`);
            }
          });
        }
      } else {
        return;
      }
    });
  };

  render() {
    const { selectInfo, visible, disabled, onClose, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <CommonModal
        title={selectInfo ? (disabled ? `查看信息` : `修改信息`) : `新增信息`}
        onCancel={() => {
          onClose && onClose();
        }}
        onOk={this.handleSubmit}
        width={500}
        visible={visible}
      >
        <Form className={styles.form}>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`同步状态`}
          >
            {getFieldDecorator("syncState", {
              initialValue: selectInfo?.syncState,
              rules: [
                {
                  required: true,
                  message: `请选择同步状态`,
                },
              ],
            })(
              <Select disabled={disabled} placeholder={`请选择同步状态`}>
                {DictGetList(`PAY_IOTCARD_SYNC_STATE`)?.map((f1) => {
                  return (
                    <Select.Option value={f1.code}>{f1.codeName}</Select.Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`卡类型`}
          >
            {getFieldDecorator("cardType", {
              initialValue: selectInfo?.cardType,
            })(
              <Select
                allowClear
                disabled={disabled}
                placeholder={`请选择卡类型`}
              >
                {DictGetList(`PAY_IOTCARD_CARD_TYPE`)?.map((f1) => {
                  return (
                    <Select.Option value={f1.code}>{f1.codeName}</Select.Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`卡供应商`}
          >
            {getFieldDecorator("cardSrc", {
              initialValue: selectInfo?.cardSrc,
            })(
              <Select
                allowClear
                disabled={disabled}
                placeholder={`请选择卡供应商`}
              >
                {DictGetList(`PAY_IOTCARD_CARD_SRC`)?.map((f1) => {
                  return (
                    <Select.Option value={f1.code}>{f1.codeName}</Select.Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`卡开始时间`}
          >
            {getFieldDecorator("cardStartTime", {
              initialValue: selectInfo?.cardStartTime
                ? moment(selectInfo.cardStartTime)
                : undefined,
            })(
              <DatePicker
                allowClear
                style={{ width: `100%` }}
                disabled={disabled}
                format={`YYYY-MM-DD`}
                placeholder={`请选择卡开始时间`}
              />
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`卡到期时间`}
          >
            {getFieldDecorator("cardExpireTime", {
              initialValue: selectInfo?.cardExpireTime
                ? moment(selectInfo.cardExpireTime)
                : undefined,
            })(
              <DatePicker
                allowClear
                style={{ width: `100%` }}
                disabled={disabled}
                format={`YYYY-MM-DD`}
                placeholder={`请选择卡到期时间`}
              />
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`购卡时间`}
          >
            {getFieldDecorator("buyCardTime", {
              initialValue: selectInfo?.buyCardTime
                ? moment(selectInfo.buyCardTime)
                : undefined,
            })(
              <DatePicker
                allowClear
                style={{ width: `100%` }}
                disabled={disabled}
                format={`YYYY-MM-DD`}
                placeholder={`请选择购卡时间`}
              />
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`续费状态`}
          >
            {getFieldDecorator("feeState", {
              initialValue: selectInfo?.feeState,
            })(
              <Select
                allowClear
                disabled={disabled}
                placeholder={`请选择续费状态`}
              >
                {DictGetList(`PAY_IOTCARD_FEE_STATE`)?.map((f1) => {
                  return (
                    <Select.Option value={f1.code}>{f1.codeName}</Select.Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`续费时间`}
          >
            {getFieldDecorator("feePayTime", {
              initialValue: selectInfo?.feePayTime
                ? moment(selectInfo.feePayTime)
                : undefined,
            })(
              <DatePicker
                allowClear
                style={{ width: `100%` }}
                disabled={disabled}
                format={`YYYY-MM-DD`}
                placeholder={`请选择续费时间`}
              />
            )}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`出库人`}
          >
            {getFieldDecorator("checkoutUser", {
              initialValue: selectInfo?.checkoutUser,
            })(<Input disabled={disabled} placeholder={`请输入出库人`} />)}
          </FormItem>
          <FormItem
            className={styles.formItem}
            {...formItemLayout}
            label={`备注`}
          >
            {getFieldDecorator("remark", {
              initialValue: selectInfo?.remark,
            })(<Input disabled={disabled} placeholder={`请输入备注`} />)}
          </FormItem>
        </Form>
      </CommonModal>
    );
  }
}
const UpdateModal = Form.create<IProps>()(UpdateModalClass);
export { UpdateModal };
