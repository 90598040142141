import React from 'react'
import './style/listfooter.less'

const ListFooter = (props) => {
    return (
        <a onClick={() => props.onSetReadBatch(props.type)}>
            <div className="list-footer">
                {props.title}
            </div>
        </a>
    )
}

export default ListFooter