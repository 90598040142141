import { CommonModal } from "components/Modal/common-modal";
import React from "react";
import { message } from "antd";
import styles from "./user-group-modal.module.less"
import classNames from "classnames";
import { UnitGroupInterface } from "httpRequest/tokiot/sys/unit/group";
import { PageClass } from "httpRequest/interface";
import { UserGroupAddModal } from "./user-group-add-modal";
import { PaginationLayout, SearchItem } from "layouts/pagination";
import { DownloadImg } from "utils/constant";
import { CommonParameter } from "utils/common-parameter";
import AddImage from "./image/add.png";
import DeleteImage from "./image/delete-blue.png";
import { SimpleModal } from "components/Modal/simple-modal";
import { UnitGroupUser, UnitGroupUserInterface } from "httpRequest/tokiot/sys/unit/group/user";
import { CommonCard } from "components/card/common-card";
interface IProps {
    visible: boolean;
    selectGroup: UnitGroupInterface.UnitGroupInfo;
    onClose?: () => void;
    onChange?: () => void;
}
interface IState {
    addUserVisible: boolean;
    selectUserList: PageClass<UnitGroupUserInterface.SelectUserInfo>;
}
class UserGroupModal extends React.PureComponent<IProps, IState> {
    state: IState = {
        addUserVisible: false,
        selectUserList: new PageClass(),
    }
    componentDidMount() {
    }
    searchData = {};
    getUserList = (data?: any) => {
        const { selectGroup } = this.props;
        UnitGroupUser.List({ unitGroupId: selectGroup.id, ...data }).then(ret => {
            this.searchData = data;
            if (ret && ret.rows && ret.rows.length > 0) {
                this.setState({ selectUserList: ret });
            } else {
                this.setState({ selectUserList: new PageClass() });
            }
        })
    }

    deleteUser = (id: string) => {
        const { onChange } = this.props;
        UnitGroupUser.Delete({ id }).then(ret => {
            if (ret) {
                this.getUserList(this.searchData);
                this.userGroupAddModalRef.current?.Refresh();
                onChange && onChange();
            } else {
                message.error(`删除设备失败`)
            }
        })
    }


    addUserVisibleChange = () => {
        this.setState({ addUserVisible: !this.state.addUserVisible });
    }
    rightRef = React.createRef<HTMLDivElement>();
    userGroupAddModalRef = React.createRef<UserGroupAddModal>();
    getUserModal = () => {
        let { addUserVisible } = this.state;
        let { selectGroup, onChange } = this.props;
        let getContainer = this.rightRef.current ?? document.body;

        return (
            <div ref={this.rightRef} >
                {addUserVisible ?
                    <UserGroupAddModal
                        ref={this.userGroupAddModalRef}
                        selectGroup={selectGroup}
                        getContainer={() => getContainer}
                        visible={addUserVisible}
                        onClose={this.addUserVisibleChange}
                        onChange={() => {
                            this.getUserList(this.searchData);
                            this.userGroupAddModalRef.current?.Refresh();
                            onChange && onChange();
                        }}
                    />
                    : ``}
            </div>
        )
    }



    render() {
        const { selectUserList, addUserVisible } = this.state;
        const { visible, onClose, selectGroup } = this.props;
        return (
            <CommonModal
                centered
                width={addUserVisible ? `1200px` : `900px`}
                title={`绑定用户`}
                visible={visible}
                onCancel={onClose}
                footer={<div style={{ height: `32px` }} />}
                rightButtons={this.getUserModal()}
                mask={true}
            >
                <div className={styles.userGroupManage} >
                    <PaginationLayout
                        onSearch={this.getUserList}
                        pageData={selectUserList}
                        showSizeChanger={false}
                        itemSize={{ width: 280, rowCount: 7, showAdd: true }}
                    >
                        {(width, height, columnCount) => {
                            let style: React.CSSProperties = { width: `${100 / (columnCount ?? 100)}%` };
                            return (
                                <div className={styles.userBody} >
                                    <CommonCard className={classNames(styles.addItem, styles.userItem)} style={style} onClick={this.addUserVisibleChange} >
                                        <div className={styles.userItemBody} >
                                            <img alt={``} className={styles.image} src={AddImage} />
                                            <div className={styles.info}>新增用户</div>
                                        </div>
                                    </CommonCard>
                                    {selectUserList.rows.map(f1 => {
                                        return (<CommonCard className={styles.userItem} style={style}>
                                            <div className={styles.userItemBody} >
                                                <SimpleModal title={`删除-${f1.realName}`} message={`确定要删除[${f1.realName}]吗？`} onOk={() => this.deleteUser(f1.id)}>
                                                    <img alt={``} className={styles.deleteImage} src={DeleteImage} />
                                                </SimpleModal>
                                                <img alt={``} className={styles.image} src={DownloadImg + f1.avatar} />
                                                <div className={styles.info}>
                                                    <div className={styles.text1}>{f1.realName}</div>
                                                    <div className={styles.text2}>{`电话：` + f1.tel}</div>
                                                </div>
                                            </div>
                                        </CommonCard>)
                                    })}
                                </div>
                            )
                        }}
                    </PaginationLayout>
                </div>
            </CommonModal>
        );
    }
}
export { UserGroupModal };

