import { Modal } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import styles from './simple-modal.module.less';
interface IProps {
    onOk: () => void;
    title?: ReactNode;
    message?: ReactNode;
}

interface IState {
    visible: boolean;
}
class SimpleModal extends React.PureComponent<IProps, IState> {
    state: IState = {
        visible: false,
    }

    componentDidMount() {
    }

    visibleChange = () => {
        this.setState({ visible: !this.state.visible });
    }

    onOk = () => {
        const { onOk, } = this.props;
        this.setState({ visible: false });
        onOk && onOk();
    }

    render() {
        const { children, onOk, title, message } = this.props;
        const { visible } = this.state;
        let last = (children as ReactElement).props.onClick;
        let body = React.cloneElement(children as ReactElement, { onClick: () => { last && last(); this.visibleChange() } });
        return (
            <React.Fragment>
                {visible ?
                    <Modal
                        destroyOnClose={true}
                        mask={true}
                        maskClosable={true}
                        width={`400px`}
                        centered
                        visible={visible}
                        title={title}
                        onOk={this.onOk}
                        onCancel={this.visibleChange}
                    >
                        <div className={styles.message}>
                            {message}
                        </div>
                    </Modal>
                    : ``}
                {body}
            </React.Fragment >
        );
    }
}

export { SimpleModal };