import device from './device';
import inspect from './inspect';
import inspectTask from './inspectTask';
import inspectTaskRecord from './inspectTaskRecord';
import other from './other';
import push from './push';
import sys from './sys';
import workOrder from './workOrder';
import document from './document';
import weather from './weather';
import linkage from './linkage';
import ota from './ota';
import datav from './datav';
import pay from './pay';
import job from './job';

/**
 * 这里包含了所有接口
 */
export default {
    device, inspect, inspectTask, inspectTaskRecord,
    other, push, sys, workOrder, document, weather, linkage,
    ota, datav, pay,job
};