import $$ from 'cmn-utils'

export default {
    devEventReport: function devEventReport(params) {
        return $$.post('/api/push/dev-event-report', params)
    },
    productVerPublic: function productVerPublic(params) {
        return $$.post('/api/push/product-ver-public', params)
    }

}