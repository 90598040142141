import { HttpUtils } from 'utils/http-utils'
import { Page, ParamsId } from 'httpRequest/interface'
import * as UnitGroupInterface from './interface'
namespace UnitGroup {
    const BaseUrl = "/api/sys/unit/group/";
    export const List = (params: UnitGroupInterface.UnitGroupListParams): Promise<Page<UnitGroupInterface.UnitGroupInfo>> => {
        return HttpUtils.Get(BaseUrl + `list`, params);
    }
    export const Create = (params: UnitGroupInterface.UnitGroupCreateParams): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + `create`, params);
    }
    export const Update = (params: UnitGroupInterface.UnitGroupUpdateParams): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + `update`, params);
    }
    export const Delete = (params: ParamsId): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + `delete`, params);
    }
}
export { UnitGroup, UnitGroupInterface };