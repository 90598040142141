import { createRoutes } from "@/utils/core";
import BasicLayout from "@/layouts/BasicLayout";
import UserLayout from "@/layouts/UserLayout";
import ScreenLayout from "@/layouts/ScreenLayout";
import IFrameLayout from "@/layouts/IFrameLayout";
import NotFound from "./Pages/404";
import Page500 from "./Pages/500";
import Coming from "./Widgets/Coming";
import Login from "./account/login";
import About from "./about";
import ForgetPwd from "./account/forget";
import Register from "./Register";
import intl from "react-intl-universal";

import Linkage from "./linkage";
//档案管理
import BuildImage from "./document/buildimage";
import ReportImage from "./document/reportimage";
import XCALImage from "./document/xcal";
//系统管理
import SystemUser from "./system/user";
import SystemArea from "./system/area";
import SystemUnit from "./system/unit";
import SystemRole from "./system/role";
import SystemUnitGroup from "./system/unit-group";
import SystemAccess from "./system/access/list/index";
import SystemAccessDetail from "./system/access/detail";
import SystemMenu from "./system/menu";
import SystemPermission from "./system/permission";
import SystemPush from "./system/push";
import SystemURL from "./system/url";
import SystemDict from "./system/dict";
import SystemCategory from "./system/category";
import SystemProtocal from "./system/protocal";
import SystemUserProtocal from "./system/userProtocal";
//记录查询
import RecordEventAll from "./record/event/all";
import RecordEventCommon from "./record/event/common";
import RecordEventHeart from "./record/event/heart";
import RecordEventRealTime from "./record/event/realtime";
import RecordEventHistory from "./record/event/history";
import RecordNotice from "./record/notice";
import RecordLog from "./record/log";
//工单管理
import OrderRealTime from "./order/realtime";
import OrderHistory from "./order/history";
import OrderAnalyze from "./order/analyze";
//巡检管理
import InspectionRecord from "./inspection/record";
import InspectionTask from "./inspection/task";
import InspectionTaskNo from "./inspection/taskno";
import InspectionTrajectory from "./inspection/trajectory";
//报表管理
import Report from "./report";
import Report2 from "./report2";
//设备管理
import DeviceIndex from "./device";
//固件管理
import Ota from "./ota";
//首页
import Home from "./home";
//数字大屏
// import Screen from './screen/datascreen/index'
import DataScreen from "./screen/datav/index";
import Datav from "./screen/index";
import Datav3D from "./screen/datav-3d";
import Datav2021 from "./screen/datav-2021";
import Player from "./player/index";
//费用账单
import ArchiveDevice from "./pay/archiveDevice/index";
import TelcomCard from "./pay/telecomCard/index";
import ServiceCharge from "./pay/serviceCharge";
import IotCard from "./pay/iot-card";
//电焊作业
import ApproveForm from "./job/approveForm";
import ApproveDetail from "./job/approveDetail";
import ApproveDeviceMap from "./job/deviceMap";
import Global from "./global";

/**
 * 主路由配置
 *
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */
const routesConfig = (app) => [
  {
    path: "/document",
    title: "档案管理",
    indexRoute: "/document/:type",
    component: BasicLayout,
    childRoutes: [
      BuildImage(app),
      ReportImage(app),
      XCALImage(app),
      NotFound(),
    ],
  },
  {
    path: "/pay",
    title: "费用账单",
    indexRoute: "/pay/:type",
    component: BasicLayout,
    childRoutes: [
      ArchiveDevice(app),
      ServiceCharge(app),
      TelcomCard(app),
      IotCard(app),
      NotFound(),
    ],
  },
  {
    path: "/account",
    title: "登录",
    indexRoute: "/account/login",
    component: UserLayout,
    childRoutes: [Login(app), ForgetPwd(app), Register(app), NotFound()],
  },
  {
    path: "/system/base",
    title: intl.get("/system"),
    component: BasicLayout,
    indexRoute: "/system/base/user",
    childRoutes: [
      SystemUser(app),
      SystemArea(app),
      SystemUnit(app),
      SystemRole(app),
      SystemUnitGroup(app),
      SystemPermission(app),
      SystemPush(app),
      Coming(),
      Page500(),
      NotFound(),
    ],
  },
  {
    path: "/system/super",
    title: intl.get("/system"),
    component: BasicLayout,
    indexRoute: "/system/super/menu",
    childRoutes: [
      SystemAccessDetail(app),
      SystemAccess(app),
      SystemMenu(app),
      SystemPermission(app),
      SystemURL(app),
      SystemDict(app),
      SystemCategory(app),
      Coming(),
      Page500(),
      NotFound(),
    ],
  },
  {
    path: "/system/protocal-manage",
    title: intl.get("/system"),
    component: BasicLayout,
    indexRoute: "/system/protocal-manage/protocal",
    childRoutes: [
      SystemProtocal(app),
      SystemUserProtocal(app),
      Coming(),
      Page500(),
      NotFound(),
    ],
  },
  {
    path: "/system",
    title: intl.get("/system"),
    component: BasicLayout,
    indexRoute: "/system/base",
    childRoutes: [],
  },
  {
    path: "/device",
    title: intl.get("/device"),
    indexRoute: "/device/index",
    component: BasicLayout,
    childRoutes: [DeviceIndex(app), NotFound()],
  },
  {
    path: "/record",
    title: intl.get("/record"),
    component: BasicLayout,
    indexRoute: "/record/event",
    childRoutes: [
      RecordEventAll(app),
      RecordEventCommon(app),
      RecordEventHeart(app),
      RecordEventRealTime(app),
      RecordEventHistory(app),
      RecordLog(app),
      RecordNotice(app),
      Coming(),
      Page500(),
      NotFound(),
    ],
  },
  {
    path: "/order",
    title: intl.get("/order"),
    indexRoute: "/order/myself",
    component: BasicLayout,
    childRoutes: [
      OrderAnalyze(app),
      OrderHistory(app),
      OrderRealTime(app),
      NotFound(),
    ],
  },
  {
    path: "/inspect",
    title: intl.get("/inspection"),
    indexRoute: "/inspect/task",
    component: BasicLayout,
    childRoutes: [
      InspectionTask(app),
      InspectionTaskNo(app),
      InspectionRecord(app),
      InspectionTrajectory(app),
      NotFound(),
    ],
  },
  {
    path: "/report2",
    title: intl.get("/report"),
    indexRoute: "/report2/index",
    component: BasicLayout,
    childRoutes: [Report(app), NotFound()],
  },
  {
    path: "/report",
    title: intl.get("/report"),
    indexRoute: "/report/index",
    component: BasicLayout,
    childRoutes: [Report2(app), NotFound()],
  },
  {
    path: "/about",
    title: "关于我们",
    indexRoute: "/about/index",
    component: BasicLayout,
    childRoutes: [About(app), NotFound()],
  },
  {
    path: "/ota",
    title: "关于我们",
    indexRoute: "/ota/index",
    component: BasicLayout,
    childRoutes: [Ota(app), NotFound()],
  },
  {
    path: "/datav/datascreen/:id",
    title: intl.get("/datav"),
    indexRoute: "/datav/datascreen/index/:id",
    component: ScreenLayout,
    childRoutes: [DataScreen(app), NotFound()],
  },
  {
    path: "/screen",
    title: "大屏",
    component: ScreenLayout,
    childRoutes: [Datav3D(app), Datav2021(app), Datav(app), NotFound()],
  },
  {
    path: "/job",
    title: "作业审批",
    indexRoute: "/job/approve",
    component: BasicLayout,
    childRoutes: [
      ApproveDetail(app),
      ApproveForm(app),
      ApproveDeviceMap(app),
      NotFound(),
    ],
  },
  {
    path: "/home",
    title: intl.get("/home"),
    component: BasicLayout,
    indexRoute: "/home/index",
    childRoutes: [Home(app), NotFound()],
  },
  {
    path: "/linkage",
    title: "智能场景",
    component: BasicLayout,
    indexRoute: "/linkage/index",
    childRoutes: [Linkage(app), NotFound()],
  },
  {
    path: "/player",
    title: "播放器",
    component: IFrameLayout,
    indexRoute: "/player",
    childRoutes: [Player(app), NotFound()],
  },
  {
    path: "/global",
    title: "外部跳转",
    component: BasicLayout,
    indexRoute: "/global",
    childRoutes: [Global(app), NotFound()],
  },
  {
    path: "/",
    title: intl.get("/home"),
    component: BasicLayout,
    indexRoute: "/home/index",
    childRoutes: [Home(app), NotFound()],
  },
];

export default (app) => createRoutes(app, routesConfig);
