import React from 'react';
import PageLoading from 'components/Loading/PageLoading';
// import {normal} from 'components/Notification';
import {message} from 'antd'
import store from 'cmn-utils/lib/store';
import {baseUrl} from '@/utils/constant'

// 系统通知, 定义使用什么风格的通知，normal或antdNotice
const notice = message;

/**
 * 应用配置 如请求格式，反回格式，异常处理方式，分页格式等
 */
export default {
    /**
     * HTML的title模板
     */
    htmlTitle: '智慧消防云平台',

    /**
     * 系统通知
     */
    notice,

    // 异步请求配置
    request: {
        prefix: baseUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        // 每次请求头部都会带着这些参数
        withHeaders: () => ({
            ...store.getStore('headers')
        }),
        credentials: 'include',
        /**
         * 因为modelEnhance需要知道服务器反回的数据，
         * 什么样的是成功，什么样的是失败，如
         * {status: true, data: ...} // 代表成功
         * {status: false, message: ...} // 代表失败
         * 实际中应该通过服务端反回的response中的
         * 成功失败标识来进行区分
         */
        afterResponse: (response, a, b, c) => {
            const {code, body, message} = response
            if (!code) return response
            if (code === 'OK') {
                return body !== null ? body : true
            } else if (code === 'UNAUTHORIZED') {
                window.location.hash = '/account/login'
                return response
            } else if (code === 'NOT_FOUND') {
                return response
            } else if (code === "FAILED") {
                notice.error(message)
                return null
            } else {
                notice.error(message)
                // throw new Error(message)
            }
            // return Promise.reject()
        },
        errorHandle: err => {
            // 请求错误全局拦截
            if (err.name === 'RequestError') {
                // notice.error(err.text || err.message);
                notice.error('网络异常，请稍后重试。');
                console.info(err.text || err.message);
            }
        }
    },

    // 全局异常
    exception: {
        global: (err, dispatch) => {
            const errName = err.name;
            // RequestError为拦截请求异常
            if (errName === 'RequestError') {
                // notice.error(err.message);
                notice.error('网络异常，请稍后重试。');
                console.error(err);
            } else {
                console.error(err);
            }
        },
    },

    // 分页助手
    pageHelper: {
        // 格式化要发送到后端的数据
        requestFormat: pageInfo => {
            const {pageNum, pageSize, filters, sorts} = pageInfo
            const keys = Object.keys(filters)
            for (let key of keys) {
                if (!filters[key]) delete filters[key]
            }
            return {
                page: pageNum,
                size: pageSize,
                sortMap: sorts,
                ...filters
            }
        },

        // 格式化从后端反回的数据
        responseFormat: ret => {
            const {
                pageNumber = 1,
                pageSize = 0,
                total = 0,
                rows = [],
                pageTotal = 0

            } = ret
            return {
                pageNum: pageNumber,
                pageSize: pageSize,
                total: total,
                totalPages: pageTotal,
                list: rows
            }
        }
    },

    // 路由加载效果
    router: {
        loading: <PageLoading loading/>
    },

    /**
     * 模拟数据时包装反回数据
     * 因为，后端反回数据时一般都会在外边包装一层状态信息
     * 如成功时：
     * {
     *   status: true,
     *   data: responseData
     * }
     * 或出错时：
     * {
     *   status: false,
     *   code: 500,
     *   message: '用户名或密码错误'
     * }
     * 这里就是配置这两个函数，为了我们模拟数据时可以少写几行代码的 orz...
     */
    mock: {
        toSuccess: response => ({
            code: 'OK',
            body: response,
            message: '模拟数据成功'
        }),

        toError: response => ({
            code: 'FAILED',
            body: response,
            message: '模拟数据失败'
        })
    }
};
