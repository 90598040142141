import { Page } from 'httpRequest/interface';
import { HttpUtils } from 'utils/http-utils'
import * as UserInterface from './interface'
const BaseUrl = "/api/sys/user/";
const User = {
    GetDetail: (): Promise<UserInterface.UserInfo> => {
        return HttpUtils.Get(BaseUrl + 'get-detail');
    },
    List: (params: UserInterface.UserListParams): Promise<Page<UserInterface.UserInfo>> => {
        return HttpUtils.Get(BaseUrl + 'list', params);
    },
    UpdatePrefer: (params: UserInterface.UpdatePreferParams): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update-prefer', params);
    },
    UpdateAvatar: (params: { avatar: string }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update-avatar', params);
    },
    UpdateRealName: (params: { realName: string }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update-real-name', params);
    },
    UpdateTel: (params: { tel: string }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update-tel', params);
    },
    UpdateEmail: (params: { email: string }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update-email', params);
    },
    UpdatePassword: (params: { oldPassword: string, newPassword: string }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'change-password', params);
    },
    Update: (params: {
        avatar: string | undefined, dataState: string, email: string, id: string, password: string,
        pushApp: boolean, pushSms: boolean, pushTel: boolean, pushWeb: boolean, pushWechat: boolean,
        pwdExpriredTimeType: number, realName: string, roleId: string, tel: string,
    }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'update', params);
    },
    Create: (params: {
        avatar: string | undefined, dataState: string, email: string, password: string,
        pushApp: boolean, pushSms: boolean, pushTel: boolean, pushWeb: boolean, pushWechat: boolean,
        pwdExpriredTimeType: number, realName: string, roleId: string, tel: string, username: string, unitId: string,
    }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'create', params);
    },
    ExistMobile: (params: { id?: string, mobile: string }): Promise<boolean> => {
        return HttpUtils.Get(BaseUrl + 'exist-mobile', params);
    },
    ExistUsername: (params: { id?: string, username: string }): Promise<boolean> => {
        return HttpUtils.Get(BaseUrl + 'exist-username', params);
    },
    Delete: (params: { id: string, }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + 'delete', params);
    },
}
export { User, UserInterface };