import { Pagination, Spin } from "antd";
import { PaginationStyle } from "components/common-styles/common-styles";
import { BaseParams, Page } from "httpRequest/interface";
import React, { ReactNode } from "react";
import styles from "./index.module.less";
import { SearchBar, SearchBarPoros, SearchItem as Item } from "./search-bar";
import ReactResizeDetector from "react-resize-detector";
import { PaginationProps } from "antd/lib/pagination";
import { CommonEmpty } from "components/Empty/common-empty";

type BaseType = SearchBarPoros & PaginationProps;
export interface PaginationLayoutPoros extends Omit<BaseType, `onSearch`> {
  pageData: Omit<Page<{}>, `rows`>;
  /** 默认请求条数(优先高于itemSize) */
  defaultPageSize?: number;
  /** 用于计算每页显示item的个数 */
  itemSize?: {
    /** item的宽度(px)*/
    width: number;
    /** 要显示多少行 */
    rowCount: number;
    /** 是否显示添加按钮（请求时数量减一） */
    showAdd?: boolean;
  };
  /** 没有数据的时候，是否显示暂无数据 */
  showEmpty?: boolean;
  children?:
    | ReactNode
    | ((height?: number, width?: number, columnCount?: number) => ReactNode);
  onSearch?: (data?: any) => void;
}
interface IState {
  searchKey: number;
}
class PaginationLayout extends React.PureComponent<
  PaginationLayoutPoros,
  IState
> {
  state: IState = {
    searchKey: 0,
  };
  bodyRef = React.createRef<HTMLDivElement>();
  pageData: BaseParams = {};
  componentDidMount() {
    const { itemSize, defaultPageSize } = this.props;
    let pageSize = 20;
    if (defaultPageSize && defaultPageSize > 0) {
      pageSize = defaultPageSize;
    } else if (itemSize) {
      let bodyRect = this.bodyRef.current?.getClientRects()[0];
      let columnCount = Math.floor((bodyRect?.width ?? 0) / itemSize.width);
      columnCount =
        columnCount * itemSize.rowCount - (itemSize.showAdd ? 1 : 0);
      if (columnCount <= 0) {
        columnCount = 1;
      }
      pageSize = columnCount;
    }
    this.pageData = { size: pageSize, page: 1 };
    this.setState({ searchKey: this.state.searchKey + 1 });
  }
  paginationChange = (page: number, pageSize?: number) => {
    this.pageData = { page: page, size: pageSize };
    this.setState({ searchKey: this.state.searchKey + 1 });
  };
  onSearch = (data?: any) => {
    const { onSearch } = this.props;
    if (!data) {
      data = {};
    }
    onSearch && onSearch({ ...this.pageData, ...data });
  };

  ifFuncton = (children: any): children is Function => {
    return typeof children === `function`;
  };

  render() {
    const { children, pageData, itemSize, showEmpty } = this.props;
    const { searchKey } = this.state;
    let paginationProps = this.props as PaginationProps;
    let searchBarProps = this.props as SearchBarPoros;
    let itemAdd = itemSize?.showAdd === true;
    return (
      <div className={styles.main}>
        <SearchBar
          {...searchBarProps}
          searchKey={searchKey}
          className={styles.searchBar}
          onSearch={this.onSearch}
        />
        <div className={styles.body} ref={this.bodyRef}>
          {pageData.total <= 0 && !itemAdd && !showEmpty ? (
            <CommonEmpty />
          ) : (
            <ReactResizeDetector handleHeight>
              {({ width, height }) => {
                let columnCount: number | undefined = undefined;
                if (width && itemSize) {
                  columnCount = Math.floor(width / itemSize.width);
                }
                if (this.ifFuncton(children)) {
                  return (
                    children(width, height, columnCount) ?? <React.Fragment />
                  );
                } else {
                  return children ?? <React.Fragment />;
                }
              }}
            </ReactResizeDetector>
          )}
        </div>
        {pageData.pageTotal > 1 ? (
          <div className={styles.paginationDiv}>
            <Pagination
              showSizeChanger
              showQuickJumper
              showTotal={(total, range) => {
                return <div className={styles.total}>{`共 ${total} 条`}</div>;
              }}
              onShowSizeChange={this.paginationChange}
              onChange={this.paginationChange}
              className={PaginationStyle}
              current={pageData.pageNumber}
              pageSize={pageData.pageSize}
              total={pageData.total}
              {...paginationProps}
            />
          </div>
        ) : (
          ``
        )}
      </div>
    );
  }
}
export type SearchItem = Item;
export { PaginationLayout };
