import React, { Component } from "react";
import { Col, Form, Icon, Input, Modal, Row, Select, Upload } from "antd";
import Panel from "@/components/Panel";
import { serviceKey, uploadImg } from "@/utils/constant";
import $$ from "cmn-utils";
import * as DICT from "@/utils/dict";
import intl from "react-intl-universal";

let headers = $$.getStore("headers");

const uploadFileType = "image/*";
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">{intl.get("button.upload2")}</div>
  </div>
);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const lastFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 22 },
  },
};
const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

let uploadKey = 1;

class DealModal extends Component {
  componentDidMount() {
    const { form } = this.props;
    const position = $$.getStore("position");
    console.info("定位", position);
    form.setFieldsValue({ lng: position[0], lat: position[1] });
  }

  componentDidUpdate() {
    headers = $$.getStore("headers");
  }

  /**
   * 处理上传
   */
  handleUpload(e) {
    const { fileList } = e;
    return fileList.map((item) => {
      const { response, status } = item;
      if (status === "done") {
        if (response && response.code === "OK") {
          return response.body;
        }
      }
    });
  }

  /**
   * 处理表单提交
   */
  handleSubmit(e) {
    e.preventDefault();
    const { onOk, form, detail } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }
      values.id = detail.id;
      if (values.image1) values.image1 = values.image1[0];
      else delete values.image1;
      if (values.image2) values.image2 = values.image2[0];
      else delete values.image2;
      if (values.image3) values.image3 = values.image3[0];
      else delete values.image3;
      if (values.image4) values.image4 = values.image4[0];
      else delete values.image4;
      const position = $$.getStore("position");
      values.lng = position[0];
      values.lat = position[1];
      onOk(values);
    });
  }

  render() {
    const { visible, title, onCancel, detail, form } = this.props;
    const { getFieldDecorator } = form;
    const { deviceList, type, alarmLogHistory } = detail;
    const modalProps = {
      visible,
      title,
      maskClosable: false,
      onOk: (e) => this.handleSubmit(e),
      onCancel,
    };
    return (
      <Modal
        width={880}
        {...modalProps}
        afterClose={() => {
          form.resetFields();
          uploadKey++;
        }}
      >
        <Row gutter={10}>
          <Col span={10}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {type == "Alarm" && alarmLogHistory ? (
                <div
                  style={{
                    marginBottom: "10px",
                    fontSize: "12px",
                  }}
                >
                  <Panel title={intl.get("order.realtime.alarmInfo.title")}>
                    <Row gutter={3}>
                      <Col span={6}>
                        {intl.get("order.realtime.alarmInfo.alarmName")}：
                      </Col>
                      <Col span={18}>{alarmLogHistory.eventName}</Col>
                    </Row>

                    <Row gutter={3}>
                      <Col span={6}>
                        {intl.get("order.realtime.alarmInfo.time")}
                      </Col>
                      <Col span={18}>{alarmLogHistory.time}</Col>
                    </Row>
                    <Row gutter={3}>
                      <Col span={24}>
                        {intl.get("order.realtime.alarmInfo.prop")}：
                      </Col>
                    </Row>
                    <Row gutter={3}>
                      <Col push={1} span={23}>
                        {JSON.parse(alarmLogHistory.params).map(
                          (item, index) => (
                            <Row
                              key={index}
                              gutter={5}
                              style={{ borderBottom: "1px solid #eee" }}
                            >
                              <Col span={7}>{item.name}：</Col>
                              <Col span={14}>{item.value}</Col>
                            </Row>
                          )
                        )}
                      </Col>
                    </Row>
                  </Panel>
                </div>
              ) : (
                ""
              )}

              <div style={{ flex: 1 }}>
                <Panel title={intl.get("order.realtime.deviceInfo.title")}>
                  {deviceList &&
                    deviceList.map((item) => (
                      <div
                        key={item.id}
                        style={{ marginBottom: "10px", fontSize: "12px" }}
                      >
                        <Row gutter={3}>
                          <Col span={6}>
                            {intl.get("order.realtime.deviceInfo.name")}：
                          </Col>
                          <Col span={18}>{item.name}</Col>
                        </Row>
                        <Row gutter={3}>
                          <Col span={6}>
                            {intl.get("order.realtime.deviceInfo.sn")}：
                          </Col>
                          <Col span={18}>{item.sn}</Col>
                        </Row>
                        <Row gutter={3}>
                          <Col span={6}>
                            {intl.get("order.realtime.deviceInfo.area")}：
                          </Col>
                          <Col span={18}>{item.areaName}</Col>
                        </Row>
                        <Row gutter={3}>
                          <Col span={6}>
                            {intl.get("order.realtime.deviceInfo.address")}：
                          </Col>
                          <Col span={18}>{item.address}</Col>
                        </Row>
                        <Row gutter={3}>
                          <Col span={6}>
                            {intl.get("order.realtime.deviceInfo.unit")}：
                          </Col>
                          <Col span={18}>{item.unitName}</Col>
                        </Row>
                      </div>
                    ))}
                </Panel>
              </div>
            </div>
          </Col>
          <Col span={14}>
            <div
            // style={{
            //     height: '350px',
            //     overflowY: 'scroll',
            //     overflowX: 'hidden'
            // }}
            >
              {/* <h4>确认完成</h4> */}
              <FormItem
                {...formItemLayout}
                label={intl.get("form.order.handleMethod")}
              >
                {getFieldDecorator("handleMethod", {
                  rules: [
                    {
                      required: true,
                      message: intl.get("form.order.handleMethod.please"),
                    },
                  ],
                })(
                  <Select style={{ width: "100%" }}>
                    {DICT.getList("ORDER_HANDLE_METHOD").map((item) => (
                      <Option key={item.code} value={item.code}>
                        {item.codeName}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem
                {...formItemLayout}
                label={intl.get("form.order.handleRemark")}
              >
                {getFieldDecorator("handleRemark", {
                  // rules: [{required: true, message: intl.get('form.order.handleRemark.please')}]
                })(
                  <TextArea
                    placeholder={intl.get("form.order.handleRemark.please")}
                    rows={4}
                  />
                )}
              </FormItem>
              <FormItem {...lastFormItemLayout} label=" " colon={false}>
                <Row gutter={10}>
                  <Col span={6}>
                    <FormItem label={"现场照片1"} colon={false}>
                      {getFieldDecorator("image1", {
                        getValueFromEvent: (e) =>
                          this.handleUpload(e, "img1Url"),
                      })(
                        <Upload
                          key={"1" + uploadKey}
                          name="file"
                          data={{ type: "AlarmImage" }}
                          accept={uploadFileType}
                          action={uploadImg}
                          listType="picture-card"
                          headers={(() => $$.getStore("headers"))()}
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: false,
                          }}
                        >
                          {form.getFieldValue("image1") &&
                          form.getFieldValue("image1").length >= 1
                            ? null
                            : uploadButton}
                        </Upload>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label={"现场照片2"} colon={false}>
                      {getFieldDecorator("image2", {
                        getValueFromEvent: (e) =>
                          this.handleUpload(e, "img1Url"),
                      })(
                        <Upload
                          key={"2" + uploadKey}
                          name="file"
                          data={{ type: "AlarmImage" }}
                          accept={uploadFileType}
                          listType="picture-card"
                          headers={(() => $$.getStore("headers"))()}
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: false,
                          }}
                        >
                          {form.getFieldValue("image2") &&
                          form.getFieldValue("image2").length >= 1
                            ? null
                            : uploadButton}
                        </Upload>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label={"现场照片3"} colon={false}>
                      {getFieldDecorator("image3", {
                        getValueFromEvent: (e) =>
                          this.handleUpload(e, "img1Url"),
                      })(
                        <Upload
                          key={"3" + uploadKey}
                          name="file"
                          data={{ type: "AlarmImage" }}
                          accept={uploadFileType}
                          action={uploadImg}
                          listType="picture-card"
                          headers={(() => $$.getStore("headers"))()}
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: false,
                          }}
                        >
                          {form.getFieldValue("image3") &&
                          form.getFieldValue("image3").length >= 1
                            ? null
                            : uploadButton}
                        </Upload>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label={"现场照片4"} colon={false}>
                      {getFieldDecorator("image4", {
                        getValueFromEvent: (e) =>
                          this.handleUpload(e, "img1Url"),
                      })(
                        <Upload
                          key={"4" + uploadKey}
                          name="file"
                          data={{ type: "AlarmImage" }}
                          accept={uploadFileType}
                          action={uploadImg}
                          listType="picture-card"
                          headers={(() => $$.getStore("headers"))()}
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: false,
                          }}
                        >
                          {form.getFieldValue("image4") &&
                          form.getFieldValue("image4").length >= 1
                            ? null
                            : uploadButton}
                        </Upload>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="经度"
                style={{ display: "none" }}
              >
                {getFieldDecorator("lng", {})(<Input placeholder="经度" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="纬度"
                style={{ display: "none" }}
              >
                {getFieldDecorator("lat", {})(<Input placeholder="纬度" />)}
              </FormItem>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default Form.create()(DealModal);
