import { Button, message, Upload } from "antd";
import { CommonBox } from "components/box/common-box";
import { ColumnProps, SearchItem, TableLayout } from "layouts/table";
import intl from "react-intl-universal";
import $$ from "cmn-utils";
import React, { PureComponent, ReactNode } from "react";
import { Role, RoleInterface } from "httpRequest/tokiot/sys/role";
import { IconItem, IconList } from "components/icon-list/icon-list";
import { PageClass } from "httpRequest/interface";
import {
  PayIotCard,
  PayIotCardInterface,
} from "httpRequest/tokiot/pay/iot-card";
import { HttpUtils } from "utils/http-utils";
import { BaseUrl, DownExcel } from "utils/constant";
import { ImportModal } from "./import-modal";
import { DictGetList, DictGetValue } from "utils/dict";
import { UpdateModal } from "./update-modal";
import moment from "moment";
import { InfoModal } from "./info-modal";
interface IProps {}
interface IState {
  updateVisible: boolean;
  importVisible: boolean;
  loading: boolean;
  infoVisible: boolean;
  selectInfo?: PayIotCardInterface.IotCardInfo;
  iotCardList: PageClass<PayIotCardInterface.IotCardInfo>;
}
class IotCard extends PureComponent<IProps, IState> {
  state: IState = {
    updateVisible: false,
    loading: false,
    infoVisible: false,
    importVisible: false,
    iotCardList: new PageClass(),
  };

  componentDidMount() {}
  searchData = {};

  getIotCardList = (data?: any) => {
    this.setState({ loading: true });
    PayIotCard.List({ ...data }).then((ret) => {
      this.searchData = data;
      if (ret && ret.rows.length > 0) {
        this.setState({ iotCardList: ret });
      } else {
        this.setState({ iotCardList: new PageClass() });
      }
      this.setState({ loading: false });
    });
  };

  refresh = () => {
    this.getIotCardList(this.searchData);
  };
  importVisibleChange = () => {
    this.setState({ importVisible: !this.state.importVisible });
  };
  updateVisibleChange = (selectInfo?: PayIotCardInterface.IotCardInfo) => {
    this.setState({ updateVisible: !this.state.updateVisible, selectInfo });
  };
  infoVisibleChange = (selectInfo?: PayIotCardInterface.IotCardInfo) => {
    this.setState({ infoVisible: !this.state.infoVisible, selectInfo });
  };
  searchList: Array<SearchItem> = [
    {
      key: `fullText`,
      name: `搜索条件`,
      type: `mini`,
      param: {
        type: `input`,
      },
    },
    {
      key: "cardType",
      name: `卡类`,
      type: `normal`,
      param: {
        type: `select`,
        dict: DictGetList(`PAY_IOTCARD_CARD_TYPE`),
      },
    },
    {
      key: `cardSrc`,
      name: `卡供应商`,
      type: `normal`,
      param: {
        type: `select`,
        dict: DictGetList(`PAY_IOTCARD_CARD_SRC`),
      },
    },
    {
      key: `feeState`,
      name: `续费状态`,
      type: `normal`,
      param: {
        type: `select`,
        dict: DictGetList(`PAY_IOTCARD_FEE_STATE`),
      },
    },
    {
      key: `state`,
      name: `状态`,
      type: `normal`,
      param: {
        type: `select`,
        dict: DictGetList(`PAY_IOTCARD_STATE`),
      },
    },
    {
      key: `syncState`,
      name: `同步状态`,
      type: `normal`,
      param: {
        type: `select`,
        dict: DictGetList(`PAY_IOTCARD_SYNC_STATE`),
      },
    },
    {
      key: `areaId`,
      name: `区域`,
      type: `normal`,
      param: {
        type: `area-select`,
      },
    },
    {
      key: `unitId`,
      name: `项目`,
      type: `normal`,
      param: {
        type: `unit-select`,
      },
    },
    {
      key: `deviceSn`,
      name: `设备编号`,
      type: `normal`,
      param: {
        type: `input`,
      },
    },
    {
      key: `iccid`,
      name: `ICCID`,
      type: `normal`,
      param: {
        type: `input`,
      },
    },
    {
      key: `imei`,
      name: `IMEI`,
      type: `normal`,
      param: {
        type: `input`,
      },
    },
    {
      key: `imsi`,
      name: `IMSI`,
      type: `normal`,
      param: {
        type: `input`,
      },
    },
    {
      key: `startTimeCard`,
      name: `卡开始时间`,
      type: `normal`,
      param: {
        type: `date-picker`,
      },
    },
    {
      key: `endTimeCard`,
      name: `卡结束时间`,
      type: `normal`,
      param: {
        type: `date-picker`,
      },
    },
    {
      key: `startTimeService`,
      name: `服务开始时间`,
      type: `normal`,
      param: {
        type: `date-picker`,
      },
    },
    {
      key: `endTimeService`,
      name: `服务结束时间`,
      type: `normal`,
      param: {
        type: `date-picker`,
      },
    },
  ];

  columns: Array<ColumnProps<PayIotCardInterface.IotCardInfo>> = [
    {
      title: `购卡时间`,
      dataIndex: "buyCardTime",
      width: 200,
    },
    {
      title: `卡类`,
      dataIndex: "cardType",
      width: 200,
      render: (value) => {
        return DictGetValue(`PAY_IOTCARD_CARD_TYPE`, value);
      },
    },
    {
      title: `区域`,
      dataIndex: "areaName",
      width: 100,
    },
    {
      title: `卡供应商`,
      dataIndex: "cardSrc",
      width: 200,
      render: (value) => {
        return DictGetValue(`PAY_IOTCARD_CARD_SRC`, value);
      },
    },
    {
      title: `卡号`,
      dataIndex: "iccid",
      width: 200,
    },
    {
      title: `状态`,
      dataIndex: "state",
      width: 100,
      render: (value) => {
        return DictGetValue(`PAY_IOTCARD_STATE`, value);
      },
    },
    {
      title: `同步状态`,
      dataIndex: "syncState",
      width: 100,
      render: (value) => {
        return DictGetValue(`PAY_IOTCARD_SYNC_STATE`, value);
      },
    },
    {
      title: `年限`,
      dataIndex: "meals",
      width: 200,
      render: (value, item) => {
        let sTime = moment(item.cardStartTime);
        let eTime = moment(item.cardExpireTime);
        if (
          item.cardStartTime &&
          item.cardExpireTime &&
          moment.isMoment(sTime) &&
          moment.isMoment(eTime)
        ) {
          return eTime.diff(sTime, `y`) + `年`;
        } else {
          return ``;
        }
      },
    },
    {
      title: `设备种类`,
      dataIndex: "productName",
      width: 200,
    },
    {
      title: `项目`,
      dataIndex: "unitName",
      width: 200,
    },
    {
      title: `设备编码`,
      dataIndex: "deviceSn",
      width: 200,
    },
    {
      title: `开卡时间`,
      dataIndex: "cardStartTime",
      width: 200,
    },
    {
      title: `报装时间`,
      dataIndex: "deviceInstallTime",
      width: 200,
    },
    {
      title: `卡到期时间`,
      dataIndex: "cardExpireTime",
      width: 200,
    },
    {
      title: `服务截止时间`,
      dataIndex: "serviceEndDate",
      width: 200,
    },
    {
      title: `出库人`,
      dataIndex: "checkoutUser",
      width: 200,
    },
    {
      title: `续费状态`,
      dataIndex: "feeState",
      width: 200,
      render: (value) => {
        return DictGetValue(`PAY_IOTCARD_FEE_STATE`, value);
      },
    },
    {
      title: `续费时间`,
      dataIndex: "feePayTime",
      width: 200,
    },
    {
      title: `备注`,
      dataIndex: "remark",
      width: 200,
    },

    {
      title: intl.get("table.baseColumns.operation"),
      dataIndex: "operate",
      width: 140,
      fixed: `right`,
      render: (text, record) => {
        let list: Array<IconItem> = [];
        if ($$.getStore("permissions").includes("PAY_IOT_CARD_SHOW")) {
          list.push({
            antd: true,
            name: intl.get("table.baseColumns.eye"),
            icon: `eye`,
            onClick: () => this.infoVisibleChange(record),
          });
        }
        if ($$.getStore("permissions").includes("PAY_IOT_CARD_EDIT")) {
          list.push({
            name: intl.get("table.baseColumns.edit"),
            icon: `edit`,
            onClick: () => this.updateVisibleChange(record),
          });
        }
        if ($$.getStore("permissions").includes("PAY_IOT_CARD_DELETE")) {
          list.push({
            message: `确认删除【${record.iccid}】`,
            name: intl.get("table.baseColumns.delete"),
            icon: `trash`,
            onClick: () => this.deleteCard(record.id),
          });
        }
        return <IconList list={list} />;
      },
    },
  ];
  deleteCard = (id: string) => {
    PayIotCard.Delete({ id }).then((ret) => {
      if (ret) {
        this.refresh();
      } else {
        message.error(`删除失败`);
      }
    });
  };
  downloadFile = () => {
    HttpUtils.GetFile(`/api/pay/iot-card/export-excel`, this.searchData);
  };
  render() {
    const {
      iotCardList: roleList,
      importVisible,
      updateVisible,
      selectInfo,
      infoVisible,
      loading,
    } = this.state;
    let buttonAdd: ReactNode = undefined;
    if ($$.getStore("permissions").includes("PAY_IOT_CARD_ADD")) {
      buttonAdd = (
        <Button
          type={"new-driver" as any}
          onClick={() => {
            this.importVisibleChange();
          }}
        >
          导入
        </Button>
      );
    }

    return (
      <CommonBox>
        {infoVisible && selectInfo ? (
          <InfoModal
            selectInfo={selectInfo}
            visible={infoVisible}
            onChange={this.refresh}
            onClose={this.infoVisibleChange}
          />
        ) : (
          ``
        )}
        {updateVisible && selectInfo ? (
          <UpdateModal
            selectInfo={selectInfo}
            visible={updateVisible}
            onChange={this.refresh}
            onClose={this.updateVisibleChange}
          />
        ) : (
          ``
        )}
        {importVisible ? (
          <ImportModal
            visible={importVisible}
            onChange={this.refresh}
            onClose={this.importVisibleChange}
          />
        ) : (
          ``
        )}
        <TableLayout
          additional={
            <Button.Group>
              {buttonAdd}

              <Button
                type={"new-driver" as any}
                onClick={() => {
                  this.downloadFile();
                }}
              >
                导出
              </Button>
            </Button.Group>
          }
          loading={loading}
          searchList={this.searchList}
          columns={this.columns}
          pageData={roleList}
          onSearch={this.getIotCardList}
        />
      </CommonBox>
    );
  }
}
export { IotCard };
