import $$ from 'cmn-utils'


const baseUrl = "/api/core/ota/";
export default {
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    download: (params) => {
        return $$.get(baseUrl + 'download', params,{responseType:'file'})
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    upgrade: (params) => {
        return $$.post(baseUrl + 'upgrade', params)
    },
    upgradeRecord: (params) => {

        Object.keys(params).map(key => {
            if (!params[key] || params[key] == 'null' || params[key] == 'undefined') delete params[key]
        })
        return $$.get(baseUrl + 'upgrade-record', params)
    },
    upload: (data, formData) => {
        // const {data, formData}=params
        console.info("表单", data, formData)
        formData.forEach((v,k)=>console.log(k,v))
        let url = baseUrl + 'upload'
        if (data) {
            let paramsArray = []
            //拼接参数
            Object.keys(data).forEach(key => paramsArray.push(key + '=' + data[key]))
            if (url.search(/\?/) === -1) {
                url += '?' + paramsArray.join('&')
            } else {
                url += '&' + paramsArray.join('&')
            }
        }
        return $$.post(url, formData,
            {
                headers: {
                    "content-type": "multipart/form-data;boundary=" + new Date().getTime()
                    , ...$$.getStore("headers")
                }
            });

    },
}