import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/system/protocal-manage/protocal",
    title: '使用协议',
    component: dynamicWrapper(app, [import("./model")], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
