import React, {Component} from "react";
import {message, Modal} from 'antd';
import http from "@/http"
import BraftEditorView from "@/components/BraftEditor/BraftEditorView"

const httpProtocal = http.tokiot.sys.protocal

class ProtocolModal extends Component {

    state = {
        info: {},
    }

    componentDidMount() {
        this.getProtocol();
    }

    getProtocol = () => {
        httpProtocal.lastVersionProtocol({})
            .then(ret => {
                if (ret) {
                    if (ret.contentWithHtml)
                        this.setState({visible: true, info: ret})
                }
            })
    }
    onClose = () => this.setState({info: {}})
    onOk = () => {
        httpProtocal.signProtocol({})
            .then(ret => {
                if (ret) {
                    message.success("签署成功")
                    this.onClose()
                }
            })
    }

    render() {
        const {info} = this.state;
        const modalProps = {
            title: '协议签署', okText: '同意并继续', cancelText: '关闭',
            width: 1100,
            visible: info.contentWithHtml,
            maskClosable: false,
            onCancel: this.onClose,
            onOk: this.onOk
        }
        return <Modal {...modalProps} >
            <BraftEditorView html={info.contentWithHtml}/>
        </Modal>
    }


}

export default ProtocolModal;