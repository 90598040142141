export const clientVersion = "V3.0.0.20230526"; // 程序版本

export const baseUrl = "/iotapp-api";
export const BaseUrl = "/iotapp-api";
export const getWSUrl = (username: string) => {
  if (window.location.protocol === `https:`) {
    return `wss://${window.location.host}${baseUrl}/ws/${username}/web`;
  } else {
    return `ws://${window.location.host}${baseUrl}/ws/${username}/web`;
  }
};

//上传图片
export const uploadImg = baseUrl + "/api/other/upload";

//查看图片
export const downloadImg = baseUrl + "/api/other/download-image-stream?fileId=";
export const DownloadImg = baseUrl + "/api/other/download-image-stream?fileId=";

//语音报警文件
export const voiceUrl = baseUrl + "/api/other/download-audio-stream?fileId=";

//上传字体图标图片
export const uploadIconImg = baseUrl + "/api/other/upload-product-icon-image";

//下载导入结果
export const downExcel = baseUrl + "/api/other/download?suffix=.xlsx&fileId=";
export const DownExcel = "/api/other/download?suffix=.xlsx&fileId=";

//获取图片验证码
export const captchaImage =
  baseUrl + "/captcha/image?width=109&height=40&imageRandom=";

//高德地图JSkey
export const amapKey = "4657af810f68c85bf65a23cb17d014ac";

//高德地图WEBkey
export const serviceKey = "2a2f98ddabc8f8aaf8a2ea950c23cd31";

//科大讯飞语音合成appId
export const appId = "5c87952d";

//科大讯飞语音合成apiKey
export const apiKey = "61ca4fb463f51b79d34bbb4e9f880977";

//属性图标
export const propsIcon = [
  "icon-leixing1",
  "icon-ID",
  "icon-leixing",
  "icon-yijihuo",
  "icon-mingcheng",
  "icon-zaixian",
  "icon-banben",
  "icon-dianya1",
  "icon-wendu",
  "icon-yunhangshijian",
  "icon-liangdu",
  "icon-jiaodu",
  "icon-kaiguan",
  "icon-icon-power-power",
  "icon-dianliang",
  "icon-fengmingqi",
  "icon-humidity",
  "icon-maibomaichongxintiaoxindianpulse",
  "icon-chaifen",
  "icon-guanyu",
  "icon-dianya",
  "icon-fuweidaohuangchijiaojiao",
  "icon-xinzaobi",
  "icon-wulianwang",
  "icon-IMSI",
  "icon-qishibianhao",
  "icon-gongshangzhucehao",
  "icon-zhiwensuo",
  "icon-icon-electricity",
  "icon-liuliang1",
  "icon-liuliang",
  "icon-icon-detector",
  "icon-liuliangshuju",
  "icon-dianchi-",
  "icon-xinhao",
  "icon-xintiao",
  "icon-liuliangcopy",
  "icon-tongtouzhijing",
  "icon-shijian1",
  "icon-icon220",
  "icon-220vdianyachazuo",
  "icon-shujuguanlisvg29",
  "icon-tzddiyaxianlu",
  "icon-lock",
  "icon-dizhi",
  "icon-shanguangdeng",
  "icon-liuliangchongzhi",
  "icon-xuanzhuan",
  "icon-sn",
  "icon-yibiao",
];

//事件图标
export const eventsIcon = [
  "icon-zhuangtai",
  "icon-shanchu",
  "icon-dianchi",
  "icon-jishuiyuce",
  "icon-fangchaibaojing",
  "icon-dengpao-guzhang",
  "icon-tongyonglei_IOjiechubaojing",
  "icon-eyigongjilian",
  "icon-xitongzijian",
  "icon-shangbao1",
  "icon-shangbao",
  "icon-yalibaojing",
  "icon-weijianceshuliang",
  "icon-weixie",
  "icon-yishilian",
  "icon-tubiao-ditu-huozaibaojingqi",
  "icon-mute",
  "icon-guzhang",
  "icon-bumingjinggai",
  "icon-shilian",
  "icon-zhinengsuo",
  "icon-shuijing",
  "icon-shijian",
  "icon-yanwubaojing",
  "icon-shujuguanlisvg85",
  "icon-dwdjing",
  "icon-huifu",
  "icon-swbj",
  "icon-64",
  "icon-pohuailindi",
  "icon-huojing",
  "icon-dianliang",
  "icon-chaifen",
  "icon-dianchi-",
  "icon-xintiao",
  "icon-tzddiyaxianlu",
];
