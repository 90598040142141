import $$ from 'cmn-utils'

const baseUrl = "/api/sys/bigdata/";
export default {
    create: (params) => {
        params = formatData({...params})
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    getByKey: (params) => {
        return $$.get(baseUrl + 'get-by-key', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    update: (params) => {
        params = formatData({...params})
        return $$.post(baseUrl + 'update', params)
    },

}

function formatData(params) {
    params.data = JSON.stringify(params.data)
    return params
}