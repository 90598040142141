import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/pay/archive-device",
    title: '归档设备',
    component: dynamicWrapper(app, [import("./model")], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
