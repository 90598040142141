import $$ from 'cmn-utils'

const baseUrl = "/api/sys/push-strategy/";
export default {
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    targetUserList: (params) => {
        return $$.get(baseUrl + 'target-user-list', params)

    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },

}