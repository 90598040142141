import $$ from 'cmn-utils'

const baseUrl = "/api/inspect-task-record/";
export default {
    detail: (params) => {
        return $$.get(baseUrl + 'detail', params)
    },
    handle: (params) => {
        return $$.post(baseUrl + 'handle', params)
    },
    listAll: (params) => {
        return $$.get(baseUrl + 'list-all', params)
    },
    listFinish: (params) => {
        return $$.get(baseUrl + 'list-finish', params)
    },
    listRoute: (params) => {
        return $$.get(baseUrl + 'list-route', params)
    },

}