import {createRoute, dynamicWrapper} from "@/utils/core";
import intl from 'react-intl-universal';

const routesConfig = app => ({
    path: "/record/event/common",
    title: intl.get('/record/event/common'),
    component: dynamicWrapper(app, [import("../model")], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
