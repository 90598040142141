import $$ from 'cmn-utils'

const baseUrl = "/api/core/device/activity-log/";
export default {
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    type: {
        list: (params) => {
            return $$.get(baseUrl + 'type/list', params)
        },
    }
}