import { TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";
import { TreeNode, TreeNodeValue } from "antd/lib/tree-select/interface";
import { SelectUnitTableModal } from "./select-unit-table-modal";
import { Unit, UnitInfoInterface } from "httpRequest/tokiot/sys/unit";
import React, { PureComponent } from "react";
export interface UnitTreeSelectProps extends TreeSelectProps<TreeNodeValue> {
    onlyType?: `Organs` | `Unit`;
}
interface IState {
    visible: boolean;
    selectUnit?: UnitInfoInterface.UnitInfo;
    loadding: boolean;
}

class UnitTreeSelect extends PureComponent<UnitTreeSelectProps, IState>{
    state: IState = {
        visible: false,
        loadding: false,
    }
    componentDidMount() {
        const { value } = this.props;
        if (value) {
            this.setState({ loadding: true });
            Unit.Get({ id: value.toString() }).then(ret => {
                if (ret) {
                    this.setState({ selectUnit: ret, loadding: false });
                }
            })

        }
    }
    selectUnit = (unit?: UnitInfoInterface.UnitInfo) => {
        const { onChange } = this.props;
        this.setState({ selectUnit: unit }, () => {
            if (onChange) {
                let ret = unit?.id ?? (undefined as any);
                onChange(ret, unit?.name ?? ``, undefined);
            }
        })
    }
    visibleChange = () => {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const { onlyType, value, ...param } = this.props;
        const { visible, selectUnit, loadding } = this.state;
        let p1 = { onClick: () => { this.visibleChange() } }
        let treeData: TreeNode | undefined = undefined;
        if (selectUnit) {
            treeData = { value: selectUnit.id, label: selectUnit.name };
        }
        return (
            <React.Fragment>
                <TreeSelect {...param} {...p1} value={loadding ? undefined : value} treeData={treeData ? [treeData] : undefined} dropdownStyle={{ display: 'none' }} onChange={(value, lable, extra) => {
                    this.selectUnit();
                }} />
                {visible ? <SelectUnitTableModal onlyType={onlyType} visible={visible} onOk={this.selectUnit} onClose={this.visibleChange} /> : ``}
            </React.Fragment>
        )
    }
}

export { UnitTreeSelect }