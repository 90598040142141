import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/document/buildimage",
    title: '施工图纸',
    component: dynamicWrapper(app, [import("../model/index")], () =>
        import("./components/index")
    )
});

export default app => createRoute(app, routesConfig);
