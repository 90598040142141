import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/pay/telcom-card",
    title: '物联网卡',
    component: dynamicWrapper(app, [import("./model")], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
