import React, {Component} from "react"
import {AlarmLevelTag, AlarmNameTag, AlarmStateTag} from "@/custom"
import {Divider, Modal} from 'antd';
import DeviceInfo from "./DeviceInfo"

import "./index.less"

class RealTimeAlarm extends Component {
    state = {
        alarmDevice: {},
        playDevice: {},
        alarmInfo: {
            address: '福建省泉州市丰泽区华大街道体育街西辅路801号',
            alarmLogId: 'aac42bb31ab64e8c9bdcb4fc0e0f5463',
            areaId: '1069411766704627713', areaName: '丰泽区',
            content: '拓科信息的869505046505879（展厅311演示）位于311发生设备自检',
            data: '[]',
            deviceId: '1252168042319937538',
            deviceName: '展厅311演示',
            deviceSn: '869975034982309',
            eventName: '设备自检',
            eventType: 'Level3Alarm',
            fitCycle: true,
            iflytekVoice: '14,0225d5381aa8ba',
            installLocation: 311,
            linkageExecuteCameraDeviceIdList: ['1298550658613198849'],
            planePosition: {
                deviceId: '1296050696852332546',
                deviceSn: '869505046505879',
                id: '1296051756121370625',
                planePictureId: '1252783878087286786',
                x: 148.5559452644363, y: 256.17326064122443
            },
            productId: '1235480228002840577',
            productName: '烟感R',
            time: '2020-09-24 12:25:39',
            unitId: '1242478974049001474',
            unitName: '拓科信息'
        },
        timestamp: 1600921539583, type: 'Alarm'
    }


    render() {
        const {visible, onClose} = this.props;
        const {alarmInfo} = this.props
        // const {alarmInfo} = this.state
        const modalProps = {
            className: 'realtime-alarm-modal',
            title: '告警通知',
            width: 1100,
            visible,
            maskClosable: false,
            onCancel: onClose,
            footer: null
        }
        const alarmDeviceId = alarmInfo.deviceId
        const playerDeviceId = alarmInfo.linkageExecuteCameraDeviceIdList && alarmInfo.linkageExecuteCameraDeviceIdList[0]

        return <Modal {...modalProps} >
            <div className='realtime-alarm-modal-body'>
                <DeviceInfo deviceId={alarmDeviceId} >
                    <div className='realtime-alarm-modal-info'>
                        <Divider
                            style={{marginTop: 1, marginBottom: 7, color: '#1890ff', fontSize: 12, fontWeight: 500}}
                            orientation="left">告警信息</Divider>
                        {showRow(`告警名称:`, <AlarmNameTag type={alarmInfo.eventType} text={alarmInfo.eventName}/>)}
                        {showRow(`告警级别:`, <AlarmLevelTag type={alarmInfo.eventType}/>)}
                        {showRow(`发生时间:`, alarmInfo.time)}
                        {showRow(`告警参数:`, alarmInfo.data && JSON.parse(alarmInfo.data)
                            .map((param) => showRow(param.name + ":", param.value)))}
                    </div></DeviceInfo>
                <DeviceInfo deviceId={playerDeviceId}></DeviceInfo>

            </div>
        </Modal>
    }

}

export default RealTimeAlarm

const showRow = (name, value) => {
    return (
        <div className='realtime-alarm-modal-row'>
            <div className='realtime-alarm-modal-row-left'>
                {name}
            </div>
            <div className='realtime-alarm-modal-row-right'>
                {value}
            </div>
        </div>
    )
}