import $$ from 'cmn-utils'

const baseUrl = "/api/sys/category/";
export default {
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    listByUnit: (params) => {
        return $$.get(baseUrl + 'list-by-unit', params)
    },
    listMain: (params) => {
        return $$.get(baseUrl + 'list-home', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },

}