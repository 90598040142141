import React from "react";
import { Button, message } from "antd";
import $$ from "cmn-utils";
import { UpdateModal } from "./update-modal";
import intl from 'react-intl-universal';
import { ColumnProps } from "antd/lib/table";
import { SearchItem, TableLayout } from "layouts/table";
import { PageClass } from "httpRequest/interface";
import { Role, RoleInterface } from "httpRequest/tokiot/sys/role";
import { CommonBox } from "components/box/common-box";
import { IconItem, IconList } from "components/icon-list/icon-list";
interface IProps {

}
interface IState {
    updateVisible: boolean,
    editVisible: boolean,
    selectInfo?: RoleInterface.RoleInfo,
    roleList: PageClass<RoleInterface.RoleInfo>;
}
class RoleClass extends React.PureComponent<IProps, IState> {
    state: IState = {
        updateVisible: false,
        editVisible: false,
        roleList: new PageClass(),
    };
    searchData = {}

    getAlarmList = (data?: any) => {
        // const { unitInfo } = this.props;
        Role.List({ ...data }).then(ret => {
            this.searchData = data;
            if (ret && ret.length > 0) {
                let roleList = new PageClass<RoleInterface.RoleInfo>();
                roleList.rows = ret;
                roleList.pageNumber = 1;
                roleList.pageTotal = 1;
                roleList.pageSize = ret.length;
                roleList.total = ret.length;
                this.setState({ roleList });
            } else {
                this.setState({ roleList: new PageClass() });
            }
        })
    }

    /**
     * 切换弹框的显示隐藏
     */
    modalVisibleChange = (updateVisible?: boolean, editVisible?: boolean, selectInfo?: RoleInterface.RoleInfo) => {
        this.setState({ updateVisible: updateVisible ?? false, editVisible: editVisible ?? false, selectInfo });
    }

    componentDidMount() {
    }

    searchList: Array<SearchItem> = [
        {
            key: `name`,
            name: `角色名称`,
            type: `all`,
            param: {
                type: `input`,
            }
        },
    ]

    columns: Array<ColumnProps<RoleInterface.RoleInfo>> = [
        {
            title: intl.get('table.system.role.roleColumns.name'),
            dataIndex: "name",
        },
        {
            title: `代码`,
            dataIndex: "code",
        },
        {
            title: `备注`,
            dataIndex: "remark",
        },
        {
            title: `排序`,
            dataIndex: "sort",
        },
        {
            title: intl.get('table.baseColumns.operation'),
            dataIndex: "operate",
            width: 200,
            render: (text, record) => {
                let list: Array<IconItem> = [];
                if ($$.getStore("permissions").includes("ROLE_SHOW")) {
                    list.push({ antd: true, name: intl.get("table.baseColumns.eye"), icon: `eye`, onClick: () => this.modalVisibleChange(true, false, record) })
                }
                if ($$.getStore("permissions").includes("ROLE_EDIT")) {
                    list.push({ name: intl.get("table.baseColumns.edit"), icon: `edit`, onClick: () => this.modalVisibleChange(true, true, record) })
                }
                if ($$.getStore("permissions").includes("ROLE_DELETE")) {
                    list.push({ message: `确认删除角色【${record.name}】`, name: intl.get("table.baseColumns.delete"), icon: `trash`, onClick: () => this.deleteRole(record.id) })
                }
                return (<IconList list={list} />)
            }
        },
    ];
    deleteRole = (id: string) => {
        Role.Delete({ id }).then(ret => {
            if (ret) {
                this.onChange();
            } else {
                message.error(`删除失败`);
            }
        })
    }
    onChange = () => {
        this.getAlarmList(this.searchData);
    }

    render() {
        const { roleList, selectInfo, updateVisible, editVisible } = this.state;

        return (
            <CommonBox>
                {updateVisible ? <UpdateModal visible={updateVisible} edit={editVisible} selectInfo={selectInfo} onChange={this.onChange} onClose={this.modalVisibleChange} /> : ``}
                <TableLayout
                    additional={<Button type={'new-driver' as any} onClick={() => { this.modalVisibleChange(true, true) }}>新增</Button>}
                    noNumber
                    searchList={this.searchList}
                    columns={this.columns}
                    pageData={roleList}
                    onSearch={this.getAlarmList}
                />
            </CommonBox>
        );
    }
}

export { RoleClass }