import $$ from 'cmn-utils'

const baseUrl = "/api/other/";
export default {
    dlFontIcon: (params) => {
        return $$.get(baseUrl + 'dl-font-icon', params)
    },
    dlImage: (params) => {
        return $$.get(baseUrl + 'dl-image', params)
    },
    dlProductImage: (params) => {
        return $$.get(baseUrl + 'dl-product-image', params)
    },
    iflytekPlayVoice: (params) => {
        return $$.get(baseUrl + 'iflytek-play-voice', params)
    },
    updateFontIcon: (params) => {
        return $$.post(baseUrl + 'upload-font-icon', params)
    },
    uploadImage: (params) => {
        return $$.post(baseUrl + 'upload-image', params)
    },
    uploadImageBase64: (params) => {
        return $$.post(baseUrl + 'upload-image-base64', params)
    },
    version:(params)=>$$.get(baseUrl + 'version', params)

}