import $$ from 'cmn-utils'

const baseUrl = "/api/sys/dict/";
export default {
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    dictLabel: (params) => {
        return $$.get(baseUrl + 'dict-label', params)
    },
    dictList: (params) => {
        return $$.get(baseUrl + 'dict-list', params)
    },
    dictValue: (params) => {
        return $$.get(baseUrl + 'dict-value', params)
    },

}