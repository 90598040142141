import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/pay/device-service-charge",
    title: '服务费',
    component: dynamicWrapper(app, [import("./model")], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
