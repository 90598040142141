import React, { Fragment } from "react";
import styles from "../index.module.less";
import intl from "react-intl-universal";
import { Badge, Divider, Form, Tabs } from "antd";
import defaultImg from "@/assets/images/pic-error.png";
import Player from "@/components/Player";
import CONST from "@/dataSource/CONST";
import * as DICT from "@/utils/dict";
import { downloadImg } from "@/utils/constant";
import Img from "@/components/Img";
import PropIcon from "@/components/Icon/PropIcon";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;
const { BadgeStatusByKey } = CONST;

const renderPlayer = (detail) => {
  const { sn, shadowProp } = detail;
  if (!shadowProp) return <React.Fragment />;
  const token = shadowProp.find((item) => "accessToken" == item.code);
  if (token) {
    const accessToken = token.value;

    const playerProps = {
      sn,
      accessToken,
      playerType: "YingShi",
    };
    return (
      <div style={{ height: 290, padding: 10, marginBottom: 20 }}>
        <Divider
          style={{
            marginTop: 1,
            marginBottom: 7,
            color: "#1890ff",
            fontSize: 12,
            fontWeight: 500,
          }}
          orientation="left"
        >
          实时视频
        </Divider>
        {/*<iframe src={`/#/player/${JSON.stringify({sn, accessToken, playerType: 'YingShi'})}`}/>*/}
        <Player key={sn} {...playerProps} />
      </div>
    );
  }
  return <Fragment />;
};

const InfoTab = (detail) => {
  return (
    <React.Fragment>
      {renderPlayer(detail)}
      <Divider
        style={{
          marginTop: 1,
          marginBottom: 7,
          color: "#1890ff",
          fontSize: 12,
          fontWeight: 500,
        }}
        orientation="left"
      >
        {intl.get("base.info")}
      </Divider>
      <div className={styles.base_list}>
        <div className={styles.list_left}>
          <div className={styles.info_item}>
            <div className={styles.item_label}>{intl.get("device.name")}：</div>
            <div className={styles.item_value}>{detail.name}</div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>{intl.get("device.type")}：</div>
            <div className={styles.item_value}>{detail.productName}</div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>{intl.get("device.sn")}：</div>
            <div title={detail.sn} className={styles.item_value}>
              {detail.sn}
            </div>
          </div>
          <Divider style={{ marginTop: 1, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>
              {intl.get("device.status")}：
            </div>
            <div className={styles.item_value}>
              <Badge
                status={BadgeStatusByKey[detail.deviceState]}
                text={DICT.getValue("DEVICE_STATE_TYPE", detail.deviceState)}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>
              {intl.get("device.alarmStatus")}：
            </div>
            <div className={styles.item_value}>
              {(() => {
                switch (detail.armState) {
                  case "Arm":
                    return (
                      <Badge
                        status="success"
                        text={intl.get("device.alarmStatus.success")}
                      />
                    );
                  default:
                    return (
                      <Badge
                        status="error"
                        text={intl.get("device.alarmStatus.error")}
                      />
                    );
                }
              })()}
            </div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>
              {intl.get("device.dataState")}：
            </div>
            <div className={styles.item_value}>
              {(() => {
                switch (detail.dataState) {
                  case "Enable":
                    return (
                      <Badge
                        status="success"
                        text={intl.get("device.dataState.true")}
                      />
                    );
                  default:
                    return (
                      <Badge
                        status="default"
                        text={intl.get("device.dataState.false")}
                      />
                    );
                }
              })()}
            </div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>
              {intl.get("device.heatBeatTime")}：
            </div>
            <div className={styles.item_value}>{detail.heartBeatTime}</div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>
              {intl.get("device.unitName")}：
            </div>
            <div className={styles.item_value}>{detail.unitName}</div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>
              {intl.get("device.infoAddress")}:
            </div>
            <div className={styles.item_value}>{detail.address}</div>
          </div>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <div className={styles.info_item}>
            <div className={styles.item_label}>
              {intl.get("device.installLocation")}:
            </div>
            <div className={styles.item_value}>{detail.installLocation}</div>
          </div>
        </div>
        <div className={styles.list_right}>
          <img
            style={{ width: "95%", height: "auto" }}
            alt=""
            src={downloadImg + detail.productImage1}
          />
        </div>
      </div>
      {/*<div className={styles.base_param_list}>*/}
      <Divider style={{ margin: 0 }} />
      {/*</div>*/}
      <div className={styles.info_item}>
        <FormItem
          label={intl.get("image4")}
          colon={false}
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          {
            <Img
              style={{ width: "60px", height: "60px" }}
              title={intl.get("image4")}
              alt="logo"
              src={detail.image4 ? downloadImg + detail.image4 : defaultImg}
            />
          }
        </FormItem>
        <FormItem
          label={intl.get("image1")}
          colon={false}
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          {
            <Img
              style={{ width: "60px", height: "60px" }}
              title={intl.get("image1")}
              alt="logo"
              src={detail.image1 ? downloadImg + detail.image1 : defaultImg}
              onError={"this.src=" + defaultImg}
            />
          }
        </FormItem>
        <FormItem
          label={intl.get("image2")}
          colon={false}
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          {
            <Img
              style={{ width: "60px", height: "60px" }}
              title={intl.get("image2")}
              alt="logo"
              src={detail.image2 ? downloadImg + detail.image2 : defaultImg}
              onError={"this.src=" + defaultImg}
            />
          }
        </FormItem>
        <FormItem
          label={intl.get("image3")}
          colon={false}
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          {
            <Img
              style={{ width: "60px", height: "60px" }}
              title={intl.get("image3")}
              alt="logo"
              src={detail.image3 ? downloadImg + detail.image3 : defaultImg}
            />
          }
        </FormItem>
      </div>
      <Divider
        style={{
          marginTop: 0,
          marginBottom: 7,
          color: "#1890ff",
          fontSize: 12,
          fontWeight: 500,
        }}
        orientation="left"
      >
        {intl.get("realTime.data")}
      </Divider>
      <div className={styles.param_list}>
        {detail && detail.shadowProp
          ? detail.shadowProp.map((v) => (
              <div key={v.code}>
                <div className={styles.param_item}>
                  <div title={v.name} className={styles.param_label}>
                    <PropIcon type={v.icon || "home"} /> {v.name}
                    <div className={styles.param_value}>
                      {v.value === null
                        ? "-"
                        : v.unit === null
                        ? v.value
                        : v.value + v.unit}
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: -5, marginBottom: 3 }} />
              </div>
            ))
          : ""}
      </div>
    </React.Fragment>
  );
};

export default InfoTab;
