import {createRoute, dynamicWrapper} from '@/utils/core'
import intl from 'react-intl-universal';

const routesConfig = app => ({
    path: '/screen/davav-2021',
    title: intl.get('site.title') + ' - ' + intl.get('/datav/display1'),
    component: dynamicWrapper(app, [], () =>
        import('./components/index')
    )
})

export default app => createRoute(app, routesConfig)
