import React from 'react'
import {Modal} from 'antd'
import './style/modal.less'

const AlarmModal = (props) => {
    const {visible, onCancel, title, data, footer} = props
    const modalProps = {visible, onCancel, title, footer}
    return (
        <Modal {...modalProps}>
            <div className="detail-item">
                <h4>告警信息</h4>
                <div className="item-container">
                    <div className="item-label">告警原因</div>
                    <div className="item-value">{data ? data.eventName : ""}（{data ? data.productName : ""}）</div>
                </div>
                <div className="item-container">
                    <div className="item-label">告警时间</div>
                    <div className="item-value">{data ? data.time : ""}</div>
                </div>
            </div>
            <div className="detail-item">
                <h4>设备信息</h4>
                <div className="item-container">
                    <div className="item-label">设备名字</div>
                    <div className="item-value">{data ? data.deviceName : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">设备编号</div>
                    <div className="item-value">{data ? data.deviceSn : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">所属区域</div>
                    <div className="item-value">{data ? data.areaName : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">详细地址</div>
                    <div className="item-value">{data ? data.address : ""}{data ? data.installLocation : ""}</div>
                </div>
                <div className="item-container">
                    <div className="item-label">所属项目</div>
                    <div className="item-value">{data ? data.unitName : ""}</div>
                </div>
            </div>
        </Modal>
    )
}

export default AlarmModal