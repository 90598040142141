import $$ from 'cmn-utils'
import {downExcel} from "@/utils/constant";

const baseUrl = "/api/sys/unit/";
export default {
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    getUserNumber: (params) => {
        return $$.get(baseUrl + 'get-user-number', params)
    },
    tree: (params) => {
        return $$.get(baseUrl + 'tree', params)
    },
    listHome: (params) => {
        return $$.get(baseUrl + 'list-home', params)
    },
    treeHome: (params) => {
        return $$.get(baseUrl + 'tree-home', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },
    asyncList: (params) => {
        return $$.get(baseUrl + 'async-list', params)
    },
    listMap: (params) => {
        return $$.get(baseUrl + 'list-map', params)
    },
    importExcel: (data, formData) => {
        let urlParms = ""
        let paramsArray = []
        //拼接参数
        Object.keys(data).forEach(key => paramsArray.push(key + '=' + data[key]))
        if (urlParms.search(/\?/) === -1) {
            urlParms += '?' + paramsArray.join('&')
        } else {
            urlParms += '&' + paramsArray.join('&')
        }
        return $$.post(baseUrl + 'import-excel' + urlParms, formData, {
            headers: {
                "content-type": "multipart/form-data;boundary=" + new Date().getTime()
                , ...$$.getStore("headers")
            }
        }).then(ret => {
            window.open(downExcel + ret, '_blank');
            return ret;
        })
    },
}