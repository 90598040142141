import {get, post} from "@/http/ys"

const baseUrl = "video/";

export default {
    pztStart: (params) => {
        return post(baseUrl + 'ptz/start', params)
    },


}