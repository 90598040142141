import React, { FC, ReactNode } from "react";
import { BaseModal, PushInfo } from "components/base-interface";
import { CommonModal } from "components/Modal/common-modal";
import { PayIotCardInterface } from "httpRequest/tokiot/pay/iot-card";
import styles from "./index.module.less";
import { CommonParams } from "httpRequest/interface";
import { CommonTag } from "components/tag/common-tag";
import { DictGetValue, getDICT } from "utils/dict";
import ColorByKey from "dataSource/CONST/ColorByKey";
import { CommonUnit } from "utils/common-parameter";

interface IProps extends BaseModal {
  pushInfo: PushInfo;
}

interface IState {}
class AreaInvasionModal extends React.PureComponent<IProps, IState> {
  state: IState = {};
  componentDidMount() {}

  RowItem: FC<{ name: string; value: ReactNode }> = (props) => {
    const { name, value } = props;
    return (
      <div className={styles.row}>
        <div className={styles.name}>{name}</div>
        <div className={styles.value}>{value}</div>
      </div>
    );
  };

  render() {
    const { visible, pushInfo, onClose } = this.props;
    const { RowItem } = this;
    // let pushInfo = JSON.parse(TestJson) as PushInfo;
    // pushInfo.data =
    //   '[{"code":"imageUrl","name":"图片","value":"https://alipicdeco.ys7.com:8089/ezviz/pic/down?url=aHR0cDovL2hpay1hbGljbG91ZC5vc3MtY24taGFuZ3pob3UtaW50ZXJuYWwuYWxpeXVuY3MuY29tLzcvRDcyNzcyNDg0XzFfMjAyMjAzMTQxNTA0MDgtRDcyNzcyNDg0LTEtMTAwMTQtMi0xP0V4cGlyZXM9MTY0Nzg0NjI1NCZPU1NBY2Nlc3NLZXlJZD1MVEFJNEdHcnExYTlrRndVNEJtR1pkblQmU2lnbmF0dXJlPXZrNEJrVzFkM3prTXB5QmdhNyUyRnVFJTJCVFpkUjAlM0Q=&crypt=2&time=2022-03-14T15:04:07&key=73878d9a65f03bc8e25c4f1cf24c6641"},{"code":"time","name":"告警时间","value":"1647241447000"}]';

    let imgUrl = ``;
    if (pushInfo.data && pushInfo.data.length > 0) {
      let alarmDataList = JSON.parse(
        CommonUnit.ParseFromString(pushInfo.data, "[]")
      ) as Array<CommonParams>;
      let item = alarmDataList.find((f1) => f1.code === `imageUrl`);
      imgUrl = item?.value ?? ``;
    }

    return (
      <CommonModal
        title={`事件详情`}
        onCancel={onClose}
        width={1000}
        visible={visible}
        footer={null}
      >
        <div className={styles.main}>
          <img src={imgUrl} className={styles.image} />
          <div className={styles.alarm_div}>
            <div
              className={styles.title}
            >{`${pushInfo.deviceName}_${pushInfo.eventName}`}</div>
            <div className={styles.alarm_info}>
              <RowItem
                name={`紧急程度`}
                value={
                  <CommonTag
                    span
                    full
                    color={(ColorByKey as any)[pushInfo.eventType]}
                    className={styles.tag}
                  >
                    {DictGetValue(`ALARM_LOG_TYPE`, pushInfo.eventType)}
                  </CommonTag>
                }
              />
              <RowItem
                name={`事件类型`}
                value={
                  <CommonTag
                    span
                    full
                    color={(ColorByKey as any)[pushInfo.eventType]}
                    className={styles.tag}
                  >
                    {pushInfo.eventName}
                  </CommonTag>
                }
              />
              <RowItem name={`发生时间`} value={pushInfo.time} />
            </div>
            <div className={styles.device_info}>
              <RowItem name={`设备名称`} value={pushInfo.deviceName} />
              <RowItem name={`所属项目`} value={pushInfo.unitName} />
              <RowItem name={`安装位置`} value={pushInfo.installLocation} />
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}
export { AreaInvasionModal };

const TestJson = `
{
  "address": "c",
  "alarmLogId": "f4c02e356eb64af7bf3e055f9d67d39b",
  "areaId": "1069411765962235906",
  "areaName": "泉州市",
  "content": "研发测试的D72772484（AI摄像头）位于c在2022-03-14 15:04:15发生区域入侵",
  "data": "",
  "deviceId": "1503184518580211714",
  "deviceName": "AI摄像头",
  "deviceSn": "D72772484",
  "eventCode": "fielddetection",
  "eventName": "区域入侵",
  "eventType": "Level1Alarm",
  "iflytekVoice": "75,1b35309abaa323",
  "installLocation": "c",
  "linkageExecuteCameraDeviceIdList": [],
  "productId": "1295530871000772610",
  "productKey": "pro_83012e89c8e5",
  "productName": "摄像机",
  "time": "2022-03-14 15:04:15",
  "unitId": "1334414235227697153",
  "unitName": "研发测试"
}
`;
