import { Table } from 'antd';
import { CheckboxStyle, TableRowStyle, TableRowStyle2, TableStyle, TableStyle2 } from 'components/common-styles/common-styles';
import { Page } from 'httpRequest/interface';
import { PaginationLayout, PaginationLayoutPoros, SearchItem as Item } from 'layouts/pagination';
import React from 'react'
import styles from "./index.module.less";
import { ColumnProps, TableProps } from 'antd/lib/table';
import classNames from 'classnames';

interface TableLayoutPoros<T> extends PaginationLayoutPoros {
    className?: string;
    pageData: Page<T>;
    noNumber?: boolean;
    theme?: 1 | 2;
}
interface IState {
}
class TableLayout<T extends object = any> extends React.PureComponent<TableLayoutPoros<T> & Omit<TableProps<T>, `dataSource`>, IState> {
    state: IState = {
    }
    componentDidMount() {
    }

    onSearch = (data?: any) => {
        const { onSearch } = this.props;
        onSearch && onSearch(data);
    }
    tableRef = React.createRef<HTMLDivElement>();


    render() {
        const { pageData, columns, rowClassName, className, noNumber, theme, rowSelection, ...param } = this.props;
        let showColumns: Array<ColumnProps<T>> = [];
        if (columns && columns.length > 0) {
            let items = columns.filter(f1 => (f1 as ColumnProps<T>).dataIndex === `line`);
            if (items && items.length > 0) {
                items.forEach(f1 => {
                    f1.width = `9px`;
                    f1.className = styles.line;
                    f1.render = () => {
                        return <div className={styles.state} style={{ width: `9px` }} />
                    };
                })
            }

            if (!noNumber) {
                let item = columns[0];
                let numberColumn: typeof item = {
                    align: `center`,
                    width: `4rem`,
                    title: '序号',
                    dataIndex: 'number',
                    render: (value, item, index) => {
                        let ret = index + 1;
                        if (pageData.pageNumber > 1) {
                            ret += (pageData.pageNumber - 1) * pageData.pageSize;
                        }
                        return ret;
                    }
                }
                showColumns = [numberColumn, ...columns];
            } else {
                showColumns = columns;
            }
        } else {
            if (!noNumber) {
                let temp: Array<ColumnProps<T>> = [{}];
                let item = temp[0];
                let numberColumn: typeof item = {
                    title: '序号',
                    dataIndex: 'number',
                    render: (value, item, index) => {
                        return index;
                    }
                }
                if (columns) {
                    showColumns = [numberColumn, ...columns];
                }
            }
        }

        let pageParam = this.props as PaginationLayoutPoros;
        let tableClass = ``;
        let tableRowClass = ``;
        switch (theme) {
            case 2:
                tableClass = TableStyle2;
                tableRowClass = TableRowStyle2;
                break;
            default:
                tableClass = TableStyle;
                tableRowClass = TableRowStyle;
                break;
        }
        if (rowSelection) {
            rowSelection.getCheckboxProps = (record) => {
                return {
                    className: CheckboxStyle,
                }
            }

        }
        return (
            <PaginationLayout {...pageParam} onSearch={this.onSearch}>
                {(width, height) => {
                    let scrollY = (height ?? 0) - (42);
                    let scrollX = (width ?? 0) - (5);
                    return (
                        <div className={styles.tableDiv} >
                            <Table<T>
                                {...param}
                                className={classNames(className, tableClass)}
                                columns={showColumns}
                                scroll={{ y: scrollY, x: scrollX }}
                                dataSource={pageData.rows}
                                pagination={false}
                                rowSelection={rowSelection}
                            />
                        </div>
                    )
                }}
            </PaginationLayout>
        )
    }
}
export * from 'antd/lib/table/interface';
export type SearchItem = Item;
export { TableLayout }
