import React from "react";
import { createRoute, dynamicWrapper } from 'utils/core';
import { UnitGroupManage } from './components';
import { UserContext } from "context/context-react";

class Routes extends React.PureComponent {
    render() {
        return (
            <UserContext.Context.Consumer>
                {user =>
                    <UnitGroupManage {...this.props} userInfo={user} />
                }
            </UserContext.Context.Consumer>
        )
    }
}

const routesConfig = (app: any) => {
    return ({
        path: "/system/base/unit-group",
        title: `项目分组`,
        component: dynamicWrapper(app, [], () => { return Routes })
    })
};

export default (app: any) => createRoute(app, routesConfig);
