import { createRoute, dynamicWrapper } from "@/utils/core";
import { IotCard } from "./components";

const routesConfig = (app) => ({
  path: "/pay/iot-card",
  title: "新版物联网卡",
  component: dynamicWrapper(app, [], () => {
    return IotCard;
  }),
});

export default (app) => createRoute(app, routesConfig);
