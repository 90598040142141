export default {
    /**  工单等级 */
    Urgent: 'warning',
    Serious: 'error',
    General: 'success',

    /** 工单状态 */
    UnAssign: 'warning',
    UnHandle: 'warning',
    UnConfirm: 'success',
    Finish: 'processing',

    /** 工单类型*/
    Alarm: 'error',
    Repair: 'success',
    Inspect: 'default',

    /** 设备状态 */
    Normal: 'success',
    // Alarm:'error',
    Lose: 'warning',
    Offline: 'default',
    NotActive: 'processing',

    /** 联动状态 */
    Start: 'success',
    Stop: 'default',

    /** 升级记录状态 */
    Success:'success',
    Failure:'error',
    Upgrading:'processing',
}