import {get, post} from "@/http/ys"

const baseUrl = "device/ptz/";

export default {
    start: (params) => {
        return post(baseUrl + 'start', params)
    },

    stop: (params) => {
        return post(baseUrl + 'stop', params)
    },
    mirror: (params) => {
        return post(baseUrl + 'mirror', params)
    },


}