import React, {Component} from "react";
import {Col, Form, Modal, Row} from "antd";
import "./style/modal.less";
import PasswordLevel from "../../utils/passwordLevel";
import PasswordInput from "../Password/PasswordInput";
import Level from "../Password/Level";
import {connect} from "dva";

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5}
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 17}
    }
};

@connect(({global}) => ({
    global,
}))
class ModifyModal extends Component {
    state = {
        score: 0,
    };

    /**
     * 计算密码评分
     * @param {*} value
     * @param {*} callback
     */
    handleCheckPwd = (value, callback) => {
        const password = new PasswordLevel(value);
        const score = password.getScore()
        this.setState({score});
        if (password.getLength() < 8) callback("至少要8个字符。");
        if (password.getNumberCount() == 0) callback("需至少包含一个数字。");
        if (password.getUpLetterCount() == 0 && password.getLowLetterCount() == 0) callback("需至少包含一个字母。");
        if (password.getSymbolCount() == 0) callback("需至少包含一个符号。");
        if (score < 60) callback("密码强度至少中级（60分），当前评分：" + score + "。");
        callback();
    };

    /**
     * 校验密码
     */
    handleCheckPwdAga(value, callback) {
        const newPassword = this.props.form.getFieldValue("newPassword");
        if (value !== newPassword) {
            callback("两次密码不一致");
        } else {
            callback();
        }
    };


    /**
     * 处理表单提交
     */
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            this.handleModifyPwd(values);
        });
    };

    /**
     * 修改密码
     */
    handleModifyPwd(values, callback) {
        new Promise(resolve => {
            this.props.dispatch({
                type: 'global/modifyPwd',
                payload: {data: values, resolve}
            })
        }).then(() => {
            callback && callback()
        })
    }

    render() {
        const {visible, onCancel, title, form} = this.props;
        const {score} = this.state;
        const {getFieldDecorator} = form;
        const modalProps = {visible, onCancel, title, onOk: e => this.handleSubmit(e)};


        return (
            <Modal {...modalProps} afterClose={() => form.resetFields()}>
                <Form>
                    <Row>
                        <Col span={18}>
                            <FormItem {...formItemLayout} label="旧密码">
                                {getFieldDecorator("oldPassword", {
                                    rules: [{required: true, message: "请输入旧密码"}]
                                })(<PasswordInput placeholder="请输入旧密码"/>)}
                            </FormItem>
                            <FormItem {...formItemLayout} label="新密码">
                                {getFieldDecorator("newPassword", {
                                    rules: [
                                        {required: true, message: "请输入新密码"},
                                        {
                                            validator: (rule, value, callback) =>
                                                this.handleCheckPwd(value, callback)
                                        }
                                    ]
                                })(<PasswordInput placeholder="请输入旧密码"/>)}

                            </FormItem>

                            <FormItem {...formItemLayout} label="确认密码">
                                {getFieldDecorator("newPasswordAga", {
                                    rules: [
                                        {required: true, message: "请输入确认密码"},
                                        {validator: (rule, value, cb) => this.handleCheckPwdAga(value, cb)}
                                    ]
                                })(<PasswordInput placeholder="请输入确认密码"/>)}
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem {...formItemLayout} >
                                <div>&nbsp;
                                </div>
                            </FormItem>
                            <FormItem {...formItemLayout}>
                                <Level score={score}/>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
            ;
    }
};

export default Form.create()(ModifyModal);
