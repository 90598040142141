// zh_CN.js
const zh_CN = {
    'site.title': '智慧消防',
    'site.home': '主页',


    '/account/forget-password': '忘记密码',
    '/account': '登录',
    '/account/login': '登录',
    '/device': '设备管理',
    '/device/detail/:id': '设备详情',
    '/device/index': '设备管理',
    '/': '首页',
    '/home/index': '首页',
    '/inspection': '巡检管理',
    '/inspection/index': '巡检管理',
    '/inspection/record': '巡检记录',
    '/inspection/task': '巡检任务',
    '/inspection/task-no': '巡检任务单',
    '/inspection/ai': '巡检轨迹',
    '/message/:type': '更多通知',
    '/order': '工单管理',
    '/order/index': '工单管理',
    '/order/analyze': '工单分析',
    '/order/history': '历史工单',
    '/order/realtime': '我的工单',
    '/record': '记录管理',
    '/record/event/all': '所有事件',
    '/record/event/common': '常规事件',
    '/record/event/heart-beat': '心跳事件',
    '/record/event/history': '历史告警',
    '/record/event/realtime': '实时告警',
    '/record/log': '日志记录',
    '/record/notice': '更多通知',
    '/report': '报表管理',
    '/report/index': '报表管理',
    '/datav': '大屏',
    '/datav/display1': '大屏',
    '/datav/datascreen': '数字大屏',
    '/system': '系统管理',
    '/system/access': '产品管理',
    '/system/access/detail/:id/:productVersionId': '产品详情',
    '/system/area': '区域管理',
    '/system/menu': '菜单管理',
    '/system/permission': '权限管理',
    '/system/push': '推送策略',
    '/system/role': '角色管理',
    '/system/unit': '项目管理',
    '/system/url': '权限URL',
    '/system/user': '用户管理',
    '/electricity/index': '智慧用电',


    '404': '页面没有找到',
    'none': '无',
    'month': '月份',
    'accumulation': '积累量',
    'count': '数量',
    'switchLanguage': '切换语言',

    'province': '省',
    'city': '市',
    'country': '区（县）',
    'area': '片区',
    'street': '街道',

    'startTime': '开始时间',
    'endTime': '结束时间',
    'happenTime': '发生时间',
    'happenDate': '发生日期',

    'alarmTotal': '告警数量',
    'alarmLevel': '紧急程度：',
    'alarmLevel.all': '全部告警',
    'alarmLevel.generic': '常规事件',
    'alarmLevel.heartBeat': '心跳事件',
    'alarmLevel.level1Alarm': '一级告警',
    'alarmLevel.level2Alarm': '二级告警',
    'alarmLevel.level3Alarm': '三级告警',
    'alarmLevel.level4Alarm': '四级告警',
    'alarmLevel.AlarmRecover': '告警恢复',
    'alarmLevel.level1Alarm.value': '非常紧急',
    'alarmLevel.level2Alarm.value': '紧急',
    'alarmLevel.level3Alarm.value': '一般',
    'alarmLevel.level4Alarm.value': '较低',


    'area.quanzhou': '泉州',
    'base.info': '基本信息',
    'realTime.data': '实时数据',
    'result.none': '暂无数据',

    'modalName.toView': '查看',
    'modalName.update': '修改',
    'modalName.add': '新增',


    'device.state.all': '全部',
    'device.state.normal': '正常',
    'device.state.alarm': '告警',
    'device.state.offline': '离线',
    'device.state.lost': '失联',
    'device.state.notActive': '未激活',
    'device.state.unknown': '未知',

    'device.actionType.normal': '常规',
    'device.actionType.arm': '布防',
    'device.actionType.disarm': '撤防',
    'device.actionType.disAlarm': '接警',


    'device.alarm.message': '告警消息',
    'device.alarm.reason': '告警原因',
    'device.alarm.time': '告警消息',


    'device': '设备',
    'device.countName': '台',
    'device.info': '设备信息',
    'device.sn': '设备编号',
    'device.name': '设备名称',
    'device.alarmName': '告警名称',
    'device.happenTime': '发生时间',
    'device.productName': '产品名称',
    'device.status': '设备状态',
    'device.alarmStatus': '布防状态',
    'device.alarmStatus.success': '已布防',
    'device.alarmStatus.error': '已撤防',
    'device.areaName': '区域名称',
    'device.area': '所属区域',
    'device.unitName': '所属项目',
    'device.dataState': '启用状态',
    'device.dataState.true': '启用',
    'device.dataState.false': '停用',
    'device.unit': '管理项目',
    'device.address': '安装地址',
    'device.infoAddress': '详细地址',
    'device.installLocation': '安装位置',
    'device.type': '设备类型',
    'device.count': '设备数量',
    'device.heatBeatTime': '数据变更',

    'product.key': '产品标识',
    'product.model': '产品型号',
    'product.type': '产品类别',
    'product.createTime': '创建时间',
    'product.status': '产品状态',
    'product.manufactureName': '厂家名称',
    'product.version': '产品版本',


    'order': '工单',
    'order.countName': '条',
    'order.sn': '工单编号',
    'order.name': '工单名称',
    'order.state': '工单状态',
    'order.state.unHandle': '未处理',
    'order.state.handle': '已处理',
    'order.handleUserName': '处理人',
    'order.happenTime': '发生时间',


    'inspection.taskNo': '任务编号',
    'inspection.device.sn': '设备编号',
    'inspection.unit': '所属项目',
    'inspection.userName': '巡检人员',
    'inspection.time': '巡检时间',
    'inspection.endTime': '结束时间',
    'inspection.state': '巡检状态',
    'inspection.state.endInspect': '已巡检',
    'inspection.state.startInspect': '未巡检',
    'inspection.result': '巡检结果',
    'inspection.resultInfo': '巡检意见',
    'inspection.address': '详细地址',
    'inspection.image': '现场照片',

    'image1': '设备图',
    'image2': '安装图',
    'image3': '安装图',
    'image4': '项目图',

    'order.handleMethod.ignore': '忽略',
    'order.handleMethod.error': '误告',
    'order.handleMethod.replaceDevice': '更换设备',
    'order.handleMethod.replaceParts': '更换部件',
    'order.handleMethod.repairParts': '维修部件',
    'order.handleMethod.repairDevice': '维修设备',
    'order.handleMethod.other': '其他',

    'order.level.general': '普通',
    'order.level.urgent': '紧急',
    'order.level.serious': '严重',

    'order.type.alarm': '报警',
    'order.type.inspect': '检查',
    'order.type.repair': '维修',

    'system.User': '用户管理',
    'system.Area': '区域管理',
    'system.Unit': '项目管理',
    'system.Role': '角色管理',


    'button.login': '登录',
    'button.nowLogin': '立即登录',
    'button.forget': '忘记密码',
    'button.reset': '确认重置',
    'button.getCode': '获取验证码',
    'button.timeAgain': 's后重试',
    'button.upload': '点击选择文件上传',
    'button.toView': '查看',
    'button.read': '标记为已读',
    'button.setRead': '设置已读',
    'button.upload2': '上传',

    'button.ok': '确定',
    'button.cancel': '取消',
    'button.close': '关闭',
    'button.detail': '详情',
    'button.search': '查询',
    'button.import': '导入',
    'button.export': '导出',

    'button.add': '添加',
    'button.create': '新增',
    'button.createOrder': '新增工单',
    'button.edit': '编辑',
    'button.update': '修改',
    'button.delete': '删除',
    'button.switch': '选择',
    'button.operation': '操作',

    'button.goBack': '返回',
    'button.full': '全屏',
    'button.fullExit': '退出全屏',

    'show': '显示',
    'hidden': '隐藏',

    'radio.true': '是',
    'radio.false': '否',


    'form': '表单',
    'form.account.login.userName': '用户名',
    'form.account.login.pleaseUserName': '请输入您的用户名',
    'form.account.login.passWord': '密码',
    'form.account.login.pleasePassWord': '请输入密码',
    'form.account.login.imageCode': '验证码',
    'form.account.login.pleaseImageCode': '请输入验证码',

    'form.account.forget.phone': '请输入绑定的手机号',
    'form.account.forget.pleasePhone': '请输入绑定的手机号',
    'form.account.forget.phoneCode': '请输入验证码',
    'form.account.forget.pleasePhoneCode': '请输入验证码',
    'form.account.forget.passWord': '请输入密码',
    'form.account.forget.pleasePassWord': '请输入密码',
    'form.account.forget.pleaseConfirmPassWord': '请输入确认密码',
    'form.account.forget.pleaseAgain': '请再次输入密码',
    'form.account.forget.passWord.unLike': '两次密码不一致',
    'form.account.forget.passWord.min': '密码最少8位',
    'form.account.forget.passWord.format': '密码必须由数字和大小写英文字母组成',
    'form.account.forget.phone.long': '手机号长度应为11位',
    'form.account.forget.phone.format': '手机号不合法',


    'form.please': '请输入',
    'form.pleaseWithArray': '类型的数据（以“,”分隔）',


    'form.device.action': '操作',
    'form.device.action.please': '请选择操作',

    'form.device.import.productType': '产品类型',
    'form.device.import.productType.please': '请选择产品类型',
    'form.device.import.productVersion': '产品版本',
    'form.device.import.productVersion.please': '请选择产品版本',
    'form.device.import.area': '所属区域',
    'form.device.import.area.please': '请选择所属区域',
    'form.device.import.unit': '所属项目',
    'form.device.import.unit.please': '请选择所属项目',
    'form.device.import.file': '文件',
    'form.device.import.file.please': '请选择文件',


    'form.device.update.name': '设备名称',
    'form.device.update.name.please': '请输入设备名称',
    'form.device.update.sn': '设备编号',
    'form.device.update.sn.please': '请输入设备编号',
    'form.device.update.productType': '产品类型',
    'form.device.update.productType.please': '请选择产品类型',
    'form.device.update.productVersion': '产品版本',
    'form.device.update.productVersion.please': '请选择产品版本',
    'form.device.update.area': '所属区域',
    'form.device.update.area.please': '请选择所属区域',
    'form.device.update.address': '详细地址',
    'form.device.update.address.please': '请输入详细地址',
    'form.device.update.langAndLat': '安装坐标',
    'form.device.update.langAndLat.please': '请选择安装坐标',
    'form.device.update.installLocation': '安装位置',
    'form.device.update.installLocation.please': '请输入安装位置',
    'form.device.update.unit': '所属项目',
    'form.device.update.unit.please': '请选择所属项目',
    'form.device.update.linkman': '联系人',
    'form.device.update.linkman.please': '请输入联系人',
    'form.device.update.tel': '联系电话',
    'form.device.update.tel.please': '请输入联系电话',


    'form.inspection.task.name': '任务名',
    'form.inspection.task.name.please': '请输入任务名',
    'form.inspection.task.unit': '巡检项目',
    'form.inspection.task.unit.please': '请选择巡检项目',
    'form.inspection.task.userName': '巡检人员',
    'form.inspection.task.userName.please': '请选择巡检人员',
    'form.inspection.task.area': '巡检区域',
    'form.inspection.task.area.please': '请选择巡检区域',
    'form.inspection.task.productType': '设备类型',
    'form.inspection.task.productType.please': '请选择设备类型',
    'form.inspection.task.startTime': '开始时间',
    'form.inspection.task.startTime.please': '请选择开始时间',
    'form.inspection.task.endTime': '结束时间',
    'form.inspection.task.endTime.please': '请选择结束时间',
    'form.inspection.task.cycle': '定期巡检',
    'form.inspection.task.cycle.please': '请选择是否定期巡检',
    'form.inspection.task.cycleDays': '巡检周期',
    'form.inspection.task.cycleDays.please': '请输入巡检周期',

    'form.order.handleMethod': '处理结果',
    'form.order.handleMethod.please': '请选择处理结果',
    'form.order.image': '现场照片',
    'form.order.image.please': '请上传现场照片',
    'form.order.handleRemark': '处理意见',
    'form.order.handleRemark.please': '请输入处理意见',

    'form.order.name': '工单名称',
    'form.order.name.please': '请填写工单名称',
    'form.order.unit': '所属项目',
    'form.order.unit.please': '请选择项目',
    'form.order.user': '处理人员',
    'form.order.user.please': '请选择处理人员',
    'form.order.area': '所属区域',
    'form.order.area.please': '请选择区域',
    'form.order.level': '工单级别',
    'form.order.level.please': '请选择工单级别',
    'form.order.productType': '设备类型',
    'form.order.productType.please': '请选择设备类型',
    'form.order.device': '工单设备',
    'form.order.device.please': '请选择工单设备',

    'form.system.access.upload.iconImage.please': '请选择需要上传的图片',
    'form.system.access.update.evenType.please': '事件类型必选',
    'form.system.access.update.actionType.please': '操作类型必选',
    'form.system.access.version.version.please': '版本必选',

    'form.system.area.type': '区域类型',
    'form.system.area.type.please': '请输入区域类型',
    'form.system.area.name': '区域名称',
    'form.system.area.name.please': '请输入区域名称',
    'form.system.area.coordinates': '区域坐标',
    'form.system.area.coordinates.please': '请选择区域坐标',
    'form.system.area.lng': '经度',
    'form.system.area.lat': '纬度',

    'form.system.menu.name': '菜单名称',
    'form.system.menu.name.please': '请输入菜单名称',
    'form.system.menu.url': '路由',
    'form.system.menu.url.please': '请输入路由',
    'form.system.menu.icon': '图标',
    'form.system.menu.icon.please': '请选择图标',
    'form.system.menu.remark': '备注',
    'form.system.menu.remark.please': '请输入菜单备注',


    'form.system.permission.resourceId': '资源ID',
    'form.system.permission.id': '主键ID',
    'form.system.permission.parentName': '资源名称',
    'form.system.permission.name': '权限名称',
    'form.system.permission.name.please': '请输入权限名称',
    'form.system.permission.code': '权限代码',
    'form.system.permission.code.please': '请输入权限代码',
    'form.system.permission.description': '权限描述',
    'form.system.permission.description.please': '请输入描述',
    'form.system.permission.url': '权限URL',


    'form.system.role.name': '角色名称',
    'form.system.role.name.please': '请输入角色名称',
    'form.system.role.permission': '角色权限',


    'form.system.unit.siteName': '站点名称',
    'form.system.unit.siteName.please': '请输入站点名称',
    'form.system.unit.label': '站点副标题',
    'form.system.unit.label.please': '请输入站点副标题',
    'form.system.unit.name': '项目名称',
    'form.system.unit.name.please': '请输入项目名称',
    'form.system.unit.linkman': '负责人',
    'form.system.unit.linkman.please': '请输入负责人',
    'form.system.unit.tel': '手机号码',
    'form.system.unit.tel.please': '请输入手机号码',
    'form.system.unit.maxUserLimit': '最大用户数',
    'form.system.unit.maxUserLimit.please': '请输入最大用户数',
    'form.system.unit.devPerms': '产品权限',
    'form.system.unit.devPerms.please': '请选择设备权限',
    'form.system.unit.remark': '备注',
    'form.system.unit.remark.please': '请输入备注',
    'form.system.unit.webLogo': '请上传Web端图标',
    'form.system.unit.webLogo.please': '请上传web端图标',
    'form.system.unit.appLogo': '请上传App端图标',
    'form.system.unit.appLogo.please': '请上传app端图标',

    'form.system.url.id': '权限ID',
    'form.system.url.description': 'URL描述',
    'form.system.url.url': 'URL地址',
    'form.system.url.description.please': '请上传app端图标',

    'form.system.user.unti': '所属项目',
    'form.system.user.unti.please': '请选择所属项目',
    'form.system.user.realName': '昵称',
    'form.system.user.realName.please': '请输入昵称',
    'form.system.user.username': '用户名',
    'form.system.user.username.please': '请输入用户名',
    'form.system.user.username.long.min': '用户名长度至少6个字符',
    'form.system.user.username.long.max': '用户名长度至多20个字符',
    'form.system.user.password': '密码',
    'form.system.user.password.please': '请输入密码',
    'form.system.user.password.please.none': '请输入密码(留空则不修改密码)',
    'form.system.user.passwordAga': '确认密码',
    'form.system.user.passwordAga.please': '请输入确认密码',
    'form.system.user.passwordAga.please.none': '请输入确认密码(留空则不修改密码)',
    'form.system.user.pwdExpriredTimeType': '密码时效',
    'form.system.user.pwdExpriredTimeType.1': '90天后过期',
    'form.system.user.pwdExpriredTimeType.0': '永久',
    'form.system.user.tel': '手机号码',
    'form.system.user.tel.please': '请输入手机号码',
    'form.system.user.email': '邮箱',
    'form.system.user.email.please': '请输入邮箱',
    'form.system.user.role': '角色',
    'form.system.user.role.please': '请选择角色',
    'form.system.user.area': '所属区域',
    'form.system.user.area.please': '请选择所属区域',
    'form.system.user.userIcon': '请点选用户图标',
    'form.system.user.userIcon.please': '请上传用户图标',
    'form.system.user.dataState.please': '请选择用户状态',
    'form.system.user.dataState.enable': '激活',
    'form.system.user.dataState.disabled': '禁用',


    'device.modalName.add': '新增设备',
    'device.modalName.detail': '设备详情',
    'device.modalName.update': '修改设备',
    'device.modalName.analyze': '通道分析',
    'device.modalName.import': '导入设备',
    'device.modalName.operation': '远程控制',
    'device.modalName.statistical': '设备状态统计',
    'device.button.arm': '布防',
    'device.button.disarm': '撤防',
    'device.button.enable': '启用',
    'device.button.disable': '停用',
    'device.button.finishAlarm': '警情处理',
    'device.button.delete': '拆除',
    'device.button.add': '报装',
    'device.button.statistical.show': '展开统计',
    'device.button.statistical.hidden': '收缩统计',

    'device.tabs.params': '状态信息',
    'device.tabs.event': '事件记录',
    'device.tabs.alarm': '告警记录',
    'device.tabs.status': '状态记录',
    'device.tabs.action': '操作记录',
    'device.tabs.control': '控制记录',

    'device.analyzeColumns.fieldList': '查询通道',
    'device.analyzeColumns.count': '查询数量',


    'home.modalName.area': '区域选择',
    'home.modalName.unit': '项目选择',
    'home.modalName.device': '设备列表',
    'home.modalName.alarm': '告警详情',
    'home.modalName.operation': '操作详情',


    'inspection.task.modalName.toView': '巡检任务详情',
    'inspection.task.modalName.update': '修改巡检任务',
    'inspection.task.modalName.add': '新增',

    'inspection.record.modalName.toView': '巡检设备详情',

    'inspection.taskNo.modalName.toView': '巡检设备',
    'inspection.trajectory.modalName.toView': '巡检设备',

    'message.tabs.install': '报装通知',
    'message.tabs.alarm': '告警消息',
    'message.tabs.order': '工单',
    'message.tabs.inspect': '巡检',
    'message.tabs.system': '系统通知',
    'message.tabs.setReadAll': '全部已读',


    'order.modalName.dayGrowthChart': '工单增长趋势',
    'order.modalName.orderStatusChart': '工单状态占比',
    'order.modalName.dealTypeChart': '已结束工单处理方式占比',
    'order.modalName.orderDeviceChart': '已结束工单设备占比',
    'order.modalName.orderTypeChart': '已结束工单类型占比',

    'order.modalName.check': '工单详情',
    'order.modalName.deal': '工单处理',


    'order.tab.all': '全部工单',
    'order.tab.unAssign': '待指派',
    'order.tab.unHandle': '待处理',
    'order.tab.handle': '已处理',
    'order.tab.unConfirm': '待确认',
    'order.tab.confirm': '已确认',
    'order.tab.finish': '已结束',

    'order.tabItem.name': '今日新增',


    'message.mobileCode.success': '验证码发送成功',
    'message.passWordReset.success': '密码重置成功',
    'message.productType.none': '请先选择产品类型',

    'message.deviceTable.none': '请选择工单设备',

    'message.upload.icon.none': '请选择图标',
    'message.upload.file.png.typeError': '您只能上传格式为png的图片',
    'message.upload.file.png.max': '您只能上传小于2M的图片',
    'message.upload.file.success': '上传成功',
    'message.uint.maxUser': '该项目最大用户剩余',

    'message.userName.exist': '用户名已存在',
    'message.userName.chinese': '用户名不能有中文',
    'message.userName.longError': '用户名长度为4-20',
    'message.realName.longError': '昵称长度为4-20',
    'message.phone.exist': '手机号已存在',
    'message.phone.longError': '手机号长度应为11位',
    'message.phone.formatError': '手机号不合法',
    'message.email.exist': '邮箱已存在',
    'message.email.none': '请输入邮箱地址',
    'message.email.formatError': '邮箱不合法',
    'message.password.different': '两次密码输入不一致',
    'message.password.long.min': '密码最少8位',
    'message.password.formatError': '密码必须由数字和大小写英文字母组成',
    'message.password.sameWithName': '密码不能与用户名相同',
    'message.unitCount.full': '该项目可添加人数已满',

    'message.global.modifyPwd.success': '修改成功，请重新登录',
    'message.global.modifyPwd.error': '旧密码错误',
    'message.global.setRead.success': '已设为已读',
    'message.global.setReadBatch.success': '设置成功',
    'message.device.changeState.success': '变更成功',
    'message.home.changeState.success': '变更成功',
    'message.home.changeState.error': '变更失败',
    'message.address.error': '请选择地址',


    'order.realtime.alarmInfo.title': '告警信息',
    'order.realtime.alarmInfo.eventName': '事件名称',
    'order.realtime.alarmInfo.alarmName': '告警名称',
    'order.realtime.alarmInfo.time': '发生时间',
    'order.realtime.alarmInfo.props': '事件参数',
    'order.realtime.alarmInfo.prop': '附加参数',

    'order.realtime.deviceInfo.title': '设备信息',
    'order.realtime.deviceInfo.name': '设备名字',
    'order.realtime.deviceInfo.sn': '设备编号',
    'order.realtime.deviceInfo.area': '所属区域',
    'order.realtime.deviceInfo.address': '详细地址',
    'order.realtime.deviceInfo.installLocation': '安装位置',
    'order.realtime.deviceInfo.unit': '所属项目',

    'order.realtime.userInfo.title': '指派信息',
    'order.realtime.userInfo.userName': '指派人',
    'order.realtime.userInfo.handleUserName': '被指派人',
    'order.realtime.userInfo.time': '指派时间',


    'order.history.alarmInfo.title': '告警信息',
    'order.history.alarmInfo.eventName': '事件名称',
    'order.history.alarmInfo.alarmName': '告警名称',
    'order.history.alarmInfo.time': '发生时间',
    'order.history.alarmInfo.props': '事件参数',
    'order.history.alarmInfo.prop': '附加参数',

    'order.history.deviceInfo.title': '设备信息',
    'order.history.deviceInfo.name': '设备名字',
    'order.history.deviceInfo.sn': '设备编号',
    'order.history.deviceInfo.area': '所属区域',
    'order.history.deviceInfo.address': '详细地址',
    'order.history.deviceInfo.installLocation': '安装位置',
    'order.history.deviceInfo.unit': '所属项目',

    'order.history.userInfo.title': '指派信息',
    'order.history.userInfo.userName': '指派人',
    'order.history.userInfo.handleUserName': '被指派人',
    'order.history.userInfo.time': '指派时间',

    'order.history.handleInfo.title': '处理信息',
    'order.history.handleInfo.handleUserName': '处理人',
    'order.history.handleInfo.handleTime': '处理时间',

    'order.history.confirmInfo.title': '确认信息',
    'order.history.confirmInfo.confirmUserName': '确认人',
    'order.history.confirmInfo.confirmTime': '确认时间',

    'order.history.complete.title': '完成信息',
    'order.history.complete.handleUserName': '处理人',
    'order.history.complete.handleResult': '处理结果',
    'order.history.complete.handleOpinion': '处理意见',
    'order.history.complete.image': '现场照片',
    'order.history.complete.time': '完成时间',


    'report.modalName.EquipmentStatePie': '设备状态统计',
    'report.modalName.EquipmentTypeBar': '设备数量统计',
    'report.modalName.EvenList': '最新告警',
    'report.modalName.AlarmGraph': '告警增长趋势',
    'report.modalName.OrderStatePie': '工单状态统计',
    'report.modalName.OrderHandleWayPie': '工单处理方式统计',
    'report.modalName.OrderTypePie': '工单类型统计',
    'report.modalName.OrderHandleList': '最新工单',
    'report.modalName.OrderCountGraph': '工单增长趋势',
    'report.modalName.DeviceGraph': '设备增长趋势',

    'screen.total': '总数',
    'screen.handleTotal': '处理数',
    'screen.online': '在线',
    'screen.alarm': '告警',


    'access.modalName.uploadProductIcon': '上传产品图标',
    'access.modalName.selectProductIcon': '选择产品图标',
    'access.modalName.setEventType': '设置事件类型',
    'access.modalName.switchVersion': '切换版本',


    'menu.modalName.add': '添加菜单',
    'menu.modalName.update': '修改菜单',
    'menu.modalName.select': '选择菜单图标',

    'permission.modalName.add': '添加资源',
    'permission.modalName.update': '修改资源',

    'role.modalName.add': '新增角色',
    'role.modalName.update': '修改角色',
    'role.modalName.toView': '查看角色',

    'unit.modalName.create': '创建项目',
    'unit.modalName.update': '修改项目',
    'unit.modalName.toView': '查看项目',

    'url.modalName.add': '添加权限',
    'url.modalName.update': '修改权限',

    'user.modalName.create': '创建用户',
    'user.modalName.update': '修改用户',
    'user.modalName.toView': '查看用户',


    'access.tabs.prop': '属性',
    'access.tabs.action': '操作',
    'access.tabs.event': '事件',
    'access.tabs.common': '常规',


    'spin.tip.Login': '登陆中...',
    'spin.tip.Forget': '处理中...',

    'dataTable.num': '序号',
    'dataTable.sum.left': '共',
    'dataTable.sum.right': '条',
    //======================表格头部======================
    'table.baseColumns.operation': '操作',
    'table.baseColumns.eye': '查看',
    'table.baseColumns.add': '添加',
    'table.baseColumns.create': '创建',
    'table.baseColumns.edit': '编辑',
    'table.baseColumns.delete': '删除',
    'table.baseColumns.handle': '处理',
    'table.baseColumns.analyze': '通道分析',
    'table.baseColumns.startTime': '开始时间',
    'table.baseColumns.endTime': '结束时间',
    'table.baseColumns.createdTime': '创建时间',
    //---------------------设备-----------------------
    'table.device.detail.actionColumns.icon': '标识',
    'table.device.detail.actionColumns.deviceSn': '设备编号',
    'table.device.detail.actionColumns.deviceName': '设备名称',
    'table.device.detail.actionColumns.actionName': '操作名称',
    'table.device.detail.actionColumns.inputParams': '输入参数',
    'table.device.detail.actionColumns.time': '操作时间',

    'table.device.detail.historyColumns.productIcon': '图标',
    'table.device.detail.historyColumns.productName': '产品名称',
    'table.device.detail.historyColumns.eventName': '事件名称',
    'table.device.detail.historyColumns.eventType': '紧急程度',
    'table.device.detail.historyColumns.deviceSn': '设备编号',
    'table.device.detail.historyColumns.deviceName': '设备名称',
    'table.device.detail.historyColumns.deviceAddress': '设备地址',
    'table.device.detail.historyColumns.time': '发生时间',
    'table.device.detail.historyColumns.handleState': '处理状态',


    'table.device.detail.alarmColumns.icon': '标识',
    'table.device.detail.alarmColumns.deviceSn': '设备编号',
    'table.device.detail.alarmColumns.deviceName': '设备名称',
    'table.device.detail.alarmColumns.eventName': '事件名称',
    'table.device.detail.alarmColumns.time': '发生时间',

    'table.device.detail.statusColumns.deviceSn': '设备编号',
    'table.device.detail.statusColumns.deviceName': '设备名称',
    'table.device.detail.statusColumns.time': '变更时间',

    'table.device.detail.searchDateColumns.startTime': '开始时间',
    'table.device.detail.searchDateColumns.endTime': '结束时间',

    'table.device.index.defaultColumns.productIcon': '图标',
    'table.device.index.defaultColumns.productName': '产品名称',
    'table.device.index.defaultColumns.sn': '设备编号',
    'table.device.index.defaultColumns.name': '设备名称',
    'table.device.index.defaultColumns.dataState': '启用状态',
    'table.device.index.defaultColumns.dataState.true': '启用',
    'table.device.index.defaultColumns.dataState.false': '停用',
    'table.device.index.defaultColumns.status': '设备状态',
    'table.device.index.defaultColumns.status.normal': '正常',
    'table.device.index.defaultColumns.status.alarm': '告警',
    'table.device.index.defaultColumns.status.lose': '失联',
    'table.device.index.defaultColumns.status.offline': '离线',
    'table.device.index.defaultColumns.status.notActive': '未激活',
    'table.device.index.defaultColumns.status.other': '未知',
    'table.device.index.defaultColumns.status.all': '全部',
    'table.device.index.defaultColumns.armStatus': '布防状态',
    'table.device.index.defaultColumns.armStatus.true': '已布防',
    'table.device.index.defaultColumns.armStatus.false': '已撤防',
    'table.device.index.defaultColumns.areaName': '所属区域',
    'table.device.index.defaultColumns.unitName': '所属项目',
    'table.device.index.defaultColumns.address': '详细地址',
    'table.device.index.defaultColumns.installLocation': '安装位置',
    'table.device.index.defaultColumns.deleteDevice': '确认删除设备',
    //--------------------巡检-----------------------
    'table.inspection.record.defaultColumns.inspectTaskNo': '任务编号',
    'table.inspection.record.defaultColumns.productIcon': '产品图标',
    'table.inspection.record.defaultColumns.productName': '设备类型',
    'table.inspection.record.defaultColumns.deviceSn': '设备编号',
    'table.inspection.record.defaultColumns.inspectUserName': '巡检人员',
    'table.inspection.record.defaultColumns.inspectTime': '巡检时间',
    'table.inspection.record.defaultColumns.state': '巡检状态',
    'table.inspection.record.defaultColumns.state.processing': '已巡检',
    'table.inspection.record.defaultColumns.state.error': '未巡检',
    'table.inspection.record.defaultColumns.inspectMethodName': '巡检方式',
    'table.inspection.record.defaultColumns.inspectResultName': '巡检结果',
    'table.inspection.record.defaultColumns.inspectResultName.normal': '正常',
    'table.inspection.record.defaultColumns.inspectResultName.repair': '报修',
    'table.inspection.record.defaultColumns.deviceAddr': '详细地址',
    'table.inspection.record.defaultColumns.unitName': '所属项目',
    'table.inspection.record.defaultColumns.areaName': '巡检区域',

    'table.inspection.task.defaultColumns.inspectNo': '任务序号',
    'table.inspection.task.defaultColumns.name': '任务名称',
    'table.inspection.task.defaultColumns.inspectUserDtoList': '巡检人员',
    'table.inspection.task.defaultColumns.cycle': '定期巡检',
    'table.inspection.task.defaultColumns.cycleDays': '巡检周期',
    'table.inspection.task.defaultColumns.startTime': '开始时间',
    'table.inspection.task.defaultColumns.endTime': '结束时间',
    'table.inspection.task.defaultColumns.areaName': '巡检区域',
    'table.inspection.task.defaultColumns.unitName': '巡检项目',

    'table.inspection.taskNo.taskData.inspectTaskNo': '任务编号',
    'table.inspection.taskNo.taskData.inspectName': '任务名称',
    'table.inspection.taskNo.taskData.inspectUserDtoList': '巡检人员',
    'table.inspection.taskNo.taskData.areaName': '巡检区域',
    'table.inspection.taskNo.taskData.unitName': '巡检项目',
    'table.inspection.taskNo.taskData.state': '任务状态',
    'table.inspection.taskNo.taskData.state.processing': '进行中',
    'table.inspection.taskNo.taskData.state.finish': '已结束',
    'table.inspection.taskNo.taskData.total': '巡检总数',
    'table.inspection.taskNo.taskData.finishTotal': '已巡检数',
    'table.inspection.taskNo.taskData.ratioEndInspect': '完成进度',
    'table.inspection.taskNo.taskData.repairTotal': '报修数量',

    'table.inspection.taskNo.recordData.inspectTaskNo': '任务编号',
    'table.inspection.taskNo.recordData.productIcon': '产品图标',
    'table.inspection.taskNo.recordData.productName': '设备类型',
    'table.inspection.taskNo.recordData.deviceSn': '设备编号',
    'table.inspection.taskNo.recordData.unitName': '所属项目',
    'table.inspection.taskNo.recordData.inspectUserName': '巡检人员',
    'table.inspection.taskNo.recordData.inspectTime': '巡检时间',
    'table.inspection.taskNo.recordData.state': '巡检状态',
    'table.inspection.taskNo.recordData.state.processing': '已巡检',
    'table.inspection.taskNo.recordData.state.error': '未巡检',
    'table.inspection.taskNo.recordData.inspectMethodName': '巡检意见',
    'table.inspection.taskNo.recordData.inspectResultName': '巡检结果',
    'table.inspection.taskNo.recordData.inspectResultName.normal': '正常',
    'table.inspection.taskNo.recordData.inspectResultName.repair': '报修',
    'table.inspection.taskNo.recordData.deviceAddr': '详细地址',

    'table.inspection.trajectory.taskData.no': '任务编号',
    'table.inspection.trajectory.taskData.inspectName': '任务名称',
    'table.inspection.trajectory.taskData.inspectUserName': '巡检人员',
    'table.inspection.trajectory.taskData.areaName': '巡检区域',
    'table.inspection.trajectory.taskData.unitName': '巡检项目',
    'table.inspection.trajectory.taskData.totalInspect': '巡检总数',
    'table.inspection.trajectory.taskData.totalPatroled': '已巡检数',
    'table.inspection.trajectory.taskData.ratioEndInspect': '完成进度',
    'table.inspection.trajectory.taskData.totalRepair': '报修数量',

    'table.inspection.trajectory.recordData.inspectTaskNo': '任务编号',
    'table.inspection.trajectory.recordData.productIcon': '产品图标',
    'table.inspection.trajectory.recordData.productName': '设备类型',
    'table.inspection.trajectory.recordData.deviceSn': '设备编号',
    'table.inspection.trajectory.recordData.unitName': '所属项目',
    'table.inspection.trajectory.recordData.inspectUserName': '巡检人员',
    'table.inspection.trajectory.recordData.inspectionTime': '巡检时间',
    'table.inspection.trajectory.recordData.inspectionMethod': '巡检方式',
    'table.inspection.trajectory.recordData.inspectionMethod.true': '手动',
    'table.inspection.trajectory.recordData.inspectionMethod.false': '自动',
    'table.inspection.trajectory.recordData.state': '巡检状态',
    'table.inspection.trajectory.recordData.state.processing': '已巡检',
    'table.inspection.trajectory.recordData.state.error': '未巡检',
    'table.inspection.trajectory.recordData.processResult': '巡检结果',
    'table.inspection.trajectory.recordData.processResult.true': '正常',
    'table.inspection.trajectory.recordData.processResult.false': '报修',
    'table.inspection.trajectory.recordData.inspectResultInfo': '巡检意见',
    'table.inspection.trajectory.recordData.deviceAddr': '详细地址',
    //------------------------通知----------------------------
    'table.message.defaultColumns.readState': '是否已读',
    'table.message.defaultColumns.readState.null': '全部',
    'table.message.defaultColumns.readState.read': '已读',
    'table.message.defaultColumns.readState.unRead': '未读',
    'table.message.defaultColumns.type': '消息类型',
    'table.message.defaultColumns.type.alarm': '告警信息',
    'table.message.defaultColumns.type.install': '报装信息',
    'table.message.defaultColumns.type.sysMsg': '系统消息',
    'table.message.defaultColumns.type.null.search': '全部',
    'table.message.defaultColumns.type.install.search': '设备报装',
    'table.message.defaultColumns.type.alarm.search': '告警信息',
    'table.message.defaultColumns.type.sysMsg.search': '系统通知',
    'table.message.defaultColumns.time': '发生时间',
    'table.message.defaultColumns.time.search': '起止时间',
    'table.message.defaultColumns.data': '信息',
    //------------------------工单--------------------
    'table.order.history.defaultColumns.no': '工单编号',
    'table.order.history.defaultColumns.name': '工单名称',
    'table.order.history.defaultColumns.level': '工单等级',
    'table.order.history.defaultColumns.level.urgent': '紧急',
    'table.order.history.defaultColumns.level.serious': '严重',
    'table.order.history.defaultColumns.level.general': '普通',
    'table.order.history.defaultColumns.state': '工单状态',
    'table.order.history.defaultColumns.state.unAssign': '待指派',
    'table.order.history.defaultColumns.state.unHandle': '待处理',
    'table.order.history.defaultColumns.state.unConfirm': '待确认',
    'table.order.history.defaultColumns.state.finish': '已结束',
    'table.order.history.defaultColumns.type': '工单类型',
    'table.order.history.defaultColumns.type.alarm': '告警',
    'table.order.history.defaultColumns.type.repair': '维修',
    'table.order.history.defaultColumns.type.inspect': '巡检',
    'table.order.history.defaultColumns.unitName': '所属项目',
    'table.order.history.defaultColumns.areaName': '所属区域',

    'table.order.realtime.orderColumns.no': '工单编号',
    'table.order.realtime.orderColumns.name': '工单名称',
    'table.order.realtime.orderColumns.level': '工单等级',
    'table.order.realtime.orderColumns.level.urgent': '紧急',
    'table.order.realtime.orderColumns.level.serious': '严重',
    'table.order.realtime.orderColumns.level.general': '普通',
    'table.order.realtime.orderColumns.state': '工单状态',
    'table.order.realtime.orderColumns.state.unAssign': '待指派',
    'table.order.realtime.orderColumns.state.unHandle': '待处理',
    'table.order.realtime.orderColumns.state.unConfirm': '待确认',
    'table.order.realtime.orderColumns.state.finish': '已结束',
    'table.order.realtime.orderColumns.type': '工单类型',
    'table.order.realtime.orderColumns.type.alarm': '告警',
    'table.order.realtime.orderColumns.type.repair': '维修',
    'table.order.realtime.orderColumns.type.inspect': '巡检',
    'table.order.realtime.orderColumns.unitName': '所属项目',
    'table.order.realtime.orderColumns.areaName': '所属区域',

    'table.order.realtime.deviceColumns.name': '设备名称',
    'table.order.realtime.deviceColumns.sn': '设备编号',
    'table.order.realtime.deviceColumns.unitName': '所属项目',
    'table.order.realtime.deviceColumns.linkman': '负责人',
    'table.order.realtime.deviceColumns.address': '安装地址',
    //-------------------------记录-----------------------------
    'table.record.event.all.defaultColumns.productIcon': '图标',
    'table.record.event.all.defaultColumns.productName': '产品名称',
    'table.record.event.all.defaultColumns.eventName': '事件名称',
    'table.record.event.all.defaultColumns.deviceSn': '设备编号',
    'table.record.event.all.defaultColumns.deviceName': '设备名称',
    'table.record.event.all.defaultColumns.eventType': '事件类型',
    'table.record.event.all.defaultColumns.eventType.generic': '常规事件',
    'table.record.event.all.defaultColumns.eventType.heartBeat': '心跳事件',
    'table.record.event.all.defaultColumns.eventType.alarmRecover': '告警恢复',
    'table.record.event.all.defaultColumns.eventType.level1Alarm': '一级告警',
    'table.record.event.all.defaultColumns.eventType.level2Alarm': '二级告警',
    'table.record.event.all.defaultColumns.eventType.level3Alarm': '三级告警',
    'table.record.event.all.defaultColumns.eventType.level4Alarm': '四级告警',
    'table.record.event.all.defaultColumns.time': '发生时间',
    'table.record.event.all.defaultColumns.deviceAddress': '设备地址',

    'table.record.event.common.defaultColumns.productIcon': '图标',
    'table.record.event.common.defaultColumns.productName': '产品名称',
    'table.record.event.common.defaultColumns.eventName': '事件名称',
    'table.record.event.common.defaultColumns.deviceSn': '设备编号',
    'table.record.event.common.defaultColumns.deviceName': '设备名称',
    'table.record.event.common.defaultColumns.time': '发生时间',

    'table.record.event.heart.defaultColumns.productIcon': '图标',
    'table.record.event.heart.defaultColumns.productName': '产品名称',
    'table.record.event.heart.defaultColumns.eventName': '事件名称',
    'table.record.event.heart.defaultColumns.deviceSn': '设备编号',
    'table.record.event.heart.defaultColumns.deviceName': '设备名称',
    'table.record.event.heart.defaultColumns.time': '发生时间',

    'table.record.event.history.defaultColumns.productIcon': '图标',
    'table.record.event.history.defaultColumns.productName': '产品名称',
    'table.record.event.history.defaultColumns.eventName': '告警名称',
    'table.record.event.history.defaultColumns.eventType': '紧急程度',
    'table.record.event.history.defaultColumns.deviceSn': '设备编号',
    'table.record.event.history.defaultColumns.deviceName': '设备名称',
    'table.record.event.history.defaultColumns.deviceAddress': '设备地址',
    'table.record.event.history.defaultColumns.time': '发生时间',
    'table.record.event.history.defaultColumns.handleState': '处理状态',

    'table.record.event.realtime.defaultColumns.productIcon': '图标',
    'table.record.event.realtime.defaultColumns.productName': '产品名称',
    'table.record.event.realtime.defaultColumns.eventName': '告警名称',
    'table.record.event.realtime.defaultColumns.eventType': '紧急程度',
    'table.record.event.realtime.defaultColumns.deviceSn': '设备编号',
    'table.record.event.realtime.defaultColumns.deviceName': '设备名称',
    'table.record.event.realtime.defaultColumns.deviceAddress': '设备地址',
    'table.record.event.realtime.defaultColumns.time': '发生时间',
    'table.record.event.realtime.defaultColumns.handleState': '处理状态',

    'table.record.log.defaultColumns.username': '用户名',
    'table.record.log.defaultColumns.type': '操作类型',
    'table.record.log.defaultColumns.remoteAddr': '用户IP',
    'table.record.log.defaultColumns.requestUri': '请求Uri',
    'table.record.log.defaultColumns.method': '请求方式',
    'table.record.log.defaultColumns.content': '操作内容',
    'table.record.log.defaultColumns.time': '操作时间',

    'table.record.notice.defaultColumns.readState': '已读状态',
    'table.record.notice.defaultColumns.readState.null': '全部',
    'table.record.notice.defaultColumns.readState.read': '已读',
    'table.record.notice.defaultColumns.readState.unread': '未读',
    'table.record.notice.defaultColumns.type': '通知类型',
    'table.record.notice.defaultColumns.type.alarm': '告警通知',
    'table.record.notice.defaultColumns.type.install': '报装通知',
    'table.record.notice.defaultColumns.type.sysMsg': '系统通知',
    'table.record.notice.defaultColumns.type.inspect': '巡检通知',
    'table.record.notice.defaultColumns.type.order': '工单通知',
    'table.record.notice.defaultColumns.type.recoverAlarm': '告警恢复通知',
    'table.record.notice.defaultColumns.type.unInstall': '拆除通知',
    'table.record.notice.defaultColumns.type.null.search': '全部',
    'table.record.notice.defaultColumns.type.install.search': '设备报装',
    'table.record.notice.defaultColumns.type.alarm.search': '告警通知',
    'table.record.notice.defaultColumns.type.sysMsg.search': '系统通知',
    'table.record.notice.defaultColumns.type.inspect.search': '巡检通知',
    'table.record.notice.defaultColumns.type.order.search': '工单通知',
    'table.record.notice.defaultColumns.type.recoverAlarm.search': '告警恢复通知',
    'table.record.notice.defaultColumns.type.unInstall.search': '拆除通知',
    'table.record.notice.defaultColumns.time': '通知时间',
    'table.record.notice.defaultColumns.data': '通知内容',
    //--------------------------大屏---------------------
    'table.screen.defaultColumns.productIcon': '标识',
    'table.screen.defaultColumns.productName': '产品名称',
    'table.screen.defaultColumns.sn': '设备编号',
    'table.screen.defaultColumns.name': '设备名称',
    'table.screen.defaultColumns.dataState': '停用状态',
    'table.screen.defaultColumns.dataState.enable': '启用',
    'table.screen.defaultColumns.dataState.disable': '停用',
    'table.screen.defaultColumns.status': '设备状态',
    'table.screen.defaultColumns.status.normal': '正常',
    'table.screen.defaultColumns.status.alarm': '告警',
    'table.screen.defaultColumns.status.lose': '失联',
    'table.screen.defaultColumns.status.offline': '离线',
    'table.screen.defaultColumns.status.notActive': '未激活',
    'table.screen.defaultColumns.status.other': '未知',
    'table.screen.defaultColumns.armStatus': '布防状态',
    'table.screen.defaultColumns.armStatus.true': '已布防',
    'table.screen.defaultColumns.armStatus.false': '已撤防',
    'table.screen.defaultColumns.areaName': '区域',
    'table.screen.defaultColumns.unitName': '项目',
    'table.screen.defaultColumns.data': '信息',
    //---------------------系统--------------------
    'table.system.access.list.defaultColumns.productKey': '产品标识',
    'table.system.access.list.defaultColumns.productIcon': '字体图标',
    'table.system.access.list.defaultColumns.productIconImage': '产品图标',
    'table.system.access.list.defaultColumns.productName': '产品名称',
    'table.system.access.list.defaultColumns.productModel': '产品型号',
    'table.system.access.list.defaultColumns.manufactureName': '厂家名称',
    'table.system.access.list.defaultColumns.lastVersionName': '最新版本',
    'table.system.access.list.defaultColumns.authorized': '启用状态',
    'table.system.access.list.defaultColumns.authorized.true': '启用',
    'table.system.access.list.defaultColumns.authorized.false': '禁用',

    'table.system.access.detail.propColumns.icon': '字体图标',
    'table.system.access.detail.propColumns.icon.switch': '选择字体图标',
    'table.system.access.detail.propColumns.name': '属性名称',
    'table.system.access.detail.propColumns.show': '是否显示',
    'table.system.access.detail.propColumns.dataType': '数据类型',
    'table.system.access.detail.propColumns.valueRange': '取值范围',
    'table.system.access.detail.propColumns.remark': '备注',

    'table.system.access.detail.actionColumns.name': '操作名称',
    'table.system.access.detail.actionColumns.actionType': '操作类型',
    'table.system.access.detail.actionColumns.actionType.modalName': '设置操作类型',
    'table.system.access.detail.actionColumns.remark': '备注',

    'table.system.access.detail.eventColumns.name': '事件名称',
    'table.system.access.detail.eventColumns.type': '事件类型',
    'table.system.access.detail.eventColumns.type.modalName': '设置事件类型',
    'table.system.access.detail.eventColumns.remark': '备注',

    'table.system.area.defaultColumns.name': '区域名称',

    'table.system.menu.defaultColumns.name': '菜单名称',
    'table.system.menu.defaultColumns.remark': '备注',
    'table.system.menu.defaultColumns.icon': '图标',
    'table.system.menu.defaultColumns.url': '路由',

    'table.system.permission.defaultColumns.resourceName': '资源名称',
    'table.system.permission.defaultColumns.permissionDtoList': '资源权限',

    'table.system.role.roleColumns.name': '角色名称',

    'table.system.role.editPermColumns.resourceName': '资源名称',
    'table.system.role.editPermColumns.permissionDtoList': '资源权限',

    'table.system.role.addPermColumns.resourceName': '资源名称',
    'table.system.role.addPermColumns.permissionDtoList': '资源权限',

    'table.system.unit.defaultColumns.name': '项目名称',
    'table.system.unit.defaultColumns.linkman': '负责人',
    'table.system.unit.defaultColumns.tel': '手机号码',
    'table.system.unit.defaultColumns.maxUserLimit': '最大用户数',
    'table.system.unit.defaultColumns.siteName': '站点名称',
    'table.system.unit.defaultColumns.label': '站点副标题',
    'table.system.unit.defaultColumns.remark': '备注',

    'table.system.url.defaultColumns.description': 'URL描述',
    'table.system.url.defaultColumns.url': 'URL地址',

    'table.system.user.defaultColumns.avatar': '头像',
    'table.system.user.defaultColumns.realName': '昵称',
    'table.system.user.defaultColumns.username': '用户名',
    'table.system.user.defaultColumns.email': '邮箱',
    'table.system.user.defaultColumns.roleName': '角色',
    'table.system.user.defaultColumns.tel': '手机号码',
    'table.system.user.defaultColumns.areaName': '所属区域',
    'table.system.user.defaultColumns.unitName': '所属项目',
    'table.system.user.defaultColumns.dataState': '激活状态',
    'table.system.user.defaultColumns.dataState.null': '全部',
    'table.system.user.defaultColumns.dataState.enable': '启用',
    'table.system.user.defaultColumns.dataState.disable': '禁用',


};
export default zh_CN;
