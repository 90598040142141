import { Icon, Modal } from "antd";
import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./common-modal.module.less";
import { CommonButton } from "components/Button/common-button";
import { CommonBox } from "components/box/common-box";
import { ModalProps } from "antd/lib/modal";
interface IProps extends ModalProps {
  noStyle?: boolean;
  leftButtons?: ReactNode;
  rightButtons?: ReactNode;
  visibleCenter?: boolean;
}

class CommonModal extends React.PureComponent<IProps> {
  static defaultProps: IProps = {
    mask: true,
    maskClosable: true,
    closable: true,
    footer: undefined,
    title: null,
    width: `auto`,
    visibleCenter: true,
  };

  componentDidMount() {}

  render() {
    const {
      noStyle,
      leftButtons,
      rightButtons,
      className,
      wrapClassName,
      children,
      footer,
      title,
      okText,
      cancelText,
      onOk,
      onCancel,
      visibleCenter,
      closable,
      okButtonProps,
      ...props
    } = this.props;
    let okButton = (
      <CommonButton className={styles.button} onClick={onOk} {...okButtonProps}>
        {okText ? okText : `确定`}
      </CommonButton>
    );
    let cancelButton = (
      <CommonButton
        className={styles.button}
        type={`default`}
        onClick={onCancel}
      >
        {cancelText ? cancelText : `取消`}
      </CommonButton>
    );
    let bottom = (
      <div className={styles.bottons}>
        {cancelButton}
        {okButton}
      </div>
    );
    return (
      <Modal
        centered
        wrapClassName={classNames(wrapClassName, styles.modalWrap, {
          [styles.modalWrapPointerEvents]: !props.mask,
        })}
        className={classNames(className, styles.modalMain)}
        footer={null}
        onCancel={onCancel}
        closable={false}
        {...props}
      >
        {noStyle ? (
          children
        ) : (
          <div className={styles.modalBody}>
            {leftButtons ? (
              <div className={styles.leftMain}>{leftButtons}</div>
            ) : (
              ``
            )}
            {visibleCenter ? (
              <div className={styles.mainBody}>
                <div className={styles.top}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.iconDiv}>
                    {closable ? (
                      <Icon
                        type="close"
                        className={styles.icon}
                        onClick={onCancel}
                      />
                    ) : (
                      ``
                    )}
                  </div>
                </div>
                <div className={styles.body}>{children}</div>
                {footer === null ? (
                  ``
                ) : (
                  <div className={styles.bottom}>
                    {footer ? footer : bottom}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {rightButtons ? (
              <div className={styles.rightMain}>{rightButtons}</div>
            ) : (
              ``
            )}
          </div>
        )}
      </Modal>
    );
  }
}

export { CommonModal };
