import React, {PureComponent} from "react"
import {TreeSelect} from "antd"
import SelectUnitTableModal from "@/components/Modal/SelectUnitTableModal"
import PropTypes from "prop-types";


/**
 * 单位异步选择组件
 * 默认值的显示问题请传treeData  ，格式  [{id:'aaa',name:'AAA'}]
 */
class UnitSyncTreeSelect extends PureComponent {
    state = {
        treeData: [],
        visible: false
    }

    componentDidMount() {
        // this.searchData()
    }

    // searchData = (name = '') => {
    //     return this.getData({name}).then(list => this.setState({treeData: list}))
    // }
    //
    // getData = (data) => {
    //     return this.$http.tokiot.sys.unit.asyncList(data).then(ret => ret.rows.map(this.fmtData))
    // }
    //
    // sync = (parentId) => {
    //     return this.getData({parentId, size: 50})
    //         .then(list => {
    //             this.setState({treeData: this.state.treeData.concat(list)})
    //         })
    // }


    // /**
    //  * 格式化数据
    //  */
    // fmtData = (record) => {
    //     record.isLeaf = this.isLeaf(record);
    //     record.key = record.id
    //     record.title = record.name
    //     delete  record.label
    //     record.value = record.id
    //     record.pId = record.parentId
    //
    //     return record
    // }

    // /**
    //  * 判断是否是子节点
    //  */
    // isLeaf = (record) => {
    //     return !record.parent
    // }


    static defaultProps = {
        fmtData: (item) => item,
    };
    static propTypes = {
        fmtData: PropTypes.func // 遍历异步加载方法
    }
    loadData = (node) => {
        return new Promise(resolve => {
            this.sync(node.props.id)
            resolve()
        })
    }


    onOpen = () => !this.props.disabled && this.setState({visible: true})
    onClose = () => this.setState({visible: false})
    onOk = (value, record) => {
        this.props.onChange(value, record.name, record)
        this.setState({treeData: [record]})
        this.onClose()
    }


    /**
     *
     * 将外面传的数据和组件内选中的数据合并一起
     */
    fmtTreeData = () => {
        const map = {}
        this.state.treeData.forEach(item => {
            map[item.id] = item
        })
        this.props.treeData && this.props.treeData.forEach(item => {
            map[item.id] = item
        })
        const treeData = []
        Object.values(map).forEach(value => {
            treeData.push(value)
        })
        return treeData;
    }

    render() {

        const {visible} = this.state
        const {value, treeData, fmtData, ...otherProps} = this.props // treeData不可删

        return <React.Fragment>
            <TreeSelect   {...otherProps}
                          onClick={this.onOpen}
                          value={value}
                          dropdownStyle={{display: 'none'}}>
                {this.fmtTreeData().map(item => <TreeSelect.TreeNode value={item.id} title={item.name} key={item.id}/>)}
            </TreeSelect>
            <SelectUnitTableModal visible={visible} onCancel={this.onClose} onSubmit={this.onOk}
                                  fmtData={fmtData}/></React.Fragment>
    }

}

export default UnitSyncTreeSelect;