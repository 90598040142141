import { Input, Tree } from "antd";
import { BaseModal } from "components/base-interface";
import React, { PureComponent } from "react";
import styles from "./select-area-modal.module.less";
import {
  AntTreeNode,
  AntTreeNodeSelectedEvent,
  TreeNodeNormal,
} from "antd/lib/tree/Tree";
import { Area, AreaInterface } from "httpRequest/tokiot/sys/area";
import debounce from "lodash.debounce";
import { CommonEmpty } from "components/Empty/common-empty";
import { CommonModal } from "components/Modal/common-modal";
type TreeDataNode = TreeNodeNormal & {
  info?: TreeDataNode;
  area: AreaInterface.AreaInfo;
};

const { Search } = Input;
const { TreeNode } = Tree;
interface IProps extends BaseModal {
  onOk: (areaInfo: AreaInterface.AreaInfo) => void;
  parentId?: string;
}
interface IState {
  unitTreeNode: Array<TreeDataNode>;
  expandedKeys: Array<string>;
}

class SelectAreaModal extends PureComponent<IProps, IState> {
  state: IState = {
    unitTreeNode: [],
    expandedKeys: [],
  };
  componentDidMount() {
    this.getUnitTerr = debounce<(name?: string) => void>(
      this.getUnitTerr,
      300,
      {
        trailing: true,
      }
    );
    this.getUnitTerr();
  }

  getNode = (area: AreaInterface.AreaInfo, isSearch?: boolean) => {
    let ret: TreeDataNode = {
      key: area.id,
      title: area.name,
      children: [],
      isLeaf: !area.parent || isSearch,
      area,
    };
    ret.info = ret;
    return ret;
  };
  getUnitTerr = (name?: string) => {
    const { parentId } = this.props;
    Area.AsyncList({ name, id: parentId ?? `1`, size: 999 }).then((ret) => {
      if (ret && ret.rows) {
        let unitTreeNode = ret.rows.map((f1) =>
          this.getNode(f1, (name?.length ?? 0) > 0)
        );
        this.setState({
          unitTreeNode,
          expandedKeys: unitTreeNode.map((f1) => f1.key),
        });
      } else {
        this.setState({ unitTreeNode: [] });
      }
    });
  };
  onSearch = (name: string) => {
    this.getUnitTerr(name);
  };
  onSelect = (treeNode: AntTreeNode) => {
    const { onOk, onClose } = this.props;
    if (`area` in treeNode.props) {
      onOk((treeNode.props as any).area);
      onClose && onClose();
    }
  };

  loadData = async (treeNode: AntTreeNode) => {
    const { unitTreeNode } = this.state;
    console.info(treeNode.props, treeNode.props.eventKey);

    const ret = await Area.AsyncList({
      parentId: treeNode.props.eventKey?.toString(),
      size: 999,
    });
    if (ret) {
      if (`info` in treeNode.props) {
        let node = (treeNode.props as any).info as TreeNodeNormal;
        node.children = ret.rows.map((f1) => this.getNode(f1));
      }
      this.setState({ unitTreeNode: [...unitTreeNode] });
    } else {
    }
  };

  renderTreeNodes = (data: Array<TreeNodeNormal>) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode {...item}>{this.renderTreeNodes(item.children)}</TreeNode>
        );
      } else {
        return <TreeNode {...item} children={undefined} />;
      }
    });
  render() {
    const { visible, onClose } = this.props;
    const { unitTreeNode, expandedKeys } = this.state;
    return (
      <CommonModal
        maskClosable
        title={"请选择区域"}
        visible={visible}
        width={300}
        onCancel={(e) => {
          onClose && onClose();
        }}
        footer={null}
      >
        <div className={styles.main}>
          <Input
            className={styles.search}
            placeholder="请输入区域名称"
            onChange={(e) => {
              this.onSearch(e.target.value);
            }}
          />
          {unitTreeNode.length > 0 ? (
            <Tree
              expandedKeys={expandedKeys}
              onExpand={(expandedKeys) => {
                this.setState({
                  expandedKeys,
                });
              }}
              className={styles.tree}
              loadData={this.loadData}
              treeData={unitTreeNode}
              onSelect={(e, info) => {
                this.onSelect(info.node);
              }}
            />
          ) : (
            <CommonEmpty />
          )}
        </div>
      </CommonModal>
    );
  }
}

export { SelectAreaModal };
