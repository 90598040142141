import React from 'react';
import dva from 'dva';
import dynamic from 'dva/dynamic';
import createLoading from 'dva-loading';
import {Router} from 'dva/router';
import createHistory from 'history/createHashHistory';
import request from 'cmn-utils/lib/request';
import createRoutes from '@/routes';
import 'assets/styles/index.less';
import 'assets/styles/button.less';
import config from './config';
import {LocaleProvider,message} from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';
import zh_TW from 'antd/es/locale/zh_TW';
import en_US from 'antd/es/locale/en_US';
import 'moment/locale/zh-cn';


import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import {addLocaleData} from 'react-intl';
import i_zh_CN from '@/locales/zh_CN';
import i_zh_CHT from '@/locales/zh_CHT';
import intl from 'react-intl-universal';
import {getLanguage} from "@/utils/tool";
import http from "@/http"
import * as DICT from "@/utils/dict"
React.Component.prototype.$http=http
React.Component.prototype.$DICT=DICT
React.Component.prototype.$message=message

addLocaleData([...en, ...zh]);  // 引入多语言环境的数据
const currentLocale = getLanguage();
intl.init({
        currentLocale: currentLocale,
        locales: {
            'zh': i_zh_CN,
            'zh-TW': i_zh_CHT,
            'en': i_zh_CN
            // 'en': i_en_US
        }
    }
);
// -> 初始化
const app = dva({
    history: createHistory()
});

// -> 插件
app.use(createLoading());
app.use({onError: config.exception.global});

// -> 请求
request.config(config.request);

// 使用mock数据
// require('./__mocks__');
// -> Developer mock data
// if (process.env.NODE_ENV === 'development') {
//   require('./__mocks__');
// }

// -> loading
dynamic.setDefaultLoadingComponent(() => config.router.loading);

// -> 注册全局模型
app.model(require('./models/global').default);
app.model(require('./models/map').default);
app.model(require('./models/dict').default);

// -> 初始化路由
app.router(({history, app}) => (
    <LocaleProvider locale={currentLocale === "zh-TW" ? zh_TW : (currentLocale === "en" ? en_US : zh_CN)}>
        <Router history={history}>{createRoutes(app)}</Router>
    </LocaleProvider>
));

// -> Start
app.start('#root');

// export global
export default {
    app,
    store: app._store,
    dispatch: app._store.dispatch
};

// 如果想可以离线使用，请使用register()代替unregister()。可能会带来一些问题，如缓存等
// 相关资料，可以从 https://bit.ly/CRA-PWA 了解
// serviceWorker.unregister();
