import device from "./device"
import camera from "./camera"
import video from "./video"

// https://open.ys7.com/doc/zh/
const baseUrl = "https://open.ys7.com/api/lapp/"

export function get(url, data) {
    return send(baseUrl + url,
        {
            method: 'GET', body: JSON.stringify(data),
            // headers: {
            //     'Content-Type':'application/x-www-form-urlencoded',
            //     'accessToken':accessToken,
            // }
        })
}

export function post(url, data) {
    let dataString = ""
    Object.keys(data).map(key => {
        dataString += dataString ? `&${key}=${data[key]}` : `${key}=${data[key]}`
    })

    // return send("http://se.api.tokiot.cn/api/sys/user/get-detail",{
    //     method:'GET',
    //     headers:{
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         'Authorization': 'bearer d8e3a91c-17fc-4dc9-a834-0578d3f16813',
    //     }
    // })
    return send(baseUrl + url,
        {
            method: 'POST', body: dataString,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
}

function send(url, config) {

    return new Promise(resolve =>
        fetch(url, config).then(response => {
            return response.json()
        }).then(({code, msg, page, data}) => {

            if ('200' == code) return resolve(data, page)
            console.error('萤石云返回错误', msg, code)


        }).catch(err => {
            console.info("萤石云失败", err);
        }))

}

export default {device, camera, video}