import {get, post} from "@/http/ys"
import ptz from "./ptz"
import preset from "./preset"
import status from "./status"

const baseUrl = "device/";

export default {

    list: (params) => {
        return post(baseUrl + 'list', params)
    },
    info: (params) => {
        return post(baseUrl + 'info', params)
    },
    capacity: (params) => {
        return post(baseUrl + 'capacity', params)
    },
    ptz,preset,status

}