import React, {Component} from 'react'
import Icon from "../Icon";
import './style/index.less';
import InfoModal from './InfoModal'
import ModifyModal from './ModifyModal'
import {Link} from "react-router-dom";
import {connect} from "dva";

@connect(({global}) => ({
    global,
}))
export default class UserDropDown extends Component {

    state = {
        modifyVisible: false,
        infoVisible: false
    }

    /**
     * 切换弹框显示隐藏
     */
    handleSwitchModal(visibleName) {
        this.setState({
            [visibleName]: !this.state[visibleName]
        })
    }

    /**
     * 处理密码修改
     */
    handleModifyPwd(values) {
        this.props.onModifyPwd(values, () => this.handleSwitchModal("modifyVisible"))
    }

    /**
     * 更新用户信息
     */
    handleUpdateUser(data) {
        new Promise(resolve => {
            this.props.dispatch({
                type: 'global/updateUser',
                payload: {data, resolve}
            })
        }).then(() => {
            this.props.dispatch({type: 'global/getUserInfo'});
            this.handleSwitchModal("infoVisible")
        })
    }

    /**
     * 校验手机号是否存在
     */
    handleExistMobile(mobile, callback) {
        new Promise(resolve => {
            this.props.dispatch({
                type: "global/existMobile",
                payload: {data: {mobile}, resolve}
            });
        }).then(ret => {
            callback(ret);
        });
    }
    /**
     * 校验邮箱是否存在
     */
    handleExistEmail(email, callback) {
        new Promise(resolve => {
            this.props.dispatch({
                type: "global/existEmail",
                payload: {data: {email}, resolve}
            });
        }).then(ret => {
            callback(ret);
        });
    }
    render() {
        const {modifyVisible, infoVisible} = this.state
        const {userInfo} = this.props
        const infoModalProps = {
            visible: infoVisible,
            onExistMobile:(mobile, callback) => this.handleExistMobile(mobile, callback),
            onExistEmail:(email, callback) => this.handleExistEmail(email, callback),
            userInfo,
            title: "个人信息",
            onCancel: () => this.handleSwitchModal("infoVisible"),
            onOk: (data) => this.handleUpdateUser(data)
        }
        const modifyModalProps = {
            visible: modifyVisible,
            title: "修改密码",
            onCancel: () => this.handleSwitchModal("modifyVisible"),
            onOk: (values) => this.handleModifyPwd(values)
        }
        return (
            <ul className="dropdown-menu list-group dropdown-persist">
                <InfoModal {...infoModalProps} />
                <ModifyModal {...modifyModalProps} />
                <li onClick={() => this.handleSwitchModal("infoVisible")} className="list-group-item">
                    <a className="animated animated-short fadeInUp">
                        <Icon type="user"/> 个人信息
                    </a>
                </li>
                <li onClick={() => this.handleSwitchModal("modifyVisible")} className="list-group-item">
                    <a className="animated animated-short fadeInUp">
                        <Icon type="edit"/> 修改密码
                    </a>
                </li>
                <li className="list-group-item dropdown-footer">
                    <Link to="/account/login">
                        <Icon type="poweroff"/> 退出
                    </Link>
                </li>
            </ul>
        )
    }
}