import React from "react";
import FlvPlayer from "./FlvPlayer";
import { VideoSwitch } from "./video-div";

/**
 * 播放器的解析器
 * 根据不同的产品型号调用不同的播放器
 * 默认使用萤石云播放器
 */
export default ({ playerType = "Yingshi", ...otherProps }) => {
  switch (playerType) {
    case "Flv":
      return <FlvPlayer {...otherProps} />;
    case "Yingshi":
      return <VideoSwitch {...otherProps} type={`security`} />;
  }
  return <VideoSwitch {...otherProps} type={`security`} />;
};
