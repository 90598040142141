import React, { FC, ReactNode } from "react";
import { BaseModal } from "components/base-interface";
import { CommonModal } from "components/Modal/common-modal";
import { PayIotCardInterface } from "httpRequest/tokiot/pay/iot-card";
import styles from "./index.module.less";
import { DictGetValue } from "utils/dict";
import moment from "moment";

interface IProps extends BaseModal {
  selectInfo: PayIotCardInterface.IotCardInfo;
}

interface IState {}
class InfoModal extends React.PureComponent<IProps, IState> {
  state: IState = {};
  componentDidMount() {}
  RowItem: FC<{ name: string; value: ReactNode }> = (props) => {
    const { name, value } = props;
    return (
      <div className={styles.row}>
        <div className={styles.name}>{name}：</div>
        <div className={styles.value}>{value}</div>
      </div>
    );
  };
  render() {
    const { visible, onClose, selectInfo } = this.props;
    const { RowItem } = this;
    let year = ``;
    let sTime = moment(selectInfo.cardStartTime);
    let eTime = moment(selectInfo.cardExpireTime);
    if (
      selectInfo.cardStartTime &&
      selectInfo.cardExpireTime &&
      moment.isMoment(sTime) &&
      moment.isMoment(eTime)
    ) {
      year = eTime.diff(sTime, `y`) + `年`;
    } else {
      year = ``;
    }
    return (
      <CommonModal
        title={`查看信息`}
        onCancel={() => {
          onClose && onClose();
        }}
        footer={null}
        width={500}
        visible={visible}
      >
        <div className={styles.main}>
          <RowItem name={`购卡时间`} value={selectInfo.buyCardTime} />
          <RowItem
            name={`卡类`}
            value={DictGetValue(`PAY_IOTCARD_CARD_TYPE`, selectInfo.cardType)}
          />
          <RowItem
            name={`卡供应商`}
            value={DictGetValue(`PAY_IOTCARD_CARD_SRC`, selectInfo.cardSrc)}
          />
          <RowItem name={`卡号`} value={selectInfo.iccid} />
          <RowItem name={`年限`} value={year} />
          <RowItem name={`设备种类`} value={selectInfo.productName} />
          <RowItem name={`项目`} value={selectInfo.unitName} />
          <RowItem name={`设备编码`} value={selectInfo.deviceSn} />
          <RowItem name={`开卡时间`} value={selectInfo.cardStartTime} />
          <RowItem name={`报装时间`} value={selectInfo.deviceInstallTime} />
          <RowItem name={`卡到期时间`} value={selectInfo.cardExpireTime} />
          <RowItem name={`出库人`} value={selectInfo.checkoutUser} />
          <RowItem
            name={`同步状态`}
            value={DictGetValue(`PAY_IOTCARD_SYNC_STATE`, selectInfo.syncState)}
          />
          <RowItem
            name={`续费状态`}
            value={DictGetValue(`PAY_IOTCARD_FEE_STATE`, selectInfo.feeState)}
          />
          <RowItem name={`续费时间`} value={selectInfo.feePayTime} />
          <RowItem name={`备注`} value={selectInfo.remark} />
        </div>
      </CommonModal>
    );
  }
}
export { InfoModal };
