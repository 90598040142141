import $$ from 'cmn-utils'
import {downExcel} from "@/utils/constant";

const baseUrl = "/api/sys/user/";
export default {
    changePassword: (params) => {
        return $$.post(baseUrl + 'change-password', params)
    },
    codeMobile: (params) => {
        return $$.post(baseUrl + 'code-mobile', params)
    },
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    existEmail: (params) => {
        return $$.get(baseUrl + 'exist-email', params)
    },
    existMobile: (params) => {
        return $$.get(baseUrl + 'exist-mobile', params)
    },
    existUsername: (params) => {
        return $$.get(baseUrl + 'exist-username', params)
    },
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    getDetail: (params) => {
        return $$.get(baseUrl + 'get-detail', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    listByUnit: (params) => {
        return $$.get(baseUrl + 'list-by-unit', params)
    },
    refreshRegistrationId: (params) => {
        return $$.post(baseUrl + 'refresh-registrationId', params)
    },
    resetPasswordMobile: (params) => {
        return $$.post(baseUrl + 'reset-password-mobile', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },
    updateHome: (params) => {
        return $$.post(baseUrl + 'update-home', params)
    },
    updateTheme: (params) => {
        return $$.post(baseUrl + 'update-theme', params)
    },
    importExcel: (data, formData) => {
        let urlParms = ""
        let paramsArray = []
        //拼接参数
        Object.keys(data).forEach(key => paramsArray.push(key + '=' + data[key]))
        if (urlParms.search(/\?/) === -1) {
            urlParms += '?' + paramsArray.join('&')
        } else {
            urlParms += '&' + paramsArray.join('&')
        }
        return $$.post(baseUrl + 'import-excel' + urlParms, formData, {
            headers: {
                "content-type": "multipart/form-data;boundary=" + new Date().getTime()
                , ...$$.getStore("headers")
            }
        }).then(ret => {
            window.open(downExcel + ret, '_blank');
            return ret;
        })
    },

}