import React, { ReactNode } from "react"
import { Popover } from "antd";
import styles from './popover-table.module.less'
import classNames from "classnames";
import { SimpleModal } from "components/Modal/simple-modal";
import { PopoverStyle } from "components/common-styles/common-styles";
import { PopoverProps } from "antd/lib/popover";
import Icon from "components/Icon";

export interface PopoverButtonItem {
    name: string;
    icon?: string | ReactNode;
    onClick?: () => void;
    tip?: { title: string, message: string }
}

interface IProps {
    buttonList: Array<PopoverButtonItem | ReactNode>;
    defaultIconClass?: string;
}
interface IState {
    popoverVisible: boolean;
}
class PopoverTable extends React.PureComponent<IProps & PopoverProps, IState> {
    state = {
        popoverVisible: false,
    }

    isPopoverButtonItem = (data: PopoverButtonItem | ReactNode): data is PopoverButtonItem => {
        let ret = data as PopoverButtonItem;
        return (ret.name !== undefined && ret.name.length > 0);
    }

    getOperatingContent = () => {
        const { buttonList } = this.props;
        let getRow = (item: PopoverButtonItem, onClick?: () => void) => {
            return (
                <div className={styles.row} onClick={e => {
                    this.popoverVisibleChange(false);
                    onClick && onClick();
                }}>
                    <div className={styles.rowDiv} >
                        {item.icon ?
                            (
                                typeof item.icon === `string` ?
                                    <Icon antd type={item.icon} className={styles.icon} />
                                    :
                                    item.icon
                            )
                            : ``}
                        <div className={styles.text}>{item.name}</div>
                    </div>
                </div >
            )
        }
        return (
            <div className={styles.operatingBox} >
                <div className={styles.bg}>
                    {buttonList.map(f1 => {
                        if (this.isPopoverButtonItem(f1)) {
                            if (f1.tip) {
                                return (
                                    <SimpleModal
                                        title={f1.tip.title}
                                        message={f1.tip.message}
                                        onOk={() => { f1.onClick && f1.onClick() }}>
                                        {getRow(f1)}
                                    </SimpleModal>
                                )
                            } else {
                                return getRow(f1, f1.onClick);
                            }
                        } else {
                            return (
                                <div className={styles.row}>
                                    {f1}
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }

    popoverVisibleChange = (visible: boolean) => {
        this.setState({ popoverVisible: visible });
    }
    render() {
        const { defaultIconClass, overlayClassName, children, ...parameter } = this.props;
        const { popoverVisible } = this.state;
        return (
            <Popover
                {...parameter}
                overlayClassName={classNames(PopoverStyle, styles.popover)}
                content={this.getOperatingContent()}
                visible={popoverVisible}
                onVisibleChange={this.popoverVisibleChange}
            >
                {children ? children :
                    <Icon antd type={`ellipsis`} className={classNames(defaultIconClass, styles.defaultIcon)} />
                }
            </Popover>
        )
    }
}

export { PopoverTable }
