import $$ from 'cmn-utils';
import modelEnhance from '@/utils/modelEnhance';
import amap from "@/http/amap"
import tokiot from "@/http/tokiot"

function setPosition(ret) {
    const position = ret.rectangle.split(";")[0].split(",");
    $$.setStore('position', position);
}

export default modelEnhance({
    namespace: 'map',
    state: {},
    subscriptions: {
        setup({dispatch, history}) {
            history.listen(({pathname}) => {
                dispatch({type: "getPosition", payload: {resolve: setPosition}});
            })
        }
    },

    effects: {
        * getAreaTree({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(tokiot.sys.area.treeByUnit, data || {});
            if (ret) {
                const areaTree = [JSON.parse(
                    JSON.stringify(ret)
                        .replace(/name/g, "title")
                        .replace(/code/g, "value")
                        .replace(/childrenList/g, "children")
                )];
                resolve && resolve(areaTree);
            }
        },
        * getDistrict({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(amap.getDistrict, data || {});
            if (ret) {
                resolve && resolve(ret);
            }
        },
        * search({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(amap.search, data || {});
            if (ret) {
                resolve && resolve(ret);
            }
        },
        * getAround({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(amap.getAround, data || {});
            if (ret) {
                resolve && resolve(ret);
            }
        },
        * getPosition({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(amap.ip, data || {});
            if (ret) {
                resolve && resolve(ret);
            }
        },
        * inputtips({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(amap.inputtips, data || {});
            if (ret) {
                resolve && resolve(ret);
            }
        },
        * geo({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(amap.geo, data || {});
            if (ret) {
                resolve && resolve(ret);
            }
        },
        * regeo({payload = {}}, {call, put}) {
            const {resolve, data} = payload
            const ret = yield call(amap.regeo, data || {});
            if (ret) {
                resolve && resolve(ret);
            }
        },

    },

    reducers: {
        commonSuccess(state, {payload}) {
            return {...state, ...payload};
        }
    },
});
