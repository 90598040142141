import React from 'react';

class LevelItem extends React.Component {

    static defaultProps = {
        color: 'gray',
        text: "",
        width: 80,
        height: 5
    };

    render() {
        const {color, text, width, height} = this.props;
        return (
            <div>
                <div style={{width: width, height: height, backgroundColor: color, marginLeft: 5, marginTop: 8}}></div>
                <div style={{textAlign: 'center', height: 3, marginTop: -10}}> {text}</div>
            </div>);

    }
}


export default LevelItem;