import classNames from 'classnames';
import React from 'react';
import styles from './common-card.module.less';
interface IProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}
class CommonCard extends React.PureComponent<IProps> {
    render() {
        const { className, style, onClick, children } = this.props
        return (
            <div className={classNames(className, styles.cardMain)} style={style}>
                <div className={classNames(styles.body, { [styles.click]: !!onClick })} onClick={e => onClick && onClick()}>
                    {children}
                </div>
            </div>
        );
    }
}

export { CommonCard };