import React, {Component} from "react";
import http from "@/http"
import DeviceInfoTab from "@/routes/device/components/tab/InfoTab"

class DeviceInfo extends Component {
    state = {
        detail: {}
    }

    componentDidMount() {
        const {deviceId} = this.props
        this.getDeviceDetail(deviceId)
    }

    getDeviceDetail = (id) => {
        id && http.tokiot.device.get({id})
            .then(ret => {
                ret && this.setState({detail: ret})
            })
    }

    render() {
        const {children} = this.props
        const {detail} = this.state

        return <div className='realtime-alarm-modal-body-device '>
            {children}
            {DeviceInfoTab(detail)}
        </div>;
    }
}

export default DeviceInfo;