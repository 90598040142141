import $$ from 'cmn-utils'

const baseUrl = "/api/inspect-task/";
export default {
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    personal: {
        list: (params) => {
            return $$.get(baseUrl + 'personal/list', params)
        },
    }

}