import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/document/xcal",
    title: '现场案例',
    component: dynamicWrapper(app, [import("../model/index")], () =>
        import("./components/index")
    )
});

export default app => createRoute(app, routesConfig);
