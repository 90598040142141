import $$ from 'cmn-utils'

const baseUrl = "/api/sys/push-log/";
export default {
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    totalUnRead: (params) => {
        return $$.get(baseUrl + 'total-un-read', params)
    },
    read: (params) => {
        return $$.post(baseUrl + 'read', params)
    },
    readAll: (params) => {
        return $$.post(baseUrl + 'read-all', params)
    },

}