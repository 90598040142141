import $$ from 'cmn-utils'

const baseUrl = "/api/sys/product/event/";
export default {
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },
}