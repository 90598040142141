import {get, post} from "@/http/ys"

const baseUrl = "camera/";

export default {

    list: (params, accessToken) => {
        return get(baseUrl + 'list', params, accessToken)
    },

}