import React, {PureComponent} from 'react';
import Icon from '../Icon';
import {Avatar, Badge, Popover} from 'antd';
import {Link} from 'dva/router';
import cx from 'classnames';
import './style/index.less';
import logoImg from 'assets/images/newDriverLogo1.png';
import shidu from 'assets/images/weather/shidu.png';
import {downloadImg} from "../../utils/constant";
import NoticeTab from "./NoticeTab";
import UserDropDown from "./UserDropDown";
import CONST from '../../dataSource/CONST';
import Img from 'components/Img';
import $$ from 'cmn-utils';
import mute from "assets/images/mute.png";
import sound from "assets/images/sound.png";


const {WeatherImgSourceByCN} = CONST

/**
 * 其本本局头部区域
 */
class NavBar extends PureComponent {
    static defaultProps = {
        fixed: true,
        theme: '' //'bg-dark',
    };
    state = {
        openSearchBox: false,
        isSound: $$.getStore('isSound')
    };
    onCloseSearchBox = () => {
        this.setState({
            openSearchBox: false
        });
    };
    onOpenSearchBox = () => {
        this.setState({
            openSearchBox: true
        });
    };

    toggleFullScreen() {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    /**
     * 关闭声音提示
     */
    handleStopSound() {
        const {isSound} = this.state
        if (isSound) {
            // window.speechSynthesis.pause()
            const audioEle = document.getElementById('audio')
            audioEle.pause()
        }
        this.setState({isSound: !isSound}, () => {
            $$.setStore('isSound', this.state.isSound)
        })
    }

    render() {
        const {openSearchBox, isSound} = this.state;
        const {
            fixed, theme, onCollapseLeftSide, collapsed,
            onExpandTopBar, toggleSidebarHeader, user, isMobile,
            onSetReadBatch, onSetRead, ws,
            installNotice, alarmNotice, systemNotice, orderNotice, inspectNotice,
            userInfo = {}, unit = {}, unReadCount = {}
        } = this.props
        const {
            countAlarm = 0, countInstall = 0, countNewInspect = 0, countNewWorkOrder = 0,
            countRecoverAlarm = 0, countSysMsg = 0, countUnInstall = 0,
        } = unReadCount
        const allUnReadCount = countAlarm + countInstall + countSysMsg
        const classnames = cx('navbar', {
            'bg-image': true,
            'navbar-fixed-top': !!fixed,
            'navbar-sm': isMobile ? true : collapsed,
            ['bg-' + theme]: !!theme
        });
        let weather = $$.getStore('weather')
        if (!weather) weather = {}
        return (
            <header className={classnames}>
                <div className="navbar-branding">
                    <Link className="navbar-brand" to="/">
                        <img
                            src={unit && unit.webLogo ? downloadImg + unit.webLogo : ""}
                            alt="logo"
                        />
                        <span style={{lineHeight: "24px", fontWeight: 600}}>
                            {unit && unit.siteName ? unit.siteName : ""}
                        </span>
                    </Link>
                    <span className="toggle_sidemenu_l" onClick={onCollapseLeftSide}>
            <Icon type="lines"/>
          </span>
                </div>
                <ul className="nav navbar-nav navbar-left clearfix">
                    <div className="weather">
                        <div>{weather.city}</div>
                        <div>
                            <img
                                style={{with: 35, height: 30}}
                                src={ WeatherImgSourceByCN[weather.wea_img]}/>

                            <div>{weather.wea}</div>

                        </div>
                        <div>{weather.tem2} / {weather.tem1}℃</div>
                        <div>
                            <img
                                src={shidu} style={{width: 30, height: 30}}
                            />
                            <div>湿度{weather.humidity}</div>
                        </div>
                    </div>
                </ul>
                <ul
                    className="nav navbar-nav navbar-right clearfix">
                    <li
                        onClick={this.toggleFullScreen
                        }>
                        <a
                            className="request-fullscreen">
                            <Icon
                                type="screen-full" style={{fontSize: 20}}/>
                        </a>
                    </li>
                    <li className="dropdown" onClick={() => this.handleStopSound()}>
                        <a className="dropdown-toggle">
                            <img
                                src={isSound ? sound : mute}
                                style={{width: "20px", height: "20px"}}
                                alt=""
                            />
                            {/*{isSound ? <img src={sound} /> : <IconFont type="icon-no-sound" antd /> }*/}
                        </a>
                    </li>
                    <li
                        className="dropdown">
                        <Popover
                            placement="bottomRight"
                            overlayClassName={cx('navbar-popup',
                                {
                                    [theme]:
                                        !!theme
                                }
                            )
                            }
                            content={
                                <NoticeTab
                                    onSetReadBatch={type => onSetReadBatch(type)}
                                    onSetRead={(id, type, state) => onSetRead(id, type, state)}
                                    installNotice={installNotice}
                                    systemNotice={systemNotice}
                                    orderNotice={orderNotice}
                                    inspectNotice={inspectNotice}
                                    alarmNotice={alarmNotice}
                                />}
                            trigger="click"
                        >
                            < a
                                className="dropdown-toggle">
                                <Badge count={allUnReadCount} offset={[3,-3]} title={'您有' + allUnReadCount + '条未读消息'}>
                                    {ws ?
                                        <Icon type="ring" style={{fontSize: 20}}/> :
                                        <Icon.Common type="changgui-jingyin1" style={{fontSize: 20}}/>

                                    }</Badge>
                            </a>
                        </Popover>
                    </li>
                    <li className="dropdown">
                        <Popover
                            placement="bottomRight"
                            title={`欢迎您 ${userInfo.realName}`}
                            overlayClassName={cx('navbar-popup', {[theme]: !!theme})}
                            content={<UserDropDown userInfo={userInfo}/>}
                            trigger="click"
                        >
                            <a className="dropdown-toggle">
                                {/*<Badge dot>*/}
                                <Avatar   src={userInfo.avatar?(downloadImg + userInfo.avatar):''}/>
                                {/*</Badge>*/}
                            </a>
                        </Popover>
                    </li>
                </ul>
                {/*<SearchBox visible={openSearchBox} onClose={this.onCloseSearchBox}/>*/}
            </header>
        )
            ;
    }
}

// const UserDropDown = props => (
//
<ul className="dropdown-menu list-group dropdown-persist">
    // <li className="list-group-item">
    // <a className="animated animated-short fadeInUp">
    // <Icon type="mail"/> 信息
    // <Badge count={5} className="label"/>
    // </a>
    // </li>
    // <li className="list-group-item">
    // <a className="animated animated-short fadeInUp">
    // <Icon type="users"/> 好友
    // <Badge count={6} className="label"/>
    // </a>
    // </li>
    // <li className="list-group-item">
    // <a className="animated animated-short fadeInUp">
    // <Icon type="gear"/> 帐户设置
    // </a>
    // </li>
    // <li className="list-group-item">
    // <a className="animated animated-short fadeInUp">
    // <Icon type="ring"/> 通知
    // </a>
    // </li>
    // <li className="list-group-item dropdown-footer">
    // <Link to="/account/login">
    // <Icon type="poweroff"/> 退出
    // </Link>
    // </li>
    // </ul>
// );

export default NavBar;
