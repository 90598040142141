import select from './select.module.less';
import form from './form.module.less';
import checkbox from './checkbox.module.less';
import radio from './radio.module.less';
import input from './input.module.less';
import inputNumber from './input-number.module.less';
import datePicker from './date-picker.module.less';
import pagination from './pagination.module.less';
import table from './table.module.less';
import tableTheme2 from './table-theme2.module.less';
import popover from './popover.module.less';
import badge from './badge.module.less';
import menu from './menu.module.less';
import timePicker from './time-picker.module.less';


let SelectDropdownStyle = select.selectDropdown;
let SelectStyle = select.select;
let FormStyle = form.form;
let CheckboxStyle = checkbox.checkbox;
let InputStyle = input.input;
let InputNumberStyle = inputNumber.inputNumber;
let RadioStyle = radio.radio;
let DatePickerStyle = datePicker.datePicker;
let PaginationStyle = pagination.pagination;
let TableStyle = table.table;
let TableRowStyle = table.row;
let TableStyle2 = tableTheme2.table;
let TableRowStyle2 = tableTheme2.row;
let PopoverStyle = popover.popover;
let BadgeStyle = badge.badge;
let MenuStyle = menu.menu;
let TimePickerStyle = timePicker.timePicker;


export {
    SelectStyle, SelectDropdownStyle,
    TableStyle, TableRowStyle,
    TableStyle2, TableRowStyle2,
    PopoverStyle,
    RadioStyle,
    FormStyle,
    CheckboxStyle,
    PaginationStyle,
    TimePickerStyle,
    InputStyle,
    InputNumberStyle,
    DatePickerStyle,
    BadgeStyle,
    MenuStyle
};