import $$ from 'cmn-utils'

const baseUrl = "/api/sys/product/config/";
export default {
    get: (params) => {
        return $$.get(baseUrl + 'get', params)
    },
    set: (params) => {
        return $$.post(baseUrl + 'set', params)
    },

}