import Button, { ButtonProps, ButtonType } from 'antd/lib/button';
import classNames from 'classnames';
import React from 'react';
import styles from './common-button.module.less';
interface IProps {
    type?: ButtonType | `new-driver`
}
class CommonButton extends React.PureComponent<IProps & ButtonProps> {
    static defaultProps: IProps & ButtonProps = {
        type: `primary`,
    }

    componentDidMount() {
    }

    render() {
        const { className, children, ...props } = this.props;
        return (
            <Button className={classNames(className, styles.button)} {...props} >{children}</Button>
        );
    }
}
export { CommonButton };