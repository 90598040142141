import './styles/screen.less'
import React from 'react'
import {connect} from 'dva'
import {Layout,notification} from 'antd'
import {Switch} from 'dva/router'
import Icon from '@/components/Icon'
import {ip, port, voiceUrl} from '../utils/constant'
import $$ from 'cmn-utils'

const {Content} = Layout

let timer = null
@connect(({global}) => ({global}))
export default class UserLayout extends React.PureComponent {



    /**
     * 打开通知提示
     */
    handleOpenNotice(type, message) {
        const {dispatch} = this.props
        console.info("打开通知提示", type)
        if (type === 'Alarm') {
            dispatch({type: 'global/getNotice', payload: {data: {type: 'Alarm', readState: 'UnRead'}}})
            notification.error({
                message: '告警通知',
                duration: 10,
                description: message,
                icon: <Icon type="ring" style={{color: 'red'}}/>
            })
        } else if (type === 'Install') {
            dispatch({type: 'global/getNotice', payload: {data: {type: 'Install', readState: 'UnRead'}}})
            notification.error({
                message: '报装通知',
                duration: 10,
                description: message,
                icon: <Icon type="info-circle" antd style={{color: 'yellow', readState: 'UnRead'}}/>
            })
        } else if (type === 'system') {
            dispatch({type: 'global/getNotice', payload: {data: {type: 'SysMsg', readState: 'UnRead'}}})
            notification.info({
                message: '系统通知',
                duration: 10,
                description: message
            })
        } else if (type === "NewWorkOrder") {
            notification.error({
                message: '工单通知',
                duration: 10,
                description: "您有一条新的工单信息",
                icon: <Icon type="info-circle" antd style={{color: 'red', readState: 'UnRead'}}/>
            })
        }
    }

    /**
     * 播放语音
     * @param {*} voice
     */
    handlePlayAudio(voice) {
        console.info("语音内容", voice)
        const audioEle = document.getElementById('audio')
        audioEle.src = voiceUrl + voice
        audioEle.play()
    }

    /**
     * 处理接手到的ws的推送
     */
    handleMsg(data) {
        if (data === "pong") return;
        if (data !== '连接成功') {
            const obj = JSON.parse(data)
            console.info("收到推送", obj.type)
            const isSound = $$.getStore('isSound')
            if (obj.type === 'Alarm') {
                const info = JSON.parse(obj.data)
                this.handleNoticeUpdate(info)
                const message =
                    info.unitName +
                    '的' +
                    info.deviceSn +
                    '（' +
                    info.deviceName +
                    '）发生' +
                    info.eventName
                this.handleOpenNotice(obj.type, message)
                if (isSound) {
                    this.handlePlayAudio(info.iflytekVoice)
                    this.handleNoticeUpdate(info)
                }
            } else if (obj.type === 'devStNewWorkOrderate') {
                const info = JSON.parse(obj.data)
                this.handleOpenNotice(obj.type, "您有一条新的工单信息")
                if (isSound) {
                    this.handlePlayAudio(info.iflytekVoice)
                }
            } else if (obj.type === 'DevState') {
                const info = JSON.parse(obj.data)
                this.handleNoticeUpdate(info)
            }
        }
    }

    /**
     * 根据推送更新界面
     */
    handleNoticeUpdate(data) {
        console.info("推送的内容---------")
        console.info(data)
        console.info("推送的内容---------")
        const {location, dispatch} = this.props
        const {pathname} = location
        // if (pathname === '/home/index') {
        //     dispatch({type: 'global/handleHomeNotice', payload: data})
        // } else if (pathname === '/device/index') {
        //     dispatch({type: 'global/handleDeviceNotice', payload: data})
        // }
    }

    /**
     * 维持ws心跳
     */
    handleOpen(ws) {
        timer = setInterval(() => {
            ws.send("ping");
        }, 5000)
    }


    /**
     * 清除ws心跳
     */
    handleClose() {
        clearInterval(timer)
    }
    render() {
        const {routerData} = this.props
        const {childRoutes} = routerData

        return (
            <Layout className="full-layout screen-layout fixed">
                <audio id="audio"/>
                <Content className="content-style">
                    <Switch>{childRoutes}</Switch>
                </Content>
            </Layout>
        )
    }
}
