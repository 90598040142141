import { createRoute, dynamicWrapper } from "@/utils/core";
import intl from 'react-intl-universal';
import { RoleClass } from './components';

const routesConfig = app => ({
    path: "/system/base/role",
    title: intl.get('/system/role'),
    component: dynamicWrapper(app, [], () => { return RoleClass })
});

export default app => createRoute(app, routesConfig);
