import React from "react";
import { UserInfo } from "httpRequest/tokiot/sys/user/interface";

class ContextClass<T> {
    private context!: React.Context<T>;
    constructor(type: React.Context<T>) {
        this.context = type;
    }

    public get Context(): React.Context<T> {
        return this.context;
    }

}

class UserContextClass extends ContextClass<UserInfo>{
    constructor() {
        super(React.createContext<UserInfo>({} as any))
    }
}

export const UserContext = new UserContextClass();