import $$ from 'cmn-utils'
import eventLog from "./eventLog"
import alarmLog from "./alarmLog"
import actionLog from "./actionLog"
import activityLog from "./activityLog"
import stateLog from "./stateLog"


const baseUrl = "/api/core/device/";
export default {
    action: (params) => {
        return $$.post(baseUrl + 'action', params)
    },
    disRing: (params) => {
        return $$.post(baseUrl + 'dis-ring', params)
    },
    changeState: (params) => {
        return $$.post(baseUrl + 'change-state', params)
    },
    countDeviceState: (params) => {
        return $$.get(baseUrl + 'count-device-state', params)
    },
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    downloadResult: (params) => {
        return $$.get(baseUrl + 'download-result', params)
    },
    exportExcel: (params) => {
        return $$.get(baseUrl + 'export-excel', params)
    },
    get: function get(params) {
        return $$.get(baseUrl + 'get', params)
    },
    handleAlarm: (params) => {
        return $$.post(baseUrl + 'handle-alarm', params)
    },
    importExcel: (params) => {
        return $$.post(baseUrl + 'import-excel', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    listMain: (params) => {
        return $$.get(baseUrl + 'list-main', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },
    getActionBindProp: (params) => {
        return $$.get(baseUrl + 'get-action-bind-prop', params)
    },
    getActionList: (params) => {
        return $$.get(baseUrl + 'get-action-list', params)
    },
    getEventList: (params) => {
        return $$.get(baseUrl + 'get-event-list', params)
    },
    getLastActionParams: (params) => {
        return $$.get(baseUrl + 'get-last-action-params', params)
    },
    eventLog, alarmLog, actionLog, activityLog, stateLog

}