import {get, post} from "@/http/ys"

const baseUrl = "device/status/";

export default {

    get: (params) => {
        return post(baseUrl + 'get', params)
    },


}