import React from "react";
import { Checkbox, Form, Input, message, Modal } from "antd";
import intl from 'react-intl-universal';
import { BaseFormModal } from "components/base-interface";
import { MyCheckboxGroup } from "./my-checkbox-group";
import { Role, RoleInterface } from "httpRequest/tokiot/sys/role";
import DataTable from "components/DataTable";
import { Permission, PermissionInterface } from "httpRequest/tokiot/sys/permission";
import { PageClass } from "httpRequest/interface";
import styles from "./update-modal.module.less";
import { CommonModal } from "components/Modal/common-modal";

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        sm: { span: 6 }
    },
    wrapperCol: {
        sm: { span: 17 }
    }
};

interface IProps extends BaseFormModal {
    selectInfo?: RoleInterface.RoleInfo,
    edit: boolean,
}

interface IState {
    roleUpdateInfo: PageClass<PermissionInterface.RoleResourcePermissionInfo>;
    selectRole?: Array<string>;
}
class UpdateModalClass extends React.PureComponent<IProps, IState>  {
    state: IState = {
        roleUpdateInfo: new PageClass<PermissionInterface.RoleResourcePermissionInfo>(),
    }
    componentDidMount() {
        this.getRoleList();
    }
    getRoleList = () => {
        const { selectInfo } = this.props;
        let http = selectInfo ? Permission.RoleResourcePermissionListUpdate({ roleId: selectInfo.id }) : Permission.RoleResourcePermissionList();
        http.then(ret => {
            if (ret && ret.length > 0) {
                let selectRole: Array<string> = [];
                ret.forEach(f1 => {
                    //创建树形结构
                    if (f1.resourceParentId) {
                        let parent = ret.find(f2 => f2.resourceId === f1.resourceParentId);
                        if (parent) {
                            if (parent.children) {
                                parent.children.push(f1)
                            } else {
                                parent.children = [f1];
                            }
                        } else {
                            f1.resourceParentId = null;
                        }
                    }
                    //获取被选中的权限
                    if (f1.permissionDtoList) {
                        selectRole = [...selectRole, ...f1.permissionDtoList.filter(f1 => f1.checked).map(f1 => f1.id)];
                    }
                });
                let tree: Array<PermissionInterface.RoleResourcePermissionInfo> = [];
                tree = ret.filter(f1 => !f1.resourceParentId);
                let roleUpdateInfo = new PageClass<PermissionInterface.RoleResourcePermissionInfo>();
                roleUpdateInfo.pageNumber = 1;
                roleUpdateInfo.pageSize = tree.length;
                roleUpdateInfo.pageTotal = 1;
                roleUpdateInfo.rows = tree;
                roleUpdateInfo.total = tree.length;

                this.setState({ roleUpdateInfo, selectRole })
            }
        })
    }

    handleSubmit = () => {
        const { onChange, onClose, selectInfo, form } = this.props;
        const { validateFieldsAndScroll } = form;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (selectInfo) {
                    Role.Update({
                        id: selectInfo.id,
                        code: values.code,
                        sort: values.sort,
                        name: values.name,
                        remark: values.remark,
                        permissionIdList: values.permissionIdList,
                    }).then(ret => {
                        if (ret) {
                            onChange && onChange();
                            onClose && onClose();
                        } else {
                            message.error(`修改失败`)
                        }
                    })
                } else {
                    Role.Create({
                        code: values.code,
                        sort: values.sort,
                        name: values.name,
                        remark: values.remark,
                        permissionIdList: values.permissionIdList,
                    }).then(ret => {
                        if (ret) {
                            onChange && onChange();
                            onClose && onClose();
                        } else {
                            message.error(`创建失败`)
                        }
                    })
                }
            } else {
                return;
            }
        });
    }

    editcolumns: Array<any> = [
        {
            title: intl.get('table.system.role.editPermColumns.resourceName'),
            name: "resourceName",
            tableItem: {
                width: 150
            }
        },
        {
            title: intl.get('table.system.role.editPermColumns.permissionDtoList'),
            name: "permissionDtoList",
            tableItem: {
                width: 540,
                render: (value: PermissionInterface.PermissionDtoList, item: PermissionInterface.RoleResourcePermissionInfo) => {
                    return (
                        <MyCheckboxGroup
                            {...this.props}
                            roleUpdateInfo={item}
                        />
                    )
                }
            }
        }
    ];

    render() {
        const { selectInfo, visible, edit, onClose, form } = this.props;
        const { roleUpdateInfo, selectRole } = this.state;
        const { getFieldDecorator } = form;

        return (
            <CommonModal
                title={selectInfo ? (edit ? `修改角色` : `查看角色`) : `新增角色`}
                onCancel={() => { onClose && onClose() }}
                onOk={this.handleSubmit}
                width={900}
                visible={visible}
            >
                <Form className={styles.form} >
                    <div className={styles.title}>
                        <FormItem className={styles.formItem} {...formItemLayout} label={intl.get('form.system.role.name')}>
                            {getFieldDecorator("name", {
                                initialValue: selectInfo?.name ?? ``,
                                rules: [{ required: true, message: intl.get('form.system.role.name.please') }]
                            })(
                                <Input disabled={!edit} placeholder={intl.get('form.system.role.name.please')} />
                            )}
                        </FormItem>
                        <FormItem className={styles.formItem}  {...formItemLayout} label={`角色代码`}>
                            {getFieldDecorator("code", {
                                initialValue: selectInfo?.code ?? ``,
                                rules: [{ required: !!!selectInfo, message: `请输入角色代码` }]
                            })(
                                <Input disabled={!!selectInfo} placeholder={`请输入角色代码`} />
                            )}
                        </FormItem>
                        <FormItem className={styles.formItem}  {...formItemLayout} label={`排序`}>
                            {getFieldDecorator("sort", {
                                initialValue: selectInfo?.sort ?? ``,
                                rules: [{ required: true, message: `请输入排序` }]
                            })(
                                <Input disabled={!edit} placeholder={`请输入排序`} />
                            )}
                        </FormItem>
                        <FormItem className={styles.formItem}  {...formItemLayout} label={`备注`}>
                            {getFieldDecorator("remark", {
                                initialValue: selectInfo?.remark ?? ``
                            })(
                                <Input disabled={!edit} placeholder={`请输入备注`} />
                            )}
                        </FormItem>
                    </div>
                    <FormItem labelCol={{ sm: { span: 4 } }} wrapperCol={{ sm: { span: 18 } }} label={intl.get('form.system.role.permission')}>
                        {getFieldDecorator("permissionIdList", {
                            initialValue: selectRole,
                            rules: [{ required: true, message: `请输入排序` }]
                        })(
                            <Checkbox.Group >
                                <DataTable
                                    columns={this.editcolumns}
                                    defaultExpandAllRows={true}
                                    height={400}
                                    dataItems={roleUpdateInfo}
                                    rowKey={"resourceId"}
                                    showNum={false}
                                    isScroll={true}
                                />
                            </Checkbox.Group>
                        )}
                    </FormItem>
                </Form>
            </CommonModal >
        );
    }
}
const UpdateModal = Form.create<IProps>()(UpdateModalClass);
export { UpdateModal };
