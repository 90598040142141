import $$ from 'cmn-utils'

const baseUrl = "/api/core/device/alarm-log/";
export default {
    history: {
        detail: (params) => {
            return $$.get(baseUrl + 'history/detail', params)
        }
    },
    list: {
        history: (params) => {
            return $$.get(baseUrl + 'list/history', params)
        },
        real: (params) => {
            return $$.get(baseUrl + 'list/real', params)
        }
    },
    total: {
        history: (params) => {
            return $$.get(baseUrl + 'total/history', params)
        },
        real: (params) => {
            return $$.get(baseUrl + 'total/real', params)
        }
    },
    delete:{
        history: (params) => {
            return $$.post(baseUrl + 'delete/history', params)
        },
        real: (params) => {
            return $$.post(baseUrl + 'delete/real', params)
        }

    }
}