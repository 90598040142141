import { CommonButton } from "components/Button/common-button";
import { CommonModal } from "components/Modal/common-modal";
import React from "react";
import { Checkbox, Icon, Input, message } from "antd";
import styles from "./device-group-add-modal.module.less"
import classNames from "classnames";
import { InputStyle } from "components/common-styles/common-styles";
import { UnitGroupInterface } from "httpRequest/tokiot/sys/unit/group";
import { UnitGroupDevice, UnitGroupDeviceInterface } from "httpRequest/tokiot/sys/unit/group/device";
import { PageClass } from "httpRequest/interface";
import { PaginationLayout } from "layouts/pagination";
import { DownloadImg } from "utils/constant";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
interface IProps {
    visible: boolean;
    selectGroup: UnitGroupInterface.UnitGroupInfo;
    onClose?: () => void;
    onChange?: () => void;
    getContainer: () => HTMLElement;
}
interface IState {
    visible: boolean;
    unSelectDeviceList: PageClass<UnitGroupDeviceInterface.UnSelectDeviceInfo>;
    checkBoxSelectDeviceList: Array<CheckboxValueType>;
}
class DeviceGroupAddModal extends React.PureComponent<IProps, IState> {
    state: IState = {
        visible: false,
        unSelectDeviceList: new PageClass(),
        checkBoxSelectDeviceList: []
    }
    searchUnDeviceListData = {};
    searchText = ``;
    searchOnlyUnBind = false;

    componentDidMount() {
    }
    Refresh = () => {
        this.getUnDeviceList(this.searchUnDeviceListData);
    }

    getUnDeviceList = (data?: any) => {
        const { selectGroup } = this.props;
        UnitGroupDevice.UnSelectList({
            unitId: selectGroup.unitId,
            currentUnitGroupId: selectGroup.id,
            fullText: this.searchText,
            onlyUnBind: this.searchOnlyUnBind,
            ...data
        }).then(ret => {
            this.searchUnDeviceListData = data;
            if (ret && ret.rows && ret.rows.length > 0) {
                this.setState({ unSelectDeviceList: ret, checkBoxSelectDeviceList: [] });
            } else {
                this.setState({ unSelectDeviceList: new PageClass() });
            }
        })
    }

    addDeviceList = () => {
        const { selectGroup, onChange, onClose } = this.props;
        const { checkBoxSelectDeviceList } = this.state;
        UnitGroupDevice.Create({ unitGroupId: selectGroup.id, deviceIdList: checkBoxSelectDeviceList as Array<string> }).then(ret => {
            if (ret) {
                onChange && onChange();
                onClose && onClose();
            } else {
                message.error(`添加设备失败`);
            }
        })
    }

    onSelectDeviceChange = (checkedValue: Array<CheckboxValueType>) => {
        this.setState({ checkBoxSelectDeviceList: checkedValue });
    }

    inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.searchText = e.target.value;
        this.Refresh();
    }
    checkboxChange = (e: CheckboxChangeEvent) => {
        this.searchOnlyUnBind = e.target.checked;
        this.Refresh();
    }

    render() {
        const { visible, onClose, getContainer } = this.props;
        let { unSelectDeviceList, checkBoxSelectDeviceList } = this.state;
        return (
            <CommonModal
                mask={false}
                wrapClassName={styles.deviceModal}
                className={styles.deviceClass}
                getContainer={getContainer}
                width={`300px`}
                title={`新增设备`}
                visible={visible}
                footer={
                    <CommonButton
                        disabled={checkBoxSelectDeviceList.length <= 0}
                        className={styles.button}
                        onClick={this.addDeviceList}
                    >添加</CommonButton>
                }
                onCancel={onClose}
            >
                <div className={styles.deviceModalDiv} >
                    <div className={styles.searchTitle}>
                        <Checkbox className={classNames(styles.checkbox)} onChange={this.checkboxChange}>未分配</Checkbox>
                        <Input className={InputStyle} allowClear onChange={this.inputChange} prefix={<Icon type="search" className={styles.icon} />} />
                    </div>
                    <div className={styles.unDeviceBody}>
                        <PaginationLayout
                            onSearch={this.getUnDeviceList}
                            pageData={unSelectDeviceList}
                            defaultPageSize={1000}
                            showSizeChanger={false}
                            showEmpty={false}
                        >
                            <Checkbox.Group value={checkBoxSelectDeviceList} className={styles.checkboxGroup} onChange={this.onSelectDeviceChange}>
                                {unSelectDeviceList.rows.map(f1 => {
                                    return (<div className={styles.deviceItem}
                                        onClick={() => {
                                            let ret = checkBoxSelectDeviceList.filter(id => id === f1.id);
                                            if (ret && ret.length > 0) {
                                                checkBoxSelectDeviceList = checkBoxSelectDeviceList.filter(id => id !== f1.id);
                                            } else {
                                                checkBoxSelectDeviceList.push(f1.id);
                                            }
                                            this.setState({ checkBoxSelectDeviceList }, this.forceUpdate);
                                        }}
                                    >
                                        <div className={styles.title}>
                                            <div className={classNames(styles.text, f1.bindUnitGroupId > 0 ? styles.green : styles.red)}>
                                                {f1.bindUnitGroupId > 0 ? (`分组：` + f1.bindUnitGroupName) : `未分配`}
                                            </div>
                                            <Checkbox value={f1.id} onClick={e => e.stopPropagation()} />
                                        </div>
                                        <div className={styles.body}>
                                            <img alt={``} className={styles.image} src={DownloadImg + f1.productImage1} />
                                            <div className={styles.info}>
                                                <div className={styles.text1}>{f1.name}</div>
                                                <div className={styles.text2}>{`位置：` + f1.installLocation}</div>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </Checkbox.Group>
                        </PaginationLayout>
                    </div>
                </div>
            </CommonModal>
        );
    }
}
export { DeviceGroupAddModal };

