
import {DatePicker, Input, InputNumber, Radio, Select} from "antd";
import intl from "react-intl-universal";
import React from "react";
import $$ from "cmn-utils";
import {baseUrl} from "@/utils/constant";

const Option = Select.Option;
const RadioGroup = Radio.Group;
export const formatAreas = (parent, source, targ) => {
    if (parent[source] && parent[source].length > 0) {
        parent[targ] = parent[source];
        parent[targ].map((item) => {
            formatAreas(item, source, targ)
        })
    }
    if (parent.id) {
        parent.key = parent.id;
        parent.value = parent.id;
    }
    return parent;
}
export const formatAreaArray = (areaArray, source, targ) => {
    areaArray.map((item) => {
        formatAreas(item, source, targ);
    })
    return areaArray;
}


export const randomId = () => {
    const time = new Date().getTime() % 1000;
    const random = Math.floor(Math.random() * 1000)
    return '' + time + random
}
/**
 * 获取浏览器cookie
 * @param name
 * @returns {*}
 */
export const getCookie = (name) => {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
        return unescape(arr[2]);
    else
        return null;
}
/**
 * 设置浏览器cookie
 * @param name
 * @param value
 * @param hour
 */
export const setCookie = (name, value, hour) => {
    let exp = new Date();
    exp.setTime(exp.getTime() + hour * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

/**
 * 判断是否支持国际化
 * 目前仅亚马逊可支持
 * @returns {boolean}
 */
export const canSwitchLanguage = () => {
    return false;
    // return ip === "54.183.165.118";
}

/**
 * 获取当前选择的语言
 * 生产环境下默认zh
 * @returns {*}
 */
export const getLanguage = () => {
    if (canSwitchLanguage()) {
        const language = getCookie("language");
        let lang = (navigator.languages && navigator.languages[0] || navigator.language);
        const currentLocale = language ? language : (lang === "zh" || lang === "zh-TW" || lang === "en" ? lang : 'zh');
        // console.info("当前浏览器语言", lang);
        // console.info("存cookie的语言", language);
        // console.info("当前选择的语言", currentLocale);
        return currentLocale;
    } else {
        return 'zh'
    }

}


/**
 * 获取字符串包含几个字符
 * @param str
 * @returns {number}
 */
export const getStrLength = (str) => {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        let c = str.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
            len++;
        }
        else {
            len += 2;
        }
    }
    return len;
}
/**
 * 是否包含中文
 * @param str
 * @returns {number}
 */
export const hasChinese = (str) => {
    for (let i = 0; i < str.length; i++) {
        let c = str.charCodeAt(i);
        //单字节加1
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
        }
        else {
            return true;
        }
    }
    return false;
}


/**
 * 全屏
 * @param el
 */
export function fullScreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
}

/**
 * 退出全屏
 * @param el
 */
export function exitFullScreen(document) {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
}


// 判断是否是全屏
export function isFullScreen() {
    const windowHeight = window.screen.height;
    const contentHeight = window.outerHeight;
    return windowHeight - 20 < contentHeight;
}

export function transformStr(str) {
    let newStr = '';
    let arr = str.split('-');//split是分隔字符串
    for (let i = 0; i < arr.length; i++) {
        let s = arr[i];
        if (i == 0) {
            newStr += s;
        } else {
            newStr += s.substr(0, 1).toLocaleUpperCase();
            newStr += s.substr(1, s.length - 1);
        }
    }
    return newStr;
}

export const formatSearchData = (data) => {
    Object.keys(data).map(key => {
        const type = typeof data[key]
        if (type !== 'boolean' && !data[key]) delete data[key]
    })
}
export const exportExcel = (data, sufUrl) => {
    if (!data.productId) {
        delete data.productId;
    }
    if (!data.categoryId) {
        delete data.categoryId;
    }
    let url = baseUrl + sufUrl;
    if (data) {
        let paramsArray = [];
        //拼接参数
        formatSearchData(data);
        Object.keys(data).forEach(key => paramsArray.push(key + "=" + data[key]));
        if (url.search(/\?/) === -1) {
            url += "?" + paramsArray.join("&");
        } else {
            url += "&" + paramsArray.join("&");
        }
    }
    fetch(url, {
        method: "GET",
        credentials: "include",
        headers: $$.getStore("headers")
    }).then(res => {
        res.blob().then(blob => {
            const fileName = getFileName(res.headers)
            downloadExcel(blob, fileName)
        });
    });
}

export function formatFormData(data) {
    const keys = Object.keys(data)
    for (let key of keys) {
        if (!data[key]) delete data[key]
    }
}

export function getInput(item) {
    if (item.dataType === "String") {
        return <Input placeholder={intl.get('form.please') + `${item.name}`}/>
    }
    else if (item.dataType === "Int") {
        return <InputNumber
            style={{width: "100%"}}
            placeholder={`范围 ${item.intMinValue}~${item.intMaxValue}`}
            step={1}
            min={item.intMinValue}
            max={item.intMaxValue}
        />
    }
    else if (item.dataType === "Float") {
        return <InputNumber
            style={{width: "100%"}}
            placeholder={`范围 ${item.floatMinValue}~${item.floatMaxValue}`}
            step={1}
            min={item.floatMinValue}
            max={item.floatMaxValue}
        />
    }
    else if (item.dataType === "Boolean") {
        return <RadioGroup>
            <Radio value="true" style={{display: 'inline'}}> {item.booleanTrueValue}</Radio>
            <Radio value="false" style={{display: 'inline'}}>{item.booleanFalseValue}</Radio>
        </RadioGroup>
    }
    else if (item.dataType === "Date") {
        return <DatePicker format="YYYY-MM-DD HH:mm:ss"/>
    }
    else if (item.dataType === "Enum") {
        const items = item.enumItems;
        return <Select>
            {Object.keys(items).map(value => (
                <Option value={value} key={items[value]}>
                    {items[value]}
                </Option>
            ))}
        </Select>
    }
    else if (item.dataType === "Array") {
        return <Input
            placeholder={intl.get('form.please') + `${
                item.arrayType
                }` + intl.get('form.pleaseWithArray')}
        />
    }
}

export function getInputRules(item) {
    if (item.dataType === "String") {
        return [{required: true, message: item.name + '必填'}]
    }
    else if (item.dataType === "Int") {
        return [
            {required: true, message: item.name + '必填'},
            {
                validator: (rule, value, callback) => {
                    if (value < item.intMinValue) callback(`${item.name}不能小于${item.intMinValue}`);
                    else if (value > item.intMaxValue) callback(`${item.name}不能大于${item.intMaxValue}`)
                    else callback()
                }
            },
        ]
    }
    else if (item.dataType === "Float") {
        return [
            {required: true, message: item.name + '必填'},
            {
                validator: (rule, value, callback) => {
                    if (value < item.floatMinValue) callback(`${item.name}不能小于${item.floatMinValue}`);
                    else if (value > item.floatMaxValue) callback(`${item.name}不能大于${item.floatMaxValue}`)
                    else callback()
                }
            },
        ]
    }
    else if (item.dataType === "Boolean") {
        return [{required: true, message: item.name + '必填'}]
    }
    else if (item.dataType === "Date") {
        return [{required: true, message: item.name + '必填'}]
    }
    else if (item.dataType === "Enum") {
        const items = item.enumItems;
        return [
            {required: true, message: item.name + '必填'},
            {enum: items}
        ]
    }
    else if (item.dataType === "Array") {
        return [{required: true, message: item.name + '必填'}]
    }

}

export function filterIconByPre(iconList, pre) {
    const result = []
    console.info("所有图标", iconList)
    console.info("item", iconList[0])
    Object.keys(iconList).map((index) => {
        const iconHtml = iconList[index]
        const name = iconHtml.id || "";
        console.info(index, name)
        // if (name.startsWith(pre)) result.push()
    })
    console.info("返回的图标", result)
    return result
}

export function getFileName(headers, defaultName = '智慧消防') {
    if (!headers || !typeof headers.get == 'function') return defaultName
    const content = headers.get("content-disposition");
    if (!content) return defaultName
    const index = content.indexOf('filename') + 9
    if (content.length <= index) return defaultName
    const fileName = decodeURIComponent(content.substring(index))
    return fileName
}

export function downloadFile(blob, fileName, type) {
    if (blob && fileName)
        $$.download(blob, fileName, type);
}

export function downloadExcel(blob, fileName) {
    downloadFile(blob, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
}

/**
 * 循环遍历数组
 * @param array 被遍历的数组
 * @param index 第几次遍历
 * @param circle 一次几个
 * @return 返回一个新的数组
 */
export function arrayForEach(array, index, circle) {
    if (array.length <= circle) return array
    const retArray = [];
    const startIndex = index * circle;
    const endIndex = startIndex + circle
    let item = {}
    for (let i = startIndex; i < endIndex; i++) {
        item = array[i % array.length]
        if (item) retArray.push(item)
    }
    return retArray
}

export function parseXml(xmlStr,type='text/xml') {

    const domParser=new DOMParser()
    const xmlDom=domParser.parseFromString(xmlStr,type)
    window.xmlDom=xmlDom
    return xmlDom
}