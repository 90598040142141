import {createRoute, dynamicWrapper} from "@/utils/core";

const routesConfig = app => ({
    path: "/player",
    title: '播放器',
    component: dynamicWrapper(app, [], () =>
        import("./components")
    )
});

export default app => createRoute(app, routesConfig);
