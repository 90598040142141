import { HttpUtils } from 'utils/http-utils'
import { Page, ParamsId } from 'httpRequest/interface'
import * as UnitGroupDeviceInterface from './interface'
namespace UnitGroupDevice {
    const BaseUrl = "/api/sys/unit/group/device/";
    export const List = (params: UnitGroupDeviceInterface.UnitGroupDeviceListParams): Promise<Page<UnitGroupDeviceInterface.SelectDeviceInfo>> => {
        return HttpUtils.Get(BaseUrl + `list`, params);
    }
    export const UnSelectList = (params: UnitGroupDeviceInterface.UnitGroupDeviceUnSelectListParams): Promise<Page<UnitGroupDeviceInterface.UnSelectDeviceInfo>> => {
        return HttpUtils.Get(BaseUrl + `un-select-list`, params);
    }
    export const Create = (params: { unitGroupId: string, deviceIdList: Array<string> }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + `create`, params);
    }
    export const Delete = (params: ParamsId): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + `delete`, params);
    }
}
export { UnitGroupDevice, UnitGroupDeviceInterface };