import {get, post} from "@/http/ys"

const baseUrl = "device/preset/";

export default {

    add: (params) => {
        return post(baseUrl + 'add', params)
    },
    move: (params) => {
        return post(baseUrl + 'move', params)
    },
    clear: (params) => {
        return post(baseUrl + 'clear', params)
    },


}