import {createRoute, dynamicWrapper} from "@/utils/core";
import intl from 'react-intl-universal';

const routesConfig = app => ({
    path: "/report2/index",
    title: intl.get('/report/index'),
    component: dynamicWrapper(app, [import("./model")], () =>
        import("./components"))
});

export default app => createRoute(app, routesConfig);
