import * as DICT from "@/utils/dict"
import CONST from "@/dataSource/CONST"
import {downloadImg} from '@/utils/constant'
import {Tag} from "antd";
import React from "react";
import {connect} from "dva";

const {DeviceStateColor, ColorByKey} = CONST;

/** 设备状态 */  //  加loading是为了解决部分场景未触发刷新的问题
@connect(({store, loading}) => ({deviceStore: store.deviceStore, loading}))
export class DeviceStateTag extends React.PureComponent {
    render() {
        let {state} = this.props
        const {style = {}, id, deviceStore, ...otherProps} = this.props
        !id && console.error('id未传', id)
        const device = deviceStore[id]
        if (device) state = device.state
        return <Tag color={DeviceStateColor[state]}
                    children={DICT.getValue('DEVICE_STATE_TYPE', state)}
                    style={{...{fontSize: 'inherit', lineHeight: 'inherit'}, ...style}}
                    {...otherProps}/>
    }
}

/** 告警名称 */
export const AlarmNameTag = ({type, text, style = {}, ...otherProps}) => (
    <Tag color={ColorByKey[type]}
         children={text}
         style={{...{fontSize: 'inherit', lineHeight: 'inherit'}, ...style}}
         {...otherProps}/>)
/** 告警级别 */
export const AlarmLevelTag = ({type, text, style = {}, ...otherProps}) => (
    <Tag color={ColorByKey[type]}
         children={DICT.getValue('EVENT_TYPE', type)}
         style={{...{fontSize: 'inherit', lineHeight: 'inherit'}, ...style}}
         {...otherProps}/>)

export const AlarmStateTag = ({state, text, style = {}, ...otherProps}) => (
    <Tag color={ColorByKey[state]}
         children={DICT.getValue('ALARM_LOG_STATE', state)}
         style={{...{fontSize: 'inherit', lineHeight: 'inherit'}, ...style}}
         {...otherProps}/>)
export const DeviceArmStateTag = ({state, text, style = {}, ...otherProps}) => (
    <Tag color={ColorByKey['ARM_STATE_' + state]}
         children={DICT.getValue('ARM_STATE', state)}
         style={{...{fontSize: 'inherit', lineHeight: 'inherit'}, ...style}}
         {...otherProps}/>)
