import React from "react";
import { connect } from "dva";
import { Layout, notification } from "antd";
import { routerRedux, Switch } from "dva/router";
import Websocket from "components/Websocket";
import NavBar from "components/NavBar";
import Icon from "components/Icon";
import RealtimeAlarm from "components/Modal/realtimeAlarm";
import { LeftSideBar, RightSideBar } from "components/SideBar";
import TopBar from "components/TopBar";
import { getWSUrl, voiceUrl } from "../utils/constant";
import pathToRegexp from "path-to-regexp";
import { enquireIsMobile } from "@/utils/enquireScreen";
import TabsLayout from "./TabsLayout";
import "./styles/basic.less";
import $$ from "cmn-utils";
import cx from "classnames";
import ProtocolModal from "components/Modal/protocol";
import { User } from "httpRequest/tokiot/sys/user";
import { UserContext } from "context/context-react";
import { AreaInvasionModal } from "./area-invasion-modal";

const { Content, Header } = Layout;

let timer = null;
let wsTimer = null;

/**
 * 基本部局
 * 可设置多种皮肤 theme: [light, grey, primary, info, warning, danger, alert, system, success, dark]
 * 可设置多种布局 [header(固定头), sidebar(固定边栏), breadcrumb(固定面包蟹), tabLayout(标签布局)]
 * @author weiq
 */
@connect(({ global }) => ({ global }))
export default class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    const theme = $$.getStore("theme") || {
      nar: "grey",
      navbar: "grey",
      leftSide: "darkgrey",
      side: "darkgrey",
      layout: ["fixedSidebar", "hidedBreadcrumbs", "hidedBreadcrumbs"],
    };
    // if (!theme.layout) {
    //     theme.layout = [
    //         'fixedHeader',
    //         'fixedSidebar',
    //         'fixedBreadcrumbs'
    //         // 'hidedBreadcrumbs',
    //         // 'tabLayout',
    //     ];
    // }
    this.state = {
      collapsedLeftSide: false, // 左边栏开关控制
      leftCollapsedWidth: 60, // 左边栏宽度
      expandTopBar: false, // 头部多功能区开合
      showSidebarHeader: false, // 左边栏头部开关
      collapsedRightSide: true, // 右边栏开关
      theme, // 皮肤设置
      currentMenu: {},
      isMobile: false,
      realtimeAlarmVisible: false,
      fielddetectionAlarmVisible: false,
      realtimeAlarmInfo: {},
    };

    props.dispatch({
      type: "global/getUserInfo",
    });
    props.dispatch({
      type: "global/getWeather",
    });
  }
  getUserInfo = () => {
    User.GetDetail().then((ret) => {
      if (ret) {
        this.setState({ userInfo: ret });
      } else {
        this.setState({ userInfo: { id: `` } });
      }
    });
  };

  getMeunMatchKeys = (flatMenu, path) => {
    return flatMenu.filter((item) => {
      return pathToRegexp(item.url).test(path);
    });
  };
  /**
   * 顶部左侧菜单图标收缩控制
   */
  onCollapseLeftSide = (_) => {
    const collapsedLeftSide =
      this.state.leftCollapsedWidth === 0
        ? true
        : !this.state.collapsedLeftSide;
    const collapsedRightSide =
      this.state.collapsedRightSide || !collapsedLeftSide;

    this.setState({
      collapsedLeftSide,
      collapsedRightSide,
      leftCollapsedWidth: 60,
    });
  };
  /**
   * 完全关闭左边栏，即宽为0
   */
  onCollapseLeftSideAll = (_) => {
    this.setState({
      collapsedLeftSide: true,
      leftCollapsedWidth: 0,
    });
  };
  /**
   * 展开面包屑所在条中的多功能区
   */
  onExpandTopBar = (_) => {
    this.setState({
      expandTopBar: true,
    });
  };
  /**
   * 与上面相反
   */
  onCollapseTopBar = (_) => {
    this.setState({
      expandTopBar: false,
    });
  };
  /**
   * 切换左边栏中头部的开合
   */
  toggleSidebarHeader = (_) => {
    this.setState({
      showSidebarHeader: !this.state.showSidebarHeader,
    });
  };
  /**
   * 切换右边栏
   */
  toggleRightSide = (_) => {
    const { collapsedLeftSide, collapsedRightSide } = this.state;
    this.setState({
      collapsedLeftSide: collapsedRightSide ? true : collapsedLeftSide,
      collapsedRightSide: !collapsedRightSide,
    });
  };
  onChangeTheme = (theme) => {
    $$.setStore("theme", theme);
    this.setState({
      theme,
    });
  };

  componentDidMount() {
    this.getUserInfo();
    this.unregisterEnquire = enquireIsMobile((ismobile) => {
      const { isMobile, theme } = this.state;
      if (isMobile !== ismobile) {
        // 如查是移动端则不固定侧边栏
        if (ismobile && $$.isArray(theme.layout)) {
          theme.layout = theme.layout.filter((item) => item !== "fixedSidebar");
        }
        this.setState({
          isMobile: ismobile,
        });
      }
    });
  }

  componentWillMount() {
    // 检查有户是否登录
    const headers = $$.getStore("headers");
    if (!headers) {
      this.props.dispatch(routerRedux.replace("/account/login"));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.pathname !== this.props.location.pathname ||
      nextProps.global.flatMenu !== this.props.global.flatMenu
    ) {
      this.setState({
        currentMenu: this.getCurrentMenu(nextProps) || {},
      });
    }
  }

  componentWillUnmount() {
    // 清理监听
    this.unregisterEnquire();
  }

  getCurrentMenu(props) {
    const {
      location: { pathname },
      global,
    } = props || this.props;
    const menu = this.getMeunMatchKeys(global.flatMenu, pathname)[0];
    return menu;
  }

  /**
   * 打开通知提示
   */
  handleOpenNotice(type, message, info) {
    const { dispatch } = this.props;
    console.info("打开通知提示", type);
    switch (type) {
      case `Alarm`:
        {
          dispatch({
            type: "global/getNotice",
            payload: { data: { type: "Alarm", readState: "UnRead" } },
          });
          const config = {
            message: "告警通知",
            duration: 10,
            description: message,
            icon: <Icon type="ring" style={{ color: "red" }} />,
          };
          notification.error(config);
          if (
            info &&
            info.eventCode === `fielddetection` &&
            info.productKey === `pro_83012e89c8e5`
          ) {
            this.fielddetectionAlarmVisibleChange(info);
          } else if (
            info.linkageExecuteCameraDeviceIdList &&
            info.linkageExecuteCameraDeviceIdList > 0
          ) {
            // 有录像，直接弹出
            this.handleRealtimeAlarm(info);
          }
        }
        break;
      case `Install`:
        {
          dispatch({
            type: "global/getNotice",
            payload: { data: { type: "Install", readState: "UnRead" } },
          });
          notification.error({
            message: "报装通知",
            duration: 10,
            description: message,
            icon: (
              <Icon
                type="info-circle"
                antd
                style={{ color: "yellow", readState: "UnRead" }}
              />
            ),
          });
        }
        break;
      case `system`:
        {
          dispatch({
            type: "global/getNotice",
            payload: { data: { type: "SysMsg", readState: "UnRead" } },
          });
          notification.info({
            message: "系统通知",
            duration: 10,
            description: message,
          });
        }
        break;
      case `NewWorkOrder`:
        {
          notification.error({
            message: "工单通知",
            duration: 10,
            description: "您有一条新的工单信息",
            icon: (
              <Icon
                type="info-circle"
                antd
                style={{ color: "red", readState: "UnRead" }}
              />
            ),
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * 播放语音
   * @param {*} voice
   */
  handlePlayAudio(voice) {
    console.info("语音内容", voice);
    const audioEle = document.getElementById("audio");
    audioEle.src = voiceUrl + voice;
    audioEle.play();
  }

  /**
   * 处理接手到的ws的推送
   */
  handleMsg(data) {
    if (data === "pong") {
      // 标记开启推送，关闭定时任务
      // const {wsTimer} = this.state
      // console.error("关闭定时任务", wsTimer)
      clearInterval(wsTimer);
      this.setState({ ws: true });
      // console.error("推送回复", data);
      return;
    }
    if (data !== "连接成功") {
      const obj = JSON.parse(data);
      console.info("收到推送", obj.type);
      const isSound = $$.getStore("isSound");
      switch (obj.type) {
        case `Alarm`:
          {
            const info = JSON.parse(obj.data);
            const message =
              info.unitName +
              "的" +
              info.deviceSn +
              "（" +
              info.deviceName +
              "）发生" +
              info.eventName;
            this.handleOpenNotice(obj.type, message, info);
            if (isSound) {
              this.handlePlayAudio(info.iflytekVoice);
            }
          }
          break;
        case `devStNewWorkOrderate`:
          {
            const info = JSON.parse(obj.data);
            this.handleOpenNotice(obj.type, "您有一条新的工单信息", info);
            if (isSound) {
              this.handlePlayAudio(info.iflytekVoice);
            }
          }
          break;
        case `DevState`:
          {
            const info = JSON.parse(obj.data);
          }
          break;

        default:
          break;
      }
    }
  }
  /**
   * 维持ws心跳
   */
  handleOpen(ws) {
    console.error("WebSocket is connection");
    this.setState({ ws: true });
    const This = this;
    timer = setInterval(() => {
      // console.error("心跳",ws)
      ws.send("ping");
      wsTimer = setTimeout(() => {
        // 定时标记关闭推送
        console.error("WebSocket connection timeOut");
        This.setState({ ws: false });
      }, 5000);
      // This.setState({wsTimer})
    }, 5000);
  }

  /**
   * 清除ws心跳
   */
  handleClose() {
    this.setState({ ws: false });
    console.error("WebSocket is closed");
    clearTimeout(timer);
  }

  handleRealtimeAlarm = (info) => {
    this.setState({ realtimeAlarmVisible: true, realtimeAlarmInfo: info });
  };
  handleRealtimeAlarmClose = () => {
    this.setState({ realtimeAlarmVisible: false, realtimeAlarmInfo: {} });
  };

  fielddetectionAlarmVisibleChange = (realtimeAlarmInfo) => {
    this.setState({
      fielddetectionAlarmVisible: !this.state.fielddetectionAlarmVisible,
      realtimeAlarmInfo,
    });
  };

  render() {
    const {
      collapsedLeftSide,
      leftCollapsedWidth,
      expandTopBar,
      showSidebarHeader,
      collapsedRightSide,
      theme = {},
      currentMenu,
      isMobile,
      fielddetectionAlarmVisible,
      ws,
    } = this.state;
    const { routerData, location, global } = this.props;
    const { userInfo: userInfoState } = this.state;
    const { menu, flatMenu, userInfo, unit, unReadCount } = global;
    const weather = $$.getStore("weather");
    const { childRoutes } = routerData;
    const classnames = cx("basic-layout", "full-layout", {
      fixed: theme.layout && theme.layout.indexOf("fixedSidebar") !== -1,
      "fixed-header":
        theme.layout && theme.layout.indexOf("fixedHeader") !== -1,
      "fixed-breadcrumbs":
        theme.layout && theme.layout.indexOf("fixedBreadcrumbs") !== -1,
      "hided-breadcrumbs":
        theme.layout &&
        theme.layout.indexOf("hidedBreadcrumbs") !== -1 &&
        (this.props.location.pathname.indexOf("home") !== -1 ||
          this.props.location.pathname.indexOf("job/device-map") !== -1),
    });
    const { realtimeAlarmVisible, realtimeAlarmInfo } = this.state;
    return (
      <Layout className={classnames}>
        <audio id="audio" />
        {fielddetectionAlarmVisible && realtimeAlarmInfo ? (
          <AreaInvasionModal
            pushInfo={realtimeAlarmInfo}
            visible={fielddetectionAlarmVisible}
            onClose={this.fielddetectionAlarmVisibleChange}
          />
        ) : (
          ``
        )}

        <ProtocolModal />
        <RealtimeAlarm
          visible={realtimeAlarmVisible}
          alarmInfo={realtimeAlarmInfo}
          onClose={this.handleRealtimeAlarmClose}
        />
        {userInfo ? (
          <Websocket
            onClose={() => this.handleClose()}
            url={getWSUrl(userInfo.username)}
            onMessage={(data) => this.handleMsg(data)}
            debug={true}
            onOpen={(ws) => this.handleOpen(ws)}
          />
        ) : (
          ""
        )}
        <Header>
          <NavBar
            ws={ws}
            unit={unit}
            userInfo={userInfo}
            collapsed={collapsedLeftSide}
            onCollapseLeftSide={this.onCollapseLeftSide}
            onExpandTopBar={this.onExpandTopBar}
            toggleSidebarHeader={this.toggleSidebarHeader}
            theme={theme.navbar}
            isMobile={isMobile}
            weather={weather}
            unReadCount={unReadCount}
          />
        </Header>
        <Layout>
          <LeftSideBar
            unit={unit}
            collapsed={collapsedLeftSide}
            leftCollapsedWidth={leftCollapsedWidth}
            showHeader={showSidebarHeader}
            onCollapse={this.onCollapseLeftSide}
            onCollapseAll={this.onCollapseLeftSideAll}
            location={location}
            theme={theme.leftSide}
            flatMenu={flatMenu}
            currentMenu={currentMenu}
            menu={menu}
            isMobile={isMobile}
          />
          <Content>
            {theme.layout.indexOf("tabLayout") >= 0 ? (
              <TabsLayout childRoutes={childRoutes} location={location} />
            ) : (
              <Layout className="full-layout">
                <Header>
                  <TopBar
                    expand={expandTopBar}
                    toggleRightSide={this.toggleRightSide}
                    collapsedRightSide={collapsedRightSide}
                    onCollapse={this.onCollapseTopBar}
                    currentMenu={currentMenu}
                    location={location}
                    theme={theme}
                  />
                </Header>
                <Content className="router-page">
                  {userInfoState ? (
                    <UserContext.Context.Provider value={userInfoState}>
                      <Switch>{childRoutes}</Switch>
                    </UserContext.Context.Provider>
                  ) : (
                    ``
                  )}
                </Content>
              </Layout>
            )}
          </Content>
          <RightSideBar
            collapsed={collapsedRightSide}
            isMobile={isMobile}
            onCollapse={this.toggleRightSide}
          />
        </Layout>
        {/*<SkinToolbox onChangeTheme={this.onChangeTheme} theme={theme}/>*/}
      </Layout>
    );
  }
}
