import React, { PureComponent } from "react";
import { Checkbox, Row, Col } from "antd";
import { PermissionInterface } from "httpRequest/tokiot/sys/permission";
import { FormComponentProps } from "antd/lib/form";

interface IProps {
    roleUpdateInfo: PermissionInterface.RoleResourcePermissionInfo;
    edit: boolean;
}
interface IState {
}
class MyCheckboxGroup extends PureComponent<IProps & FormComponentProps, IState> {
    state: IState = {
    };

    componentDidMount() {
    }

    componentWillReceiveProps(last: IProps) {
    }
    render() {
        const { roleUpdateInfo, edit } = this.props;
        return (
            <Row>
                {roleUpdateInfo.permissionDtoList ? roleUpdateInfo.permissionDtoList.map(item =>
                    <Col span={6} style={{ width: 120 }}>
                        <Checkbox disabled={!edit} key={item.id} value={item.id}>
                            {item.name}
                        </Checkbox>
                    </Col>) : ''}
            </Row>
        );
    }
}

export { MyCheckboxGroup }