import $$ from 'cmn-utils'

const baseUrl = "/api/inspect/";
export default {
    create: (params) => {
        return $$.post(baseUrl + 'create', params)
    },
    delete: (params) => {
        return $$.post(baseUrl + 'delete', params)
    },
    accountsArea: (params) => {
        return $$.get(baseUrl + 'accounts-area', params)
    },
    detail: (params) => {
        return $$.get(baseUrl + 'detail', params)
    },
    list: (params) => {
        return $$.get(baseUrl + 'list', params)
    },
    unitAccounts: (params) => {
        return $$.get(baseUrl + 'unit-accounts', params)
    },
    update: (params) => {
        return $$.post(baseUrl + 'update', params)
    },

}