import area from "./area";
import unit from "./unit";
import pushStrategy from "./pushStrategy";
import log from "./log";
import url from "./url";
import user from "./user";
import menu from "./menu";
import role from "./role";
import permission from "./permission";
import pushLog from "./pushLog";
import product from "./product";
import dict from "./dict";
import industryCategory from "./industryCategory";
import about from "./about";
import organ from "./organ";
import protocal from "./protocal"
import bigdata from "./bigdata"

export default {
    area, unit, pushStrategy, log, url, user, menu, role,
    permission, pushLog, product, dict, industryCategory, about,
    organ, protocal,bigdata
}