import $$ from "cmn-utils";
import modelEnhance from "@/utils/modelEnhance";
import * as api from "../services";
import jsonTree from "@aximario/json-tree";
import { routerRedux } from "dva/router";
import http from "@/http";
import { baseUrl, clientVersion } from "@/utils/constant";
import intl from "react-intl-universal";
import { message } from "antd";

const loopMenu = (menu, pitem = {}) => {
  menu.forEach((item) => {
    if (pitem.url) {
      item.parentPath = pitem.parentPath
        ? pitem.parentPath.concat(pitem.url)
        : [pitem.url];
    }
    if (item.children && item.children.length) {
      loopMenu(item.children, item);
    }
  });
};
export default modelEnhance({
  namespace: "global",
  state: {
    noticeList: [],
    menu: [],
    flatMenu: [],
    unit: {},
    unReadCount: {},
    version: {
      server: "",
      client: clientVersion,
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        dispatch({ type: "getServerVersion" });
        if (pathname.indexOf("account" == -1)) {
          const store = {
            headers: $$.getStore("headers"),
            accessToken: $$.getStore("accessToken"),
            position: $$.getStore("position"),
            weather: $$.getStore("weather"),
          };
          Object.keys(store).map((key) => {
            if (!store[key]) {
              console.error("store " + key + " is null now");
            }
          });
        }
      });
    },
  },

  effects: {
    *getUserInfo({ payload }, { call, put }) {
      const ret = yield call(api.getUserInfo, {});
      if (ret) {
        const {
          username,
          role,
          email,
          tel,
          unit,
          unitId,
          area,
          areaId,
          menuList,
          realName,
          avatar,
          nav,
          side,
          layout,
          permissionCodeList,
        } = ret;
        console.info("用户区域信息", area);
        // if (layout && layout.length > 0) {
        // const theme = {
        //     leftSide: side,
        //     navbar: nav,
        //     layout: layout.split(',')
        // };
        const theme = {
          nar: "grey",
          navbar: "grey",
          leftSide: "darkgrey",
          side: "darkgrey",
          layout: ["fixedSidebar", "hidedBreadcrumbs", "hidedBreadcrumbs"],
        };
        $$.setStore("theme", theme);
        // }
        $$.setStore("permissions", permissionCodeList);
        routerTitle(menuList);
        const treeJson = jsonTree(menuList, {
          id: "id",
          pid: "parentId",
          children: "children",
        });
        loopMenu(treeJson);
        yield put({ type: "getMenuSuccess", payload: treeJson });
        const userInfo = {
          username,
          avatar,
          realName,
          email,
          tel,
          areaId,
          unitId,
          role: role.name,
          unitInfo: unit,
          unit: unit.name,
          area: area.name,
          unitPosition: { latitude: unit.lat, longitude: unit.lng },
          areaCode: area.code,
        };
        $$.setStore("userInfo", userInfo);
        if (unit.name.length === 0) {
          unit.name = "智慧消防";
        }
        yield put({ type: "getUnReadCount", payload: {} });
        yield put({
          type: "commonSuccess",
          payload: { username, role, unit, area, userInfo },
        });
      }
    },
    *getServerVersion({ payload }, { call, put }) {
      const ret = yield call(http.tokiot.other.version, {});
      if (ret) {
        yield put({
          type: "commonSuccess",
          payload: {
            version: {
              server: ret.version,
              client: clientVersion,
            },
          },
        });
      }
    },
    *getUnitTree({ payload = {} }, { call, put }) {
      const { resolve, data } = payload;
      const ret = yield call(http.tokiot.sys.unit.tree, data || {});
      if (ret) {
        const units = JSON.parse(
          JSON.stringify(ret)
            .replace(/name/g, "title")
            .replace(/id/g, "value")
            .replace(/childrenList/g, "children")
        );
        resolve && resolve([units]);
      }
    },
    *getNotice({ payload = {} }, { call, put }) {
      const { resolve, data } = payload;
      const ret = yield call(http.tokiot.sys.pushLog.list, data || {});
      if (ret) {
        yield put({ type: "getUnReadCount", payload: {} });
        yield put({ type: "commonSuccess", payload: { noticeList: ret.rows } });
        resolve && resolve(ret);
      }
    },
    *loadMoreNotice({ payload = {} }, { call, put, select }) {
      const { resolve, data } = payload;
      const ret = yield call(http.tokiot.sys.pushLog.list, data || {});
      const old = yield select((state) => state.global.noticeList);
      if (ret) {
        const notice = [...old, ...ret.rows];
        yield put({ type: "commonSuccess", payload: { noticeList: notice } });
      }
      resolve && resolve(ret);
    },

    *getUnReadCount({ payload = {} }, { call, put }) {
      const { resolve, data } = payload;
      const unReadCount = yield call(
        http.tokiot.sys.pushLog.totalUnRead,
        data || {}
      );
      if (unReadCount) {
        yield put({ type: "commonSuccess", payload: { unReadCount } });
        resolve && resolve(unReadCount);
      }
    },

    *setRead({ payload }, { call, put }) {
      const { resolve, data } = payload;
      const type = data.type;
      delete data.type;
      const ret = yield call(http.tokiot.sys.pushLog.read, data || {});
      if (ret) {
        message.success(intl.get("message.global.setRead.success"));
        yield put({
          type: "getNotice",
          payload: { data: { type, readState: "UnRead" } },
        });
        resolve && resolve(ret);
      }
    },

    *setReadBatch({ payload }, { call, put }) {
      const { resolve, data } = payload;
      const ret = yield call(http.tokiot.sys.pushLog.readAll, data || {});
      if (ret) {
        message.success(intl.get("message.global.setReadBatch.success"));
        yield put({ type: "getNotice", payload: { data } });
        resolve && resolve(ret);
      }
    },
    /* 查询天气已交给BasicLayout处理*/
    *getWeather({ payload = {} }, { call, put }) {
      const { resolve, data } = payload;
      const headers = $$.getStore("headers");
      if (headers) {
        fetch(baseUrl + "/api/weather/get-now-weather", {
          method: "GET",
          credentials: "include",
          headers,
        })
          .then((response) => {
            return response.json();
          })
          .then((res) => {
            if (res.code === "OK") {
              const ret = res.body;
              const weather = JSON.parse(ret);
              console.info("天气", weather);
              if ("100" != weather.errcode) {
                $$.setStore("weather", weather);
                resolve && resolve(weather);
              }
            }
          });
      }
    },
    *modifyPwd({ payload }, { call, put }) {
      const { data, resolve } = payload;
      const ret = yield call(http.tokiot.sys.user.changePassword, data || {});
      if (ret) {
        message.success(intl.get("message.global.modifyPwd.success"));
        resolve();
        yield put(routerRedux.replace("/account/login"));
      } else {
        // message.error(intl.get('message.global.modifyPwd.error'));
      }
    },
    *updateUser({ payload }, { call }) {
      const { data, resolve } = payload;
      const ret = yield call(http.tokiot.sys.user.updateHome, data || {});
      if (ret) {
        resolve && resolve();
      }
    },
    *getOrderDetail({ payload }, { put, call }) {
      const { data, resolve } = payload;
      const ret = yield call(http.tokiot.workOrder.detail, data || {});
      if (ret) {
        resolve && resolve(ret);
      }
    },
    *dealOrder({ payload }, { call }) {
      const { data, resolve } = payload;
      const ret = yield call(http.tokiot.workOrder.handle, data);
      if (ret) {
        message.success("处理成功");
        resolve && resolve(ret);
      }
    },
    *existMobile({ payload }, { call }) {
      const { data, resolve } = payload;
      const ret = yield call(http.tokiot.sys.user.existMobile, data);
      resolve(ret);
    },
    *existEmail({ payload }, { call }) {
      const { data, resolve } = payload;
      const ret = yield call(http.tokiot.sys.user.existEmail, data);
      resolve(ret);
    },
  },

  reducers: {
    getMenuSuccess(state, { payload }) {
      return {
        ...state,
        menu: payload,
        flatMenu: getFlatMenu(payload),
      };
    },
    commonSuccess(state, { payload }) {
      return { ...state, ...payload };
    },
  },
});

export function getFlatMenu(menus) {
  let menu = [];
  menus.forEach((item) => {
    if (item.children) {
      menu = menu.concat(getFlatMenu(item.children));
    }
    menu.push(item);
  });
  return menu;
}

// 该方法是为了实现面包屑标题可配置化（同步菜单）
function routerTitle(menu) {
  // const routerTitleMap = $$.getStore('routerTitleMap');
  // if (!routerTitleMap) return;
  const routerTitle = {};
  menu.map((item) => {
    routerTitle[item.url] = item.name;
  });
  $$.setStore("routerTitleMap", routerTitle);
}

function generateUUID() {
  let d = Date.now();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function getVideo(text) {
  const url =
    "https://neuhubdemo.jd.com/api/tts/stream?reqid=" +
    generateUUID() +
    "&text=" +
    text +
    "&tim=0&sp=1";
  new Promise((resolve) =>
    fetch(url)
      .then((response) => {
        console.info("语音请求返回", response);
        return response.json();
      })
      .then((res) => {
        console.info("语音格式化返回", res);
        resolve(res);
      })
      .catch((err) => {
        console.info("语音请求失败", err);
      })
  );
}
