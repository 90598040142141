import { CommonModal } from "components/Modal/common-modal";
import React from "react";
import { message } from "antd";
import styles from "./device-group-modal.module.less"
import { UnitGroupInterface } from "httpRequest/tokiot/sys/unit/group";
import { UnitGroupDevice, UnitGroupDeviceInterface } from "httpRequest/tokiot/sys/unit/group/device";
import { PageClass } from "httpRequest/interface";
import { DeviceGroupAddModal } from "./device-group-add-modal";
import { PaginationLayout } from "layouts/pagination";
import { DownloadImg } from "utils/constant";
import { CommonParameter } from "utils/common-parameter";
import AddImage from "./image/add.png";
import DeleteImage from "./image/delete-blue.png";
import { SimpleModal } from "components/Modal/simple-modal";
import { CommonCard } from "components/card/common-card";
import classNames from "classnames";
interface IProps {
    visible: boolean;
    selectGroup: UnitGroupInterface.UnitGroupInfo;
    onClose?: () => void;
    onChange?: () => void;
}
interface IState {
    addDeviceVisible: boolean;
    selectDeviceList: PageClass<UnitGroupDeviceInterface.SelectDeviceInfo>;
}
class DeviceGroupModal extends React.PureComponent<IProps, IState> {
    state: IState = {
        addDeviceVisible: false,
        selectDeviceList: new PageClass(),
    }
    componentDidMount() {
    }
    searchData = {};
    getDeviceList = (data?: any) => {
        const { selectGroup } = this.props;
        UnitGroupDevice.List({ unitGroupId: selectGroup.id, ...data }).then(ret => {
            this.searchData = data;
            if (ret && ret.rows && ret.rows.length > 0) {
                this.setState({ selectDeviceList: ret });
            } else {
                this.setState({ selectDeviceList: new PageClass() });
            }
        })
    }

    deleteDevice = (id: string) => {
        const { onChange } = this.props;
        UnitGroupDevice.Delete({ id }).then(ret => {
            if (ret) {
                this.getDeviceList(this.searchData);
                this.deviceGroupAddModalRef.current?.Refresh();
                onChange && onChange();
            } else {
                message.error(`删除设备失败`)
            }
        })
    }


    addDeviceVisibleChange = () => {
        this.setState({ addDeviceVisible: !this.state.addDeviceVisible });
    }
    rightRef = React.createRef<HTMLDivElement>();
    deviceGroupAddModalRef = React.createRef<DeviceGroupAddModal>();
    getDeviceModal = () => {
        let { addDeviceVisible } = this.state;
        let { selectGroup, onChange } = this.props;
        let getContainer = this.rightRef.current ?? document.body;
        return (
            <div ref={this.rightRef} >
                {addDeviceVisible ?
                    <DeviceGroupAddModal
                        ref={this.deviceGroupAddModalRef}
                        selectGroup={selectGroup}
                        getContainer={() => getContainer}
                        visible={addDeviceVisible}
                        onClose={this.addDeviceVisibleChange}
                        onChange={() => {
                            this.getDeviceList(this.searchData);
                            this.deviceGroupAddModalRef.current?.Refresh();
                            onChange && onChange();
                        }}
                    />
                    : ``}
            </div>
        )
    }

    render() {
        const { selectDeviceList, addDeviceVisible } = this.state;
        const { visible, onClose, selectGroup } = this.props;
        return (
            <CommonModal
                centered
                width={addDeviceVisible ? `1200px` : `900px`}
                title={`绑定设备`}
                visible={visible}
                onCancel={onClose}
                footer={<div style={{ height: `32px` }} />}
                rightButtons={this.getDeviceModal()}
                mask={true}
            >
                <div className={styles.deviceGroupManage} >
                    <PaginationLayout
                        onSearch={this.getDeviceList}
                        pageData={selectDeviceList}
                        showSizeChanger={false}
                        itemSize={{ width: 280, rowCount: 7, showAdd: true }}
                    >
                        {(width, height, columnCount) => {
                            let style: React.CSSProperties = { width: `${100 / (columnCount ?? 100)}%` };
                            return (
                                <div className={styles.deviceBody} >
                                    <CommonCard className={classNames(styles.addItem, styles.deviceItem)} style={style} onClick={this.addDeviceVisibleChange} >
                                        <div className={styles.deviceItemBody} >
                                            <img alt={``} className={styles.image} src={AddImage} />
                                            <div className={styles.info}>新增设备</div>
                                        </div>
                                    </CommonCard>
                                    {selectDeviceList.rows.map(f1 => {
                                        return (<CommonCard className={styles.deviceItem} style={style}>
                                            <div className={styles.deviceItemBody} >
                                                <SimpleModal title={`删除-${f1.deviceName}`} message={`确定要删除[${f1.deviceName}]吗？`} onOk={() => this.deleteDevice(f1.id)}>
                                                    <img alt={``} className={styles.deleteImage} src={DeleteImage} />
                                                </SimpleModal>
                                                <img alt={``} className={styles.image} src={DownloadImg + f1.productImage1} />
                                                <div className={styles.info}>
                                                    <div className={styles.text1}>{f1.deviceName}</div>
                                                    <div className={styles.text2}>{`位置：` + f1.installLocation}</div>
                                                </div>
                                            </div>
                                        </CommonCard>)
                                    })}
                                </div>
                            )
                        }}
                    </PaginationLayout>
                </div>
            </CommonModal>
        );
    }
}
export { DeviceGroupModal };

