import classNames from "classnames";
import React from "react";
import styles from "./common-tag.module.less";
import hexRgb, { RgbaObject } from "hex-rgb";

type TagType = "orange" | `blue` | `red` | `green`;
interface IProps {
  full?: boolean;
  type?: TagType;
  className?: string;
  radius?: boolean;
  color?: string;
  span?: boolean;
}
class CommonTag extends React.PureComponent<IProps> {
  componentDidMount() {}

  render() {
    const { className, children, type, radius, color, full, span } = this.props;
    let tagColor: string | undefined = undefined;
    let style: React.CSSProperties | undefined = undefined;
    if (type) {
      tagColor = styles[type];
    }
    if (!tagColor) {
      if (color) {
        let rgb: RgbaObject | undefined;
        try {
          rgb = hexRgb(color);
        } catch (error) {
          rgb = undefined;
        }
        if (rgb) {
          if (full) {
            style = {
              background: `rgba(${rgb.red},${rgb.green},${rgb.blue},1)`,
              border: `0 solid ${color}`,
              color: `#FFF`,
            };
          } else {
            style = {
              background: `rgba(${rgb.red},${rgb.green},${rgb.blue},0.2)`,
              border: `0.05rem solid ${color}`,
              color: color,
            };
          }
        } else {
          tagColor = styles.blue;
        }
      } else {
        tagColor = styles.blue;
      }
    }
    if (span) {
      return (
        <span
          style={style}
          className={classNames(className, styles.tagMain, tagColor, {
            [styles.radius]: radius,
          })}
        >
          {children}
        </span>
      );
    } else {
      return (
        <div
          style={style}
          className={classNames(className, styles.tagMain, tagColor, {
            [styles.radius]: radius,
          })}
        >
          {children}
        </div>
      );
    }
  }
}

export { CommonTag };
