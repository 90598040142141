import { TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";
import { TreeNode, TreeNodeValue } from "antd/lib/tree-select/interface";
import { Area, AreaInterface } from "httpRequest/tokiot/sys/area";
import React, { PureComponent } from "react";
import { SelectAreaModal } from "./select-area-modal";
export interface AreaTreeSelectProps extends TreeSelectProps<TreeNodeValue> {}
interface IState {
  visible: boolean;
  selectUnit?: AreaInterface.AreaInfo;
  loadding: boolean;
}

class AreaTreeSelect extends PureComponent<AreaTreeSelectProps, IState> {
  state: IState = {
    visible: false,
    loadding: false,
  };
  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({ loadding: true });
      Area.Get({ id: value.toString() }).then((ret) => {
        if (ret) {
          this.setState({ selectUnit: ret, loadding: false });
        }
      });
    }
  }
  selectUnit = (unit?: AreaInterface.AreaInfo) => {
    const { onChange } = this.props;
    this.setState({ selectUnit: unit }, () => {
      if (onChange) {
        let ret = unit?.id ?? (undefined as any);
        onChange(ret, unit?.name ?? ``, undefined);
      }
    });
  };
  visibleChange = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { value, ...param } = this.props;
    const { visible, selectUnit, loadding } = this.state;
    let p1 = {
      onClick: () => {
        this.visibleChange();
      },
    };
    let treeData: TreeNode | undefined = undefined;
    if (selectUnit) {
      treeData = { value: selectUnit.id, label: selectUnit.name };
    }
    return (
      <React.Fragment>
        <TreeSelect
          {...param}
          {...p1}
          value={loadding ? undefined : value}
          treeData={treeData ? [treeData] : undefined}
          dropdownStyle={{ display: "none" }}
          onChange={(value, lable, extra) => {
            this.selectUnit();
          }}
        />
        {visible ? (
          <SelectAreaModal
            visible={visible}
            onOk={this.selectUnit}
            onClose={this.visibleChange}
          />
        ) : (
          ``
        )}
      </React.Fragment>
    );
  }
}

export { AreaTreeSelect };
