import modelEnhance from "@/utils/modelEnhance";
import tokiot from "@/http/tokiot"
import $$ from 'cmn-utils';

export default modelEnhance({
    namespace: "DICT",
    state: {},
    subscriptions: {
        setup({ dispatch, history }) {
            history.listen(({ pathname }) => {
                if (pathname.indexOf('account') == -1) {
                    dispatch({ type: "init", payload: { data: { size: 9999 } } });
                }
            });
        }
    },
    effects: {
        * init({ payload = {} }, { call, put, select }) {
            const { resolve, data } = payload
            const ret = yield call(tokiot.sys.dict.list, data || {});
            if (ret && ret.rows) {
                const dictList = ret.rows;
                const DICT = {};
                let item = {}
                Object.keys(dictList).map(key => {
                    item = dictList[key];
                    if (DICT[item.type] === undefined) DICT[item.type] = { map: {}, list: [] }
                    DICT[item.type].map[item.value] = item.label;
                    DICT[item.type].list.push({ code: item.value, codeName: item.label });
                })
                $$.setStore('DICT', DICT);
                // yield put({type: "commonSuccess", payload: {pageData}});
                resolve && resolve(ret)
            }
        },
        * getList({ payload = {} }, { call, put, select }) {
            const { resolve, data } = payload
            const ret = yield call(tokiot.sys.dict.dictList, data || {});
            if (ret) {
                resolve && resolve(ret)
            }
        },
        * getValue({ payload = {} }, { call, put, select }) {
            const { resolve, data } = payload
            const ret = yield call(tokiot.sys.dict.dictValue, data || {});
            if (ret) {
                resolve && resolve(ret)
            }
        },
        * getLabel({ payload = {} }, { call, put, select }) {
            const { resolve, data } = payload
            const ret = yield call(tokiot.sys.dict.dictList, data || {});
            if (ret) {
                resolve && resolve(ret)
            }
        },
        * getDropDownDataByType({ payload = {} }, { call, put, select }) {
            const { resolve, data } = payload
            const ret = yield call(tokiot.sys.dict.dictList, data || {});
            if (ret) {
                resolve && resolve(JSON.parse(JSON.stringify(ret).replace(/label/g, "codeName").replace(/value/g, "code")))
            }
        },
    },
    reducers: {
        commonSuccess(state, { payload }) {
            return { ...state, ...payload };
        }
    }
});
