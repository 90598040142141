import React, {PureComponent} from "react";
import "braft-editor/dist/index.css";
import PropTypes from "prop-types";
import cx from 'classnames';

class BraftEditorView extends PureComponent {

    static propTypes = {
        html: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        style: {}
    };

    render() {
        const {html, style, className} = this.props;
        const defaultStyle = {
            paddingTop: 20, paddingLeft: 20, paddingRight: 20, paddingBottom: 20,overflowY:'auto'
        }
        return <div className={cx('DraftEditor-editorContainer', className)}
                    style={{...defaultStyle, ...style}}
                    dangerouslySetInnerHTML={{__html: html}}/>
    }

}

export default BraftEditorView