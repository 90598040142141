import $$ from 'cmn-utils'

const baseUrl = "/api/sys/area/";
export default {
    tree: (params) => {
        return $$.get(baseUrl + 'tree', params)
    },
    treeHome: (params) => {
        return $$.get(baseUrl + 'tree-home', params)
    },
    listHome: (params) => {
        return $$.get(baseUrl + 'list-home', params)
    },
    listIntersection: (params) => {
        return $$.get(baseUrl + 'list-intersection', params)
    },
    treeByUnit: (params) => {
        return $$.get(baseUrl + 'tree-by-unit', params)
    },

}