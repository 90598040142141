import React from "react";
import xue from '../../assets/images/weather/xue.png';

import lei from '../../assets/images/weather/lei.png';
import shachen from '../../assets/images/weather/shachen.png';
import wu from '../../assets/images/weather/wu.png';
import bingbao from '../../assets/images/weather/yu.png';
import yun from '../../assets/images/weather/yun.png';
import yu from '../../assets/images/weather/yu.png';
import yin from '../../assets/images/weather/yin.png';
import qing from '../../assets/images/weather/qing.png';

import 霾 from '../../assets/images/weather/d-mai.png';
import 暴雪 from '../../assets/images/weather/d-baoxue.png';
import 大雪转暴雪 from '../../assets/images/weather/d-daxuezhuanbaoxue.png';
import 大雪 from '../../assets/images/weather/d-daxue.png';
import 晴 from '../../assets/images/weather/d-qing.png';
import 阵雪 from '../../assets/images/weather/d-zhenxue.png';
import 中雪 from '../../assets/images/weather/d-zhongxue.png';
import 特大暴雨 from '../../assets/images/weather/d-tedabaoyu.png';
import 大暴雨转特大暴雨 from '../../assets/images/weather/d-dabaoyuzhuantedabaoyu.png';
import 大暴雨 from '../../assets/images/weather/d-dabaoyu.png';
import 阴 from '../../assets/images/weather/d-yin.png';
import 中雨 from '../../assets/images/weather/d-zhongyu.png';
import 雨夹雪 from '../../assets/images/weather/d-yujiaxue.png';
import 小雨 from '../../assets/images/weather/d-xiaoyu.png';
import 多云 from '../../assets/images/weather/d-duoyun.png';
import 雷阵雨伴有冰雹 from '../../assets/images/weather/d-leizhenyubanyoubingbao.png';
import 大雨 from '../../assets/images/weather/d-dayu.png';
import 浮尘 from '../../assets/images/weather/d-fuchen.png';
import 小雪转中雪 from '../../assets/images/weather/d-xiaoxuezhuanzhongxue.png';
import 沙尘暴 from '../../assets/images/weather/d-shachenbao.png';
import 暴雨 from '../../assets/images/weather/d-baoyu.png';
import 雷阵雨 from '../../assets/images/weather/d-leizhenyu.png';
import 扬沙 from '../../assets/images/weather/d-yangsha.png';
import 小雪 from '../../assets/images/weather/d-xiaoxue.png';
import 冻雨 from '../../assets/images/weather/d-dongyu.png';
import 大雨转暴雨 from '../../assets/images/weather/d-dayuzhuanbaoyu.png';
import 强沙尘暴 from '../../assets/images/weather/d-qiangshachenbao.png';
import 阵雨 from '../../assets/images/weather/d-zhenyu.png';
import 中雨转大雨 from '../../assets/images/weather/d-zhongyuzhuandayu.png';
import 小雨转中雨 from '../../assets/images/weather/d-xiaoyuzhuanzhongyu.png';
import 暴雨转大暴雨 from '../../assets/images/weather/d-baoyuzhuandabaoyu.png';
import 雾 from '../../assets/images/weather/d-wu.png';
import 小雨转阴 from '../../assets/images/weather/d-xiaoyu.png';
import 阴转多云 from '../../assets/images/weather/d-duoyun.png';
export default {
    // 没有冰雹的图片，暂时使用雨，
    xue,
    lei,
    shachen,
    wu,
    bingbao,
    yun,
    yu,
    yin,
    qing,
    霾,
    暴雪,
    大雪转暴雪,
    大雪,
    晴,
    阵雪,
    中雪,
    特大暴雨,
    大暴雨转特大暴雨,
    大暴雨,
    阴,
    中雨,
    雨夹雪,
    小雨,
    多云,
    雷阵雨伴有冰雹,
    大雨,
    浮尘,
    小雪转中雪,
    沙尘暴,
    暴雨,
    雷阵雨,
    扬沙,
    小雪,
    冻雨,
    大雨转暴雨,
    强沙尘暴,
    阵雨,
    中雨转大雨,
    小雨转中雨,
    暴雨转大暴雨,
    雾,
    小雨转阴,
    阴转多云,
}