// zh_CHT.js
const zh_CHT = {
    'site.title': '智慧消防',
    'site.home': '主頁',


    '/account/forget-password': '忘記密碼',
    '/account': '登入',
    '/account/login': '登入',
    '/device': '設備管理',
    '/device/detail/:id': '設備詳情',
    '/device/index': '設備管理',
    '/': '首頁',
    '/home/index': '首頁',
    '/inspection': '巡檢管理',
    '/inspection/index': '巡檢管理',
    '/inspection/record': '巡檢記錄',
    '/inspection/task': '巡檢任務',
    '/inspection/task-no': '巡檢任務單',
    '/inspection/ai': '巡檢軌跡',
    '/message/:type': '更多通知',
    '/order': '工單管理',
    '/order/index': '工單管理',
    '/order/analyze': '工單分析',
    '/order/history': '歷史工單',
    '/order/realtime': '我的工單',
    '/record': '記錄查詢',
    '/record/event/all': '所有事件',
    '/record/event/common': '常規事件',
    '/record/event/heart-beat': '警急事件',
    '/record/event/history': '歷史警告',
    '/record/event/realtime': '實時警告',
    '/record/log': '日誌記錄',
    '/record/notice': '更多通知',
    '/report': '報表管理',
    '/report/index': '報表管理',
    '/datav': '螢幕',
    '/datav/display1': '螢幕',
    '/datav/datascreen': '數字螢幕',
    '/system': '系統管理',
    '/system/access': '接入管理',
    '/system/access/detail/:id/:productVersionId': '產品詳情',
    '/system/area': '區域管理',
    '/system/menu': '目錄管理',
    '/system/permission': '權限管理',
    '/system/push': '推送策略',
    '/system/role': '角色管理',
    '/system/unit': '單位管理',
    '/system/url': '權限URL',
    '/system/user': '用戶管理',
    '/electricity/index': '智慧用電',


    '404': '頁面沒有找到',
    'none': '無',
    'month': '月份',
    'accumulation': '累積量',
    'count': '數量',
    'switchLanguage': '切換語言',

    'province': '省',
    'city': '市',
    'country': '區（縣）',
    'area': '片區',
    'street': '街道',

    'startTime': '開始時間',
    'endTime': '結束時間',
    'happenTime': '發生時間',
    'happenDate': '發生日期',

    'alarmTotal': '警告數量',
    'alarmLevel': '緊急程度：',
    'alarmLevel.all': '全部警告',
    'alarmLevel.generic': '常規事件',
    'alarmLevel.heartBeat': '警急事件',
    'alarmLevel.level1Alarm': '一級警告',
    'alarmLevel.level2Alarm': '二級警告',
    'alarmLevel.level3Alarm': '三級警告',
    'alarmLevel.level4Alarm': '四級警告',
    'alarmLevel.AlarmRecover': '告警恢復',
    'alarmLevel.level1Alarm.value': '非常緊急',
    'alarmLevel.level2Alarm.value': '緊急',
    'alarmLevel.level3Alarm.value': '一般',
    'alarmLevel.level4Alarm.value': '較低',


    'area.quanzhou': '泉州',
    'base.info': '基本信息',
    'realTime.data': '實時數據',
    'result.none': '暫無數據',

    'modalName.toView': '查看',
    'modalName.update': '修改',
    'modalName.add': '新增',


    'device.state.all': '全部',
    'device.state.normal': '正常',
    'device.state.alarm': '告警',
    'device.state.offline': '離線',
    'device.state.lost': '失聯',
    'device.state.notActive': '未激活',
    'device.state.unknown': '未知',

    'device.actionType.normal': '常規',
    'device.actionType.arm': '防護',
    'device.actionType.disarm': '撤防',
    'device.actionType.disAlarm': '接警',


    'device.alarm.message': '警告消息',
    'device.alarm.reason': '警告原因',
    'device.alarm.time': '警告消息',


    'device': '設備',
    'device.countName': '臺',
    'device.info': '設備信息',
    'device.sn': '設備編號',
    'device.name': '設備名稱',
    'device.alarmName': '警告名稱',
    'device.happenTime': '發生時間',
    'device.productName': '產品名稱',
    'device.status': '設備狀態',
    'device.alarmStatus': '防護狀態',
    'device.alarmStatus.success': '已防護',
    'device.alarmStatus.error': '已撤防',
    'device.areaName': '區域名稱',
    'device.area': '所屬區域',
    'device.unitName': '所屬單位',
    'device.unit': '管理單位',
    'device.dataState': '啓用狀態',
    'device.dataState.true': '啟用',
    'device.dataState.false': '禁用',
    'device.address': '安裝地址',
    'device.infoAddress': '詳細地址',
    'device.installLocation': '安裝位置',
    'device.type': '設備類型',
    'device.count': '設備數量',
    'device.heatBeatTime': '數據變更',

    'product.key': '產品標識',
    'product.model': '產品型號',
    'product.type': '產品類別',
    'product.createTime': '創建時間',
    'product.status': '產品狀態',
    'product.manufactureName': '廠家名稱',
    'product.version': '產品版本',


    'order': '工單',
    'order.countName': '條',
    'order.sn': '工單編號',
    'order.name': '工單名稱',
    'order.state': '工單狀態',
    'order.state.unHandle': '未處理',
    'order.state.handle': '已處理',
    'order.handleUserName': '處理人',
    'order.happenTime': '發生時間',


    'inspection.taskNo': '任務編號',
    'inspection.device.sn': '設備編號',
    'inspection.unit': '所屬單位',
    'inspection.userName': '巡檢人員',
    'inspection.time': '巡檢時間',
    'inspection.endTime': '結束时间',
    'inspection.state': '巡檢狀態',
    'inspection.state.endInspect': '已巡檢',
    'inspection.state.startInspect': '未巡檢',
    'inspection.result': '巡檢結果',
    'inspection.resultInfo': '巡檢意見',
    'inspection.address': '詳細地址',
    'inspection.image': '現場照片',

    'image1': '設備圖',
    'image2': '標籤圖',
    'image3': '安裝圖',
    'image4': '項目圖',

    'order.handleMethod.ignore': '忽略',
    'order.handleMethod.error': '錯誤',
    'order.handleMethod.replaceDevice': '更換設備',
    'order.handleMethod.replaceParts': '更換部件',
    'order.handleMethod.repairParts': '維修部件',
    'order.handleMethod.repairDevice': '維修設備',
    'order.handleMethod.other': '其他',

    'order.level.general': '普通',
    'order.level.urgent': '緊急',
    'order.level.serious': '嚴重',

    'order.type.alarm': '報警',
    'order.type.inspect': '檢查',
    'order.type.repair': '維修',

    'system.User': '用戶管理',
    'system.Area': '區域管理',
    'system.Unit': '單位管理',
    'system.Role': '角色管理',


    'button.login': '登入',
    'button.nowLogin': '立即登錄',
    'button.forget': '忘記密碼',
    'button.reset': '確認重置',
    'button.getCode': '獲取驗證碼',
    'button.timeAgain': 's後重試',
    'button.upload': '點擊選擇文件上傳',
    'button.toView': '查看',
    'button.read': '標記為已讀',
    'button.setRead': '設置已讀',
    'button.upload2': '上傳',

    'button.ok': '確定',
    'button.cancel': '取消',
    'button.close': '關閉',
    'button.detail': '詳情',
    'button.search': '查詢',
    'button.import': '導入',
    'button.export': '導出',

    'button.add': '添加',
    'button.create': '新增工單',
    'button.edit': '編輯',
    'button.update': '修改',
    'button.delete': '刪除',
    'button.switch': '選擇',
    'button.operation': '操作',

    'button.goBack': '返回',
    'button.full': '全屏',
    'button.fullExit': '退出全屏',

    'show': '顯示',
    'hidden': '隱藏',

    'radio.true': '是',
    'radio.false': '否',


    'form': '表單',
    'form.account.login.userName': '帳號',
    'form.account.login.pleaseUserName': '請輸入您的帳號',
    'form.account.login.passWord': '密碼',
    'form.account.login.pleasePassWord': '請輸入密碼',
    'form.account.login.imageCode': '驗證碼',
    'form.account.login.pleaseImageCode': '請輸入驗證碼',

    'form.account.forget.phone': '請輸入綁定的手機號碼',
    'form.account.forget.pleasePhone': '請輸入綁定的手機號碼',
    'form.account.forget.phoneCode': '請輸入驗證碼',
    'form.account.forget.pleasePhoneCode': '請輸入驗證碼',
    'form.account.forget.passWord': '請輸入密碼',
    'form.account.forget.pleasePassWord': '請輸入密碼',
    'form.account.forget.pleaseConfirmPassWord': '請輸入確認密碼',
    'form.account.forget.pleaseAgain': '請再次輸入密碼',
    'form.account.forget.passWord.unLike': '兩次密碼不一致',
    'form.account.forget.passWord.min': '密碼最少8位',
    'form.account.forget.passWord.format': '密碼必須由數字和大小寫英文字母組成',
    'form.account.forget.phone.long': '手機號碼長度應為10位',
    'form.account.forget.phone.format': '手機號不合法',


    'form.please': '請輸入',
    'form.pleaseWithArray': '類型的數據（以“,”分隔）',


    'form.device.action': '操作',
    'form.device.action.please': '請選擇操作',

    'form.device.import.productType': '產品類型',
    'form.device.import.productType.please': '請選擇產品類型',
    'form.device.import.productVersion': '產品版本',
    'form.device.import.productVersion.please': '請選擇產品版本',
    'form.device.import.area': '所屬區域',
    'form.device.import.area.please': '請選擇所屬區域',
    'form.device.import.unit': '所屬單位',
    'form.device.import.unit.please': '請選擇所屬單位',
    'form.device.import.file': '文件',
    'form.device.import.file.please': '請選擇文件',


    'form.device.update.name': '設備名稱',
    'form.device.update.name.please': '請輸入設備名稱',
    'form.device.update.sn': '設備編號',
    'form.device.update.sn.please': '請輸入設備編號',
    'form.device.update.productType': '產品類型',
    'form.device.update.productType.please': '請選擇產品類型',
    'form.device.update.productVersion': '產品版本',
    'form.device.update.productVersion.please': '請選擇產品版本',
    'form.device.update.area': '所屬區域',
    'form.device.update.area.please': '請選擇所屬區域',
    'form.device.update.address': '詳細地址',
    'form.device.update.address.please': '請輸入詳細地址',
    'form.device.update.langAndLat': '安裝坐標',
    'form.device.update.langAndLat.please': '請選擇安裝坐標',
    'form.device.update.installLocation': '安裝位置',
    'form.device.update.installLocation.please': '請輸入安裝位置',
    'form.device.update.unit': '所屬單位',
    'form.device.update.unit.please': '請選擇所屬單位',
    'form.device.update.linkman': '聯絡人',
    'form.device.update.linkman.please': '請輸入聯絡人',
    'form.device.update.tel': '聯絡電話',
    'form.device.update.tel.please': '請輸入聯絡電話',


    'form.inspection.task.name': '任務名',
    'form.inspection.task.name.please': '請輸入任務名',
    'form.inspection.task.unit': '巡檢單位',
    'form.inspection.task.unit.please': '請選擇巡檢單位',
    'form.inspection.task.userName': '巡檢人員',
    'form.inspection.task.userName.please': '請選擇巡檢人員',
    'form.inspection.task.area': '巡檢區域',
    'form.inspection.task.area.please': '請選擇巡檢區域',
    'form.inspection.task.productType': '設備類型',
    'form.inspection.task.productType.please': '請選擇設備類型',
    'form.inspection.task.startTime': '開始時間',
    'form.inspection.task.startTime.please': '請選擇開始時間',
    'form.inspection.task.endTime': '結束時間',
    'form.inspection.task.endTime.please': '請選擇結束時間',
    'form.inspection.task.cycle': '定期巡檢',
    'form.inspection.task.cycle.please': '請選擇是否定期巡檢',
    'form.inspection.task.cycleDays': '巡檢週期（天）',
    'form.inspection.task.cycleDays.please': '請輸入巡檢週期',

    'form.order.handleMethod': '處理結果',
    'form.order.handleMethod.please': '請選擇處理結果',
    'form.order.image': '現場照片',
    'form.order.image.please': '請上傳現場照片',
    'form.order.handleRemark': '處理建議',
    'form.order.handleRemark.please': '請輸入處理建議',

    'form.order.name': '工單名稱',
    'form.order.name.please': '請填寫工單名稱',
    'form.order.unit': '所屬單位',
    'form.order.unit.please': '請選擇單位',
    'form.order.user': '處理人員',
    'form.order.user.please': '請選擇處理人員',
    'form.order.area': '所屬區域',
    'form.order.area.please': '請選擇區域',
    'form.order.level': '工單級別',
    'form.order.level.please': '請選擇工單級別',
    'form.order.productType': '設備類型',
    'form.order.productType.please': '請選擇設備類型',
    'form.order.device': '工單設備',
    'form.order.device.please': '請選擇工單設備',

    'form.system.access.upload.iconImage.please': '請選擇需要上傳的圖片',
    'form.system.access.update.evenType.please': '事件類型必選',
    'form.system.access.update.actionType.please': '操作類型必選',
    'form.system.access.version.version.please': '版本必選',

    'form.system.area.type': '區域類型',
    'form.system.area.type.please': '請輸入區域類型',
    'form.system.area.name': '區域名稱',
    'form.system.area.name.please': '請輸入區域名稱',
    'form.system.area.coordinates': '區域坐標',
    'form.system.area.coordinates.please': '請選擇區域坐標',
    'form.system.area.lng': '經度',
    'form.system.area.lat': '緯度',

    'form.system.menu.name': '目錄名稱',
    'form.system.menu.name.please': '請輸入目錄名稱',
    'form.system.menu.url': '網址',
    'form.system.menu.url.please': '請輸入網址',
    'form.system.menu.icon': '圖標',
    'form.system.menu.icon.please': '請選擇圖標',
    'form.system.menu.remark': '備註',
    'form.system.menu.remark.please': '請輸入目錄備註',


    'form.system.permission.resourceId': '資源ID',
    'form.system.permission.id': '主鍵ID',
    'form.system.permission.parentName': '資源名稱',
    'form.system.permission.name': '權限名稱',
    'form.system.permission.name.please': '請輸入權限名稱',
    'form.system.permission.code': '權限代碼',
    'form.system.permission.code.please': '請輸入權限代碼',
    'form.system.permission.description': '權限描述',
    'form.system.permission.description.please': '請輸入描述',
    'form.system.permission.url': '權限URL',


    'form.system.role.name': '角色名稱',
    'form.system.role.name.please': '請輸入角色名稱',
    'form.system.role.permission': '角色權限',


    'form.system.unit.siteName': '站點名稱',
    'form.system.unit.siteName.please': '請輸入站點名稱',
    'form.system.unit.label': '站點副標題',
    'form.system.unit.label.please': '請輸入站點副標題',
    'form.system.unit.name': '單位名稱',
    'form.system.unit.name.please': '請輸入單位名稱',
    'form.system.unit.linkman': '負責人',
    'form.system.unit.linkman.please': '請輸入負責人',
    'form.system.unit.tel': '手機號碼',
    'form.system.unit.tel.please': '請輸入手機號碼',
    'form.system.unit.maxUserLimit': '最大用戶數',
    'form.system.unit.maxUserLimit.please': '請輸入最大用戶數',
    'form.system.unit.devPerms': '產品權限',
    'form.system.unit.devPerms.please': '請選擇設備權限',
    'form.system.unit.remark': '備註',
    'form.system.unit.remark.please': '請輸入備註',
    'form.system.unit.webLogo': '請上傳Web端圖標',
    'form.system.unit.webLogo.please': '請上傳web端圖標',
    'form.system.unit.appLogo': '請上傳App端圖標',
    'form.system.unit.appLogo.please': '請上傳app端圖標',

    'form.system.url.id': '權限ID',
    'form.system.url.description': 'URL描述',
    'form.system.url.url': 'URL地址',
    'form.system.url.description.please': '請上傳app端圖標',

    'form.system.user.unti': '所屬單位',
    'form.system.user.unti.please': '請選擇所屬單位',
    'form.system.user.realName': '暱稱',
    'form.system.user.realName.please': '請輸入暱稱',
    'form.system.user.username': '用戶名',
    'form.system.user.username.please': '請輸入帳號',
    'form.system.user.username.long.min': '帳號長度至少6個字符',
    'form.system.user.username.long.max': '帳號長度至多20個字符',
    'form.system.user.password': '密碼',
    'form.system.user.password.please': '請輸入密碼',
    'form.system.user.password.please.none': '請輸入密碼(留空則不修改密碼)',
    'form.system.user.passwordAga': '確認密碼',
    'form.system.user.passwordAga.please': '請輸入確認密碼',
    'form.system.user.passwordAga.please.none': '請輸入確認密碼(留空則不修改密碼)',
    'form.system.user.pwdExpriredTimeType': '密碼時效',
    'form.system.user.pwdExpriredTimeType.1': '90天后過期',
    'form.system.user.pwdExpriredTimeType.0': '永久',
    'form.system.user.tel': '手機號碼',
    'form.system.user.tel.please': '請輸入手機號碼',
    'form.system.user.email': '電子信箱',
    'form.system.user.email.please': '請輸入電子信箱',
    'form.system.user.role': '角色',
    'form.system.user.role.please': '請選擇角色',
    'form.system.user.area': '所屬區域',
    'form.system.user.area.please': '請選擇所屬區域',
    'form.system.user.userIcon': '請點選用戶圖標',
    'form.system.user.userIcon.please': '請上傳用戶圖標',
    'form.system.user.dataState.please': '請選擇用戶狀態',
    'form.system.user.dataState.enable': '啟用',
    'form.system.user.dataState.disabled': '禁用',


    'device.modalName.add': '新增設備',
    'device.modalName.detail': '設備詳情',
    'device.modalName.update': '修改設備',
    'device.modalName.analyze': '通道分析',
    'device.modalName.import': '導入設備',
    'device.modalName.operation': '遠程控制',
    'device.modalName.statistical': '設備狀態統計',
    'device.button.arm': '防護',
    'device.button.disarm': '撤防',
    'device.button.enable': '啟用',
    'device.button.disable': '停用',
    'device.button.finishAlarm': '警情處理',
    'device.button.delete': '拆除',
    'device.button.add': '報裝',
    'device.button.statistical.show': '展開統計',
    'device.button.statistical.hidden': '收縮統計',

    'device.tabs.params': '狀態訊息',
    'device.tabs.event': '事件記錄',
    'device.tabs.alarm': '警告記錄',
    'device.tabs.status': '狀態記錄',
    'device.tabs.action': '操作記錄',
    'device.tabs.control': '控制记录',


    'device.analyzeColumns.fieldList': '查詢通道',
    'device.analyzeColumns.count': '查詢數量',


    'home.modalName.area': '區域選擇',
    'home.modalName.unit': '單位選擇',
    'home.modalName.device': '設備列表',
    'home.modalName.alarm': '告警詳情',
    'home.modalName.operation': '操作詳情',


    'inspection.task.modalName.toView': '巡檢任務詳情',
    'inspection.task.modalName.update': '巡檢任務修改',
    'inspection.task.modalName.add': '新增',

    'inspection.record.modalName.toView': '巡檢設備詳情',

    'inspection.taskNo.modalName.toView': '巡檢設備',
    'inspection.trajectory.modalName.toView': '巡檢設備',

    'message.tabs.install': '報裝信息',
    'message.tabs.alarm': '告警信息',
    'message.tabs.order': '工單信息',
    'message.tabs.inspect': '巡檢信息',
    'message.tabs.system': '系統消息',
    'message.tabs.setReadAll': '全部設置已讀',


    'order.modalName.dayGrowthChart': '工單增長趨勢',
    'order.modalName.orderStatusChart': '工單狀態佔比',
    'order.modalName.dealTypeChart': '已結束工單處理方式佔比',
    'order.modalName.orderDeviceChart': '已結束工單設備佔比',
    'order.modalName.orderTypeChart': '已結束工單類型占比',

    'order.modalName.check': '工單詳情',
    'order.modalName.deal': '工單處理',


    'order.tab.all': '全部工單',
    'order.tab.unAssign': '待指派',
    'order.tab.unHandle': '待處理',
    'order.tab.handle': '已處理',
    'order.tab.unConfirm': '待確認',
    'order.tab.confirm': '已確認',
    'order.tab.finish': '已結束',

    'order.tabItem.name': '今日新增',


    'message.mobileCode.success': '驗證碼發送成功',
    'message.passWordReset.success': '密碼重置成功',
    'message.productType.none': '請先選擇產品類型',

    'message.deviceTable.none': '請選擇工單設備',

    'message.upload.icon.none': '請選擇圖標',
    'message.upload.file.png.typeError': '您只能上傳格式為png的圖片',
    'message.upload.file.png.max': '您只能上傳小於2M的圖片',
    'message.upload.file.success': '上傳成功',
    'message.uint.maxUser': '該單位最大用戶剩餘',

    'message.userName.exist': '帳號已存在',
    'message.userName.chinese': '用戶名不能有中文',
    'message.userName.longError': '帳號長度為4-20',
    'message.realName.longError': '昵稱長度為4-20',
    'message.phone.exist': '手機號碼已存在',
    'message.phone.longError': '手機碼長度應為10位',
    'message.phone.formatError': '手機號碼不合法',
    'message.email.exist': '電子信箱已存在',
    'message.email.none': '請輸入電子信箱地址',
    'message.email.formatError': '電子信箱不合法',
    'message.password.different': '兩次密碼輸入不一致',
    'message.password.long.min': '密碼最少8位',
    'message.password.formatError': '密碼必須由數字和大小寫英文字母組成',
    'message.password.sameWithName': '密碼不能與用戶名相同',
    'message.unitCount.full': '該單位可新增人數已滿',

    'message.global.modifyPwd.success': '修改成功，請重新登入',
    'message.global.modifyPwd.error': '舊密碼錯誤',
    'message.global.setRead.success': '已設為已讀',
    'message.global.setReadBatch.success': '已全部設置為已讀',
    'message.device.changeState.success': '變更成功',
    'message.home.changeState.success': '變更成功',
    'message.home.changeState.error': '變更失敗',
    'message.address.error': '請選擇地址',


    'order.realtime.alarmInfo.title': '警告訊息',
    'order.realtime.alarmInfo.eventName': '事件名稱',
    'order.realtime.alarmInfo.alarmName': '告警名稱',
    'order.realtime.alarmInfo.time': '發生時間',
    'order.realtime.alarmInfo.props': '事件參數',
    'order.realtime.alarmInfo.prop': '附加參數',

    'order.realtime.deviceInfo.title': '設備訊息',
    'order.realtime.deviceInfo.name': '設備名字',
    'order.realtime.deviceInfo.sn': '設備編號',
    'order.realtime.deviceInfo.area': '所屬區域',
    'order.realtime.deviceInfo.address': '詳細地址',
    'order.realtime.deviceInfo.unit': '所屬單位',

    'order.realtime.userInfo.title': '指派訊息',
    'order.realtime.userInfo.userName': '指派人',
    'order.realtime.userInfo.handleUserName': '被指派人',
    'order.realtime.userInfo.time': '指派時間',


    'order.history.alarmInfo.title': '警告訊息',
    'order.history.alarmInfo.eventName': '事件名稱',
    'order.history.alarmInfo.alarmName': '告警名稱',
    'order.history.alarmInfo.time': '發生時間',
    'order.history.alarmInfo.props': '事件參數',
    'order.history.alarmInfo.prop': '附加參數',

    'order.history.deviceInfo.title': '設備訊息',
    'order.history.deviceInfo.name': '設備名字',
    'order.history.deviceInfo.sn': '設備編號',
    'order.history.deviceInfo.area': '所屬區域',
    'order.history.deviceInfo.address': '詳細地址',
    'order.history.deviceInfo.unit': '所屬單位',

    'order.history.userInfo.title': '指派訊息',
    'order.history.userInfo.userName': '指派人',
    'order.history.userInfo.handleUserName': '被指派人',
    'order.history.userInfo.time': '指派時間',

    'order.history.handleInfo.title': '處理訊息',
    'order.history.handleInfo.handleUserName': '處理人',
    'order.history.handleInfo.handleTime': '處理時間',

    'order.history.confirmInfo.title': '確認信息',
    'order.history.confirmInfo.confirmUserName': '確認人',
    'order.history.confirmInfo.confirmTime': '確認時間',

    'order.history.complete.title': '完成訊息',
    'order.history.complete.handleUserName': '處理人',
    'order.history.complete.handleResult': '處理結果',
    'order.history.complete.handleOpinion': '處理意見',
    'order.history.complete.image': '現場照片',
    'order.history.complete.time': '完成時間',


    'report.modalName.EquipmentStatePie': '設備狀態統計',
    'report.modalName.EquipmentTypeBar': '設備數量統計',
    'report.modalName.EvenList': '最新警告',
    'report.modalName.AlarmGraph': '告警增長趨勢',
    'report.modalName.OrderStatePie': '工單狀態統計',
    'report.modalName.OrderHandleWayPie': '工單處理方式統計',
    'report.modalName.OrderTypePie': '工單類型統計',
    'report.modalName.OrderHandleList': '最新工單',
    'report.modalName.OrderCountGraph': '工單增長趨勢',
    'report.modalName.DeviceGraph': '設備增長趨勢',

    'screen.total': '總數',
    'screen.handleTotal': '處理數',
    'screen.online': '在線',
    'screen.alarm': '告警',


    'access.modalName.uploadProductIcon': '上傳產品圖標',
    'access.modalName.selectProductIcon': '選擇產品圖標',
    'access.modalName.setEventType': '設置事件類型',
    'access.modalName.switchVersion': '切換版本',


    'menu.modalName.add': '新增目錄',
    'menu.modalName.update': '修改目錄',
    'menu.modalName.select': '選擇目錄圖標',

    'permission.modalName.add': '新增資源',
    'permission.modalName.update': '修改資源',

    'role.modalName.add': '新增角色',
    'role.modalName.update': '更新角色',
    'role.modalName.toView': '查看角色',

    'unit.modalName.create': '新增單位',
    'unit.modalName.update': '修改單位',
    'unit.modalName.toView': '查看單位',

    'url.modalName.add': '新增權限',
    'url.modalName.update': '修改權限',

    'user.modalName.create': '新增用戶',
    'user.modalName.update': '修改用戶',
    'user.modalName.toView': '查看用戶',


    'access.tabs.prop': '屬性',
    'access.tabs.action': '操作',
    'access.tabs.event': '事件',
    'access.tabs.common': '常規',


    'spin.tip.Login': '登入中...',
    'spin.tip.Forget': '處理中...',

    'dataTable.num': '序號',
    'dataTable.sum.left': '共',
    'dataTable.sum.right': '條',
    //======================表格頭部======================
    'table.baseColumns.operation': '操作',
    'table.baseColumns.eye': '查看',
    'table.baseColumns.add': '添加',
    'table.baseColumns.create': '創建',
    'table.baseColumns.edit': '編輯',
    'table.baseColumns.delete': '刪除',
    'table.baseColumns.handle': '處理',
    'table.baseColumns.analyze': '通道分析',
    'table.baseColumns.startTime': '開始時間',
    'table.baseColumns.endTime': '結束時間',
    'table.baseColumns.createdTime': '創建時間',
    //---------------------設備-----------------------
    'table.device.detail.actionColumns.icon': '標識',
    'table.device.detail.actionColumns.deviceSn': '設備編號',
    'table.device.detail.actionColumns.deviceName': '設備名稱',
    'table.device.detail.actionColumns.actionName': '操作名稱',
    'table.device.detail.actionColumns.inputParams': '輸入參數',
    'table.device.detail.actionColumns.time': '操作時間',

    'table.device.detail.historyColumns.productIcon': '圖標',
    'table.device.detail.historyColumns.productName': '產品名稱',
    'table.device.detail.historyColumns.eventName': '告警名稱',
    'table.device.detail.historyColumns.eventType': '緊急程度',
    'table.device.detail.historyColumns.deviceSn': '設備編號',
    'table.device.detail.historyColumns.deviceName': '設備名稱',
    'table.device.detail.historyColumns.deviceAddress': '設備地址',
    'table.device.detail.historyColumns.time': '發生時間',
    'table.device.detail.historyColumns.handleState': '處理狀態',


    'table.device.detail.alarmColumns.icon': '標示',
    'table.device.detail.alarmColumns.deviceSn': '設備編號',
    'table.device.detail.alarmColumns.deviceName': '設備名稱',
    'table.device.detail.alarmColumns.eventName': '事件名稱',
    'table.device.detail.alarmColumns.time': '發生時間',

    'table.device.detail.statusColumns.deviceSn': '設備編號',
    'table.device.detail.statusColumns.deviceName': '設備名稱',
    'table.device.detail.statusColumns.time': '變更時間',

    'table.device.detail.searchDateColumns.startTime': '開始時間',
    'table.device.detail.searchDateColumns.endTime': '結束時間',

    'table.device.index.defaultColumns.productIcon': '圖標',
    'table.device.index.defaultColumns.productName': '產品名稱',
    'table.device.index.defaultColumns.sn': '設備編號',
    'table.device.index.defaultColumns.name': '設備名稱',
    'table.device.index.defaultColumns.dataState': '啓用狀態',
    'table.device.index.defaultColumns.dataState.true': '啟用',
    'table.device.index.defaultColumns.dataState.false': '停用',
    'table.device.index.defaultColumns.status': '設備狀態',
    'table.device.index.defaultColumns.status.normal': '正常',
    'table.device.index.defaultColumns.status.alarm': '告警',
    'table.device.index.defaultColumns.status.lose': '失聯',
    'table.device.index.defaultColumns.status.offline': '離線',
    'table.device.index.defaultColumns.status.notActive': '未激活',
    'table.device.index.defaultColumns.status.other': '未知',
    'table.device.index.defaultColumns.status.all': '全部',
    'table.device.index.defaultColumns.armStatus': '防護狀態',
    'table.device.index.defaultColumns.armStatus.true': '已防護',
    'table.device.index.defaultColumns.armStatus.false': '已撤防',
    'table.device.index.defaultColumns.areaName': '所屬區域',
    'table.device.index.defaultColumns.unitName': '所屬單位',
    'table.device.index.defaultColumns.address': '詳細地址',
    'table.device.index.defaultColumns.deleteDevice': '確認刪除設備',
    //--------------------巡檢-----------------------
    'table.inspection.record.defaultColumns.inspectTaskNo': '任務編號',
    'table.inspection.record.defaultColumns.productIcon': '產品圖標',
    'table.inspection.record.defaultColumns.productName': '設備類型',
    'table.inspection.record.defaultColumns.deviceSn': '設備編號',
    'table.inspection.record.defaultColumns.inspectUserName': '巡檢人員',
    'table.inspection.record.defaultColumns.inspectTime': '巡檢時間',
    'table.inspection.record.defaultColumns.state': '巡檢狀態',
    'table.inspection.record.defaultColumns.state.processing': '已巡檢',
    'table.inspection.record.defaultColumns.state.error': '未巡檢',
    'table.inspection.record.defaultColumns.inspectMethodName': '巡檢方式',
    'table.inspection.record.defaultColumns.inspectResultName': '巡檢結果',
    'table.inspection.record.defaultColumns.inspectResultName.normal': '正常',
    'table.inspection.record.defaultColumns.inspectResultName.repair': '報修',
    'table.inspection.record.defaultColumns.deviceAddr': '詳細地址',
    'table.inspection.record.defaultColumns.unitName': '所屬單位',
    'table.inspection.record.defaultColumns.areaName': '巡檢區域',

    'table.inspection.task.defaultColumns.inspectNo': '任務序號',
    'table.inspection.task.defaultColumns.name': '任務名稱',
    'table.inspection.task.defaultColumns.inspectUserDtoList': '巡檢人員',
    'table.inspection.task.defaultColumns.cycle': '定期巡檢',
    'table.inspection.task.defaultColumns.cycleDays': '巡檢週期（天）',
    'table.inspection.task.defaultColumns.startTime': '開始時間',
    'table.inspection.task.defaultColumns.endTime': '結束时间',
    'table.inspection.task.defaultColumns.areaName': '巡檢區域',
    'table.inspection.task.defaultColumns.unitName': '巡檢單位',

    'table.inspection.taskNo.taskData.inspectTaskNo': '任務編號',
    'table.inspection.taskNo.taskData.inspectName': '任務名稱',
    'table.inspection.taskNo.taskData.inspectUserDtoList': '巡檢人員',
    'table.inspection.taskNo.taskData.areaName': '巡檢區域',
    'table.inspection.taskNo.taskData.unitName': '巡檢單位',
    'table.inspection.taskNo.taskData.state': '任務狀態',
    'table.inspection.taskNo.taskData.state.processing': '進行中',
    'table.inspection.taskNo.taskData.state.finish': '已結束',
    'table.inspection.taskNo.taskData.total': '巡檢總數',
    'table.inspection.taskNo.taskData.finishTotal': '已巡檢數',
    'table.inspection.taskNo.taskData.ratioEndInspect': '完成進度',
    'table.inspection.taskNo.taskData.repairTotal': '報修數量',

    'table.inspection.taskNo.recordData.inspectTaskNo': '任務編號',
    'table.inspection.taskNo.recordData.productIcon': '產品圖標',
    'table.inspection.taskNo.recordData.productName': '設備類型',
    'table.inspection.taskNo.recordData.deviceSn': '設備編號',
    'table.inspection.taskNo.recordData.unitName': '所屬單位',
    'table.inspection.taskNo.recordData.inspectUserName': '巡檢人員',
    'table.inspection.taskNo.recordData.inspectTime': '巡檢時間',
    'table.inspection.taskNo.recordData.state': '巡檢狀態',
    'table.inspection.taskNo.recordData.state.processing': '已巡檢',
    'table.inspection.taskNo.recordData.state.error': '未巡檢',
    'table.inspection.taskNo.recordData.inspectMethodName': '巡檢意見',
    'table.inspection.taskNo.recordData.inspectResultName': '巡檢結果',
    'table.inspection.taskNo.recordData.inspectResultName.normal': '正常',
    'table.inspection.taskNo.recordData.inspectResultName.repair': '報修',
    'table.inspection.taskNo.recordData.deviceAddr': '詳細地址',

    'table.inspection.trajectory.taskData.no': '任務編號',
    'table.inspection.trajectory.taskData.inspectName': '任務名稱',
    'table.inspection.trajectory.taskData.inspectUserName': '巡檢人員',
    'table.inspection.trajectory.taskData.areaName': '巡檢區域',
    'table.inspection.trajectory.taskData.unitName': '巡檢單位',
    'table.inspection.trajectory.taskData.totalInspect': '巡檢總數',
    'table.inspection.trajectory.taskData.totalPatroled': '已巡檢數',
    'table.inspection.trajectory.taskData.ratioEndInspect': '完成進度',
    'table.inspection.trajectory.taskData.totalRepair': '報修數量',

    'table.inspection.trajectory.recordData.inspectTaskNo': '任務編號',
    'table.inspection.trajectory.recordData.productIcon': '產品圖標',
    'table.inspection.trajectory.recordData.productName': '設備類型',
    'table.inspection.trajectory.recordData.deviceSn': '設備編號',
    'table.inspection.trajectory.recordData.unitName': '所屬單位',
    'table.inspection.trajectory.recordData.inspectUserName': '巡檢人員',
    'table.inspection.trajectory.recordData.inspectionTime': '巡檢時間',
    'table.inspection.trajectory.recordData.inspectionMethod': '巡檢方式',
    'table.inspection.trajectory.recordData.inspectionMethod.true': '手動',
    'table.inspection.trajectory.recordData.inspectionMethod.false': '自動',
    'table.inspection.trajectory.recordData.state': '巡檢狀態',
    'table.inspection.trajectory.recordData.state.processing': '已巡檢',
    'table.inspection.trajectory.recordData.state.error': '未巡檢',
    'table.inspection.trajectory.recordData.processResult': '巡檢結果',
    'table.inspection.trajectory.recordData.processResult.true': '正常',
    'table.inspection.trajectory.recordData.processResult.false': '報修',
    'table.inspection.trajectory.recordData.inspectResultInfo': '巡檢意見',
    'table.inspection.trajectory.recordData.deviceAddr': '詳細地址',
    //------------------------通知----------------------- -----
    'table.message.defaultColumns.readState': '是否已讀',
    'table.message.defaultColumns.readState.null': '全部',
    'table.message.defaultColumns.readState.read': '已讀',
    'table.message.defaultColumns.readState.unRead': '未讀',
    'table.message.defaultColumns.type': '消息類型',
    'table.message.defaultColumns.type.alarm': '告警信息',
    'table.message.defaultColumns.type.install': '報裝信息',
    'table.message.defaultColumns.type.sysMsg': '系統消息',
    'table.message.defaultColumns.type.null.search': '全部',
    'table.message.defaultColumns.type.install.search': '設備報裝',
    'table.message.defaultColumns.type.alarm.search': '告警信息',
    'table.message.defaultColumns.type.sysMsg.search': '系統通知',
    'table.message.defaultColumns.time': '發生時間',
    'table.message.defaultColumns.time.search': '起止時間',
    'table.message.defaultColumns.data': '訊息',
    //------------------------工單--------------------
    'table.order.history.defaultColumns.no': '工單編號',
    'table.order.history.defaultColumns.name': '工單名稱',
    'table.order.history.defaultColumns.level': '工單等級',
    'table.order.history.defaultColumns.level.urgent': '緊急',
    'table.order.history.defaultColumns.level.serious': '嚴重',
    'table.order.history.defaultColumns.level.general': '普通',
    'table.order.history.defaultColumns.state': '工單狀態',
    'table.order.history.defaultColumns.state.unAssign': '待指派',
    'table.order.history.defaultColumns.state.unHandle': '待處理',
    'table.order.history.defaultColumns.state.unConfirm': '待確認',
    'table.order.history.defaultColumns.state.finish': '已結束',
    'table.order.history.defaultColumns.type': '工單類型',
    'table.order.history.defaultColumns.type.alarm': '警告',
    'table.order.history.defaultColumns.type.repair': '維修',
    'table.order.history.defaultColumns.type.inspect': '巡檢',
    'table.order.history.defaultColumns.unitName': '所屬單位',
    'table.order.history.defaultColumns.areaName': '所屬區域',

    'table.order.realtime.orderColumns.no': '工單編號',
    'table.order.realtime.orderColumns.name': '工單名稱',
    'table.order.realtime.orderColumns.level': '工單等級',
    'table.order.realtime.orderColumns.level.urgent': '緊急',
    'table.order.realtime.orderColumns.level.serious': '嚴重',
    'table.order.realtime.orderColumns.level.general': '普通',
    'table.order.realtime.orderColumns.state': '工單狀態',
    'table.order.realtime.orderColumns.state.unAssign': '待指派',
    'table.order.realtime.orderColumns.state.unHandle': '待處理',
    'table.order.realtime.orderColumns.state.unConfirm': '待確認',
    'table.order.realtime.orderColumns.state.finish': '已結束',
    'table.order.realtime.orderColumns.type': '工單類型',
    'table.order.realtime.orderColumns.type.alarm': '告警',
    'table.order.realtime.orderColumns.type.repair': '維修',
    'table.order.realtime.orderColumns.type.inspect': '巡檢',
    'table.order.realtime.orderColumns.unitName': '所屬單位',
    'table.order.realtime.orderColumns.areaName': '所屬區域',

    'table.order.realtime.deviceColumns.name': '設備名稱',
    'table.order.realtime.deviceColumns.sn': '設備編號',
    'table.order.realtime.deviceColumns.unitName': '所屬單位',
    'table.order.realtime.deviceColumns.linkman': '負責人',
    'table.order.realtime.deviceColumns.address': '安裝地址',
    //-------------------------記錄---------------------- -------
    'table.record.event.all.defaultColumns.productIcon': '圖標',
    'table.record.event.all.defaultColumns.productName': '產品名稱',
    'table.record.event.all.defaultColumns.eventName': '事件名稱',
    'table.record.event.all.defaultColumns.deviceSn': '設備編號',
    'table.record.event.all.defaultColumns.deviceName': '設備名稱',
    'table.record.event.all.defaultColumns.eventType': '事件類型',
    'table.record.event.all.defaultColumns.eventType.generic': '常規事件',
    'table.record.event.all.defaultColumns.eventType.heartBeat': '警急事件',
    'table.record.event.all.defaultColumns.eventType.alarmRecover': '告警恢復',
    'table.record.event.all.defaultColumns.eventType.level1Alarm': '一級警告',
    'table.record.event.all.defaultColumns.eventType.level2Alarm': '二級警告',
    'table.record.event.all.defaultColumns.eventType.level3Alarm': '三級警告',
    'table.record.event.all.defaultColumns.eventType.level4Alarm': '四級警告',
    'table.record.event.all.defaultColumns.time': '發生時間',
    'table.record.event.all.defaultColumns.deviceAddress': '設備地址',

    'table.record.event.common.defaultColumns.productIcon': '圖標',
    'table.record.event.common.defaultColumns.productName': '產品名稱',
    'table.record.event.common.defaultColumns.eventName': '事件名稱',
    'table.record.event.common.defaultColumns.deviceSn': '設備編號',
    'table.record.event.common.defaultColumns.deviceName': '設備名稱',
    'table.record.event.common.defaultColumns.time': '發生時間',

    'table.record.event.heart.defaultColumns.productIcon': '圖標',
    'table.record.event.heart.defaultColumns.productName': '產品名稱',
    'table.record.event.heart.defaultColumns.eventName': '事件名稱',
    'table.record.event.heart.defaultColumns.deviceSn': '設備編號',
    'table.record.event.heart.defaultColumns.deviceName': '設備名稱',
    'table.record.event.heart.defaultColumns.time': '發生時間',

    'table.record.event.history.defaultColumns.productIcon': '圖標',
    'table.record.event.history.defaultColumns.productName': '產品名稱',
    'table.record.event.history.defaultColumns.eventName': '告警名稱',
    'table.record.event.history.defaultColumns.eventType': '緊急程度',
    'table.record.event.history.defaultColumns.deviceSn': '設備編號',
    'table.record.event.history.defaultColumns.deviceName': '設備名稱',
    'table.record.event.history.defaultColumns.deviceAddress': '設備地址',
    'table.record.event.history.defaultColumns.time': '發生時間',
    'table.record.event.history.defaultColumns.handleState': '處理狀態',

    'table.record.event.realtime.defaultColumns.productIcon': '圖標',
    'table.record.event.realtime.defaultColumns.productName': '產品名稱',
    'table.record.event.realtime.defaultColumns.eventName': '告警名稱',
    'table.record.event.realtime.defaultColumns.eventType': '緊急程度',
    'table.record.event.realtime.defaultColumns.deviceSn': '設備編號',
    'table.record.event.realtime.defaultColumns.deviceName': '設備名稱',
    'table.record.event.realtime.defaultColumns.deviceAddress': '設備地址',
    'table.record.event.realtime.defaultColumns.time': '發生時間',
    'table.record.event.realtime.defaultColumns.handleState': '處理狀態',

    'table.record.log.defaultColumns.username': '帳號',
    'table.record.log.defaultColumns.type': '操作類型',
    'table.record.log.defaultColumns.remoteAddr': '用戶IP',
    'table.record.log.defaultColumns.requestUri': '請求Uri',
    'table.record.log.defaultColumns.method': '請求方式',
    'table.record.log.defaultColumns.content': '操作內容',
    'table.record.log.defaultColumns.time': '操作時間',

    'table.record.notice.defaultColumns.readState': '已讀狀態',
    'table.record.notice.defaultColumns.readState.null': '全部',
    'table.record.notice.defaultColumns.readState.read': '已讀',
    'table.record.notice.defaultColumns.readState.unread': '未讀',
    'table.record.notice.defaultColumns.type': '通知類型',
    'table.record.notice.defaultColumns.type.alarm': '警告通知',
    'table.record.notice.defaultColumns.type.install': '安裝通知',
    'table.record.notice.defaultColumns.type.sysMsg': '系統通知',
    'table.record.notice.defaultColumns.type.inspect': '巡檢通知',
    'table.record.notice.defaultColumns.type.order': '工單通知',
    'table.record.notice.defaultColumns.type.recoverAlarm': '告警恢復通知',
    'table.record.notice.defaultColumns.type.unInstall': '拆除通知',
    'table.record.notice.defaultColumns.type.null.search': '全部',
    'table.record.notice.defaultColumns.type.install.search': '設備報裝',
    'table.record.notice.defaultColumns.type.alarm.search': '警告通知',
    'table.record.notice.defaultColumns.type.sysMsg.search': '系統通知',
    'table.record.notice.defaultColumns.type.inspect.search': '巡檢通知',
    'table.record.notice.defaultColumns.type.order.search': '工單通知',
    'table.record.notice.defaultColumns.type.recoverAlarm.search': '告警恢復通知',
    'table.record.notice.defaultColumns.type.unInstall.search': '拆除通知',
    'table.record.notice.defaultColumns.time': '通知時間',
    'table.record.notice.defaultColumns.data': '通知内容',
    //--------------------------大屏-------------------- -
    'table.screen.defaultColumns.productIcon': '標識',
    'table.screen.defaultColumns.productName': '產品名稱',
    'table.screen.defaultColumns.sn': '設備編號',
    'table.screen.defaultColumns.name': '設備名稱',
    'table.screen.defaultColumns.dataState': '停用狀態',
    'table.screen.defaultColumns.dataState.enable': '啟用',
    'table.screen.defaultColumns.dataState.disable': '停用',
    'table.screen.defaultColumns.status': '設備狀態',
    'table.screen.defaultColumns.status.normal': '正常',
    'table.screen.defaultColumns.status.alarm': '告警',
    'table.screen.defaultColumns.status.lose': '失聯',
    'table.screen.defaultColumns.status.offline': '離線',
    'table.screen.defaultColumns.status.notActive': '未激活',
    'table.screen.defaultColumns.status.other': '未知',
    'table.screen.defaultColumns.armStatus': '防護狀態',
    'table.screen.defaultColumns.armStatus.true': '已防護',
    'table.screen.defaultColumns.armStatus.false': '已撤防',
    'table.screen.defaultColumns.areaName': '區域',
    'table.screen.defaultColumns.unitName': '單位',
    'table.screen.defaultColumns.data': '信息',
    //---------------------系統--------------------
    'table.system.access.list.defaultColumns.productKey': '產品標識',
    'table.system.access.list.defaultColumns.productIcon': '字體圖標',
    'table.system.access.list.defaultColumns.productIconImage': '產品圖標',
    'table.system.access.list.defaultColumns.productName': '產品名稱',
    'table.system.access.list.defaultColumns.productModel': '產品型號',
    'table.system.access.list.defaultColumns.manufactureName': '廠家名稱',
    'table.system.access.list.defaultColumns.lastVersionName': '版本',
    'table.system.access.list.defaultColumns.authorized': '啓用狀態',
    'table.system.access.list.defaultColumns.authorized.true': '啓用',
    'table.system.access.list.defaultColumns.authorized.false': '禁用',

    'table.system.access.detail.propColumns.icon': '字體圖標',
    'table.system.access.detail.propColumns.icon.switch': '選擇字體圖標',
    'table.system.access.detail.propColumns.name': '屬性名稱',
    'table.system.access.detail.propColumns.show': '是否顯示',
    'table.system.access.detail.propColumns.dataType': '數據類型',
    'table.system.access.detail.propColumns.valueRange': '取值範圍',
    'table.system.access.detail.propColumns.remark': '備注',

    'table.system.access.detail.actionColumns.name': '操作名稱',
    'table.system.access.detail.actionColumns.actionType': '操作類型',
    'table.system.access.detail.actionColumns.actionType.modalName': '設置操作類型',
    'table.system.access.detail.actionColumns.remark': '備註',

    'table.system.access.detail.eventColumns.name': '事件名稱',
    'table.system.access.detail.eventColumns.type': '類型',
    'table.system.access.detail.eventColumns.type.modalName': '設置事件類型',
    'table.system.access.detail.eventColumns.remark': '備註',

    'table.system.area.defaultColumns.name': '區域名稱',

    'table.system.menu.defaultColumns.name': '目錄名稱',
    'table.system.menu.defaultColumns.remark': '備註',
    'table.system.menu.defaultColumns.icon': '圖標',
    'table.system.menu.defaultColumns.url': '路由',

    'table.system.permission.defaultColumns.resourceName': '資源名稱',
    'table.system.permission.defaultColumns.permissionDtoList': '資源權限',

    'table.system.role.roleColumns.name': '角色名稱',

    'table.system.role.editPermColumns.resourceName': '資源名稱',
    'table.system.role.editPermColumns.permissionDtoList': '資源權限',

    'table.system.role.addPermColumns.resourceName': '資源名稱',
    'table.system.role.addPermColumns.permissionDtoList': '資源權限',

    'table.system.unit.defaultColumns.name': '單位名稱',
    'table.system.unit.defaultColumns.linkman': '負責人',
    'table.system.unit.defaultColumns.tel': '手機號碼',
    'table.system.unit.defaultColumns.maxUserLimit': '最大用戶數',
    'table.system.unit.defaultColumns.siteName': '站點名稱',
    'table.system.unit.defaultColumns.label': '站點副標題',
    'table.system.unit.defaultColumns.remark': '備註',

    'table.system.url.defaultColumns.description': 'URL描述',
    'table.system.url.defaultColumns.url': 'URL地址',

    'table.system.user.defaultColumns.avatar': '頭像',
    'table.system.user.defaultColumns.realName': '暱稱',
    'table.system.user.defaultColumns.username': '用戶名',
    'table.system.user.defaultColumns.email': '郵箱',
    'table.system.user.defaultColumns.roleName': '角色',
    'table.system.user.defaultColumns.tel': '手機號碼',
    'table.system.user.defaultColumns.areaName': '所屬區域',
    'table.system.user.defaultColumns.unitName': '所屬單位',
    'table.system.user.defaultColumns.dataState': '激活狀態',
    'table.system.user.defaultColumns.dataState.null': '全部',
    'table.system.user.defaultColumns.dataState.enable': '啟用',
    'table.system.user.defaultColumns.dataState.disable': '禁用',


};
export default zh_CHT;
