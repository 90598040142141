import { HttpUtils } from 'utils/http-utils'
import { Page, ParamsId } from 'httpRequest/interface'
import * as UnitGroupUserInterface from './interface'
namespace UnitGroupUser {
    const BaseUrl = "/api/sys/unit/group/user/";
    export const List = (params: UnitGroupUserInterface.UnitGroupUserListParams): Promise<Page<UnitGroupUserInterface.SelectUserInfo>> => {
        return HttpUtils.Get(BaseUrl + `list`, params);
    }
    export const UnSelectList = (params: UnitGroupUserInterface.UnitGroupUserUnSelectListParams): Promise<Page<UnitGroupUserInterface.UnSelectUserInfo>> => {
        return HttpUtils.Get(BaseUrl + `un-select-list`, params);
    }
    export const Create = (params: { unitGroupId: string, userIdList: Array<string> }): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + `create`, params);
    }
    export const Delete = (params: ParamsId): Promise<boolean> => {
        return HttpUtils.Post(BaseUrl + `delete`, params);
    }
}
export { UnitGroupUser, UnitGroupUserInterface };