import React, { FC, useState } from "react";
import styles from "./video-div.module.less";
import EZUIKit from "ezuikit-js";
import { Button } from "antd";
import classNames from "classnames";

export const VideoSwitch: FC<Omit<IProps, `type`>> = (props) => {
  const [state, setState] = useState<{ type: `security` | `voice` }>({
    type: `security`,
  });
  const { type } = state;
  return (
    <div className={classNames(styles.videoMain)}>
      <VideoDiv key={type} {...props} type={type} />
      <div className={styles.buttons}>
        <Button
          type={type === `security` ? `primary` : `default`}
          className={styles.button}
          onClick={() => {
            setState({ type: `security` });
          }}
        >
          默认模式
        </Button>
        <Button
          type={type === `voice` ? `primary` : `default`}
          className={styles.button}
          onClick={() => {
            setState({ type: `voice` });
          }}
        >
          对讲模式
        </Button>
      </div>
    </div>
  );
};

interface IProps {
  accessToken: string;
  sn: string;
  type: `security` | `voice`;
}
interface IState {
  isPlayr: boolean;
  loadding: boolean;
}

class VideoDiv extends React.PureComponent<IProps, IState> {
  state: IState = {
    isPlayr: true,
    loadding: false,
  };
  playr: any | undefined = undefined; //定义播放器
  componentDidMount() {
    console.info(EZUIKit.state.countTimer);
    (window as any).EZUIKit = EZUIKit;
    console.info((window as any).EZUIKit.state.countTimer);
    this.open();
  }
  componentDidUpdate(last: IProps) {
    if (this.playr) {
      // this.playr.destory();
      // this.open();
    }
  }
  componentWillUnmount() {
    if (this.playr) {
      try {
        const { state } = (window as any).EZUIKit;
        if (state.recodeTimer > 0) {
          clearInterval(state.recodeTimer);
          state.recodeTimer = undefined;
        }
        if (state.countTimer > 0) {
          clearInterval(state.countTimer);
          state.countTimer = undefined;
        }
        this.playr.stopTalk();
        this.playr.stop();
        delete (window as any).EZUIKit;
      } catch (error) {}
    }
  }
  open = () => {
    const { sn, accessToken, type } = this.props;
    console.info(`type`, type);
    this.playr = new EZUIKit.EZUIKitPlayer({
      id: `video-container-${sn}`,
      accessToken: accessToken,
      apiDomain: ``,
      template: type,
      audio: 0,
      plugin: [`talk`],
      isReliesReady: true,
      url: `ezopen://open.ys7.com/${sn}/1.live`,
      handleTalkError: () => {
        console.info(`handleTalkError`);
      },
      openSoundCallBack: () => {
        console.info(`openSoundCallBack`);
      },
      closeSoundCallBack: () => {
        console.info(`closeSoundCallBack`);
      },
      capturePictureCallBack: () => {
        console.info(`capturePictureCallBack`);
      },
      startSaveCallBack: () => {
        console.info(`startSaveCallBack`);
      },
      stopSaveCallBack: () => {
        console.info(`stopSaveCallBack`);
      },
      fullScreenCallBack: (arg: any) => {
        console.info(`fullScreenCallBack`);
      },
      cancelFullScreenCallBack: () => {
        console.info(`cancelFullScreenCallBack`);
      },
      getOSDTimeCallBack: () => {
        console.info(`getOSDTimeCallBack`);
      },
      handleSuccess: () => {
        console.info(`handleSuccess`);
      },
      handleError: (err?: any) => {},
      onHistroyCallBack: () => {
        console.info(`onHistroyCallBack`);
      },
    });
  };

  render() {
    const { sn } = this.props;

    return (
      <React.Fragment>
        <div id={`video-container-${sn}`} className={styles.video}></div>
      </React.Fragment>
    );
  }
}
export { VideoDiv };
