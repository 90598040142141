import classNames from 'classnames';
import React from 'react';
import styles from './common-box.module.less';

class CommonBox extends React.PureComponent<{ className?: string }> {
    render() {
        const { className, children } = this.props
        return (
            <div className={classNames(className, styles.boxMain)}>
                <div className={styles.body}>
                    {children}
                </div>
            </div>
        );
    }
}

export { CommonBox };