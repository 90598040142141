export default class PasswordLevel {
    password = "";

    constructor(password) {
        if (password)
            this.password = password;
    }

    getLength() {
        const length = this.password.length;
        // console.info("密码长度：", length);
        return length;
    }

    getUpLetterCount() {
        const password = this.password;
        // 计算密码包含多少个数字
        let upLetterCount = 0;
        if (/[A-Z]/.test(password)) {
            upLetterCount = password.match(/[A-Z]/g).length;
        }
        // console.info("大写长度：", upLetterCount);
        return upLetterCount;
    }

    getLowLetterCount() {
        const password = this.password;
        // 计算密码包含多少个数字
        let lowLetterCount = 0;
        if (/[a-z]/.test(password)) {
            lowLetterCount = password.match(/[a-z]/g).length;
        }
        // console.info("小写长度：", lowLetterCount);
        return lowLetterCount;
    }

    getNumberCount() {
        const password = this.password;
        // 计算密码包含多少个数字
        let numberCount = 0;
        if (/[0-9]/.test(password)) {
            numberCount = password.match(/[0-9]/g).length;
        }
        // console.info("数字长度：", numberCount);
        return numberCount;
    }

    getSymbolCount() {
        const password = this.password;
        // 计算密码包含多少个数字
        let symbolCount = 0;
        symbolCount = this.getLength() - this.getNumberCount() - this.getLowLetterCount() - this.getUpLetterCount();
        // console.info("符号长度：", symbolCount);
        return symbolCount;
    }

    getScore() {
        let score = 0;
        const length = this.getLength();
        const upLetterCount = this.getUpLetterCount();
        const lowLetterCount = this.getLowLetterCount();
        const numberCount = this.getNumberCount();
        const symbolCount = this.getSymbolCount();


        // 长度得分
        if (length == 4) score += 5;
        else if (5 <= length && length < 8) score += 10;
        else if (length >= 8) score += 25;

        // 大小写得分
        if (upLetterCount > 0) score += 10;
        if (lowLetterCount > 0) score += 10;

        // 数字得分
        if (numberCount == 1) score += 10;
        else if (numberCount >= 2) score += 25;

        // 符号得分
        if (symbolCount == 1) score += 10;
        else if (symbolCount >= 2) score += 25;

        // 奖励分
        //5分： 大小写字母  数字 符号
        if (upLetterCount > 0 && lowLetterCount > 0 && numberCount > 0 && symbolCount > 0) score += 5;
        //3分： 字母        数字 符号
        else if (upLetterCount + lowLetterCount > 0 && numberCount > 0 && symbolCount > 0) score += 3;
        //2分： 字母        数字
        else if ((upLetterCount + lowLetterCount > 0 && numberCount > 0)) score += 2;
        console.info("最终得分：", score);
        return score;

    }


}