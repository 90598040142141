import { DataSourceType, ParamsId } from "httpRequest/interface";
import { DeviceInterface } from "httpRequest/tokiot/device";
import { AlarmInterface } from "httpRequest/tokiot/device/alarm";
import { UserInterface } from "httpRequest/tokiot/sys/user";
import { PushMessageInterface } from "httpRequest/tokiot/sys/push-message";
export type RoutePath =
  | `/home`
  | `/visualization`
  | `/device`
  | `/control`
  | `/data`
  | `/fault`
  | `/log`
  | `/report`
  | `/system`
  | `/about`;
export interface MenuItem {
  name: string;
  key: RoutePath;
  children?: Array<MenuItem>;
}
export interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}
type Refresh<T> = (param?: T) => void;
class RefreshClass<T = {}> {
  private chageList: Array<Refresh<T>> = [];
  public AddFun(fun: Refresh<T>) {
    if (this.chageList.findIndex((f1) => f1 === fun) < 0) {
      this.chageList.push(fun);
    }
  }
  public Refresh(param?: T) {
    if (this.chageList.length > 0) {
      this.chageList.forEach((f1) => f1(param));
    }
  }
}
class CommonParameterModal {
  /** 单例 */
  private static readonly self = new CommonParameterModal();
  public static GetCommonParameterModal = (): CommonParameterModal => {
    return CommonParameterModal.self;
  };

  /** 选中设备 */
  public SetSelectDevice?: (selectDevice?: DeviceInterface.DeviceInfo) => void;
  /** 选中告警 */
  public SetSelectAlarm?: (
    selectDevice?: AlarmInterface.AlarmInfo | DeviceInterface.DeviceInfo
  ) => void;
  /** 选中推送消息 */
  public SetSelectPushInfo?: (
    selectPushMessage?: PushMessageInterface.PushMessage
  ) => void;
  /** 刷新用户信息 */
  public RefreshUserInfo?: () => void;
  /** 比较ID是否改变 */
  public isChange = (data1?: ParamsId, data2?: ParamsId) => {
    let id1 = undefined;
    let id2 = undefined;
    if (data1 && data1.id) {
      id1 = data1.id;
    }
    if (data2 && data2.id) {
      id2 = data2.id;
    }
    return id1 !== id2;
  };
  /** 告警状态改变 */
  public AlarmChange = new RefreshClass<string>();
  /** 设备状态改变 */
  public DeviceChange = new RefreshClass();
  /** 告警推送 */
  public AlarmPush = new RefreshClass<AlarmInterface.AlarmInfo>();
  /** 推送改变 */
  public PushChange = new RefreshClass();

  MenuList: Array<MenuItem> = [
    {
      name: `首页`,
      key: `/home`,
    },
    {
      name: `可视化`,
      key: `/visualization`,
    },
    {
      name: `设备管理`,
      key: `/device`,
    },
    {
      name: `远程控制`,
      key: `/control`,
    },
    {
      name: `数据采集`,
      key: `/data`,
    },
    {
      name: `故障报警`,
      key: `/fault`,
    },
    {
      name: `日志管理`,
      key: `/log`,
    },
    {
      name: `报表管理`,
      key: `/report`,
    },
    {
      name: `系统管理`,
      key: `/system`,
    },
    {
      name: `关于我们`,
      key: `/about`,
    },
  ];
}

let CommonParameter = CommonParameterModal.GetCommonParameterModal();
class CommonUnit {
  //HTML编码转义
  static ParseFromString = (value: string, defValue: string) => {
    let paramsString = defValue;
    const parser = new DOMParser();
    if (typeof value === `string` && value.length > 0) {
      const dom = parser.parseFromString(value, "text/html");
      paramsString = dom.body.textContent ?? defValue;
    }
    return paramsString;
  };
}

export { CommonParameter, CommonUnit };
