import { HttpUtils } from "utils/http-utils";
import { Page, ParamsId } from "httpRequest/interface";
import * as UnitInfoInterface from "./interface";
namespace Unit {
  const BaseUrl = "/api/sys/unit/";
  export const Get = (
    params: ParamsId
  ): Promise<UnitInfoInterface.UnitInfo> => {
    return HttpUtils.Get(BaseUrl + `get`, params);
  };
  export const GetPanel = (
    params: ParamsId
  ): Promise<UnitInfoInterface.UnitPanel> => {
    return HttpUtils.Get(BaseUrl + `get-panel`, params);
  };
  export const Tree = (params?: {
    parentId?: string;
  }): Promise<UnitInfoInterface.UnitInfo> => {
    return HttpUtils.Get(BaseUrl + `tree`, params);
  };
  export const AsyncList = (
    params?: UnitInfoInterface.AsyncListParam
  ): Promise<Page<UnitInfoInterface.UnitInfo>> => {
    return HttpUtils.Get(BaseUrl + `async-list`, params);
  };
  export const ListMap = (
    params: UnitInfoInterface.ListMapParams
  ): Promise<Page<UnitInfoInterface.UnitTotalInfo>> => {
    return HttpUtils.Get(BaseUrl + `list-map`, params);
  };

}
export { Unit, UnitInfoInterface };
